// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentVersion: 'dev',
  appUrl: 'https://staging.ritning.harjedalskok.se',
  oauthLoginUrl: 'https://testrapport.harjedalskok.se/oauth/authorize',
  oauthTokenUrl: 'https://testrapport.harjedalskok.se/oauth/token',
  oauthCallbackUrl: 'https://staging.ritning.harjedalskok.se/oauth/callback',
  oauthVerifyToken: 'https://testrapport.harjedalskok.se/oauth/verifytoken',
  oauthRefreshToken: 'https://testrapport.harjedalskok.se/oauth/refreshtoken',
  validationUrl: 'https://testrapport.harjedalskok.se/drawing/validations',
  quoteUrl: 'https://testrapport.harjedalskok.se/drawing/quote/',
  userSettings: 'https://testrapport.harjedalskok.se/drawing/usersettings/',
  productOrderUrl: 'https://testrapport.harjedalskok.se/drawing/productorder/',
  autoUpdateProductOrderUrl:
    'https://testrapport.harjedalskok.se/drawing/autoupdateproductorder/',
  updateProductOrderUrl: 'https://testrapport.harjedalskok.se/drawing/updateproductorder/',
  showProductOrder: 'https://testrapport.harjedalskok.se/products/showproductorder/',
  products: 'https://testrapport.harjedalskok.se/drawing/products',
  productFile: 'https://testrapport.harjedalskok.se/drawing/productfile/',
  pdfUpload: 'https://testrapport.harjedalskok.se/drawing/uploadpdf',
  log: 'https://testrapport.harjedalskok.se/drawing/log',
  // appUrl: 'https://staging.ritning.harjedalskok.se',
  // oauthLoginUrl: 'https://rapport.harjedalskok.se/test/oauth/authorize',
  // oauthTokenUrl: 'https://rapport.harjedalskok.se/test/oauth/token',
  // oauthCallbackUrl: 'https://staging.ritning.harjedalskok.se/oauth/callback',
  // oauthVerifyToken: 'https://rapport.harjedalskok.se/test/oauth/verifytoken',
  // oauthRefreshToken: 'https://rapport.harjedalskok.se/test/oauth/refreshtoken',
  // validationUrl: 'https://rapport.harjedalskok.se/test/drawing/validations',
  // quoteUrl: 'https://rapport.harjedalskok.se/test/drawing/quote/',
  // userSettings: 'https://rapport.harjedalskok.se/test/drawing/usersettings/',
  // productOrderUrl: 'https://rapport.harjedalskok.se/test/drawing/productorder/',
  // autoUpdateProductOrderUrl:
  //   'https://rapport.harjedalskok.se/test/drawing/autoupdateproductorder/',
  // updateProductOrderUrl:
  //   'https://rapport.harjedalskok.se/test/drawing/updateproductorder/',
  // showProductOrder:
  //   'https://rapport.harjedalskok.se/test/products/showproductorder/',
  // products: 'https://rapport.harjedalskok.se/test/drawing/products',
  // productFile: 'https://rapport.harjedalskok.se/test/drawing/productfile/',
  // pdfUpload: 'https://rapport.harjedalskok.se/test/drawing/uploadpdf',
  // log: 'https://rapport.harjedalskok.se/test/drawing/log',
  oauthClientId: 'drawing',
  secret: 'secret',
  // temporary tokens

  tempAuth:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1MDEzIiwiYXVkIjoic3RhZ2luZy5yaXRuaW5nLmhhcmplZGFsc2tvay5zZSIsIm5iZiI6MTY3OTg1NDQxMCwiaXNzIjoiaHR0cHM6XC9cL3JhcHBvcnQuaGFyamVkYWxza29rLnNlXC90ZXN0IiwiZXhwIjoxNjc5OTI2NDEwLCJpYXQiOjE2Nzk4NTQ0MTAsImNsaWVudF9pZCI6ImRyYXdpbmciLCJqdGkiOiJhMzBjNTc0OC05ZGM3LTQ3YjctODE2MC03NTZiY2JmODExNjEifQ.WpedsVmRSVDuGYSDvbzIoDfNaCJKbJvO6FEyFUC5npKDOl0GWmdNXRB8f7pOk-dUKr9na2P1-6r-mRp-GVlxhRbP6u0bXVzZMCa8h6oHcwkRXQ5qS2y_ysnwomfVSL3E3_4LeWfvfmxEww0mhkWARfbqb5XWj27X6Z7o7Gvst6NcLqm838VXR4IJ4uZ2pTsWLgztyf0dGpJ2oghA2MXOfBYdYh8060UI_uHtuu3QjSpY_nErrO5cHgOj1uVPxjUieiN9yE14X0T-Cg6q4rM0EP17dT54DxHihEhpZPzAlqLUysBs2_Ksdyott8oPOKP42EruWLOOpE_ofJZvleXa1pdY8ztd-5WKOG0K1eMVqWal6JjpY0aZbk9cCWUu1Vp-UrNNsF6lcl1POwQ2_Nv-yKPLNpT9XmdAWb64uDilF8TwuEq2DuL_rUoW3Iz1wvyq-lkWUy15FAHbTq8uWag6Xk46TkcTpEOwkrmWtb5dCtRbPOt3wmNiCOJDWJ9MsWB6oBRY1NDcjA5rIsocAMR3Dyaqc8CYaNud3EyKBru618Q35Ti_k-MjhRQFfdALEmRXXIFSjFCywQtV5uVQdFLy2WkLkPkskTjZkPqX6bgyNkAGoI54UwGmmydixjv_YCzppHtGR_1leLjdL52UvdnPkJfMQOflCelLyYS4vOEHia0',
  tempRefresh:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1MDEzIiwiZXhwIjoxNjI2OTMwNDA3LCJjbGllbnRfaWQiOiJkcmF3aW5nIn0.XckllgEhoQL7BEwf4bbyOYgG05cxKDGC-BMb1DbafchaYCBdFtdzof10IFk0jjQwTm3vM6RO_7XZIzfsBwf13-kNknHNft8iBrZ90YpNYjDus21m-hZpa4obO50iDzbSvpmrrtJhdr0kbgjaYvuZShg4L3Xm6tdkbnpCXzCzhQ8UQfxfdz3IAX9jecWPAZT2pDAQCQQTOCQ9F6oiDm77kKyw0ex7rmtjVsWuoDNplbsN2Ct8jYHOAwFTSxpRafkVyw7TjYgBmleb1ukNIY4RiAJPvwxSKGv5LkOkXSzwPzlxKytoVhvtgtjhaUCesPZCJmZNrn9STWx8JkAvX68LUWHvlce5B2De_Cj_BnhzPGQ8CAm0bdz-uWdnVXlAdfUxmHp0OQEPVa92-HHopksny4t3GOSL2-cQUQr9JJmql4ypXNEbIelS50X5EdyC_ip1x7hG5iVoOeyjj14BwblVQv5r43HDtG_sUJphz2P5qMg9KbiWGVYWxA5JCpN3LrIG5mzTP7TOUPio11uoj7ygxrqkHJ5Bh3ZUlXmymXKHjfkJdMunCiH9f5MemcCD_f2ao4QwhER-cd8YVC3AiYseW4PlerE9ftH545FAm6bFNt8TmEZUQEL-qS4DeTpT9Ch--xtXjbdbbucnJ93-CiEZvgiYbjtwb2MjcIt1TSu_tks',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
