import { Layer } from 'konva/types/Layer';
import { MeasureLine } from '../model/measureLine';
import { Product } from '../model/product';

export class MeasurementCollisionDetectionUtil {
  static relocateMeasurementFlags(layers: Layer[]) {
    layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        this.fitMeasurements(product);
      });
    });
  }

  static fitMeasurements(product: Product) {
    const sortedLines = [];
    product.find('.measurement-line').each((measurement: MeasureLine) => {
      if (
        sortedLines[0] &&
        sortedLines[0].lengthValue < measurement.lengthValue
      ) {
        sortedLines.push(measurement);
      } else {
        sortedLines.unshift(measurement);
      }
    });

    const wLineIndex = sortedLines.findIndex((line: MeasureLine) => {
      return line.additionalText == 'b: ';
    });
    if (wLineIndex != -1) {
      const widthLine = sortedLines[wLineIndex];
      sortedLines.splice(wLineIndex, 1);
      sortedLines.unshift(widthLine);
    }

    sortedLines.forEach((line: MeasureLine, i: number) => {
      if (
        line.flagPosition.x < 0 ||
        line.flagPosition.x + line.flagDimensions.x > line.attrs.width
      ) {
        line.changeFlagOrientation();
        line.updateFlagPosition();
      }
      if (line.flagPosition.y + line.flagDimensions.y > line.attrs.height) {
        line.flagPosition.y = line.attrs.height - line.flagDimensions.y;
      }
      if (line.flagPosition.y < 0) {
        line.flagPosition.y = 0;
      }

      sortedLines.forEach((line2: MeasureLine, j: number) => {
        if (i > j) {
          if (line.checkIfVertical()) {
            if (
              line.flagDimensions.y + line.flagPosition.y >
                line2.flagPosition.y &&
              line.flagPosition.y <
                line2.flagPosition.y + line2.flagDimensions.y &&
              line.flagDimensions.x + line.flagPosition.x >
                line2.flagPosition.x &&
              line.flagPosition.x <
                line2.flagPosition.x + line2.flagDimensions.x
            ) {
              line.flagPosition.y =
                line2.flagPosition.y - line.flagDimensions.y - 3;
            }
          } else {
            if (
              line.flagDimensions.y + line.flagPosition.y >
                line2.flagPosition.y &&
              line.flagPosition.y <
                line2.flagPosition.y + line2.flagDimensions.y &&
              line.flagDimensions.x + line.flagPosition.x >
                line2.flagPosition.x &&
              line.flagPosition.x <
                line2.flagPosition.x + line2.flagDimensions.x
            ) {
              line.flagPosition.x =
                line2.flagPosition.x - line.flagDimensions.x - 3;
            }
          }
        }
      });
    });
  }
}
