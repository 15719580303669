import { Component, OnDestroy } from '@angular/core';
import { LogConsts } from 'src/app/consts/logConsts';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-general-settings-menu',
  templateUrl: './general-settings-menu.component.html',
  styleUrls: ['./general-settings-menu.component.scss'],
})
export class GeneralSettingsMenuComponent implements OnDestroy {
  generalSettings: GeneralSettings = new GeneralSettings(null);
  generalSettingsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private logService: LogService
  ) {
    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe(
        (generalSettings: GeneralSettings) => {
          this.generalSettings = generalSettings;
        }
      );
  }

  ngOnDestroy(): void {
    this.generalSettingsEmitterSub.unsubscribe();
  }

  updateSettings(): void {
    this.navbarService.generalSettingsEmitter.emit(this.generalSettings);
    console.log(this.generalSettings);
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Updating general settings')
      .subscribe(() => {});
  }
}
