import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  constructor(private httpClient: HttpClient) {}

  getQuote(quoteId: string): Observable<any> {
    const headers = new HttpHeaders();

    return this.httpClient.get(environment.quoteUrl + quoteId, {
      headers,
    });
  }
}
