import { EventEmitter, Injectable } from '@angular/core';
import { Alert } from '../model/alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alertEmitter = new EventEmitter<Alert>();
  alertResponseEmitter = new EventEmitter<string>();

  constructor() {}
}
