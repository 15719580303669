export class ValidationLevel {
  advanced: boolean;
  basic: boolean;
  name: string;
  validationLevelID: number;

  constructor(
    advanced: boolean,
    basic: boolean,
    name: string,
    validationLevelID: number
  ) {
    this.advanced = advanced;
    this.basic = basic;
    this.name = name;
    this.validationLevelID = validationLevelID;
  }
}
