import Konva from 'konva';
import { Shape } from 'konva/types/Shape';
import { Vector2d } from 'konva/types/types';
import { ProductSettings } from '../productSettings';
import { SpecialParams } from '../specialParams';

export class Cabin extends Konva.Group {
  settings: ProductSettings;
  specialParams: SpecialParams;
  isFictional: boolean;
  fictionalType: string;
  perspective = false;
  lean = false;

  constructor(
    context,
    settings: ProductSettings,
    isFictional: boolean,
    fictionalType: string,
    specialParams: SpecialParams,
    perspective?: boolean,
    lean?: boolean
  ) {
    super(context);
    this.attrs.name = 'cabin';
    this.settings = settings;
    this.fictionalType = fictionalType;
    this.specialParams = specialParams;
    this.perspective = perspective;
    this.lean = lean;
    if (isFictional) {
      this.drawFictionalCabin();
    } else if (this.perspective) {
      this.drawPerspectiveCabin();
    } else {
      this.drawCabin();
    }
    // cutoffs
    // topLeft: 2
    // topRight: 3
    // bottomRight: 4
    // bottomLeft: 5
  }

  drawCabin(): void {
    if (
      !this.settings ||
      !this.settings.WIDTH ||
      (!this.settings.HEIGHT && !this.settings.CABIN_HEIGHT)
    ) {
      return;
    }

    let w = 20;
    if (this.lean) {
      w = 5;
    }

    if (isNaN(this.attrs.height)) {
      this.attrs.height = 0;
    }

    let twd = 1;
    let tp = 0;
    let rhd = 1;
    let rp = 0;
    let bwd = 1;
    let bp = 0;
    let lhd = 1;
    let lp = 0;

    let aw = 0;
    let ah = 0;
    let ax = 0;
    let ay = 0;

    let bw = 0;
    let bh = 0;
    let bx = 0;
    let by = 0;

    let fa = 0;
    let fb = 0;
    let fc = 0;
    let fd = 0;

    let fya = 0;
    let fyb = 0;

    let p = this.attrs.width / 4;
    let b = this.attrs.height / 2;

    switch (this.settings.CUT_OFF_PLACEMENT) {
      case '2':
        twd = 1.3333;
        tp = this.attrs.width / 4;
        lhd = 2;
        lp = this.attrs.height / 2;

        aw = tp + 20;
        ah = 20;
        ax = 0;
        ay = lp;

        bw = 20;
        bh = lp;
        bx = tp;
        by = 0;

        fa = p;
        fb = p + 20;
        fc = 20;
        fd = 0;

        fya = 0;
        fyb = b;
        break;
      case '3':
        twd = 1.3333;
        rhd = 2;
        rp = this.attrs.height / 2;

        aw = p;
        ah = 20;
        ax = 3 * p;
        ay = b;

        bw = 20;
        bh = b;
        bx = 3 * p;
        by = 0;

        fa = 3 * p - 20;
        fb = 3 * p;
        fc = this.attrs.width;
        fd = this.attrs.width - 20;

        fya = 0;
        fyb = b;

        break;
      case '4':
        rhd = 2;
        bwd = 1.3333;

        aw = p;
        ah = 20;
        ax = 3 * p;
        ay = b;

        bw = 20;
        bh = b;
        bx = 3 * p;
        by = b;

        fb = this.attrs.width - 20;
        fa = this.attrs.width;
        fc = 3 * p - 20;
        fd = 3 * p;

        fya = b;
        fyb = this.attrs.height;

        break;
      case '5':
        bwd = 1.3333;
        bp = this.attrs.width / 4;
        lhd = 2;

        aw = p + 20;
        ah = 20;
        ax = 0;
        ay = b;

        bw = 20;
        bh = b;
        bx = p;
        by = b;

        fa = 0;
        fb = 20;
        fc = p + 20;
        fd = p;

        fya = b;
        fyb = this.attrs.height;

        break;
    }

    const leftHeatshield = this.settings.HEATSHIELD_LEFTSIDE === '12' ? 12 : 0;
    const rightHeatshield =
      this.settings.HEATSHIELD_RIGHTSIDE === '12' ? 12 : 0;
    // later we will have custom cabin walls where top or bottom wall can have different thickness
    //  this should be changed in the constructor

    this.add(
      new Konva.Rect({
        width: this.attrs.width,
        height: this.attrs.height,
        name: 'rect',
        fill: 'rgb(239,239,239)',
      })
    );

    if (
      this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
      this.settings.CUT_OFF_TYPE == 2
    ) {
      this.add(
        new Konva.Rect({
          x: ax,
          y: ay,
          width: aw,
          height: ah,
          name: 'prodStroke',
        })
      );
      this.add(
        new Konva.Rect({
          x: bx,
          y: by,
          width: bw,
          height: bh,
          name: 'prodStroke',
        })
      );
    } else if (
      this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
      this.settings.CUT_OFF_TYPE == 1
    ) {
      this.add(
        new Konva.Shape({
          sceneFunc(context, shape) {
            context.beginPath();
            context.moveTo(fa, fya);
            context.lineTo(fb, fya);
            context.lineTo(fc, fyb);
            context.lineTo(fd, fyb);
            context.closePath();
            context.fillStrokeShape(shape);
          },
          fill: 'black',
          name: 'prodStroke',
        })
      );
    }
    // top
    this.add(
      new Konva.Rect({
        x: tp,
        y: 0,
        width: this.attrs.width / twd,
        height: w,
        name: 'prodStroke',
      })
    );

    // bottom
    if (this.settings.CABIN_BOTTOM_HEIGHT) {
      this.add(
        new Konva.Rect({
          x: bp,
          y: this.attrs.height - Number(this.settings.CABIN_BOTTOM_HEIGHT),
          width: this.attrs.width / bwd,
          height: Number(this.settings.CABIN_BOTTOM_HEIGHT),
          name: 'prodStroke',
        })
      );
    } else {
      this.add(
        new Konva.Rect({
          x: bp,
          y: this.attrs.height - w,
          width: this.attrs.width / bwd,
          height: w,
          name: 'prodStroke',
        })
      );
    }
    // right
    if (!this.specialParams.hideRightCabin) {
      if (this.settings.CABIN_COVER_RIGHT) {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - Number(this.settings.CABIN_COVER_RIGHT),
            y: rp,
            width: Number(this.settings.CABIN_COVER_RIGHT) - rightHeatshield,
            height: this.attrs.height / rhd,
            name: 'prodStroke',
          })
        );
      } else if (this.attrs.strokeWidth) {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - this.attrs.strokeWidth,
            y: rp,
            width: this.attrs.strokeWidth,
            height: this.attrs.height / rhd,
            name: 'prodStroke',
          })
        );
      } else {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - w,
            y: rp,
            width: w,
            height: this.attrs.height / rhd,
            name: 'prodStroke',
          })
        );
      }
      // right heatshield
      if (rightHeatshield) {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - rightHeatshield,
            y: rp,
            width: 12,
            height: this.attrs.height / rhd,
            fill: 'red',
            name: 'prodStrokeHeat',
            opacity: 0.5,
          })
        );
      }
    }
    // left
    if (!this.specialParams.hideLeftCabin) {
      if (this.settings.CABIN_COVER_LEFT) {
        this.add(
          new Konva.Rect({
            x: leftHeatshield,
            y: lp,
            width: Number(this.settings.CABIN_COVER_LEFT) - leftHeatshield,
            height: this.attrs.height / lhd,
            name: 'prodStroke',
          })
        );
      } else if (this.attrs.strokeWidth) {
        this.add(
          new Konva.Rect({
            x: leftHeatshield,
            y: lp,
            width: this.attrs.strokeWidth,
            height: this.attrs.height / lhd,
            name: 'prodStroke',
          })
        );
      } else {
        this.add(
          new Konva.Rect({
            x: 0,
            y: lp,
            width: w,
            height: this.attrs.height / lhd,
            name: 'prodStroke',
          })
        );
      }

      if (leftHeatshield) {
        this.add(
          new Konva.Rect({
            x: 0,
            y: lp,
            width: 12,
            height: this.attrs.height / lhd,
            fill: 'red',
            name: 'prodStrokeHeat',
            opacity: 0.5,
          })
        );
      }
    }
  }

  drawPerspectiveCabin() {
    if (
      !this.settings ||
      !this.settings.WIDTH ||
      (!this.settings.HEIGHT && !this.settings.CABIN_HEIGHT)
    ) {
      return;
    }

    const w = this.attrs.width;
    const h = this.attrs.height;
    const a = 100;
    const leftHeatshield = this.settings.HEATSHIELD_LEFTSIDE === '12' ? 12 : 0;
    const rightHeatshield =
      this.settings.HEATSHIELD_RIGHTSIDE === '12' ? 12 : 0;

    let twd = 1;
    let tp = 0;
    let tg = 1;
    let rhd = 1;
    let rp = 0;
    let bwd = 1;
    let bp = 0;
    let bg = 1;
    let lhd = 1;
    let lp = 0;

    switch (this.settings.CUT_OFF_PLACEMENT) {
      case '2':
        twd = 1.3333;
        tp = this.attrs.width / 4;
        lhd = 2;
        lp = this.attrs.height / 2;
        this.drawTopLeft();
        break;
      case '3':
        twd = 1.3333;
        rhd = 2;
        rp = this.attrs.height / 2;
        tg = this.attrs.width / 4;
        this.drawTopRight();
        break;
      case '4':
        rhd = 2;
        bwd = 1.3333;
        bg = this.attrs.width / 4;
        this.drawBottomRight();
        break;
      case '5':
        bwd = 1.3333;
        bp = this.attrs.width / 4;
        lhd = 2;
        this.drawBottomLeft();
        break;
    }

    if (this.settings.RIGHT_LEFT === 'RIGHT') {
      this.add(
        new Konva.Shape({
          sceneFunc(context, shape) {
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(w, -a);
            context.lineTo(w, h - a);
            context.lineTo(0, h);
            context.closePath();
            context.fillStrokeShape(shape);
          },
          fill: 'rgb(239,239,239)',
          name: 'rect',
        })
      );
      this.find('.rect').each((r: Shape) => {
        r.moveToBottom();
      });
      this.drawCustomShape([
        { x: tp, y: 25 - tp / twd },
        { x: w - tg, y: -a + 25 + tg / twd },
        { x: w - tg, y: -a + tg / twd },
        { x: tp, y: 0 - tp / twd },
      ]);
      this.drawCustomShape([
        { x: bp, y: h - 25 - bp / bwd },
        { x: w - bg, y: h - a - 25 + bg / bwd },
        { x: w - bg, y: h - a + bg / bwd },
        { x: bp, y: h - bp / bwd },
      ]);
      if (this.settings.CABIN_COVER_LEFT) {
        this.add(
          new Konva.Rect({
            x: leftHeatshield,
            y: 5 + lp,
            width: Number(this.settings.CABIN_COVER_LEFT),
            height: this.attrs.height / lhd - 25,
            name: 'prodStroke',
          })
        );
      } else if (this.attrs.strokeWidth) {
        this.add(
          new Konva.Rect({
            x: leftHeatshield,
            y: 5 + lp,
            width: this.attrs.strokeWidth,
            height: this.attrs.height / lhd - 25,
            name: 'prodStroke',
          })
        );
      } else {
        this.add(
          new Konva.Rect({
            x: 0,
            y: 5 + lp,
            width: 20,
            height: this.attrs.height / lhd - 25,
            name: 'prodStroke',
          })
        );
      }

      if (!this.specialParams.hideRightCabin) {
        if (this.settings.CABIN_COVER_RIGHT) {
          this.add(
            new Konva.Rect({
              x:
                this.attrs.width -
                Number(this.settings.CABIN_COVER_RIGHT) -
                rightHeatshield,
              y: -a + 20 + rp,
              width: Number(this.settings.CABIN_COVER_RIGHT),
              height: this.attrs.height / rhd - 30,
              name: 'prodStroke',
            })
          );
        } else if (this.attrs.strokeWidth) {
          this.add(
            new Konva.Rect({
              x: this.attrs.width - this.attrs.strokeWidth,
              y: -a + 20 + rp,
              width: this.attrs.strokeWidth,
              height: this.attrs.height / rhd - 30,
              name: 'prodStroke',
            })
          );
        } else {
          this.add(
            new Konva.Rect({
              x: this.attrs.width - 20,
              y: -a + 20 + rp,
              width: 20,
              height: this.attrs.height / rhd - 30,
              name: 'prodStroke',
            })
          );
        }
      }
    } else {
      this.add(
        new Konva.Shape({
          sceneFunc(context, shape) {
            context.beginPath();
            context.moveTo(0, -a);
            context.lineTo(w, 0);
            context.lineTo(w, h);
            context.lineTo(0, h - a);
            context.closePath();
            context.fillStrokeShape(shape);
          },
          fill: 'rgb(239,239,239)',
          name: 'rect',
        })
      );

      this.find('.rect').each((r: Shape) => {
        r.moveToBottom();
      });

      this.drawCustomShape([
        { x: tp, y: -a + 25 + tp / twd },
        { x: tp, y: -a + tp / twd },
        { x: w - tg, y: 0 - tg / twd },
        { x: w - tg, y: 25 - tg / twd },
      ]);

      this.drawCustomShape([
        { x: bp, y: h - a + bp / bwd },
        { x: bp, y: h - a - 25 + bp / bwd },
        { x: w - bg, y: h - 25 - bg / bwd },
        { x: w - bg, y: h - bg / bwd },
      ]);
      if (this.settings.CABIN_COVER_RIGHT) {
        this.add(
          new Konva.Rect({
            x:
              this.attrs.width -
              Number(this.settings.CABIN_COVER_RIGHT) -
              rightHeatshield,
            y: 10 + rp,
            width: Number(this.settings.CABIN_COVER_RIGHT),
            height: this.attrs.height / rhd - 30,
            name: 'prodStroke',
          })
        );
      } else if (this.attrs.strokeWidth) {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - this.attrs.strokeWidth,
            y: 10 + rp,
            width: this.attrs.strokeWidth,
            height: this.attrs.height / rhd - 30,
            name: 'prodStroke',
          })
        );
      } else {
        this.add(
          new Konva.Rect({
            x: this.attrs.width - 20,
            y: 10 + rp,
            width: 20,
            height: this.attrs.height / rhd - 30,
            name: 'prodStroke',
          })
        );
      }

      if (!this.specialParams.hideLeftCabin) {
        if (this.settings.CABIN_COVER_LEFT) {
          this.add(
            new Konva.Rect({
              x: leftHeatshield,
              y: -a + 20 + lp,
              width: Number(this.settings.CABIN_COVER_LEFT),
              height: this.attrs.height / lhd - 20,
              name: 'prodStroke',
            })
          );
        } else if (this.attrs.strokeWidth) {
          this.add(
            new Konva.Rect({
              x: leftHeatshield,
              y: -a + 20 + lp,
              width: this.attrs.strokeWidth,
              height: this.attrs.height / lhd - 20,
              name: 'prodStroke',
            })
          );
        } else {
          this.add(
            new Konva.Rect({
              x: 0,
              y: -a + 20 + lp,
              width: 20,
              height: this.attrs.height / lhd - 20,
              name: 'prodStroke',
            })
          );
        }
      }
    }
  }

  drawFictionalCabin() {
    this.deleteCabin();
    this.add(
      new Konva.Rect({
        width: this.attrs.width,
        height: this.attrs.height,
        name: 'rect',
      })
    );

    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.attrs.width,
        height: 20,
        name: 'prodStroke',
      })
    );

    if (!this.specialParams.hideRightCabin) {
      this.add(
        new Konva.Rect({
          x: Number(this.attrs.width - this.attrs.strokeWidth),
          y: 0,
          width: 20,
          height: Number(this.attrs.height),
          name: 'prodStroke',
        })
      );
    }

    this.add(
      new Konva.Rect({
        x: 0,
        y: this.attrs.height - 20,
        width: this.attrs.width,
        height: 20,
        name: 'prodStroke',
      })
    );

    if (!this.specialParams.hideLeftCabin) {
      this.add(
        new Konva.Rect({
          x: 0,
          y: 0,
          width: 20,
          height: this.attrs.height,
          name: 'prodStroke',
        })
      );
    }

    if (this.fictionalType === 'oven') {
      this.drawOven();
    } else if (this.fictionalType === 'microwave') {
      this.drawMicrowave();
    } else if (this.fictionalType === 'refrigerator') {
      this.drawRefrigerator();
    } else if (this.fictionalType === 'dishwasher') {
      this.drawDishwasher();
    } else if (this.fictionalType === 'fan-small') {
      this.drawFanSmall();
    } else if (this.fictionalType === 'fan') {
      this.drawFan();
    } else if (this.fictionalType === 'window') {
      this.drawWindow();
    } else if (this.fictionalType === 'double-window') {
      this.drawDoubleWindow();
    } else if (this.fictionalType === 'tall-oven') {
      this.drawTallOven();
    }
  }

  drawMicrowave() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 50,
        width: this.attrs.width - 150,
        height: this.attrs.height - 100,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: this.attrs.height - 55,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: this.attrs.height / 2,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: 55,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
  }

  drawRefrigerator() {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 500,
        width: this.attrs.width,
        height: 20,
        name: 'rect',
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 100,
        y: 150,
        width: 20,
        height: 200,
        name: 'rect',
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 100,
        y: 800,
        width: 20,
        height: 450,
        name: 'rect',
        fill: 'black',
      })
    );
  }

  drawOven() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 150,
        width: this.attrs.width - 100,
        height: this.attrs.height - 200,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 60,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width / 2,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: 60,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
  }

  drawTallOven() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 150,
        width: this.attrs.width - 100,
        height: this.attrs.height - 300,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 10,
        y: this.attrs.height - 100,
        width: this.attrs.width - 20,
        height: 1,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 60,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width / 2,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: 60,
        y: 75,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
  }

  drawWindow() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 50,
        width: this.attrs.width - 100,
        height: this.attrs.height - 100,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
  }

  drawDoubleWindow() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 50,
        width: this.attrs.width / 2 - 75,
        height: this.attrs.height - 100,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: this.attrs.width / 2 + 25,
        y: 50,
        width: this.attrs.width / 2 - 75,
        height: this.attrs.height - 100,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
  }

  drawDishwasher() {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 100,
        width: this.attrs.width,
        height: 20,
        name: 'rect',
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 100,
        y: 50,
        width: this.attrs.width - 200,
        height: 20,
        name: 'rect',
        fill: 'black',
      })
    );
  }

  drawFanSmall() {
    // this.add(new Konva.Rect({ x: 10, y: 10, width: this.attrs.width - 20, height: this.attrs.height - 10, name: 'rect', strokeWidth: 20, stroke: 'black' }))
    this.add(
      new Konva.Rect({
        x: 110,
        y: this.attrs.height - 75,
        width: this.attrs.width - 220,
        height: 30,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
  }

  drawFan() {
    this.deleteCabin();
    this.add(
      new Konva.Rect({
        x: 10,
        y: this.attrs.height - 100,
        width: this.attrs.width - 20,
        height: 90,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 110,
        y: this.attrs.height - 70,
        width: this.attrs.width - 220,
        height: 30,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: this.attrs.width / 2 - 50,
        y: 10,
        width: 100,
        height: this.attrs.height - 110,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Line({
        points: [5, this.attrs.height - 95, this.attrs.width / 2 - 55, 5],
        stroke: 'black',
        strokeWidth: 20,
        name: 'rect',
      })
    );
    this.add(
      new Konva.Line({
        points: [
          this.attrs.width - 5,
          this.attrs.height - 95,
          this.attrs.width / 2 + 55,
          5,
        ],
        stroke: 'black',
        strokeWidth: 20,
        name: 'rect',
      })
    );
  }

  deleteCabin() {
    this.find('.prodStroke').each((stroke) => {
      stroke.destroy();
    });
    this.find('.rect').each((rect) => {
      rect.destroy();
    });
  }

  drawTopLeft() {
    if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 },
        { x: this.attrs.width / 4, y: this.attrs.height / 2 - 25 },
        { x: this.attrs.width / 4, y: -25 },
        { x: this.attrs.width / 4 + 20, y: -38 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height / 2 - 13 },
        { x: 0, y: this.attrs.height / 2 + 25 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 + 5 },
        { x: this.attrs.width / 4, y: -25 },
        { x: this.attrs.width / 4 + 20, y: -25 },
        { x: 20, y: this.attrs.height / 2 + 5 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 - 100 },
        { x: this.attrs.width / 4, y: this.attrs.height / 2 - 75 },
        { x: this.attrs.width / 4, y: -75 },
        { x: this.attrs.width / 4 + 20, y: -38 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height / 2 - 38 },
        { x: 0, y: this.attrs.height / 2 - 75 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 - 75 },
        { x: this.attrs.width / 4, y: -50 },
        { x: this.attrs.width / 4 + 20, y: -50 },
        { x: 20, y: this.attrs.height / 2 - 75 },
      ]);
    }
  }

  drawTopRight() {
    if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 - 100 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height / 2 - 75 },
        { x: (3 * this.attrs.width) / 4, y: -75 },
        { x: (3 * this.attrs.width) / 4 - 20, y: -38 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height / 2 - 38 },
        { x: this.attrs.width, y: this.attrs.height / 2 - 75 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 - 75 },
        { x: (3 * this.attrs.width) / 4, y: -50 },
        { x: (3 * this.attrs.width) / 4 - 20, y: -50 },
        { x: this.attrs.width - 20, y: this.attrs.height / 2 - 75 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height / 2 - 25 },
        { x: (3 * this.attrs.width) / 4, y: -25 },
        { x: (3 * this.attrs.width) / 4 - 20, y: -38 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height / 2 - 13 },
        { x: this.attrs.width, y: this.attrs.height / 2 + 25 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 + 10 },
        { x: (3 * this.attrs.width) / 4, y: -25 },
        { x: (3 * this.attrs.width) / 4 - 20, y: -25 },
        { x: this.attrs.width - 20, y: this.attrs.height / 2 + 10 },
      ]);
    }
  }

  drawBottomRight() {
    if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 - 100 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height / 2 - 75 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height - 75 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height - 75 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height / 2 - 88 },
        { x: this.attrs.width, y: this.attrs.height / 2 - 125 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 - 110 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height - 75 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height - 75 },
        { x: this.attrs.width - 20, y: this.attrs.height / 2 - 110 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height / 2 - 25 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height - 25 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height - 50 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height / 2 - 68 },
        { x: this.attrs.width, y: this.attrs.height / 2 - 25 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: this.attrs.width, y: this.attrs.height / 2 - 20 },
        { x: (3 * this.attrs.width) / 4, y: this.attrs.height - 40 },
        { x: (3 * this.attrs.width) / 4 - 20, y: this.attrs.height - 40 },
        { x: this.attrs.width - 20, y: this.attrs.height / 2 - 20 },
      ]);
    }
  }

  drawBottomLeft() {
    if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 },
        { x: this.attrs.width / 4, y: this.attrs.height / 2 - 25 },
        { x: this.attrs.width / 4, y: this.attrs.height - 25 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height - 50 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height / 2 - 68 },
        { x: 0, y: this.attrs.height / 2 - 25 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'RIGHT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 - 20 },
        { x: this.attrs.width / 4, y: this.attrs.height - 40 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height - 40 },
        { x: 20, y: this.attrs.height / 2 - 20 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'STRAIGHT' ||
        this.settings.CUT_OFF_TYPE == 2) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 - 100 },
        { x: this.attrs.width / 4, y: this.attrs.height / 2 - 75 },
        { x: this.attrs.width / 4, y: this.attrs.height - 75 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height - 75 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height / 2 - 88 },
        { x: 0, y: this.attrs.height / 2 - 125 },
      ]);
    } else if (
      (this.settings.CUT_OFF_TYPE === 'DIAGONAL' ||
        this.settings.CUT_OFF_TYPE == 1) &&
      this.settings.RIGHT_LEFT === 'LEFT'
    ) {
      this.drawCustomShape([
        { x: 0, y: this.attrs.height / 2 - 110 },
        { x: this.attrs.width / 4, y: this.attrs.height - 75 },
        { x: this.attrs.width / 4 + 20, y: this.attrs.height - 75 },
        { x: 20, y: this.attrs.height / 2 - 110 },
      ]);
    }
  }

  drawCustomShape(points: Vector2d[]): void {
    this.add(
      new Konva.Shape({
        sceneFunc(context, shape) {
          context.beginPath();
          context.moveTo(points[0].x, points[0].y);
          points.forEach((p: Vector2d) => {
            if (p === points[0]) {
              return;
            }
            context.lineTo(p.x, p.y);
          });
          context.closePath();
          context.fillStrokeShape(shape);
        },
        fill: 'black',
        name: 'prodStroke',
      })
    );
  }
}
