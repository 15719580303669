import { ProductSettings } from './productSettings';
import { Setting } from './setting';

export class GeneralSettings {
  settings: Setting[];
  productSettings: ProductSettings;

  constructor(settigns: Setting[]) {
    this.settings = settigns;
  }

  getSettingValue(settingMapping: string): any {
    if (
      this.settings.find(
        (s: Setting) => s.mapping && s.mapping.name === settingMapping
      )
    ) {
      return this.settings.find(
        (s: Setting) => s.mapping && s.mapping.name === settingMapping
      ).value;
    } else {
      return '';
    }
  }

  updateProductSettings() {
    if (!this.settings) {
      return;
    }
    this.settings.forEach((setting: Setting) => {
      if (setting.mapping === 'GLOBAL_COMMENT') {
        setting.mapping
          ? (this.productSettings[setting.mapping.name] = setting.value)
          : '';
      }
      setting.mapping
        ? (this.productSettings[setting.mapping.name] = setting.value)
        : 0;
    });
  }
}
