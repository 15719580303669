import { Component, OnDestroy } from '@angular/core';
import { SpinnerAction } from 'src/app/model/spinnerAction';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnDestroy {
  communicate: string[] = [];

  spinnerActionEmitterSub;

  constructor(private navbarService: NavbarService) {
    this.spinnerActionEmitterSub =
      this.navbarService.spinnerActionEmitter.subscribe((x: SpinnerAction) => {
        switch (x.action) {
          case 'add':
            this.addToCommunicate(x.text);
            break;
          case 'reset':
            this.resetCommunicate();
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.spinnerActionEmitterSub.unsubscribe();
  }

  addToCommunicate(text): void {
    this.communicate.push(text);
  }

  resetCommunicate(): void {
    this.communicate = [];
  }
}
