import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Actions } from 'src/app/consts/actions';
import { ConfigConsts } from 'src/app/consts/configConsts';
import { LogConsts } from 'src/app/consts/logConsts';
import { Configuration } from 'src/app/model/configuration';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { Product } from 'src/app/model/product';
import { Validation } from 'src/app/model/validation';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ProductUtils } from 'src/app/utils/productUtils';
import { ValidationUtils } from 'src/app/utils/validationUtils';

@Component({
  selector: 'app-bin',
  templateUrl: './bin.component.html',
  styleUrls: ['./bin.component.scss'],
})
export class BinComponent implements OnDestroy {
  products: Product[] = [];
  generalSettings: GeneralSettings;
  validations: Validation[];
  config: Configuration;
  generalSettingsInitialized = false;

  binEmitterSub;
  newBinEmitterSub;
  generalSettingsEmitterSub;
  validationEmitterSub;
  configEmitterSub;
  newProductFromBinEmitterSub;

  @ViewChild('close') close: ElementRef;

  constructor(
    private navbarService: NavbarService,
    private storage: LocalStorageService,
    private productService: ProductService,
    private validationService: ValidationService,
    private logService: LogService
  ) {
    this.newBinEmitterSub = this.navbarService.newBinProductEmitter.subscribe(
      (product) => {
        product.position({ x: 0.5, y: 0 });
        this.products.push(product);
        this.navbarService.binEmitter.emit(this.products);
      }
    );

    this.binEmitterSub = this.navbarService.binEmitter.subscribe((products) => {
      this.products = products.sort(
        (a: Product, b: Product) => a.attrs.id - b.attrs.id
      );
    });

    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe(
        (generalSettings: GeneralSettings) => {
          if (this.generalSettingsInitialized) {
            ProductUtils.updateSettings(this.products, generalSettings);
          } else {
            this.generalSettingsInitialized = true;
          }
        }
      );

    this.validationEmitterSub =
      this.validationService.validationEmitter.subscribe(
        (validations: Validation[]) => {
          this.validations = validations;
        }
      );

    this.configEmitterSub = this.navbarService.configEmitter.subscribe(
      (x: Configuration) => {
        this.config = x;
      }
    );

    this.newProductFromBinEmitterSub =
      this.navbarService.newProductFromBinEmitter.subscribe(
        (product: Product) => {
          this.addProduct(product);
        }
      );
  }
  ngOnDestroy(): void {
    this.binEmitterSub.unsubscribe();
    this.newBinEmitterSub.unsubscribe();
    this.generalSettingsEmitterSub.unsubscribe();
    this.validationEmitterSub.unsubscribe();
    this.configEmitterSub.unsubscribe();
    this.newProductFromBinEmitterSub.unsubscribe();
  }

  editProduct(product: Product): void {
    this.navbarService.productActionEmitter.emit(Actions.CANCEL);
    product.isActive = true;
    this.productService.productsEmitter.emit([product]);
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Editing product ' + product.type)
      .subscribe(() => {});
  }

  addProduct(product: Product): void {
    product.attrs.x = 0;
    product.attrs.y = 0;
    product.isActive = false;
    product.fixedWidth = this.config.fixedDimensions === ConfigConsts.YES;
    this.productService.productsEmitter.emit([product]);

    if (
      ValidationUtils.validateProduct(product, this.validations) &&
      ValidationUtils.checkSettingsValid(product)
    ) {
      const x = this.products.indexOf(product);
      if (x !== -1) {
        this.products.splice(x, 1);
        this.navbarService.newProductEmitter.emit(product);
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(LogConsts.INFO, 'Adding new product ' + product.type)
          .subscribe(() => {});
      }
    } else {
      alert('Valideringsfel.');
    }
    if (this.config.closeBinOnClick === ConfigConsts.YES) {
      this.close.nativeElement.click();
    }
  }

  deleteProduct(product): void {
    const x = this.products.indexOf(product);
    this.products.splice(x, 1);
  }

  checkValidity(product): boolean {
    return ProductUtils.checkValidity(product);
  }
}
