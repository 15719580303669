<div class="navbar fixed-bottom d-flex justify-content-between">
  <div class="custom-navgroup">
    <!-- <div class="btn-group btn-group-lg p-2" role="group">
            <button class="btn btn-secondary">
                {{selectedProducts[0]?.type}}
            </button>
        </div> -->
    <div class="btn-group btn-group-lg p-2" role="group">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelProductSelection()"
      >
        <i-bs name="arrow-left-square"></i-bs>
      </button>
    </div>
  </div>
  <div class="custom-navgroup">
    <div class="btn-group btn-group-lg p-2" role="group">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="selectProduct()"
        data-toggle="modal"
        data-target="#interiorModal"
      >
        <i-bs name="tools"></i-bs>
      </button>
      <!-- <button type="button" class="btn btn-secondary"  data-toggle="modal" data-target="#minorBinModal">
                <i-bs name="plus-circle"></i-bs>
            </button> -->
      <!-- <button type="button" class="btn btn-secondary" (click)="copyProducts()">
                <i-bs name="plus-square"></i-bs>
            </button> -->
    </div>
    <div class="btn-group btn-group-lg p-2" role="group">
      <button
        type="button"
        class="btn"
        [ngClass]="selectSticky ? 'btn-primary' : 'btn-secondary'"
        (click)="selectStickyProducts()"
      >
        <i-bs name="arrows-move"></i-bs>
      </button>
    </div>
    <div
      *ngIf="
        selectedProducts &&
        selectedProducts.length > 0 &&
        selectedProducts[0].productSettings.TOP_FRONT
      "
      class="btn-group btn-group-lg p-2"
      role="group"
    >
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#drawerPositionerMenuModal"
      >
        <i-bs name="file-break"></i-bs>
      </button>
    </div>
  </div>
  <div class="custom-navgroup">
    <div *ngIf="!editDrawing" class="btn-group btn-group-lg p-2" role="group">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="deleteProducts()"
      >
        <i-bs name="trash"></i-bs>
      </button>
    </div>
  </div>
</div>
