<div
  class="modal right"
  id="wallEditModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "WALL" | translate }}</h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "NAME" | translate }}:
          </label>
          <input
            id="wallName"
            class="col-sm-6 form-control"
            [(ngModel)]="wall.wallName"
            name="wallName"
            #wallName="ngModel"
            type="text"
            placeholder="{{ 'NAME' | translate }}"
            required
            [disabled]="!editDrawing"
            [ngClass]="
              !wallName.valid && wallName.dirty
                ? 'border-danger'
                : 'border-secondary'
            "
          />
          <div
            *ngIf="!wallName.valid && wallName.dirty"
            class="text-danger custom-left-padding"
          >
            {{ "SET" | translate }} {{ "NAME" | translate }}
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "WIDTH" | translate }} (mm):</label
          >
          <input
            id="wallWidth"
            class="col-sm-6 form-control"
            type="number"
            [(ngModel)]="width"
            #wallWidth="ngModel"
            [min]="3000"
            [ngClass]="
              !wallWidth.valid && wallWidth.dirty
                ? 'border-danger'
                : 'border-secondary'
            "
            placeholder="{{ 'WIDTH' | translate }}  (mm)"
            required
            [disabled]="!editDrawing"
          />
          <div
            *ngIf="!wallWidth.valid && wallWidth.dirty"
            class="text-danger custom-left-padding"
          >
            {{ "SET" | translate }} {{ "WIDTH" | translate }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "HEIGHT" | translate }} (mm):</label
          >
          <input
            id="height"
            class="col-sm-6 form-control"
            type="number"
            [(ngModel)]="height"
            placeholder="{{ 'HEIGHT' | translate }} (mm)"
            #wallHeight="ngModel"
            [ngClass]="
              !wallHeight.valid && wallHeight.dirty
                ? 'border-danger'
                : 'border-secondary'
            "
            required
            [disabled]="!editDrawing"
          />
          <div
            *ngIf="!wallHeight.valid && wallHeight.dirty"
            class="text-danger custom-left-padding"
          >
            {{ "SET" | translate }} {{ "HEIGHT" | translate }}
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "SOCKEL" | translate }} {{ "HEIGHT" | translate }} (mm):</label
          >
          <input
            id="sockelHeight"
            class="col-sm-6 form-control"
            type="number"
            [(ngModel)]="sockelHeight"
            placeholder="{{ 'SOCKEL' | translate }} {{
              'HEIGHT' | translate
            }} (mm)"
            #wallSockelHeight="ngModel"
            [ngClass]="
              !wallSockelHeight.valid && wallSockelHeight.dirty
                ? 'border-danger'
                : 'border-secondary'
            "
            required
            [disabled]="!editDrawing"
          />
          <div
            *ngIf="!wallSockelHeight.valid && wallSockelHeight.dirty"
            class="text-danger custom-left-padding"
          >
            {{ "SET" | translate }} {{ "SOCKEL" | translate }}
            {{ "HEIGHT" | translate }}
          </div>
        </div>
        <div *ngIf="editDrawing" class="form-group row">
          <label class="col-sm-5 col-form-label"> </label>
          <button class="btn btn-secondary col-sm-6" (click)="updateView()">
            {{ "UPDATE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
