import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Product } from 'src/app/model/product';
import { ProductInteractionConfig } from 'src/app/model/productInteractionConfig';
import { AuthorisationService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-selected-product-menu',
  templateUrl: './selected-product-menu.component.html',
  styleUrls: ['./selected-product-menu.component.scss'],
})
export class SelectedProductMenuComponent implements OnDestroy {
  @Input() selectedProducts: Product[] = [];
  bin: Product[] = [];
  editDrawing: boolean;
  selectSticky = false;

  binEmitterSub: any;
  permissionsEmitterSub;
  productConfigEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService,
    private authService: AuthorisationService,
    private logService: LogService
  ) {
    this.navbarService.productConfigEmitter.emit({
      connectToAnother: this.selectSticky,
    } as ProductInteractionConfig);

    this.binEmitterSub = this.navbarService.binEmitter.subscribe((bin) => {
      this.bin = bin;
    });

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editDrawing = permissions.editDrawing;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.binEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
  }

  cancelProductSelection(): void {
    this.selectSticky = false;
    this.navbarService.productConfigEmitter.emit({
      connectToAnother: this.selectSticky,
    } as ProductInteractionConfig);
    this.navbarService.productActionEmitter.emit(Actions.CANCEL);
    this.navbarService.productActionEmitter.emit(Actions.CLICK);
  }

  deleteProducts(): void {
    this.navbarService.productActionEmitter.emit(Actions.DELETE);
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Moving product back to the bin')
      .subscribe(() => {});
  }

  copyProducts(): void {
    this.navbarService.productActionEmitter.emit(Actions.COPY);
  }

  selectProduct(): void {
    this.navbarService.productActionEmitter.emit(Actions.UPDATE_MINOR_PRODUCT);
  }

  selectStickyProducts(): void {
    this.selectSticky = !this.selectSticky;
    this.navbarService.productConfigEmitter.emit({
      connectToAnother: this.selectSticky,
    } as ProductInteractionConfig);
    this.navbarService.productActionEmitter.emit(Actions.CLICK);
  }
}
