import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { ValidationCommunicate } from '../model/subvalidators/validationMessage';
import { environment } from 'src/environments/environment';
import { Validation } from '../model/validation';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  validationEmitter = new EventEmitter<Validation[]>();
  validationMessagesEmitter = new EventEmitter<ValidationCommunicate[]>();
  simpleValidationEmitter = new EventEmitter<Validation[]>();

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  getValidations(): Observable<any> {
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + token
    );

    return this.httpClient.get(environment.validationUrl, {
      headers,
    });
  }
}
