export enum Mapping {
  AMOUNT = 'AMOUNT',
  AMOUNT_SHELF = 'AMOUNT_SHELF',
  AMOUNT_SHELF_MAX = 'AMOUNT_SHELF_MAX',
  AMOUNT_SHELF_MIN = 'AMOUNT_SHELF_MIN',
  AMOUNT_OF_CABINS = 'AMOUNT_OF_CABINS',
  CABIN_BOTTOM_HEIGHT = 'CABIN_BOTTOM_HEIGHT',
  CABIN_HEIGHT = 'CABIN_HEIGHT',
  WIDTH = 'WIDTH',
  WIDTH_MAX = 'WIDTH_MAX',
  WIDTH_MIN = 'WIDTH_MIN',
  DESIGN_OF_PRODUCT = 'DESIGN_OF_PRODUCT',
  DEPTH = 'DEPTH',
  DEPTH_EXISTING_CABINS = 'DEPTH_EXISTING_CABINS',
  DEPTH_MAX = 'DEPTH_MAX',
  DEPTH_MIN = 'DEPTH_MIN',
  SUGGESTION_ALIGNMENT_MIDDLE_FRONT = 'SUGGESTION_ALIGNMENT_MIDDLE_FRONT',
  SUGGESTION_ALIGNMENT_BOTTOM_FRONT = 'SUGGESTION_ALIGNMENT_BOTTOM_FRONT',
  FRONT_YES_NO = 'FRONT_YES_NO',
  FRONT_TOP = 'FRONT_TOP',
  FRONT_BOTTOM = 'FRONT_BOTTOM',
  AMOUNT_MIDDLE_FRONTS = 'AMOUNT_MIDDLE_FRONTS',
  HEIGHT_MIDDLE_FRONTS = 'HEIGHT_MIDDLE_FRONTS',
  HINGES_CC = 'HINGES_CC',
  HIGH_MIN_HEIGHT_FRONT = 'HIGH_MIN_HEIGHT_FRONT',
  RIGHT_LEFT = 'RIGHT_LEFT',
  HEIGHT = 'HEIGHT',
  HEIGHT_BLIND_FRONT = 'HEIGHT_BLIND_FRONT',
  HEIGHT_FRONT = 'HEIGHT_FRONT',
  HEIGHT_MAX = 'HEIGHT_MAX',
  HEIGHT_BETWEEN_CABINS_ROOF = 'HEIGHT_BETWEEN_CABINS_ROOF',
  HEIGHT_MIN = 'HEIGHT_MIN',
  HEIGHT_SPAREPART = 'HEIGHT_SPAREPART',
  HEIGHT_EXITING_CABINS = 'HEIGHT_EXITING_CABINS',
  INTERNAL_WIDTH = 'INTERNAL_WIDTH',
  INTERNAL_WIDTH_MAX = 'INTERNAL_WIDTH_MAX',
  INTERNAL_WIDTH_MIN = 'INTERNAL_WIDTH_MIN',
  INTERNAL_HEIGHT = 'INTERNAL_HEIGHT',
  INTERNAL_HEIGHT_MAX = 'INTERNAL_HEIGHT_MAX',
  INTERNAL_HEIGHT_MIN = 'INTERNAL_HEIGHT_MIN',
  BOTTOM_FRONT = 'BOTTOM_FRONT',
  MAPPING = 'MAPPING',
  HANDLES_YES_NO = 'HANDLES_YES_NO',
  TOP_FRONT = 'TOP_FRONT',
  TOP_FRONT_COVER = 'TOP_FRONT_COVER',
  BASE_HEIGHT = 'BASE_HEIGHT',
  BASE_HEIGHT_MAX = 'BASE_HEIGHT_MAX',
  BASE_HEIGHT_MIN = 'BASE_HEIGHT_MIN',
  COLUMN_COVERING = 'COLUMN_COVERING',
  STD_MIN_HEIGHT_FRONT = 'STD_MIN_HEIGHT_FRONT',
  CABINs_HEIGHT = 'CABINs_HEIGHT',
  COVER_CABIN = 'COVER_CABIN',
  CABIN_COVER_LEFT = 'CABIN_COVER_LEFT',
  CABIN_COVER_RIGHT = 'CABIN_COVER_RIGHT',
  TOTAL_WITHD = 'TOTAL_WITHD',
  TOTAL_FRONT_HEIGHT = 'TOTAL_FRONT_HEIGHT',
  HEATSHIELD_RIGHTSIDE = 'HEATSHIELD_RIGHTSIDE',
  HEATSHIELD_LEFTSIDE = 'HEATSHIELD_LEFTSIDE',
  FRONTS_MIDDLE_HEIGHT = 'FRONTS_MIDDLE_HEIGHT',
  FRONTS_MIDDLE_HEIGHT2 = 'FRONTS_MIDDLE_HEIGHT2',
  FRONTS_MIDDLE_HEIGHT3 = 'FRONTS_MIDDLE_HEIGHT3',
  FRONT_SPICERACK_TOP = 'FRONT_SPICERACK_TOP',
  FRONT_SPICERACK_BOTTOM = 'FRONT_SPICERACK_BOTTOM',
  TOP_BOTTOM = 'TOP_BOTTOM',
  COMMENTS = 'COMMENTS',
  DOUBLE_DOOR = 'DOUBLE_DOOR',
  HEIGHT_2 = 'HEIGHT_2',
  TOP_DOOR_HEIGHT = 'TOP_DOOR_HEIGHT',
  LIGHT_RAMP_YES_NO = 'LIGHT_RAMP_YES_NO',
  POSITIONNUMBER = 'POSITIONNUMBER',
  FICTIONAL_HEIGHT = 'FICTIONAL_HEIGHT',
  HINGES_CC_TOP = 'HINGES_CC_TOP',
  HINGES_CC_BOTTOM = 'HINGES_CC_BOTTOM',
  INTERNAL_WIDTH_LEFT = 'INTERNAL_WIDTH_LEFT',
  INTERNAL_WIDTH_RIGHT = 'INTERNAL_WIDTH_RIGHT',
  WIDTH_2 = 'WIDTH_2',
  HANDLE_PLACEMENT = 'HANDLE_PLACEMENT',
  HANDLE_LAT_HORZ = 'HANDLE_LAT_HORZ',
  COVERSIDE_PERSPECTIVE = 'COVERSIDE_PERSPECTIVE',
  HINGES_CC_TOP2 = 'HINGES_CC_TOP2',
  HINGES_CC_BOTTOM2 = 'HINGES_CC_BOTTOM2',
  STANDINGLAYING = 'STANDINGLAYING',
  HANDLES_TYPE = 'HANDLES_TYPE',
  HANDLES_YES = 'HANDLES_YES',
  GLOBAL_COMMENT = 'GLOBAL_COMMENT',
  HINGES_AMOUNT = 'HINGES_AMOUNT',
  CONNECTED_TO_POSITIONNUMBER = 'CONNECTED_TO_POSITIONNUMBER',
  DRAWER_1_DEPTH = 'DRAWER_1_DEPTH',
  DRAWER_2_DEPTH = 'DRAWER_2_DEPTH',
  DRAWER_3_DEPTH = 'DRAWER_3_DEPTH',
  DRAWER_4_DEPTH = 'DRAWER_4_DEPTH',
  DRAWER_5_DEPTH = 'DRAWER_5_DEPTH',
  HINGE_CC_EXTRA = 'HINGE_CC_EXTRA',
  EXTRA_HINGE = 'EXTRA_HINGE',
  BLINDFRONT_HANDLE = 'BLINDFRONT_HANDLE',
  CUT_OFF_TYPE = 'CUT_OFF_TYPE',
  CUT_OFF_PLACEMENT = 'CUT_OFF_PLACEMENT',
  DOOR_TYPE = 'DOOR_TYPE',
  TIPON1 = 'TIPON1',
}
