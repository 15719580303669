<div
  class="modal fade"
  id="productModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CHOOSE_CATEGORY" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="categories" class="list-group">
          <li
            *ngFor="let category of categories"
            class="list-group-item custom-list-item"
          >
            <div class="d-flex justify-content-between">
              <div
                class="d-flex align-items-center custom-extra-padding custom-width"
              >
                <div class="text-truncate d-inline-block">
                  {{ category.name }}
                </div>
              </div>
              <div
                class="btn-group btn-group-lg"
                role="group"
                (click)="chooseCategory(category)"
              >
                <button
                  class="btn btn-secondary"
                  data-toggle="modal"
                  data-target="#productListModal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i-bs name="plus"></i-bs>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
