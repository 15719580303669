<div
  class="modal right"
  id="interiorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modal"
>
  <div class="modal-dialog" *ngIf="sampleProduct">
    <div class="modal-content container">
      <div class="row">
        <div class="col-6">
          <app-minor-canvas
            class="custom-fixed-top"
            (touchstart)="unfocusAll()"
            [productCopy]="productCopy"
          >
          </app-minor-canvas>
        </div>
        <div class="col-6" #inputs>
          <div class="modal-header">
            <h5 class="modal-title">{{ sampleProduct.type }}</h5>
            <button
              type="button"
              class="close btn-primary"
              #close
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            *ngIf="
              mainProduct.subProducts && mainProduct.subProducts.length > 0
            "
            class="btn-group btn-group-md p-2 custom-btn-group"
          >
            <button
              class="btn btn-secondary"
              (click)="switchBetweenParentAndChild()"
            >
              <i-bs class="" name="arrow-left-right"></i-bs>
            </button>
            <button
              *ngIf="
                mainProduct.subProducts &&
                mainProduct.subProducts.length > 0 &&
                sampleProduct.subProducts &&
                sampleProduct.subProducts.length === 0
              "
              class="btn btn-secondary"
              (click)="deleteSubProduct()"
            >
              <i-bs class="" name="trash"></i-bs>
            </button>
          </div>

          <!-- here comes tab manager -->

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="setTab(1)"
                [ngClass]="tab === 1 ? 'active' : ''"
                >{{ "DETAILS" | translate }}
                <span
                  *ngIf="
                    sampleProduct.validationMessages &&
                    sampleProduct.validationMessages.length > 0
                  "
                  class="badge badge-pill badge-danger custom-badge"
                  >!</span
                >

                <span
                  *ngIf="sampleProduct.absentSettings.length > 0"
                  class="badge badge-pill badge-warning custom-badge"
                  >!</span
                ></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="setTab(0)"
                [ngClass]="tab === 0 ? 'active' : ''"
                >{{ "INFORMATION" | translate }}
                <span
                  *ngIf="false"
                  class="badge badge-pill badge-danger custom-badge"
                  >!</span
                ></a
              >
            </li>
            <li *ngIf="!isFictional" class="nav-item">
              <a
                class="nav-link"
                (click)="setTab(2)"
                [ngClass]="tab === 2 ? 'active' : ''"
                >{{ "CALCULATED" | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                (click)="setTab(3)"
                [ngClass]="tab === 3 ? 'active' : ''"
                >{{ "COMMENT" | translate }}</a
              >
            </li>
          </ul>

          <div *ngIf="tab === 0" class="modal-body d-flex flex-column">
            <div *ngIf="sampleProduct?.isFictional">
              <label class="custom-label">
                {{ "WIDTH" | translate }}: {{ sampleProduct.WIDTH }}
              </label>
            </div>
            <div *ngIf="sampleProduct?.isFictional">
              <label class="custom-label">
                {{ "HEIGHT" | translate }}: {{ sampleProduct.HEIGHT }}
              </label>
            </div>
            <div *ngIf="sampleProduct?.additionalInfo?.color">
              <label class="custom-label">
                {{ "COLOR" | translate }}:
                {{ sampleProduct.additionalInfo.color }}
              </label>
            </div>
            <div *ngIf="sampleProduct?.additionalInfo?.material">
              <label class="custom-label">
                {{ "MATERIAL" | translate }}:
                {{ sampleProduct.additionalInfo.material }}
              </label>
            </div>
            <div *ngIf="sampleProduct?.additionalInfo?.edge">
              <label class="custom-label">
                {{ "EDGE" | translate }}:
                {{ sampleProduct.additionalInfo.edge }}
              </label>
            </div>
            <div *ngIf="sampleProduct?.additionalInfo?.handle">
              <label class="custom-label">
                {{ "HANDLE" | translate }}:
                {{ sampleProduct.additionalInfo.handle }}
              </label>
            </div>
            <div *ngFor="let setting of sampleProduct.settings; index as i">
              <div *ngIf="setting.type === 'FIXED' && setting.show">
                <div *ngIf="setting.settingType === 'TEXTFIELD'">
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <input
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    #input
                    (focusout)="onSettingChange($event, setting)"
                    type="number"
                    [disabled]="true"
                  />
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div *ngIf="setting.settingType === 'DROPDOWN'">
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <select
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    #input
                    (focusout)="onSettingChange($event, setting)"
                    type="number"
                    [disabled]="true"
                  >
                    <option
                      *ngFor="let option of setting.settingOptionSet"
                      [disabled]="!option.active"
                      value="{{ option.value }}"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div *ngIf="setting.settingType === 'RADIO_VERTICAL'">
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <select
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    #input
                    (focusout)="onSettingChange($event, setting)"
                    type="number"
                    [disabled]="true"
                  >
                    <option
                      *ngFor="let option of setting.settingOptionSet"
                      [disabled]="!option.active"
                      value="{{ option.value }}"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="tab === 1" class="modal-body d-flex flex-column">
            <div *ngFor="let setting of sampleProduct.settings; index as i">
              <div *ngIf="setting.type === 'EDITABLE' && setting.show">
                <div *ngIf="setting.settingType === 'TEXTFIELD'">
                  <label class="custom-label"> {{ setting.name }}: </label>
                  <div class="d-flex flex-row">
                    <input
                      id="name"
                      name="name"
                      #name="ngModel"
                      class="form-control"
                      [(ngModel)]="sampleProduct.settings[i].value"
                      #input
                      (focusout)="onSettingChange($event, setting)"
                      [disabled]="!editDrawing"
                      type="{{
                        setting.dataType === 'NUMERIC' ? 'number' : 'text'
                      }}"
                      [ngClass]="
                        !name.valid && editDrawing
                          ? 'border-danger'
                          : 'border-secondary'
                      "
                    />
                    <input
                      *ngIf="sampleProduct.settings[i].showOverrideButton"
                      type="checkbox"
                      class="custom-checkbox custom-padding"
                      [disabled]="!editDrawing"
                      (click)="onSettingUpdate($event, setting)"
                      #input
                      [(ngModel)]="sampleProduct.settings[i].override"
                    />
                  </div>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div *ngIf="setting.settingType === 'DROPDOWN'">
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <select
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    [disabled]="!editDrawing"
                    (focusout)="onSettingChange($event, setting)"
                    type="number"
                    #input
                    [ngClass]="
                      !name.valid && editDrawing
                        ? 'border-danger'
                        : 'border-secondary'
                    "
                  >
                    <option
                      *ngFor="let option of setting.settingOptionSet"
                      [disabled]="!option.active"
                      value="{{ option.value }}"
                    >
                      {{ option.name }}
                    </option>
                  </select>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="
                    setting.settingType === 'RADIO' ||
                    setting.settingType === 'RADIO_VERTICAL'
                  "
                >
                  <label class="custom-label"> {{ setting.name }}: </label>
                  <div *ngIf="!setting.readonly">
                    <select
                      class="form-control"
                      id="name"
                      name="name"
                      #name="ngModel"
                      [(ngModel)]="sampleProduct.settings[i].value"
                      [disabled]="!editDrawing"
                      (focusout)="onSettingChange($event, setting)"
                      type="number"
                      #input
                      [ngClass]="
                        !name.valid && editDrawing
                          ? 'border-danger'
                          : 'border-secondary'
                      "
                    >
                      <option
                        *ngFor="let option of setting.settingOptionSet"
                        [disabled]="!option.active"
                        value="{{ option.value }}"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="!name.valid && editDrawing"
                      class="text-danger custom-left-padding"
                    >
                      {{ "SET_VALUE" | translate }}
                    </div>
                  </div>
                  <div *ngIf="setting.readonly">
                    <select
                      id="name"
                      name="name"
                      #name="ngModel"
                      class="form-control"
                      [(ngModel)]="sampleProduct.settings[i].value"
                      #input
                      (focusout)="onSettingChange($event, setting)"
                      type="number"
                      [disabled]="true"
                    >
                      <option
                        *ngFor="let option of setting.settingOptionSet"
                        [disabled]="!option.active"
                        value="{{ option.value }}"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngFor="
                let message of sampleProduct.validationMessages;
                index as i
              "
            >
              <div class="text-danger custom-left-padding">
                {{ message.out }}
              </div>
            </div>
            <div
              *ngIf="sampleProduct.absentSettings.length > 0"
              class="text-warning custom-left-padding"
            >
              {{ "ABSENT_SETTINGS" | translate }}:
              <div
                *ngFor="let message of sampleProduct.absentSettings; index as i"
              >
                <div class="text-warning custom-left-padding">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="tab === 2" class="modal-body d-flex flex-column">
            <div *ngFor="let setting of sampleProduct.settings; index as i">
              <div
                *ngIf="
                  setting.type === 'CALCULATED' &&
                  setting.show &&
                  editCalculatedPermission
                "
              >
                <div *ngIf="setting.settingType === 'TEXTFIELD'">
                  <label class="custom-label"> {{ setting.name }}: </label>
                  <div class="d-flex flex-row">
                    <input
                      id="name"
                      name="name"
                      #name="ngModel"
                      class="form-control"
                      [(ngModel)]="sampleProduct.settings[i].value"
                      [disabled]="!editDrawing"
                      (focusout)="onCalculatedSettingChange($event, setting)"
                      #input
                      type="{{
                        setting.dataType === 'NUMERIC' ? 'number' : 'text'
                      }}"
                      [ngClass]="
                        setting.isFrozen ? 'border-warning' : 'border-secondary'
                      "
                    />
                    <input
                      *ngIf="sampleProduct.settings[i].showOverrideButton"
                      type="checkbox"
                      class="custom-checkbox custom-padding"
                      #input
                      (focusout)="onSettingUpdate($event, setting)"
                      [(ngModel)]="sampleProduct.settings[i].override"
                    />
                  </div>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div *ngIf="setting.settingType === 'DROPDOWN'">
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <select
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    [disabled]="!editDrawing"
                    #input
                    (focusout)="onCalculatedSettingChange($event, setting)"
                    type="number"
                    [ngClass]="
                      setting.isFrozen ? 'border-warning' : 'border-secondary'
                    "
                  >
                    <option
                      *ngFor="let option of setting.settingOptionSet"
                      [disabled]="!option.active"
                      value="{{ option.value }}"
                    >
                      {{ option.name }}
                    </option>
                  </select>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>

                <div
                  *ngIf="
                    setting.settingType === 'RADIO' ||
                    setting.settingType === 'RADIO_VERTICAL'
                  "
                >
                  <label class="custom-label"> {{ setting.name }}: </label>

                  <select
                    id="name"
                    name="name"
                    #name="ngModel"
                    class="form-control"
                    [(ngModel)]="sampleProduct.settings[i].value"
                    [disabled]="!editDrawing"
                    #input
                    (focusout)="onCalculatedSettingChange($event, setting)"
                    type="number"
                    [ngClass]="
                      setting.isFrozen ? 'border-warning' : 'border-secondary'
                    "
                  >
                    <option
                      *ngFor="let option of setting.settingOptionSet"
                      [disabled]="!option.active"
                      value="{{ option.value }}"
                    >
                      {{ option.name }}
                    </option>
                  </select>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  setting.type === 'CALCULATED' &&
                  setting.show &&
                  !editCalculatedPermission
                "
              >
                <label class="custom-label"> {{ setting.name }}: </label>
                <input
                  id="name"
                  name="name"
                  #name="ngModel"
                  class="form-control"
                  [(ngModel)]="sampleProduct.settings[i].value"
                  #input
                  (focusout)="onCalculatedSettingChange($event, setting)"
                  type="{{
                    setting.dataType === 'NUMERIC' ? 'number' : 'text'
                  }}"
                  [ngClass]="
                    setting.isFrozen ? 'border-warning' : 'border-secondary'
                  "
                  [disabled]="true"
                />
                <div
                  *ngIf="!setting.mapping && adminPermission"
                  class="text-danger custom-left-padding"
                >
                  {{ "SETTING_ISNT_MAPPED" | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="editCalculatedPermission && editDrawing">
              <label class="custom-label"> </label>
              <div class="d-flex flex-row">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="refreshSettings()"
                >
                  {{ "REFRESH_SETTINGS" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="tab === 3" class="modal-body d-flex flex-column">
            <div *ngFor="let setting of sampleProduct.settings; index as i">
              <div *ngIf="setting.type === 'COMMENT'">
                <div *ngIf="setting.settingType === 'TEXTAREA'">
                  <label class="custom-label"> {{ setting.name }}: </label>
                  <div class="d-flex flex-row">
                    <textarea
                      id="name"
                      name="name"
                      #name="ngModel"
                      class="form-control"
                      [(ngModel)]="sampleProduct.settings[i].value"
                      [disabled]="!editDrawing"
                      #input
                      (focusout)="onSettingChange($event, setting)"
                      type="text"
                      rows="8"
                      [ngClass]="
                        !name.valid && editDrawing
                          ? 'border-danger'
                          : 'border-secondary'
                      "
                    ></textarea>
                  </div>

                  <div
                    *ngIf="!name.valid && editDrawing"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SET_VALUE" | translate }}
                  </div>
                  <div
                    *ngIf="!setting.mapping"
                    class="text-danger custom-left-padding"
                  >
                    {{ "SETTING_ISNT_MAPPED" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
