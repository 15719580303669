import { Mapping } from './mapping';

export class MandatorySettings {
  static readonly DESIGN = [Mapping.DESIGN_OF_PRODUCT];
  static readonly CABIN = [
    Mapping.CABIN_COVER_LEFT,
    Mapping.CABIN_COVER_RIGHT,
    Mapping.CABIN_HEIGHT,
  ];
  static readonly DOORS = [
    Mapping.HANDLES_TYPE,
    Mapping.HANDLES_YES_NO,
    Mapping.HANDLE_LAT_HORZ,
    Mapping.HANDLE_PLACEMENT,
    Mapping.RIGHT_LEFT,
  ];
  static readonly DRAWERS = [
    Mapping.TOP_FRONT,
    Mapping.FRONTS_MIDDLE_HEIGHT,
    Mapping.BOTTOM_FRONT,
  ];
  // DRAWERS = [Mapping.D]
}
