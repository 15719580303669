import Konva from 'konva';
import { Product } from '../model/product';
import { ProductInteractionConfig } from '../model/productInteractionConfig';
import { Wall } from '../model/wall';

export class CollisionDetection {
  static checkCollisions(
    activeLayer: Konva.Layer,
    productConfig: ProductInteractionConfig
  ) {
    // collisions should only work for entities
    activeLayer.children.each((g: any) => {
      this.checkCollision(activeLayer, g, productConfig);
    });
  }

  static checkCollision(
    activeLayer: Konva.Layer,
    object: Product,
    productConfig: ProductInteractionConfig
  ): void {
    let validator = true;
    activeLayer.children.each((h: any) => {
      if (object === h || !h.isEntity || productConfig?.connectToAnother) {
        return;
      }

      if (h.isProduct && object.isProduct) {
        if (this.haveOuterIntersection(object.attrs, h.attrs)) {
          validator = false;
        }
      }
      if (h.isWall && object.isProduct) {
        if (!this.haveInnerIntersection(object.attrs, h.attrs)) {
          this.moveToAcceptable(object, h);
          object.updatePreviousPosition();
        }

        this.changeHandlePlacement(object, h);
      }
      // if (h.isWall && object.isBottom === true && object.isProduct) {
      //     object.position({ x: object.attrs.x, y: h.attrs.height - object.attrs.height - object.bottomPlacement})
      // }
    });

    if (!validator) {
      object.isValid = false;
    } else {
      object.isValid = true;
    }
  }

  static haveOuterIntersection(r1: any, r2: any): boolean {
    const a = 0;
    return !(
      r2.x >= r1.x + r1.width + a ||
      r2.y >= r1.y + r1.height + a ||
      r2.x + r2.width + a <= r1.x ||
      r2.y + r2.height + a <= r1.y
    );
  }

  static haveInnerIntersection(r1: any, r2: any): boolean {
    const a = 0;
    return !(
      r2.x > r1.x - a ||
      r2.y > r1.y - a ||
      r2.x + r2.width < r1.x + r1.width + a ||
      r2.y + r2.height < r1.y + r1.height + a
    );
  }

  static moveToAcceptable(product: Product, wall: Wall) {
    if (product.attrs.x < wall.attrs.x) {
      product.position({ x: wall.attrs.x, y: product.attrs.y });
    }
    if (
      product.attrs.x + product.attrs.width >
      wall.attrs.x + wall.attrs.width
    ) {
      product.position({
        x: wall.attrs.width - product.attrs.width,
        y: product.attrs.y,
      });
    }
    if (product.attrs.y < wall.attrs.y) {
      product.position({ x: product.attrs.x, y: wall.attrs.y });
    }
    if (
      product.attrs.y + product.attrs.height >
      wall.attrs.y + wall.attrs.height
    ) {
      product.position({
        x: product.attrs.x,
        y: wall.attrs.height - product.attrs.height - product.bottomPlacement,
      });
    }
  }

  static changeHandlePlacement(product: Product, wall: Wall) {
    // product.handlePlacement = 'bottom'
    // if (product.attrs.y + product.attrs.height >= wall.attrs.y + wall.attrs.height && product.productSettings.TOP_BOTTOM === '10') {
    //     product.handlePlacement = 'top'
    // }
  }
}
