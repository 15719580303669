export class Configuration {
  snap: string;
  closeBinOnClick: string;
  automaticHeatshields: string;
  fixedDimensions: string;
  // autoWidthSockel: string;
  autoWidthDekorlist: string;
  ffdoorInteraction: string;
  autoSave: string;
}
