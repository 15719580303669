import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarService } from 'src/app/services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthorisationService } from 'src/app/services/auth.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ProductOrderService } from 'src/app/services/productorder.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { TokenResponse } from 'src/app/model/tokenResponse';
import { ProductService } from 'src/app/services/product.service';
import { AlertService } from 'src/app/services/alert.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ValidationUtils } from 'src/app/utils/validationUtils';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { SettingMapUtils } from 'src/app/utils/settingMapUtil';
import { Actions } from 'src/app/consts/actions';
import { StorageConsts } from 'src/app/consts/storage';
import { ConfigConsts } from 'src/app/consts/configConsts';
import { LogService } from 'src/app/services/log.service';
import { LogConsts } from 'src/app/consts/logConsts';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FictionalSettingsUtil } from 'src/app/utils/fictionalSettingsUtil';
import { DrawingConfigExtractor } from 'src/app/utils/drawingConfigExtractor';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
})
export class MainViewComponent implements OnInit, OnDestroy {
  loading = true;

  languageSwitchEmitterSub;
  stageActionEmitterSub;
  alertResponseEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private translate: TranslateService,
    private auth: AuthorisationService,
    private productService: ProductService,
    private quoteService: QuoteService,
    private productOrderService: ProductOrderService,
    private storage: LocalStorageService,
    private alertService: AlertService,
    private validationService: ValidationService,
    private logService: LogService,
    private deviceService: DeviceDetectorService
  ) {
    translate.setDefaultLang('se');
    translate.use('se');

    this.languageSwitchEmitterSub =
      this.navbarService.languageSwitchEmitter.subscribe((language: string) => {
        switch (language) {
          case 'en':
            translate.use('en');
            break;
          case 'se':
            translate.use('se');
            break;
          case 'no':
            translate.use('no');
            break;
        }
      });

    this.stageActionEmitterSub =
      this.navbarService.stageActionEmitter.subscribe((action) => {
        switch (action) {
          case Actions.SAVE_LOCALLY:
            this.navbarService.spinnerActionEmitter.emit({
              action: 'add',
              text: 'SAVE_LOCALLY',
            });
            // this.saveLocally();
            break;
          case Actions.LOAD_LOCAL:
            // this.loadLocal();
            break;
          case Actions.SAVE_ORDER:
            this.loading = true;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'add',
              text: 'SAVE',
            });
            break;
          case Actions.SAVE_AND_PDF:
            this.loading = true;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'add',
              text: 'SAVE_AND_PDF',
            });
            break;
          case Actions.SAVE_AND_RETURN:
            this.loading = true;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'add',
              text: 'SAVE_AND_RETURN',
            });
            break;
          case Actions.SAVE_COMPLETE:
            this.loading = false;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'reset',
              text: '',
            });
            break;
          case Actions.SAVE_FAILED:
            this.loading = false;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'reset',
              text: '',
            });
            break;
          case Actions.ACTIVATE_PDF_ONLY:
            this.loading = false;
            this.navbarService.spinnerActionEmitter.emit({
              action: 'reset',
              text: '',
            });
            break;
        }
      });

    this.alertResponseEmitterSub =
      this.alertService.alertResponseEmitter.subscribe((response) => {
        switch (response) {
          case Actions.SAVE_ORDER:
            this.navbarService.stageActionEmitter.emit(Actions.SAVE_ORDER);
            break;
          case Actions.REAUTHORIZE:
            this.refreshToken(false);
            break;
          case Actions.REAUTHORIZE_AND_SAVE:
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.languageSwitchEmitterSub.unsubscribe();
    this.stageActionEmitterSub.unsubscribe();
    this.alertResponseEmitterSub.unsubscribe();
  }

  ngOnInit(): void {
    this.loading = true;
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'LOADING',
    });
    // temp. Uncomment for debugging
    // this.storage.set('access_token', environment.tempAuth);
    // this.storage.set('refresh_token', environment.tempRefresh)

    this.authorize(true);

    const c = document.getElementById('mainContainer');

    setInterval(() => {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Auto authorisation')
        .subscribe(() => {});
      this.authorize(false);
    }, 600000);
    c.addEventListener('wheel', (event) => {
      //
      // event.preventDefault();
    });

    c.addEventListener('touchmove', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
  }

  authorize(updateOrder): void {
    if (!this.storage.get(StorageConsts.ACCESS_TOKEN)) {
      this.refreshToken(updateOrder);
      return;
    }
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'AUTHORISATION',
    });
    this.auth.verifyToken().subscribe(
      (newToken) => {
        this.navbarService.spinnerActionEmitter.emit({
          action: 'add',
          text: 'AUTHORISATION_COMPLETE',
        });
        if (updateOrder) {
          this.getProductOrder();
          this.getValidations();
          this.setFictionalProducts();
        } else {
          this.navbarService.spinnerActionEmitter.emit({
            action: 'reset',
            text: '',
          });
          this.loading = false;
        }

        // this.getProductList();
      },
      (error) => {
        // tslint:disable-next-line: no-empty
        if (!updateOrder) {
          this.logService
            .postLog(LogConsts.WARNING, 'Refreshing token')
            .subscribe(() => {});
        }
        this.refreshToken(updateOrder);
      }
    );
  }

  refreshToken(updateOrder): void {
    if (!this.storage.get(StorageConsts.REFRESH_TOKEN)) {
      this.login();
      return;
    }

    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'REFRESHING_TOKEN',
    });

    this.auth.refreshToken().subscribe(
      (response: TokenResponse) => {
        console.log(response);
        this.storage.set(StorageConsts.REFRESH_TOKEN, response.refresh_token);
        this.storage.set(StorageConsts.ACCESS_TOKEN, response.access_token);
        this.authorize(updateOrder);
        this.navbarService.spinnerActionEmitter.emit({
          action: 'add',
          text: 'TOKEN_REFRESHED',
        });
      },
      (error) => {
        if (!updateOrder) {
          this.logService
            .postLog(LogConsts.WARNING, 'Relogging')
            .subscribe(() => {});
        }
        this.login();
      }
    );
  }

  refreshTokenWIthoutRelog(): void {
    if (!this.storage.get(StorageConsts.REFRESH_TOKEN)) {
      this.login();
      return;
    }
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'REFRESHING_TOKEN',
    });

    this.auth.refreshToken().subscribe(
      (response: TokenResponse) => {
        console.log(response);
        this.storage.set(StorageConsts.REFRESH_TOKEN, response.refresh_token);
        this.storage.set(StorageConsts.ACCESS_TOKEN, response.access_token);
        this.authorize(false);
        this.navbarService.spinnerActionEmitter.emit({
          action: 'add',
          text: 'TOKEN_REFRESHED',
        });
      },
      (error) => {
        alert('error status: ' + error.status);
      }
    );
  }

  login(): void {
    this.auth.authorizeAndLogin();
  }

  getProductOrder(): void {
    const prodId: string = this.storage.get(StorageConsts.ORDER_ID);
    if (!prodId) {
      alert('no order ID');
      return;
    }
    this.logService.setOrderId(prodId);
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'LOADING_PRODUCT_ORDER',
    });
    this.productOrderService.getProductOrder(prodId).subscribe(
      (order) => {
        this.logService.setUserName(order?.userDrawingSettings?.userName);
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(
            LogConsts.INFO,
            'Loaded order from device: ' +
              this.deviceService.getDeviceInfo().userAgent
          )
          .subscribe(() => {});

        this.postLoadStageCreation(order);
        this.productOrderService.LoadOrderEmitter.emit(order);
        this.navbarService.spinnerActionEmitter.emit({
          action: 'reset',
          text: 'PRODUCT_ORDER_LOADED',
        });
        this.updateUserPermissions(order);
        console.log(order);
      },
      (error) => {
        alert('error status: ' + error.status);
        // tslint:disable-next-line: no-empty
        this.logService
          .postLog(LogConsts.ERROR, 'Cannot load order')
          .subscribe(() => {});
      }
    );
  }

  setFictionalProducts(): void {
    this.productService.getFictionalProductListData().subscribe(
      (x) => {
        this.productService.fictionalProductListEmitter.emit(x);
      },
      (error) => {}
    );
  }

  getProductList(): void {
    this.productService.getProductListData().subscribe(
      (x) => {
        this.productService.productsListEmitter.emit(x);
      },
      (error) => {
        this.productService.getLocalProductListData().subscribe((x) => {
          this.productService.productsListEmitter.emit(x);
        });
      }
    );
  }

  postLoadStageCreation(order): void {
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'CREATING_STAGE',
    });
    const n = DrawingConfigExtractor.parseConfig(order.drawingConfig);
    const o = n?.config;

    this.navbarService.configEmitter.emit({
      snap: o?.snap ? o.snap : ConfigConsts.MEDIUM,
      closeBinOnClick: o?.closeBinOnClick ? o.closeBinOnClick : ConfigConsts.NO,
      automaticHeatshields: o?.automaticHeatshields
        ? o.automaticHeatshields
        : ConfigConsts.YES,
      fixedDimensions: o?.fixedDimensions ? o.fixedDimensions : ConfigConsts.NO,
      // autoWidthSockel: o?.autoWidthSockel ? o.autoWidthSockel : ConfigConsts.YES,
      autoWidthDekorlist: o?.autoWidthDekorlist
        ? o.autoWidthDekorlist
        : ConfigConsts.YES,
      ffdoorInteraction: o?.ffdoorInteraction
        ? o.ffdoorInteraction
        : ConfigConsts.YES,
      autoSave: o?.autoSave ? o.autoSave : '5',
    });

    this.navbarService.orderStatusEmitter.emit(order.status);

    this.loading = false;
    this.navbarService.stageActionEmitter.emit(Actions.CREATE_STAGE);

    // all basic elements should be downloaded from productorder

    this.navbarService.sidebarSettingsEmitter.emit({
      automaticZoom: true,
      automaticZoomOut: false,
      manualZoom: false,
      moveable: false,
      zoom: 1,
    });

    if (this.storage.get(StorageConsts.PDF_STATUS) === 'active') {
      this.storage.set(StorageConsts.PDF_STATUS, 'none');
      this.navbarService.stageActionEmitter.emit(Actions.GENERATE_PDF);
    }

    const generalSettings = new GeneralSettings(
      SettingMapUtils.mapSettings(order.productOrderSettings, 0)
    );

    generalSettings.settings.push(
      FictionalSettingsUtil.getFictionalDekorlistSetting()
    );
    generalSettings.settings.push(
      FictionalSettingsUtil.getFictionalSockelSetting()
    );
    generalSettings.settings.push(
      FictionalSettingsUtil.getFictionalNewCabinSetting()
    );

    if (n) {
      FictionalSettingsUtil.updateGeneralSettings(
        generalSettings,
        n.settingValues
      );
    }
    this.navbarService.generalSettingsEmitter.emit(generalSettings);

    this.navbarService.spinnerActionEmitter.emit({ action: 'reset', text: '' });
  }

  updateUserPermissions(order): void {
    if (order.userDrawingSettings) {
      this.auth.permissionsEmitter.emit(order.userDrawingSettings);
    } else {
      const n = this.storage.get(StorageConsts.ACCESS_TOKEN);
      if (n) {
        const m = this.auth.parseJwt(n).access;
        if (m.find((p: string) => p === 'EDIT_SETTING')) {
          this.auth.permissionsEmitter.emit({
            editCalculatedSettings: true,
            editDrawing: true,
            ignoreValidations: false,
            readDrawing: true,
          });
        } else {
          this.auth.permissionsEmitter.emit({
            editCalculatedSettings: false,
            editDrawing: true,
            ignoreValidations: false,
            readDrawing: true,
          });
        }
      }
    }
  }

  getValidations(): void {
    this.validationService.getValidations().subscribe((response) => {
      this.validationService.validationEmitter.emit(
        ValidationUtils.createValidations(response)
      );
    });
  }
}
