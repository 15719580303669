import Konva from 'konva';
import { Entity } from './entity';

export class Wall extends Entity {
  isWall = true;
  wallName: string;
  selectedSide: string;
  sockelHeight: number;
  stroke: number;
  recentlyEdited = false;

  constructor(
    context: any,
    wallName: string,
    selectedSide: string,
    sockelHeight: number
  ) {
    super(context);
    this.wallName = wallName;
    this.selectedSide = selectedSide;
    this.attrs.name = 'wall';
    this.stroke = 3;
    this.sockelHeight = sockelHeight;
    this.drawBasicRect();
  }

  drawBasicRect() {
    this.clearWall();
    this.add(
      new Konva.Rect({
        width: this.attrs.width,
        height: this.attrs.height,
        fill: 'white',
      })
    );
    // top
    this.add(
      new Konva.Rect({
        x: -this.stroke,
        y: -this.stroke,
        width: this.attrs.width + 2 * this.stroke,
        height: this.stroke,
        name: 'prodStroke',
        fill: 'black',
      })
    );
    // right
    this.add(
      new Konva.Rect({
        x: this.attrs.width,
        width: this.stroke,
        height: this.attrs.height + this.stroke,
        name: 'prodStroke',
        fill: 'black',
      })
    );
    // bottom
    this.add(
      new Konva.Rect({
        y: this.attrs.height,
        width: this.attrs.width + this.stroke,
        height: this.stroke,
        name: 'prodStroke',
        fill: 'black',
      })
    );
    // left
    this.add(
      new Konva.Rect({
        x: -this.stroke,
        width: this.stroke,
        height: this.attrs.height + this.stroke,
        name: 'prodStroke',
        fill: 'black',
      })
    );
  }

  clearWall() {
    this.find('.prodStroke').each((stroke) => {
      stroke.destroy();
    });
    this.find('.rect').each((rect) => {
      rect.destroy();
    });
  }
}
