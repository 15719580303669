export class Option {
  id: number;
  name: string;
  value: string;
  active = true;

  constructor(id, name, value) {
    this.id = id;
    this.name = name;
    this.value = value;
  }
}
