<div
  class="modal fade"
  id="wallListModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "WALLS" | translate }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul
          *ngIf="editDrawing"
          id="list"
          class="list-group"
          [sortablejs]="walls"
          [sortablejsOptions]="sorted"
        >
          <li
            *ngFor="let wall of walls; index as index"
            class="list-group-item custom-list-item"
          >
            <div class="d-flex justify-content-between">
              <div class="btn-group btn-group-lg custom-width">
                <button
                  class="btn btn-secondary custom-width"
                  (click)="select(index)"
                >
                  <div class="dr-text-wrap">
                    {{ wall.wallName }}
                  </div>
                </button>
              </div>
              <div class="btn-group btn-group-md" role="group">
                <button
                  class="btn btn-secondary"
                  (click)="delete(wall)"
                  [disabled]="walls.length === 1 || !allowDelete"
                >
                  <i-bs name="trash"></i-bs>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="edit(wall)"
                  data-toggle="modal"
                  data-target="#wallEditModal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i-bs name="tools"></i-bs>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  [disabled]="true || walls.length === 1"
                >
                  <i-bs name="arrow-down-up"></i-bs>
                </button>
              </div>
            </div>
          </li>
        </ul>
        <ul *ngIf="!editDrawing" id="list" class="list-group">
          <li
            *ngFor="let wall of walls; index as index"
            class="list-group-item custom-list-item"
          >
            <div class="d-flex justify-content-between">
              <div class="btn-group btn-group-lg custom-width">
                <button
                  class="btn btn-secondary custom-width"
                  (click)="select(index)"
                >
                  <div class="dr-text-wrap">
                    {{ wall.wallName }}
                  </div>
                </button>
              </div>
              <div class="btn-group btn-group-md" role="group">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="edit(wall)"
                  data-toggle="modal"
                  data-target="#wallEditModal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i-bs name="tools"></i-bs>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer custom-footer">
        <div class="btn-group btn-group-md" role="group">
          <button class="btn btn-secondary" (click)="toggleDelete()">
            <i-bs *ngIf="!allowDelete" name="lock"></i-bs>
            <i-bs *ngIf="allowDelete" name="unlock"></i-bs>
            <i-bs name="trash"></i-bs>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
