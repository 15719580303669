import { Component, OnDestroy, OnInit } from '@angular/core';
import { Alert } from 'src/app/model/alert';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, OnDestroy {
  alert: Alert = new Alert('', '', '');
  // INFO ACTION ALERT ALERT_ACTION
  alertEmitterSub;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertEmitterSub = this.alertService.alertEmitter.subscribe(
      (alert: Alert) => {
        this.alert = alert;
      }
    );
  }

  ngOnDestroy(): void {
    this.alertEmitterSub.unsubscribe();
  }

  ok(): void {
    this.alertService.alertResponseEmitter.emit(this.alert.key);
  }
}
