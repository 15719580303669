import { Component } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-file-menu',
  templateUrl: './file-menu.component.html',
  styleUrls: ['./file-menu.component.scss'],
})
export class FileMenuComponent {
  constructor(private navbarService: NavbarService) {}

  saveLocally(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_LOCALLY);
  }

  loadLocal(): void {
    this.navbarService.stageActionEmitter.emit(Actions.LOAD_LOCAL);
  }

  saveOrder(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_ORDER);
  }
}
