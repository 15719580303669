export class ValidationCommunicate {
  left: number;
  right: number;
  diff: number;
  message: string;
  out: string;
  productName: string;
  isError: boolean;
  isValidated: boolean;
  isUnvalidated: boolean;

  constructor(message: string) {
    this.message = message;
    this.left = 0;
    this.right = 0;
    this.diff = 0;
    this.out = '';
    this.productName = '';
  }

  changeLeft(val) {
    this.left = val;
    this.updateOut();
  }

  changeRight(val) {
    this.right = val;
    this.updateOut();
  }

  changeDiff(val) {
    this.diff = val;
    this.updateOut();
  }

  updateOut() {
    this.out = this.message
      .replace('$result.left', this.left.toString())
      .replace('$result.right', this.right.toString())
      .replace('$result.diff', this.diff.toString());
  }
}
