<div class="fixed-bottom">
  <div class="custom badge badge-secondary">
    <div class="d-flex flex-column">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchInnerMeasurements()"
        [ngClass]="measurementConfig?.inner ? 'btn-light' : 'btn-secondary'"
      >
        {{ "MEASUREMENTS_INSIDE" | translate : "" }}
      </button>
      <button
        *ngIf="showOuter"
        type="button"
        class="btn btn-secondary"
        (click)="switchOuterMeasurements()"
        [ngClass]="measurementConfig?.outer ? 'btn-light' : 'btn-secondary'"
      >
        {{ "MEASUREMENTS_OUTSIDE" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchTags()"
        [ngClass]="measurementConfig?.tags ? 'btn-light' : 'btn-secondary'"
      >
        {{ "NAME" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchDrawerAlign()"
        [ngClass]="
          measurementConfig?.drawerAlign ? 'btn-light' : 'btn-secondary'
        "
      >
        {{ "DRAWER_ALIGN" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchFilter()"
        [ngClass]="measurementConfig?.filterOn ? 'btn-light' : 'btn-secondary'"
      >
        {{ "FILTER" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchNewCabin()"
        [ngClass]="measurementConfig?.newCabins ? 'btn-light' : 'btn-secondary'"
      >
        {{ "NEW_CABINS" | translate }}
      </button>
      <button
        type=" button"
        class="btn btn-secondary"
        (click)="switchAll()"
        [ngClass]="
          measurementConfig?.outer && measurementConfig?.inner
            ? 'btn-light'
            : 'btn-secondary'
        "
      >
        {{ "ALL" | translate }}
      </button>
    </div>
  </div>
</div>
