export class ValidationItem {
  item: string;
  itemId: number;
  sortIndex: number;
  type: string;
  validation: any;

  constructor(
    item: string,
    itemId: number,
    sortIndex: number,
    type: string,
    validation: any
  ) {
    this.item = item;
    this.itemId = itemId;
    this.sortIndex = sortIndex;
    this.type = type;
    this.validation = validation;
  }
}
