import { Vector } from 'html2canvas/dist/types/render/vector';
import Konva from 'konva';

export class AlertDots extends Konva.Group {
  slots: Vector[] = [{ x: 60, y: 25 } as Vector, { x: 120, y: 25 } as Vector];

  constructor(context, comment, alert) {
    super(context);
    this.attrs.name = 'dots';
    this.attrs.x = 0;
    this.attrs.y = 0;
    if (comment) {
      this.drawCommentDot(this.slots[0]);
      if (alert) {
        this.drawAlertDot(this.slots[1]);
      }
    } else if (alert) {
      this.drawAlertDot(this.slots[0]);
    }
  }

  drawCommentDot(pos: Vector): void {
    this.add(
      new Konva.Rect({
        x: this.attrs.width - pos.x,
        y: pos.y,
        width: 40,
        height: 40,
        fill: 'rgb(242, 192, 40)',
        name: 'commentDot',
        cornerRadius: [5, 5, 5, 5],
        opacity: 0.8,
      })
    );
  }

  drawAlertDot(pos: Vector): void {
    this.add(
      new Konva.Rect({
        x: this.attrs.width - pos.x,
        y: pos.y,
        width: 40,
        height: 40,
        fill: 'rgb(242, 87, 50)',
        name: 'alertDot',
        cornerRadius: [5, 5, 5, 5],
        opacity: 0.8,
      })
    );
  }
}
