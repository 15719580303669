import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MainViewComponent } from './components/main-view/main-view.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { WallFormComponent } from './components/wall-form/wall-form.component';
import { BinComponent } from './components/bin/bin.component';
import { SelectedProductMenuComponent } from './components/selected-product-menu/selected-product-menu.component';
import { MeasurementMenuComponent } from './components/measurement-menu/measurement-menu.component';
import { InteriorComponent } from './components/interior/interior.component';
import { SelectedProductNavbarComponent } from './components/selected-product-navbar/selected-product-navbar.component';
import { FileMenuComponent } from './components/file-menu/file-menu.component';
import { GeneralSettingsMenuComponent } from './components/general-settings-menu/general-settings-menu.component';
import { ProductFormSpecificComponent } from './components/product-form-specific/product-form-specific.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { WallEditComponent } from './components/wall-edit/wall-edit.component';
import { WallListComponent } from './components/wall-list/wall-list.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ProductFormListComponent } from './components/product-form-list/product-form-list.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LocalStorageModule } from 'angular-2-local-storage';
import { AuthComponent } from './components/auth/auth.component';
import { QuoteComponent } from './components/quote/quote.component';
import { OrderComponent } from './components/order/order.component';
import { InfoComponent } from './components/info/info.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BackToHkComponent } from './components/back-to-hk/back-to-hk.component';
import { PdfGenerateComponent } from './components/pdf-generate/pdf-generate.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { GeneralInformationComponent } from './components/general-information/general-information.component';
import { GeneralCommentComponent } from './components/general-comment/general-comment.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { MinorCanvasComponent } from './components/minor-canvas/minor-canvas.component';
import { MinorBinComponent } from './components/minor-bin/minor-bin.component';
import { DrawerPositionerMenuComponent } from './components/drawer-positioner-menu/drawer-positioner-menu.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    CanvasComponent,
    NavbarComponent,
    MainViewComponent,
    ProductFormComponent,
    WallFormComponent,
    BinComponent,
    SelectedProductMenuComponent,
    MeasurementMenuComponent,
    InteriorComponent,
    SelectedProductNavbarComponent,
    FileMenuComponent,
    GeneralSettingsMenuComponent,
    ProductFormSpecificComponent,
    SideNavbarComponent,
    WallEditComponent,
    WallListComponent,
    ProductFormListComponent,
    AuthComponent,
    QuoteComponent,
    OrderComponent,
    InfoComponent,
    SpinnerComponent,
    BackToHkComponent,
    PdfGenerateComponent,
    RedirectComponent,
    GeneralInformationComponent,
    GeneralCommentComponent,
    ConfigurationComponent,
    MinorCanvasComponent,
    MinorBinComponent,
    DrawerPositionerMenuComponent,
    InfoBoxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxBootstrapIconsModule.pick(allIcons, {
      width: '1.5em',
      height: '1.5em',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    FormsModule,
    LocalStorageModule.forRoot({
      prefix: 'drawing',
      storageType: 'localStorage',
    }),
    SortablejsModule.forRoot({ animation: 50 }),
    AuthModule.forRoot({
      // The domain and clientId were configured in the previous chapter
      domain: 'YOUR_DOMAIN',
      clientId: 'YOUR_CLIENT_ID',

      // Request this audience at user authentication time
      audience: 'https://YOUR_DOMAIN/api/v2/',

      // Request this scope at user authentication time
      scope: 'read:current_user',

      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
            uri: 'https://YOUR_DOMAIN/api/v2/*',
            tokenOptions: {
              // The attached token should target this audience
              audience: 'https://YOUR_DOMAIN/api/v2/',

              // The attached token should have these scopes
              scope: 'read:current_user',
            },
          },
        ],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
