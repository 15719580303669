
import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';
import { Setting } from '../model/setting';

export class hideSettingsUtil {
  static hideUnusedSettings(product: Product) {
    if (!product.settings) {
      return;
    }
    product.settings.forEach((setting: Setting) => {
      if (!setting.mapping) {
        return;
      }
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HINGES_CC_TOP2,
        Mapping.HEIGHT,
        899,
        'more'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HINGES_CC_BOTTOM2,
        Mapping.HEIGHT,
        1499,
        'more'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.FRONT_BOTTOM,
        Mapping.FRONT_SPICERACK_BOTTOM,
        '1',
        'equal'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.FRONT_TOP,
        Mapping.FRONT_SPICERACK_TOP,
        '1',
        'equal'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HINGE_CC_EXTRA,
        Mapping.EXTRA_HINGE,
        'YES',
        'equal'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HINGES_AMOUNT,
        '',
        '',
        'always'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HANDLES_YES_NO,
        Mapping.TIPON1,
        '0',
        'equal'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HANDLE_LAT_HORZ,
        Mapping.TIPON1,
        '0',
        'equal'
      );
      this.updateSetting(
        product.settings,
        setting,
        Mapping.HANDLES_TYPE,
        Mapping.TIPON1,
        '0',
        'equal'
      );
    });
  }

  static findSetting(settings: Setting[], mapping: string): Setting {
    return settings.find(
      (s: Setting) => s.mapping && s.mapping.name === mapping
    );
  }


    static updateSetting(settings: Setting[], sts: Setting, stms: string, stmc: string, cond: any, x: string) {
        //
        if (sts.mapping.name === stms) {
            if (x === 'always') {
                sts.show = false;
            }
            const s = this.findSetting(settings, stmc);
            if (!s) {
                return;
            }
            switch (x) {
                case 'more':
                    if (!s || !(s.value >= cond)) {
                        sts.show = false;
                    } else {
                        sts.show = true;
                    }
                    break;
                case 'less':
                    if (!s || !(s.value <= cond)) {
                        sts.show = false;
                    } else {
                        sts.show = true;
                    }
                    break;
                case 'equal':
                    if (!s || !(s.value === cond)) {
                        sts.show = false;
                    } else {
                        sts.show = true;
                    }
                    break;
            }
        }
    }
  }

