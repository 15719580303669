import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Product } from 'src/app/model/product';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';
import { PositionNumberUtil } from 'src/app/utils/positionNumberUtil';

export class DrawerStatus {
  drawer: Product;
  status: boolean;
}

@Component({
  selector: 'app-drawer-positioner-menu',
  templateUrl: './drawer-positioner-menu.component.html',
  styleUrls: ['./drawer-positioner-menu.component.scss'],
})
export class DrawerPositionerMenuComponent implements OnDestroy {
  drawers: DrawerStatus[] = [];
  mainDrawer: Product;
  productsEmitterSub;

  constructor(
    private productService: ProductService,
    private navbarService: NavbarService,
    private logService: LogService
  ) {
    this.productsEmitterSub = this.productService.allProductsEmitter.subscribe(
      (products: Product[]) => {
        const d = PositionNumberUtil.sortByPositionnumber(
          this.filterDrawers(products)
        );
        this.drawers = [];
        d.forEach((dd: Product) => {
          this.drawers.push({ drawer: dd, status: true });
        });
        this.drawers.forEach((drawer: DrawerStatus) => {
          if (drawer.drawer.isActive) {
            this.mainDrawer = drawer.drawer;
            drawer.status = false;
          }
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.productsEmitterSub.unsubscribe();
  }

  filterDrawers(products: Product[]): Product[] {
    if (!products) {
      return null;
    }
    return products.filter((product: Product) => {
      if (
        product.productSettings &&
        product.productSettings.TOP_FRONT_EDIT !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  changeMainDrawer(drawer: any): void {
    this.drawers.forEach((d: any) => {
      d.status = true;
    });
    this.mainDrawer = drawer.drawer;
    drawer.status = false;
  }

  applyDrawerChanges(): void {
    this.drawers.forEach((drawer: any) => {
      if (!drawer.status) {
        return;
      }
      if (
        this.mainDrawer.productSettings.TOP_FRONT !==
        drawer.drawer.productSettings.TOP_FRONT
      ) {
        drawer.drawer.changeValue('TOP_FRONT_EDIT', 0);
        drawer.drawer.changeValue(
          'TOP_FRONT_EDIT',
          this.mainDrawer.productSettings.TOP_FRONT -
            drawer.drawer.productSettings.TOP_FRONT
        );
      }
    });
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Aligning drawers')
      .subscribe(() => {});
    this.navbarService.stageActionEmitter.emit(Actions.REDRAW);
  }

  enableDrawerForEdit(d: any): void {
    d.status = !d.status;
  }
}
