<div id="mainContainer">
  <app-side-navbar [ngClass]="loading ? 'hide' : 'show'"></app-side-navbar>
  <app-general-information
    [ngClass]="loading ? 'hide' : 'show'"
  ></app-general-information>
  <app-product-form></app-product-form>
  <app-product-form-list></app-product-form-list>
  <app-product-form-specific></app-product-form-specific>
  <app-wall-form></app-wall-form>
  <app-configuration></app-configuration>
  <app-general-settings-menu></app-general-settings-menu>
  <app-bin></app-bin>
  <app-minor-bin></app-minor-bin>
  <app-wall-edit></app-wall-edit>
  <app-drawer-positioner-menu></app-drawer-positioner-menu>
  <app-wall-list></app-wall-list>
  <app-interior></app-interior>
  <app-info-box></app-info-box>
  <app-canvas></app-canvas>
  <app-back-to-hk></app-back-to-hk>
  <app-navbar [ngClass]="loading ? 'hide' : 'show'"></app-navbar>
  <app-info></app-info>
  <app-spinner></app-spinner>
</div>
