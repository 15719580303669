import { Component, OnDestroy, OnInit } from '@angular/core';
import { Layer } from 'konva/types/Layer';
import { Options } from 'sortablejs';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Wall } from 'src/app/model/wall';
import { AuthorisationService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-wall-list',
  templateUrl: './wall-list.component.html',
  styleUrls: ['./wall-list.component.scss'],
})
export class WallListComponent implements OnInit, OnDestroy {
  walls: Wall[] = [];
  layers: Layer[] = [];
  activeLayer: Layer;
  sorted: Options;
  editDrawing: boolean;

  allowDelete: boolean;

  layerEmitterSub;
  layersEmitterSub;
  permissionsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private authService: AuthorisationService,
    private logService: LogService
  ) {
    this.layerEmitterSub = this.navbarService.layerEmitter.subscribe(
      (layer) => {
        this.activeLayer = layer;
      }
    );
    this.layersEmitterSub = this.navbarService.layersEmitter.subscribe(
      (layers) => {
        const walls = [];
        this.layers = layers;
        layers.forEach((layer, i) => {
          layer.find('.wall').each((wall) => walls.push(wall));
        });
        this.walls = walls;
      }
    );

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editDrawing = permissions.editDrawing;
        }
      }
    );

    this.allowDelete = false;
  }

  ngOnDestroy(): void {
    this.layerEmitterSub.unsubscribe();
    this.layersEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
  }

  ngOnInit(): void {
    this.sorted = {
      onUpdate: (event) => {
        if (!this.editDrawing) {
          return;
        }
        if (Math.abs(event.oldIndex - event.newIndex) === 1) {
          const oldL = this.layers[event.oldIndex];
          const newL = this.layers[event.newIndex];
          this.layers[event.oldIndex] = newL;
          this.layers[event.newIndex] = oldL;
        } else if (event.oldIndex > event.newIndex) {
          const oldL = this.layers[event.oldIndex];
          this.layers.splice(event.newIndex, 0, oldL);
          this.layers.splice(event.oldIndex + 1, 1);
        } else {
          const oldL = this.layers[event.oldIndex];
          this.layers.splice(event.newIndex + 1, 0, oldL);
          this.layers.splice(event.oldIndex, 1);
        }
        if (this.activeLayer.index === this.layers[event.oldIndex].index) {
          this.select(event.newIndex);
        } else {
          this.layers.forEach((layer, i) => {
            if (this.activeLayer.index === layer.index) {
              this.select(i);
            }
          });
        }
        this.navbarService.stageActionEmitter.emit(Actions.CLEANUP_LAYERS);
      },
    };
  }

  toggleDelete(): void {
    this.allowDelete = !this.allowDelete;
  }

  delete(wall): void {
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Deleting wall: ' + wall.wallName)
      .subscribe(() => {});
    wall.destroy();
    this.navbarService.stageActionEmitter.emit(Actions.CLEANUP_LAYERS);
  }

  edit(wall): void {
    // tslint:disable-next-line: no-empty
    this.logService.postLog(LogConsts.INFO, 'Editing wall').subscribe(() => {});
    this.navbarService.wallEditEmitter.emit(wall);
  }

  select(index): void {
    this.navbarService.switchLayersEmitter.emit(index);
  }
}
