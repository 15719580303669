import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogConsts } from 'src/app/consts/logConsts';
import { WallConsts } from 'src/app/consts/wallConsts';
import { Wall } from 'src/app/model/wall';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-wall-form',
  templateUrl: './wall-form.component.html',
  styleUrls: ['./wall-form.component.scss'],
})
export class WallFormComponent implements OnInit, OnDestroy {
  wall: Wall;

  selectedSide: string;
  sockelHeight: number;

  newWallCleanupEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private logService: LogService
  ) {
    this.newWallCleanupEmitterSub =
      this.navbarService.newWallCleanupEmitter.subscribe((response) => {
        this.initBasicWall();
        this.selectedSide = null;
      });
  }

  ngOnDestroy(): void {
    this.newWallCleanupEmitterSub.unsubscribe();
  }

  ngOnInit(): void {
    this.initBasicWall();
  }

  createWall(): void {
    if (this.wall.attrs.width < WallConsts.WIDTH_MIN) {
      this.wall.attrs.width = WallConsts.WIDTH_MIN;
    }
    if (this.wall.attrs.width > WallConsts.WIDTH_MAX) {
      this.wall.attrs.width = WallConsts.WIDTH_MAX;
    }
    this.navbarService.newWallEmitter.emit(
      new Wall(
        {
          x: 0,
          y: 0,
          width: this.wall.attrs.width,
          height: this.wall.attrs.height,
          stroke: 'black',
          strokeWidth: 1,
          name: 'wall',
        },
        this.wall.wallName,
        this.selectedSide,
        this.wall.sockelHeight
      )
    );
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Adding new wall: ' + this.wall.wallName)
      .subscribe(() => {});
  }

  initBasicWall(): void {
    this.wall = new Wall(
      {
        width: WallConsts.INITIAL_WIDTH,
        height: WallConsts.INITIAL_HEIGHT,
        stroke: 'black',
        strokeWidth: 5,
        name: 'wall',
      },
      null,
      null,
      WallConsts.INITIAL_SOCKEL
    );
  }

  changeSideSelection(side: string): void {
    if (this.selectedSide === side) {
      this.selectedSide = null;
      return;
    }
    this.selectedSide = side;
    // if (this.wall.wallName === null ||
    //     this.wall.wallName === '' ||
    //     this.wall.wallName.toLowerCase() === 'right wall' ||
    //     this.wall.wallName.toLowerCase() === 'left wall') {
    //   if (side === 'R') {
    //     this.wall.wallName = 'right wall'
    //   } else if (side === 'L') {
    //     this.wall.wallName = 'left wall'
    //   } else {
    //     this.wall.wallName = null;
    //   }
    // }
  }
}
