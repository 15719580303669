import Konva from 'konva';
import { Vector2d } from 'konva/types/types';

export class MeasureLine extends Konva.Group {
  line: Konva.Line;
  Flag: Konva.Text;
  anchor1: Vector2d;
  anchor2: Vector2d;
  fSize: number;
  additionalText = '';
  switchPlacement = false;
  overrideValue: number;
  changeColor: boolean;
  lengthValue: number;
  flagPosition: Vector2d;
  flagDimensions: Vector2d;
  text = '';
  textLength = 0;
  flagPlacement = '';

  constructor(
    context: any,
    anchor1: Vector2d,
    anchor2: Vector2d,
    fSize: number,
    additionalText?: string,
    switchPlacement?: boolean,
    overrideValue?: number,
    changeColor?: boolean,
    flagPlacement?: string
  ) {
    super(context);
    this.attrs.name = 'measurement-line';
    this.anchor1 = anchor1;
    this.anchor2 = anchor2;
    this.fSize = fSize;
    this.changeColor = changeColor;
    additionalText ? (this.additionalText = additionalText) : '';
    this.switchPlacement = switchPlacement;
    overrideValue ? (this.overrideValue = overrideValue) : null;
    flagPlacement ? (this.flagPlacement = flagPlacement) : null;
    this.flagPosition = { x: 0, y: 0 };
    this.flagDimensions = { x: 0, y: 0 };
    if (anchor1 == undefined || anchor2 == undefined) {
      return;
    }
    this.lengthValue = Math.abs(
      this.anchor1.x - this.anchor2.x + (this.anchor1.y - this.anchor2.y)
    );
    this.createLine(anchor1, anchor2);
    this.updateText();
    this.updateTextLength();
    this.updateFlagDimensions();
    this.updateFlagPosition();
    // this.createFlag(anchor1, anchor2);
  }

  createFlag(): void {
    if (this.anchor1 == undefined || this.anchor2 == undefined) {
      return;
    }

    if (this.lengthValue >= 0) {
      let fill = 'darkblue';
      let textFill = 'white';
      if (this.changeColor) {
        fill = 'rgb(242, 192, 40)';
        textFill = 'black';
      }
      // anchor and value
      this.add(
        new Konva.Rect({
          x: this.flagPosition.x,
          y: this.flagPosition.y,
          width: this.flagDimensions.x,
          cornerRadius: 2 / this.fSize,
          height: this.flagDimensions.y,
          fill,
          name: 'measurement-flag',
        })
      );
      this.add(
        new Konva.Text({
          x: this.flagPosition.x + 1 / this.fSize,
          y: this.flagPosition.y + 0.5 / this.fSize,
          text: this.text,
          fontSize: 10 / this.fSize,
          fontFamily: 'Arial',
          align: 'center',
          fill: textFill,
        })
      );
    }
  }

  createLine(anchor1, anchor2): void {
    if (anchor1 == undefined || anchor2 == undefined) {
      return;
    }
    this.add(
      new Konva.Line({
        points: [
          Number(anchor1.x),
          Number(anchor1.y),
          Number(anchor2.x),
          Number(anchor2.y),
        ],
        stroke: 'rgb(100, 100, 100)',
        dash: [12, 4],
        strokeWidth: 3,
        name: 'line',
      })
    );
  }

  updateText(): void {
    if (this.overrideValue) {
      this.text = this.additionalText + this.overrideValue;
    } else {
      this.text = this.additionalText + this.lengthValue.toFixed(0);
    }
  }

  updateTextLength(): void {
    this.textLength = this.text.length;
    if (this.additionalText) {
      this.textLength -= 1;
    }
  }

  updateFlagDimensions(): void {
    this.flagDimensions.x = (this.textLength * 5 + 5) / this.fSize;
    this.flagDimensions.y = 10 / this.fSize;
  }

  updateFlagPosition(): void {
    if (this.flagPlacement === 'TOP') {
      this.flagPosition.y = this.anchor2.y - 40;
    }
    if (this.flagPlacement === 'BOTTOM') {
      this.flagPosition.y = this.anchor1.y + 20;
    }
    if (this.flagPlacement === '') {
      this.flagPosition.y = (this.anchor1.y + this.anchor2.y) / 2;
    }
    if (this.switchPlacement) {
      this.flagPosition.x = (this.anchor1.x + this.anchor2.x) / 2;
    } else {
      this.flagPosition.x =
        (this.anchor1.x + this.anchor2.x) / 2 -
        (this.textLength * 5 + 5) / this.fSize;
    }
  }

  getLength(): Vector2d {
    return {
      x: Math.abs(this.anchor1.x - this.anchor2.x),
      y: Math.abs(this.anchor1.y - this.anchor2.y),
    };
  }

  getTotalLength(): number {
    const x = Math.abs(this.anchor1.x - this.anchor2.x);
    const y = Math.abs(this.anchor1.y - this.anchor2.y);
    return x > y ? x : y;
  }

  changeFlagOrientation() {
    this.switchPlacement = !this.switchPlacement;
  }

  checkIfVertical(): boolean {
    if (this.anchor1.x === this.anchor2.x) {
      return true;
    } else {
      return false;
    }
  }
}
