import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import Konva from 'konva';
import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';
import { ConfigConsts } from 'src/app/consts/configConsts';
import { Product } from 'src/app/model/product';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';
import { Measurements } from 'src/app/utils/measurements';
import { ProductUtils } from 'src/app/utils/productUtils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-minor-canvas',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './minor-canvas.component.html',
  styleUrls: ['./minor-canvas.component.scss'],
})
export class MinorCanvasComponent implements OnChanges {
  minorStage: Stage;
  layer: Layer;
  @Input('productCopy') product: Product;

  showAddButton = false;
  showPicture = false;
  allowPicture = false;

  pictureURL: string;
  imageToShow: any;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService
  ) {}

  initStage(): Stage {
    const stage = new Konva.Stage({
      x: 0,
      y: 0,
      container: 'minorContainer',
      width: 470,
      height: 1200,
      scaleX: 0.5,
      scaleY: 0.5,
    });

    // define size and position

    return stage;
  }

  initLayer(): Layer {
    const layer = new Konva.Layer();
    return layer;
  }

  ngOnChanges(): void {
    this.productUpdate();
  }

  productUpdate(): void {
    if (!this.minorStage) {
      this.minorStage = this.initStage();
    }
    if (!this.layer) {
      this.layer = this.initLayer();
      this.minorStage.add(this.layer);
    }
    this.layer.find('.product').each((product: Product) => {
      product.destroy();
    });
    if (this.product.attrs.x === 0.5) {
      this.showAddButton = true;
    } else {
      this.showAddButton = false;
    }
    if (this.product) {
      this.product.isActive = false;
      this.product.modified = true;
      this.product.clearMeasurements();
      this.product.updateBorderColors();
      this.product.deletePositionMarker();
      this.product.deleteComment();
      this.product.deleteSubproductIndicator();
      this.product.position({ x: 100, y: 100 });
      this.product.deleteDekorlistSockel();
      this.product.updateProduct();
      this.product.updateProduct();
      this.product.updateSubproducts();
      this.layer.add(this.product);
      if (this.product.graphicFileID) {
        const pURL = environment.productFile + this.product.graphicFileID;
        if (pURL !== this.pictureURL) {
          this.pictureURL = pURL;
          this.productService.getImage(this.pictureURL).subscribe(
            (imageBlob) => {
              this.allowPicture = true;
              this.createImageFromBlob(imageBlob);
            },
            (error) => {
              this.allowPicture = false;
              this.showPicture = false;
            }
          );
          console.log(this.pictureURL);
        } else {
          this.allowPicture = false;
          this.showPicture = false;
        }
      }
    }
    Measurements.showInnerMeasurements(this.layer, 0.4, ConfigConsts.YES, true);
    console.log('after', this.product);
    this.minorStage.draw();
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.imageToShow =
          'data:image/jpeg' + reader.result.toString().substring(13);
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  addProduct(): void {
    this.navbarService.newProductFromBinEmitterCloseInterior.emit(this.product);
  }

  togglePicture(): void {
    this.showPicture = !this.showPicture;
  }

  zoom(): void {
    let scale = 0;
    const w = this.minorStage.attrs.width / this.product.attrs.width;
    const h = this.minorStage.attrs.height / this.product.attrs.height;

    if (w > h) {
      scale = h;
    } else {
      scale = w;
    }
    this.minorStage.attrs.x = -this.layer.attrs.x * scale;
    this.minorStage.scale({ x: scale, y: scale });
  }

  checkValidity(product): boolean {
    return ProductUtils.checkValidity(product);
  }
}
