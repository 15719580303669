import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Actions } from 'src/app/consts/actions';

import { Product } from 'src/app/model/product';
import { Setting } from 'src/app/model/setting';
import { AuthorisationService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductInteractionUtils } from 'src/app/utils/producInteractionUtils';

@Component({
  selector: 'app-interior',
  templateUrl: './interior.component.html',
  styleUrls: ['./interior.component.scss'],
})
export class InteriorComponent implements OnDestroy {
  tab = 1;
  activeProducts: Product[] = [];
  sampleProduct: Product;
  mainProduct: Product;
  subIndex = 0;
  productCopy: Product = new Product(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
  editCalculatedPermission: boolean;
  adminPermission: boolean;
  editDrawing: boolean;
  isFictional: boolean;

  productsEmitterSub;
  permissionsEmitterSub;
  newProductFromBinEmitterCloseInteriorSub;

  @ViewChild('close') close: ElementRef;
  @ViewChild('inputs') inputs: ElementRef;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService,
    private authService: AuthorisationService
  ) {
    this.navbarService.productActionEmitter.emit(Actions.UPDATE_MINOR_PRODUCT);

    this.productsEmitterSub = this.productService.productsEmitter.subscribe(
      (products: Product[]) => {
        this.activeProducts = products.filter((product) => product.isActive);
        if (
          this.activeProducts !== undefined &&
          this.activeProducts.length > 0
        ) {
          this.mainProduct = this.activeProducts[0];
          this.mainProduct.isActive = true;
          this.isFictional = this.mainProduct.isFictional;
          this.changeProductSelection();
          this.updateMinorProduct();
          if (this.mainProduct.isFictional) {
            this.mainProduct.tab = 3;
          }

          this.tab = this.mainProduct.tab;
        }
      }
    );

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editCalculatedPermission = permissions.editCalculatedSettings;
          this.adminPermission = permissions.editCalculatedSettings;
          this.editDrawing = permissions.editDrawing;
        }
      }
    );

    this.newProductFromBinEmitterCloseInteriorSub =
      this.navbarService.newProductFromBinEmitterCloseInterior.subscribe(
        (x: Product) => {
          this.close.nativeElement.click();
          this.navbarService.newProductFromBinEmitter.emit(this.mainProduct);
        }
      );
  }

  ngOnDestroy(): void {
    this.productsEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
    this.newProductFromBinEmitterCloseInteriorSub.unsubscribe();
  }

  setTab(tab): void {
    this.tab = tab;
    this.mainProduct.tab = tab;
  }

  onSettingChange(event, setting: Setting): void {
    // console.log(event)
    // this.sampleProduct.unfreezeSettings()
    this.activeProducts.forEach((product) => {
      if (setting.mapping && product.type === this.sampleProduct.type) {
        setting.isUnfrozing = true;
        setting.previousValue = setting.value;
        product.changeValue(setting.mapping.name, setting.value);
        product.checkForAlertDots();
        ProductInteractionUtils.automaticComments(product);
      }
    });
    this.updateMinorProduct();
    this.navbarService.productActionEmitter.emit(Actions.REDRAW);
  }

  onCalculatedSettingChange(event, setting: Setting): void {
    setting.freeze();
    this.activeProducts.forEach((product) => {
      if (setting.mapping && product.type === this.sampleProduct.type) {
        product.changeValue(setting.mapping.name, setting.value);
      }
    });
    this.updateMinorProduct();
    this.navbarService.productActionEmitter.emit(Actions.REDRAW);
  }

  refreshSettings(): void {
    this.sampleProduct.unfreezeSettings();
    this.sampleProduct.updateSettings();
    this.updateMinorProduct();
  }

  onSettingUpdate(event, setting): void {
    setting.override = event.returnValue;
    this.sampleProduct.updateSettings();
    this.updateMinorProduct();
  }

  deleteSubProduct(): void {
    if (
      this.mainProduct.subProducts &&
      this.mainProduct.subProducts.length > 0 &&
      this.sampleProduct.subProducts &&
      this.sampleProduct.subProducts.length === 0
    ) {
      this.navbarService.newBinProductEmitter.emit(this.sampleProduct);
      this.sampleProduct.destroy();

      this.mainProduct.subProducts = this.mainProduct.subProducts.filter(
        (sub: Product) => sub.id() !== this.sampleProduct.id()
      );

      this.switchBetweenParentAndChild();
      this.updateMinorProduct();
    }
  }

  updateMinorProduct(): void {
    this.productCopy = this.mainProduct.clone();
    this.productCopy.fixedWidth = true;
    this.productCopy.graphicFileID = this.mainProduct.graphicFileID;
    this.productCopy.productSettings = { ...this.mainProduct.productSettings };
    this.productCopy.handlePlacement = this.mainProduct.handlePlacement;
    this.productCopy.settings = [...this.mainProduct.settings];
    this.productCopy.doors = [...this.mainProduct.doors];
    this.productCopy.design = this.mainProduct.design;
    this.productCopy.positionMarker = this.mainProduct.positionMarker;
    this.mainProduct.subProducts.forEach((sub: Product) => {
      this.productCopy.subProducts.push(sub);
    });
    if (this.mainProduct.isFictional) {
      this.productCopy.isFictional = this.mainProduct.isFictional;
      this.productCopy.fictionalID = this.mainProduct.fictionalID;
      this.productCopy.fictionalType = this.mainProduct.fictionalType;
    }
    this.navbarService.productActionEmitter.emit(Actions.UPDATE_MINOR_PRODUCT);
  }

  switchBetweenParentAndChild(): void {
    if (
      this.mainProduct.subProducts &&
      this.mainProduct.subProducts.length > this.subIndex
    ) {
      this.subIndex += 1;
    } else {
      this.subIndex = 0;
    }
    this.changeProductSelection();
  }

  unfocusAll(): void {
    if ('activeElement' in document) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  changeProductSelection(): void {
    if (this.subIndex === 0) {
      this.sampleProduct = this.mainProduct;
    } else {
      this.sampleProduct = this.mainProduct.subProducts[this.subIndex - 1];
    }
  }
}
