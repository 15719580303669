import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  userName;
  orderId;

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}
  postLog(level: string, message: string): Observable<any> {
    const log = { level: level, message: this.updateMessage(message) };
    console.log(log);
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'charset=utf-8');

    // return this.httpClient.get('./../assets/test.json');

    return this.httpClient.post(environment.log, log, {
      headers,
    });
  }

  updateMessage(message): string {
    let a = '';
    if (this.userName) {
      a = '[user: ' + this.userName + '] ';
    }
    let b = '';
    if (this.orderId) {
      b = '[order_id: ' + this.orderId + '] ';
    }

    return a + b + message;
  }

  setUserName(name: string): void {
    this.userName = name;
  }

  setOrderId(id: string): void {
    this.orderId = id;
  }
}
