<div class="navbar fixed-top custom-margin custom-colour d-flex flex-column">
  <div class="align-self-start custom-height">
    <p>
      {{ "ORDER_ID" | translate }}:
      {{ orderId }}
      |
      {{ "USER" | translate }}:
      {{ userName }}
    </p>
  </div>
  <div *ngIf="saveTime" class="align-self-start custom-height">
    <p>
      <i-bs *ngIf="saveUpdated" class="text-success" name="check"></i-bs>
      {{ "SAVE_DATE" | translate }}:
      {{ saveTime }}
    </p>
  </div>
</div>
