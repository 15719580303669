<div
  *ngIf="communicate && communicate.length !== 0"
  class="custom d-flex justify-content-center"
>
  <div class="card align-self-center">
    <div class="card-header d-flex flex-column">
      <div
        class="spinner-border text-secondary align-self-center"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <span *ngFor="let text of communicate" class="align-self-center">
        {{ text | translate }}.
      </span>
    </div>
  </div>
</div>
