import Konva from 'konva';
import { PositionNumber } from './position';

export class Front extends Konva.Group {
  visibilityType = 'exterior';
  placement;
  positionMarker = 0;

  constructor(context: any, placement: string) {
    super(context);
    this.attrs.x = 0;
    this.attrs.y = 0;
    this.placement = placement;
    this.drawFront();
    this.attrs.name = 'front';
  }

  drawFront(): void {
    if (!this.attrs.width || !this.attrs.height) {
      return;
    }

    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.attrs.width,
        height: this.attrs.height,
        name: 'frontStroke',
        stroke: 'black',
        strokeWidth: 1,
      })
    );

    // fillPattern
    this.drawFillPattern();
  }

  updatePositionMarker(upsideDown): void {
    let x = false;
    if (this.parent && this.parent.name() === 'drawer') {
      x = true;
    }
    this.add(
      new PositionNumber(this.attrs, this.positionMarker, upsideDown, x)
    );
  }

  drawFillPattern(): void {
    if (this.attrs.height) {
      this.add(
        new Konva.Line({
          points: [
            0,
            this.attrs.height / 2,
            this.attrs.width,
            this.attrs.height / 2,
          ],
          strokeWidth: this.attrs.height - 20,
          name: 'drawerFillPattern',
          stroke: 'black',
          dash: [1, 30],
        })
      );
    }
  }

  drawPdfFillPattern(): void {
    if (this.attrs.height) {
      for (let i = 0; i < 16; ++i) {
        this.add(
          new Konva.Line({
            points: [
              (i / 16) * this.attrs.width,
              0,
              (i / 16) * this.attrs.width,
              this.attrs.height,
            ],
            strokeWidth: 1,
            stroke: 'black',
          })
        );
      }
    }
  }
}
