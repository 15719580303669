import { Layer } from 'konva/types/Layer';
import { Product } from '../model/product';
import { ProductInteractionConfig } from '../model/productInteractionConfig';
import { Setting } from '../model/setting';
import { ValidationItem } from '../model/subvalidators/validationItem';
import { ValidationLevel } from '../model/subvalidators/validationLevel';
import { ValidationCommunicate } from '../model/subvalidators/validationMessage';
import { Validation } from '../model/validation';
import { CollisionDetection } from './collisonDetection';
import { OrderUtils } from './orderUtils';

export class ValidationUtils {
  // validate products
  static validateProducts(
    layers: Layer[],
    validations: Validation[],
    bin: Product[],
    productConfig: ProductInteractionConfig
  ): ValidationCommunicate[] {
    const validationComunnicates: ValidationCommunicate[] = [];
    const products: Product[] = [];

    layers.forEach((layer) => {
      layer.find('.product').each((product: Product) => {
        products.push(product);
      });
      CollisionDetection.checkCollisions(layer, productConfig);
    });

    if (!products) {
      return null;
    }
    products.forEach((product) => {
      let productValidity = product.isValid;
      product.validationMessages = [];

      if (!productValidity) {
        // for other languages create translateUtil
        const collisionMessage = new ValidationCommunicate('Kollision.');
        collisionMessage.productName =
          product.productSettings.POSITIONNUMBER + '.) ' + product.type;
        collisionMessage.isError = true;
        collisionMessage.out = 'Kollision.';
        product.validationMessages.push(collisionMessage);
        validationComunnicates.push(collisionMessage);
      }

      const vals = validations.filter((validation) => {
        return (
          (validation.product && validation.product.name === product.type) ||
          (validation.subcategory &&
            validation.subcategory.name === product.type) ||
          (validation.product &&
            validation.product.productID === product.productID) ||
          (validation.subcategory &&
            validation.subcategory.subCategoryID === product.subcategoryID)
        );
      });

      if (!vals) {
        return;
      }
      vals.forEach((val: Validation) => {
        if (!val.validationItems) {
          return;
        }
        const comp = val.validationItems.find(
          (item: ValidationItem) => item.type === 'COMPARISION'
        );

        if (!comp) {
          return;
        }

        const leftSide: ValidationItem[] = val.validationItems.filter(
          (item: ValidationItem) => item.sortIndex < comp.sortIndex
        );
        const rightSide: ValidationItem[] = val.validationItems.filter(
          (item: ValidationItem) => item.sortIndex > comp.sortIndex
        );

        const leftValue = this.calculate(leftSide, product);
        const rightValue = this.calculate(rightSide, product);

        if (!this.compare(leftValue, comp.item, rightValue)) {
          val.message.changeLeft(leftValue);
          val.message.changeRight(rightValue);
          val.message.productName =
            product.productSettings.POSITIONNUMBER + '.) ' + product.type;
          val.message.isError = true;
          product.validationMessages.push(val.message);
          validationComunnicates.push(val.message);
          productValidity = false;
        }
      });
      product.isValid = productValidity;
      if (productValidity) {
        const validMessage = new ValidationCommunicate('valid');
        validMessage.productName =
          product.productSettings.POSITIONNUMBER + '.) ' + product.type;
        validMessage.isValidated = true;
        validationComunnicates.push(validMessage);
      }
    });

    if (bin) {
      bin.forEach((product) => {
        const unvalidatedMessage = new ValidationCommunicate('');
        unvalidatedMessage.isUnvalidated = true;
        unvalidatedMessage.productName = product.type;
        validationComunnicates.push(unvalidatedMessage);
        this.validateProduct(product, validations);
      });
    }
    return validationComunnicates;
  }

  static validateProduct(product: Product, validations: Validation[]): boolean {
    product.validationMessages = [];
    const vals = validations.filter((validation) => {
      return (
        (validation.product && validation.product.name === product.type) ||
        (validation.subcategory && validation.subcategory.name === product.type)
      );
    });
    if (!vals) {
      return false;
    }
    product.isValid = true;
    vals.forEach((val: Validation) => {
      if (!val.validationItems) {
        return;
      }
      const comp = val.validationItems.find(
        (item: ValidationItem) => item.type === 'COMPARISION'
      );

      if (!comp) {
        return;
      }

      const leftSide: ValidationItem[] = val.validationItems.filter(
        (item: ValidationItem) => item.sortIndex < comp.sortIndex
      );
      const rightSide: ValidationItem[] = val.validationItems.filter(
        (item: ValidationItem) => item.sortIndex > comp.sortIndex
      );

      const leftValue = this.calculate(leftSide, product);
      const rightValue = this.calculate(rightSide, product);

      if (!this.compare(leftValue, comp.item, rightValue)) {
        val.message.changeLeft(leftValue);
        val.message.changeRight(rightValue);
        val.message.productName =
          product.productSettings.POSITIONNUMBER + '.) ' + product.type;
        val.message.isError = true;
        product.validationMessages.push(val.message);
        product.isValid = false;
      }
    });
    return product.isValid;
  }

  static checkSettingsValid(product: Product): boolean {
    const a = false;
    product.settings.forEach((s: Setting) => {
      if (s.dataType !== 'TEXT') {
        s.calculate(product.settings);
      }
    });
    return true;
  }

  static compare(left: number, comparision: string, right: number): boolean {
    switch (comparision) {
      case 'LESS_OR_EQUAL':
        return left <= right;
      case 'GREATER_OR_EQUAL':
        return left >= right;
      case 'LESS':
        return left < right;
      case 'GREATER':
        return left > right;
      case 'EQUAL':
        return left === right;
    }
    return true;
  }

  static calculate(
    validationItems: ValidationItem[],
    product: Product
  ): number {
    let op = '';
    validationItems.forEach((item: ValidationItem) => {
      switch (item.type) {
        case 'ARITHMETIC':
          op += this.parseArithmetics(item.item);
          break;
        case 'SETTING':
          op += this.getSettingValue(item.item, product.settings);
          break;
        case 'VALUE':
          op += item.item;
          break;
      }
    });

    if (!op.search('.*/0([^.]|$|.(0{4,}.*|0{1,4}([^0-9]|$))).*')) {
      op = '0';
    }

    if (Number(op) === 0) {
      op = '0';
    }

    return Math.round(this.parse(op));
  }

  static parseArithmetics(item): string {
    switch (item) {
      case 'PLUS':
        return '+';
      case 'MINUS':
        return '-';
      case 'DIVIDE':
        return '/';
      case 'MULTIPLY':
        return '*';
      case 'RIGHT_PARENTHESIS':
        return ')';
      case 'LEFT_PARENTHESIS':
        return '(';
      case 'POWER':
        return '**';
      case 'SQRT':
        return 'Math.sqrt';
    }
    return null;
  }

  static getSettingValue(item, settings): number {
    const x = settings.find((setting) => setting.id == item);
    return x && x.value ? x.value : 0;
  }

  // create and return validations
  static createValidations(data: any[]): Validation[] {
    if (!data) {
      return null;
    }
    const validations: Validation[] = [];
    data.forEach((v: any) => {
      // create components
      if (!v.activeOnDrawing || v.archived) {
        return;
      }
      let validationItems: ValidationItem[] = [];
      if (v.validationItems) {
        validationItems = v.validationItems.map((item) => {
          return new ValidationItem(
            item.item,
            item.itemId,
            item.sortIndex,
            item.type,
            item.validation
          );
        });
      }
      let validationLevel: ValidationLevel = null;
      if (v.validationLevel) {
        validationLevel = new ValidationLevel(
          v.validationLevel.advanced,
          v.validationLevel.basic,
          v.validationLevel.name,
          v.validationLevel.validationLevelID
        );
      }

      let message: ValidationCommunicate = null;
      if (v.message) {
        message = new ValidationCommunicate(v.message);
      }
      validations.push(
        new Validation(
          message,
          v.name,
          v.product,
          v.setting,
          v.subcategory,
          v.validationID,
          validationItems,
          validationLevel,
          v.validationType
        )
      );
    });
    return validations;
  }

  static checkValidity(layers: Layer[]): boolean {
    let isValid = true;
    layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        if (!product.isValid) {
          isValid = false;
        }
      });
    });
    return isValid;
  }

  static parse(str) {
    return Function(`'use strict'; return (${str})`)();
  }

  static getAvailableSimpleValidations(order, validations): Validation[] {
    const availableSubCategories = OrderUtils.extractSubcategories(order);
    const basicValidations = validations.filter(
      (validation: any) =>
        validation.validationType === 'BASIC' ||
        validation.validationType === 'GENERAL'
    );

    return basicValidations.filter((bval: Validation) => {
      let sub = false;
      if (!bval.subcategory) {
        return false;
      }
      availableSubCategories.forEach((a: any) => {
        if (!sub && bval.subcategory.name === a) {
          sub = true;
        }
      });
      if (order.validatedFromDrawing) {
        bval.checkSimpleValidation();
      }
      if (sub) {
        return true;
      } else {
        return false;
      }
    });
  }

  static customValidations(
    layers: Layer[],
    dekorlists: any[],
    sockels: any[]
  ): ValidationCommunicate[] {
    let fictionalDekorlists: Product[] = [];
    let fictionalSockels: Product[] = [];

    let communicates: ValidationCommunicate[] = [];

    layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        if (product.productSettings.HAS_DEKORLIST === 'YES') {
          fictionalDekorlists.push(product);
        }
        if (product.productSettings.HAS_SOCKEL === 'YES') {
          fictionalSockels.push(product);
        }
      });
    });
    this.dekorlistValidation(fictionalDekorlists, dekorlists)?.forEach(
      (c: any) => {
        communicates.push(c);
      }
    );
    this.sockelValidation(fictionalSockels, sockels)?.forEach((c: any) => {
      communicates.push(c);
    });
    return communicates;
  }

  static dekorlistValidation(
    products: Product[],
    orderRows: any[]
  ): ValidationCommunicate[] {
    let dekorlistsLength = 0;

    let validationComunnicates: ValidationCommunicate[] = [];

    products?.forEach((p: Product) => {
      dekorlistsLength += p.WIDTH;
    });

    orderRows?.forEach((row: any, i: number) => {
      if (2400 * orderRows.length < dekorlistsLength) {
        row.validated = false;
        row.validatedFromDrawing = false;
        row.validatedByDrawingAccess = false;
        row.status = 'NEW';

        const collisionMessage = new ValidationCommunicate(
          'Dekorlist too long.'
        );
        collisionMessage.productName = row.productName;
        collisionMessage.isError = true;
        collisionMessage.out =
          dekorlistsLength +
          ' mm' +
          ' is more than: ' +
          2400 * orderRows.length +
          ' mm';
        validationComunnicates.push(collisionMessage);
      } else {
        row.validated = true;
        row.validatedFromDrawing = true;
        row.validatedByDrawingAccess = true;
        row.status = 'VALIDATED_DRAWING';
      }
    });

    return validationComunnicates;
  }

  static sockelValidation(
    products: Product[],
    orderRows: any[]
  ): ValidationCommunicate[] {
    let sockelLength = 0;

    let validationComunnicates: ValidationCommunicate[] = [];

    products?.forEach((p: Product) => {
      sockelLength += p.WIDTH;
    });

    orderRows?.forEach((row: any, i: number) => {
      if (2400 * orderRows.length < sockelLength) {
        row.validated = false;
        row.validatedFromDrawing = false;
        row.validatedByDrawingAccess = false;
        row.status = 'NEW';

        const collisionMessage = new ValidationCommunicate('Sockel too long.');
        collisionMessage.productName = row.productName;
        collisionMessage.isError = true;
        collisionMessage.out =
          sockelLength +
          ' mm' +
          ' is more than: ' +
          2400 * orderRows.length +
          ' mm';
        validationComunnicates.push(collisionMessage);
      } else {
        row.validated = true;
        row.validatedFromDrawing = true;
        row.validatedByDrawingAccess = true;
        row.status = 'VALIDATED_DRAWING';
      }
    });

    return validationComunnicates;
  }
}
