import { Component, OnDestroy, OnInit } from '@angular/core';
import Konva from 'konva';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Alert } from 'src/app/model/alert';
import { Configuration } from 'src/app/model/configuration';
import { Product } from 'src/app/model/product';
import { Wall } from 'src/app/model/wall';
import { AlertService } from 'src/app/services/alert.service';
import { AuthorisationService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy {
  measurementMenuButtonToggled = true;
  fileMenuButtonToggled = true;
  activeLayer: Konva.Layer;
  activeWallName = '';
  productMenuActive = false;
  measurementConfig = {
    inner: false,
    outer: false,
    tags: false,
    drawerAlign: false,
    filterOn: false,
    newCabins: false,
  };
  bin: Product[] = [];
  config: Configuration;
  editDrawing: boolean;
  productSubscriber: any;
  selectedProducts: Product[];

  layerEmitterSub;
  productsEmitterSub;
  measurementsEmitterSub;
  binEmitterSub;
  permissionsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService,
    private alertService: AlertService,
    private authService: AuthorisationService,
    private logService: LogService
  ) {
    this.layerEmitterSub = this.navbarService.layerEmitter.subscribe(
      (layer) => {
        this.getWallName(layer);
      }
    );

    this.productSubscriber = this.productService.productsEmitter.subscribe(
      (products) => {
        this.selectedProducts = products.filter((product) => product.isActive);
      }
    );

    this.productsEmitterSub = this.productService.productsEmitter.subscribe(
      (products) => {
        this.checkProductSelection(products);
      }
    );

    this.measurementsEmitterSub =
      this.navbarService.measurementsEmitter.subscribe((config) => {
        this.measurementConfig = config;
      });

    this.binEmitterSub = this.navbarService.binEmitter.subscribe((bin) => {
      this.bin = bin;
    });

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editDrawing = permissions.editDrawing;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.layerEmitterSub.unsubscribe();
    this.productSubscriber.unsubscribe();
    this.productsEmitterSub.unsubscribe();
    this.measurementsEmitterSub.unsubscribe();
    this.binEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
  }

  measurementMenuToggle(): void {
    this.measurementMenuButtonToggled = !this.measurementMenuButtonToggled;
  }

  fileMenuToggle(): void {
    this.fileMenuButtonToggled = !this.fileMenuButtonToggled;
  }

  getWallName(layer): void {
    if (layer) {
      layer.find('.wall').each((wall: Wall) => {
        this.activeWallName = wall.wallName;
      });
    }
  }

  checkProductSelection(products): void {
    const test = products.find((product) => product.isActive);
    if (products != null && products[0] != null && !!test) {
      this.productMenuActive = true;
    } else {
      this.productMenuActive = false;
    }
  }

  switchWall(next: boolean): void {
    this.navbarService.wallSwitchEmitter.emit(next);
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Changing wall')
      .subscribe(() => {});
  }

  save(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_ORDER);
  }

  saveOrder(): void {
    // later change to validate service and then to alert
    this.alertService.alertEmitter.emit(
      new Alert('GO_BACK_TO_HK_RAPPORT', 'ACTION', 'saveOrder')
    );
    // this.navbarService.stageActionEmitter.emit('saveOrder')
  }

  cleanupWall(): void {
    this.navbarService.newWallCleanupEmitter.emit('');
  }
}
