import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';

export class CommentUtil {
  static addPhrase(product: Product, phrase: string) {
    if (!this.findPhrase(product.productSettings.COMMENTS, phrase)) {
      product.changeValue(
        Mapping.COMMENTS,
        phrase +
          (product.productSettings.COMMENTS
            ? product.productSettings.COMMENTS
            : '')
      );
    }
  }

  static removePhrase(product: Product, phrase: string) {
    if (this.findPhrase(product.productSettings.COMMENTS, phrase)) {
      product.changeValue(
        Mapping.COMMENTS,
        product.productSettings.COMMENTS?.replace(phrase, '')
      );
    }
  }

  static findPhrase(comment: string, phrase: string): boolean {
    return comment?.includes(phrase);
  }
}
