import Konva from 'konva';
import { Vector2d } from 'konva/types/types';
import { Front } from './front';

export class Drawer extends Konva.Group {
  visibilityType = 'interior';
  type: string;
  front: Front;

  constructor(context: any, type: string) {
    super(context);
    this.attrs.name = 'drawer';
    this.type = type;
    this.attrs.strokeWidth = 10;
    this.drawDrawer();
  }

  drawDrawer() {
    if (!this.attrs.width || !this.attrs.height) {
      return;
    }
    // this.deleteDrawer();
    // this.add(new Konva.Rect({ width: this.attrs.width - this.attrs.strokeWidth, height: this.attrs.height - this.attrs.strokeWidth, name: 'rect' }));
    // right
    this.add(
      new Konva.Rect({
        x: this.attrs.width - this.attrs.strokeWidth,
        y: 0,
        width: this.attrs.strokeWidth,
        height: this.attrs.height,
        fill: 'black',
        name: 'drawerStroke',
      })
    );
    // bottom
    this.add(
      new Konva.Rect({
        x: 0,
        y: this.attrs.height - this.attrs.strokeWidth,
        width: this.attrs.width,
        height: this.attrs.strokeWidth,
        fill: 'black',
        name: 'drawerStroke',
      })
    );
    // left
    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.attrs.strokeWidth,
        height: this.attrs.height,
        fill: 'black',
        name: 'drawerStroke',
      })
    );
  }

  addFront(): void {
    this.add(new Front(this.attrs, null));
    // this.front = new Front(this.attrs, null);
  }

  addHandle(handlePlacement: Vector2d, handleOr: string, handleType: string) {
    if (!handlePlacement) {
      return;
    }
    let w = !(handleOr === 'L') ? 20 : 120;
    let h = handleOr === 'L' ? 20 : 60;
    let dy = handleOr !== 'L' ? 0 : 0;
    const dx = !(handleOr === 'L') ? 0 : -30;

    switch (handleType) {
      case 'KNOP':
        w = 30;
        h = 30;
        dy = 0;
    }

    this.add(
      new Konva.Rect({
        x: Number(handlePlacement.x) + dx,
        y: Number(handlePlacement.y) + dy,
        width: w,
        height: h,
        fill: 'black',
        name: 'handle',
      })
    );
  }

  deleteDrawer() {
    this.find('.handle').each((stroke) => {
      stroke.destroy();
    });
    this.find('.drawerStroke').each((stroke) => {
      stroke.destroy();
    });
    this.find('.rect').each((rect) => {
      rect.destroy();
    });
  }
}
