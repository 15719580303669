import { EventEmitter, Injectable } from '@angular/core';
import Konva from 'konva';
import { Configuration } from '../model/configuration';
import { GeneralSettings } from '../model/generalSettings';
import { MeasurementConfig } from '../model/measurementConfig';
import { Product } from '../model/product';
import { ProductInteractionConfig } from '../model/productInteractionConfig';
import { SidebarSettings } from '../model/sidebarSettings';
import { SpinnerAction } from '../model/spinnerAction';
import { Wall } from '../model/wall';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  newProductEmitter = new EventEmitter<Product>();
  newFictionalProductEmitter = new EventEmitter<any>();
  newWallEmitter = new EventEmitter<Wall>();
  newWallCleanupEmitter = new EventEmitter<any>();
  newBinProductEmitter = new EventEmitter<Product>();
  newProductFromBinEmitter = new EventEmitter<Product>();
  newProductFromBinEmitterCloseInterior = new EventEmitter<Product>();

  orderStatusEmitter = new EventEmitter<string>();

  configEmitter = new EventEmitter<Configuration>();
  productConfigEmitter = new EventEmitter<ProductInteractionConfig>();

  wallSwitchEmitter = new EventEmitter<boolean>();
  switchLayersEmitter = new EventEmitter<number>();

  binEmitter = new EventEmitter<Product[]>();
  layerEmitter = new EventEmitter<Konva.Layer>();
  layersEmitter = new EventEmitter<Konva.Layer[]>();
  productActionEmitter = new EventEmitter<string>();
  stageActionEmitter = new EventEmitter<string>();
  generalSettingsEmitter = new EventEmitter<GeneralSettings>();
  sidebarSettingsEmitter = new EventEmitter<SidebarSettings>();

  loadActionEmitter = new EventEmitter<number>();

  spinnerActionEmitter = new EventEmitter<SpinnerAction>();

  productCategoryCreatorEmitter = new EventEmitter<any>();
  productCreatorEmitter = new EventEmitter<Product>();
  wallEditEmitter = new EventEmitter<Wall>();

  languageSwitchEmitter = new EventEmitter<string>();

  measurementsEmitter = new EventEmitter<MeasurementConfig>();

  constructor() {}
}
