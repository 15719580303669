export class Alert {
  text: string;
  type: string;
  key: string;

  constructor(text: string, type: string, key: string) {
    this.text = text;
    this.type = type;
    this.key = key;
  }
}
