import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { Mapping } from 'src/app/consts/mapping';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { Setting } from 'src/app/model/setting';
import { SidebarSettings } from 'src/app/model/sidebarSettings';
import { AuthorisationService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, OnDestroy {
  language: string;
  toggledSidebar = false;
  sidebarSettings: SidebarSettings;
  toggledGeneralComment = false;
  comment: string;

  sidebarSettingsEmitterSub;
  generalSettingsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private auth: AuthorisationService
  ) {
    this.sidebarSettingsEmitterSub =
      this.navbarService.sidebarSettingsEmitter.subscribe((x) => {
        this.sidebarSettings = x;
      });
    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe(
        (gs: GeneralSettings) => {
          const c = this.findComment(gs);
          if (c && c.value) {
            this.comment = c.value.replace(new RegExp('\n', 'g'), '<br />');
          } else {
            this.comment = '';
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.sidebarSettingsEmitterSub.unsubscribe();
    this.generalSettingsEmitterSub.unsubscribe();
  }

  ngOnInit(): void {
    this.language = 'EN';
    this.changeLanguage();
  }

  changeLanguage(): void {
    if (this.language === 'EN') {
      this.language = 'SE';
      this.navbarService.languageSwitchEmitter.emit('se');
    } else if (this.language === 'SE') {
      this.language = 'NO';
      this.navbarService.languageSwitchEmitter.emit('no');
    } else {
      this.language = 'EN';
      this.navbarService.languageSwitchEmitter.emit('en');
    }
  }

  toggleSidebar(): void {
    this.toggledSidebar = !this.toggledSidebar;
  }

  toggleAutoZoom(): void {
    this.sidebarSettings.automaticZoom = !this.sidebarSettings.automaticZoom;
    this.sidebarSettings.automaticZoomOut = false;
    this.sidebarSettings.manualZoom = false;
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.navbarService.stageActionEmitter.emit(Actions.ZOOM);
  }

  toggleAutoZoomOut(): void {
    this.sidebarSettings.automaticZoomOut =
      !this.sidebarSettings.automaticZoomOut;
    this.sidebarSettings.automaticZoom = false;
    this.sidebarSettings.manualZoom = false;
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.navbarService.stageActionEmitter.emit(Actions.ZOOM);
  }

  toggleManualZoom(): void {
    this.sidebarSettings.automaticZoom = false;
    this.sidebarSettings.automaticZoomOut = false;
    this.sidebarSettings.manualZoom = !this.sidebarSettings.manualZoom;
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.navbarService.stageActionEmitter.emit(Actions.ZOOM);
  }

  toggleMoveable(): void {
    this.sidebarSettings.moveable = !this.sidebarSettings.moveable;
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.navbarService.stageActionEmitter.emit(Actions.ZOOM);
  }

  toggleGeneralComment(): void {
    this.toggledGeneralComment = !this.toggledGeneralComment;
  }

  onZoomChange(): void {
    this.navbarService.sidebarSettingsEmitter.emit(this.sidebarSettings);
    this.navbarService.stageActionEmitter.emit(Actions.ZOOM);
  }

  findComment(gs: GeneralSettings): Setting {
    if (!gs) {
      return null;
    }
    return gs.settings.find((s: Setting) => {
      return s.mapping && s.mapping.name === Mapping.GLOBAL_COMMENT;
    });
  }

  validateAuth(): void {
    this.auth.authorizeAndLogin();
  }
}
