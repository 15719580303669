import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ConfigConsts } from 'src/app/consts/configConsts';
import { LogConsts } from 'src/app/consts/logConsts';
import { Configuration } from 'src/app/model/configuration';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  config: Configuration = new Configuration();

  snapOptions = [
    ConfigConsts.ZERO,
    ConfigConsts.WEAK,
    ConfigConsts.MEDIUM,
    ConfigConsts.STRONG,
  ];

  closeOptions = [ConfigConsts.YES, ConfigConsts.NO];

  heatshields = [ConfigConsts.YES, ConfigConsts.NO];

  fixedDimensions = [ConfigConsts.YES, ConfigConsts.NO];

  autoWidthSockel = [ConfigConsts.YES, ConfigConsts.NO];

  autoWidthDekorlist = [ConfigConsts.YES, ConfigConsts.NO];

  autoSave = [ConfigConsts.FIVE, ConfigConsts.TEN, ConfigConsts.FIFTEEN];

  configSub;

  constructor(
    private storage: LocalStorageService,
    private navbarService: NavbarService,
    private logService: LogService
  ) {
    this.configSub = this.navbarService.configEmitter.subscribe(
      (config: Configuration) => {
        this.config = config;
      }
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.configSub.unsubscribe();
  }

  update(): void {
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Updating configuration')
      .subscribe(() => {});
    this.navbarService.configEmitter.emit(this.config);
  }
}
