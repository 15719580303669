import { ValidationItem } from './subvalidators/validationItem';
import { ValidationLevel } from './subvalidators/validationLevel';
import { ValidationCommunicate } from './subvalidators/validationMessage';

export class Validation {
  message: ValidationCommunicate;
  name: string;
  product: any;
  setting: any;
  subcategory: any;
  validationID: number;
  validationItems: ValidationItem[];
  validationLevel: ValidationLevel;
  validationType: string;
  simpleValidationChecked: boolean;

  constructor(
    message: ValidationCommunicate,
    name: string,
    product: any,
    setting: any,
    subcategory: any,
    validationID: number,
    validationItems: ValidationItem[],
    validationLevel: ValidationLevel,
    validationType: string
  ) {
    this.message = message;
    this.name = name;
    this.product = product;
    this.setting = setting;
    this.subcategory = subcategory;
    this.validationID = validationID;
    this.validationItems = validationItems;
    this.validationLevel = validationLevel;
    this.validationType = validationType;
    this.simpleValidationChecked = false;
  }

  checkSimpleValidation() {
    this.simpleValidationChecked = !this.simpleValidationChecked;
  }
}
