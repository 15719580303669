export class ProductSettings {
  width: number;
  height: number;
  depth: number;
  widthMin: number;
  widthMax: number;
  heightMin: number;
  heightMax: number;
  depthMin: number;
  depthMax: number;
  internalHeight: number;
  internalHeightMin: number;
  internalHeightMax: number;
  internalWidth: number;
  internalWidthMin: number;
  internalWidthMax: number;
  columnCovering: boolean;

  bottomFront: number;
  cabinBottomHeight: number;
  totalFrontHeight: number;
  cabinCoverLeft;
  cabinCoverRight;
  amountShelfMin: number;
  amountShelfMax: number;
  amountShelf: number;

  topFront: boolean;
  topFrontCover: number;

  heightrMiddleFronts: number;
  // cabinsHeight: number;

  amount: number;
  hingesCC: number;

  heatshieldRight: boolean;
  heatshieldLeft: boolean;

  front: boolean;

  baseHeight: number;
  baseHeightMax: number;
  baseHeightMin: number;

  depthExistingCabins: number;
  heightExistingCabins: number;

  heightBetweenCabinsRoof: number;
  heightBlindFront: number;
  amountOfCabins: number;

  rightLeft: string;
  frontTop: boolean;
  frontBottom: boolean;

  stdMinHeightFront: number;
  highMinHeightFront: number;
  passbitHeight: number;
  productDesign: string;

  AMOUNT;
  AMOUNT_SHELF;
  AMOUNT_SHELF_MAX;
  AMOUNT_SHELF_MIN;
  AMOUNT_OF_CABINS;
  CABIN_BOTTOM_HEIGHT;
  CABIN_HEIGHT;
  WIDTH;
  WIDTH_MAX;
  WIDTH_MIN;
  DESIGN_OF_PRODUCT;
  DEPTH;
  DEPTH_EXISTING_CABINS;
  DEPTH_MAX;
  DEPTH_MIN;
  SUGGESTION_ALIGNMENT_MIDDLE_FRONT;
  SUGGESTION_ALIGNMENT_BOTTOM_FRONT;
  FRONT_YES_NO;
  FRONT_TOP;
  FRONT_BOTTOM;
  AMOUNT_MIDDLE_FRONTS;
  HEIGHT_MIDDLE_FRONTS;
  HINGES_CC;
  HIGH_MIN_HEIGHT_FRONT;
  RIGHT_LEFT;
  HEIGHT;
  HEIGHT_BLIND_FRONT;
  HEIGHT_FRONT;
  HEIGHT_MAX;
  HEIGHT_BETWEEN_CABINS_ROOF;
  HEIGHT_MIN;
  HEIGHT_SPAREPART;
  HEIGHT_EXITING_CABINS;
  INTERNAL_WIDTH;
  INTERNAL_WIDTH_MAX;
  INTERNAL_WIDTH_MIN;
  INTERNAL_HEIGHT;
  INTERNAL_HEIGHT_MAX;
  INTERNAL_HEIGHT_MIN;
  MAPPING;
  BOTTOM_FRONT;
  TOP_FRONT;
  TOP_FRONT_COVER;
  BASE_HEIGHT;
  BASE_HEIGHT_MAX;
  BASE_HEIGHT_MIN;
  COLUMN_COVERING;
  STD_MIN_HEIGHT_FRONT;
  CABINs_HEIGHT;
  COVER_CABIN;
  CABIN_COVER_LEFT;
  CABIN_COVER_RIGHT;
  TOTAL_WITHD;
  TOTAL_FRONT_HEIGHT;
  HEATSHIELD_RIGHTSIDE;
  HEATSHIELD_LEFTSIDE;
  FRONTS_MIDDLE_HEIGHT;
  FRONTS_MIDDLE_HEIGHT2;
  FRONTS_MIDDLE_HEIGHT3;
  FRONT_SPICERACK_TOP;
  FRONT_SPICERACK_BOTTOM;
  TOP_BOTTOM;
  COMMENTS;
  DOUBLE_DOOR;
  HEIGHT_2;
  TOP_DOOR_HEIGHT;
  LIGHT_RAMP_YES_NO;
  POSITIONNUMBER;
  FICTIONAL_HEIGHT;
  HINGES_CC_TOP;
  HINGES_CC_BOTTOM;
  INTERNAL_WIDTH_LEFT;
  INTERNAL_WIDTH_RIGHT;
  WIDTH_2;
  HANDLE_PLACEMENT;
  HANDLE_LAT_HORZ;
  COVERSIDE_PERSPECTIVE;
  HINGES_CC_TOP2;
  HINGES_CC_BOTTOM2;
  STANDINGLAYING;
  HANDLES_TYPE;
  HANDLES_YES_NO;
  CONNECTED_TO_POSITIONNUMBER;
  GLOBAL_COMMENT;
  DRAWER_HANDLE_PLACEMENT;
  HINGES_AMOUNT;
  TOP_FRONT_EDIT;
  BOTTOM_FRONT_EDIT;
  DRAWER_1_DEPTH;
  DRAWER_2_DEPTH;
  DRAWER_3_DEPTH;
  DRAWER_4_DEPTH;
  DRAWER_5_DEPTH;
  EXTRA_HINGE;
  HINGE_CC_EXTRA;
  BLINDFRONT_HANDLE;
  CUT_OFF_PLACEMENT;
  CUT_OFF_TYPE;
  NEWCABIN;
  NEW_CABIN;
  DOORTYPE;
  NOT_MAPPED_SETTING;
  NOT_MAPPED_SETTING2;
  NOT_MAPPED_SETTING3;
  TIPON1;
  HAS_DEKORLIST;
  HAS_SOCKEL;
}
