import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  uploadPdf(pdf: any): Observable<any> {
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const pdfFile = new File(
      [pdf.output('blob')],
      this.storage.get(StorageConsts.ORDER_ID) + '.pdf'
    );
    const formData = new FormData();
    formData.append('pdf-file', pdfFile);
    formData.append('productOrderID', this.storage.get(StorageConsts.ORDER_ID));

    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + token
    );

    return this.httpClient.post(environment.pdfUpload, formData, {
      headers,
    });
  }
}
