import { Configuration } from '../model/configuration';
import { GeneralSettings } from '../model/generalSettings';
import { Setting } from '../model/setting';
import { Mapping } from '../model/subsettings/mapping';
import { SettingValueItem } from '../model/subsettings/settingValueItem';

export class SettingValue {
  value: string;
  id: number;
}

export class ConfigFictionalData {
  config: Configuration;
  settingValues: SettingValue[];
}

export class DrawingConfigExtractor {
  static stringifyConfig(
    config: Configuration,
    generalSettings: GeneralSettings
  ): string {
    const configFictionalData = new ConfigFictionalData();
    configFictionalData.config = config;
    configFictionalData.settingValues = generalSettings.settings
      .filter((s: Setting) => s.fictional)
      .map((s: Setting) => {
        return {
          value: s.value,
          id: s.id,
        };
      });
    return JSON.stringify(configFictionalData);
  }

  static parseConfig(configString: string): ConfigFictionalData {
    return JSON.parse(configString) as ConfigFictionalData;
  }
}
