import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { StorageConsts } from 'src/app/consts/storage';

@Component({
  selector: 'app-pdf-generate',
  templateUrl: './pdf-generate.component.html',
  styleUrls: ['./pdf-generate.component.scss'],
})
export class PdfGenerateComponent {
  code: string;
  state: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private storage: LocalStorageService,
    private router: Router
  ) {
    this.activeRoute.queryParams.subscribe((param) => {
      this.storage.set(StorageConsts.ORDER_ID, this.decode(param.id));
      this.storage.set(StorageConsts.PDF_STATUS, 'active');
      this.router.navigate(['main']);
    });
  }

  decode(str): string {
    return str
      ? str.toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
      : '';
  }
}
