import Konva from 'konva';

export class Windows extends Konva.Group {
  noWindows: number;
  constructor(context, noWindows, type) {
    super(context);
    this.attrs.name = 'windows';
    this.noWindows = noWindows;
    this.attrs.x = 0;
    this.attrs.y = 0;
    this.drawWindows(type);
  }

  drawWindows(type: number) {
    let fill = '#add8e6';
    let border = null;
    switch (type) {
      case 1:
        fill = '#add8e6';
        break;
      case 2:
        fill = 'rgb(215,215,215)';
        border = 1;
        break;
    }
    switch (this.noWindows) {
      case 1:
        this.add(
          new Konva.Rect({
            x: 60,
            y: 60,
            width: this.attrs.width - 120,
            height: this.attrs.height - 120,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        break;
      case 4:
        this.add(
          new Konva.Rect({
            x: 60,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 2 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 2 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: 60,
            y: this.attrs.height / 2 + 30,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 2 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: this.attrs.height / 2 + 30,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 2 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        break;
      case 6:
        this.add(
          new Konva.Rect({
            x: 60,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: 60,
            y: this.attrs.height / 3 + 30,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: this.attrs.height / 3 + 30,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: 60,
            y: (this.attrs.height * 2) / 3,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: (this.attrs.height * 2) / 3,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 3 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        break;
      case 8:
        // first
        this.add(
          new Konva.Rect({
            x: 60,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: 60,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        // second
        this.add(
          new Konva.Rect({
            x: 60,
            y: this.attrs.height / 4 + 40,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: this.attrs.height / 4 + 40,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        // third
        this.add(
          new Konva.Rect({
            x: 60,
            y: this.attrs.height / 2 + 20,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: this.attrs.height / 2 + 20,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        // fourth
        this.add(
          new Konva.Rect({
            x: 60,
            y: (this.attrs.height * 3) / 4,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );

        this.add(
          new Konva.Rect({
            x: this.attrs.width / 2 + 30,
            y: (this.attrs.height * 3) / 4,
            width: this.attrs.width / 2 - 90,
            height: this.attrs.height / 4 - 90,
            fill: fill,
            stroke: border,
            opacity: 0.5,
          })
        );
        break;
    }
  }
}
