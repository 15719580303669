import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { StorageConsts } from 'src/app/consts/storage';
import { TokenResponse } from 'src/app/model/tokenResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  private code: string;
  private state: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private storage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((param) => {
      this.code = param.code;
      this.state = param.state;
      this.postToken(this.code, this.state);
    });
  }

  postToken(code: string, state: string): void {
    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('code_verifier', this.storage.get('codeVerifier'))
      .append('redirect_uri', environment.oauthCallbackUrl)
      .append('client_secret', environment.secret)
      .append('client_id', environment.oauthClientId);
    this.httpClient
      .post(environment.oauthTokenUrl, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .subscribe(
        (response: TokenResponse) => {
          this.storage.set(StorageConsts.REFRESH_TOKEN, response.refresh_token);
          this.storage.set(StorageConsts.ACCESS_TOKEN, response.access_token);
          this.router.navigate(['main']);
        },
        (error) => {}
      );
  }
}
