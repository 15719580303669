import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Option } from '../model/subsettings/option';

export class SettingUtil {
  static disableHigherSettingValues(maxValue: number, setting: Setting): void {
    if (!setting.settingOptionSet) {
      return;
    }
    if (Number(maxValue) === 0) {
      setting.value = 0;
    }
    setting.settingOptionSet.sort(
      (a: Option, b: Option) => Number(b.value) - Number(a.value)
    );
    setting.settingOptionSet.forEach((option: Option, i: number) => {
      if (Number(option.value) > Number(maxValue)) {
        option.active = false;
        if (Number(setting.value) > Number(maxValue)) {
          if (i < setting.settingOptionSet.length - 1) {
            setting.value = setting.settingOptionSet[i + 1].value;
          } else {
            setting.value = 0;
          }
        }
      } else {
        option.active = true;
      }

      if (Number(setting.value) === 0) {
        setting.settingOptionSet.forEach((option: Option) => {
          if (setting.value === 0 && option.active) {
            setting.value = option.value;
          }
        });
      }
    });
  }
}
