<div
  class="modal fade"
  id="infoBoxModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "CHECKLIST" | translate }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="list-group">
          <li
            *ngFor="let b of infoBox"
            class="custom-padding d-flex align-content-center"
            (click)="checkInfoBox(b)"
          >
            <input
              class="align-self-center custom-margin"
              [(ngModel)]="b.value"
              type="checkbox"
            />
            {{ b.text | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
