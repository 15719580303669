import { Component, Input } from '@angular/core';
import { MeasurementConfig } from 'src/app/model/measurementConfig';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-measurement-menu',
  templateUrl: './measurement-menu.component.html',
  styleUrls: ['./measurement-menu.component.scss'],
})
export class MeasurementMenuComponent {
  @Input() measurementConfig: MeasurementConfig;

  showOuter = true;
  innerMeasurements: boolean;

  constructor(private navbarService: NavbarService) {}

  switchOuterMeasurements(): void {
    if (this.measurementConfig) {
      this.measurementConfig.outer = !this.measurementConfig.outer;
      this.navbarService.measurementsEmitter.emit(this.measurementConfig);
    }
  }

  switchInnerMeasurements(): void {
    if (this.measurementConfig) {
      this.measurementConfig.inner = !this.measurementConfig.inner;
      this.navbarService.measurementsEmitter.emit(this.measurementConfig);
    }
  }

  switchTags(): void {
    this.measurementConfig.tags = !this.measurementConfig.tags;
    this.navbarService.measurementsEmitter.emit(this.measurementConfig);
  }

  switchDrawerAlign(): void {
    this.measurementConfig.drawerAlign = !this.measurementConfig.drawerAlign;
    this.navbarService.measurementsEmitter.emit(this.measurementConfig);
  }

  switchFilter(): void {
    this.measurementConfig.filterOn = !this.measurementConfig.filterOn;
    this.navbarService.measurementsEmitter.emit(this.measurementConfig);
  }

  switchNewCabin(): void {
    this.measurementConfig.newCabins = !this.measurementConfig.newCabins;
    this.navbarService.measurementsEmitter.emit(this.measurementConfig);
  }

  switchAll(): void {
    if (this.measurementConfig) {
      if (
        this.measurementConfig.inner &&
        this.measurementConfig.outer &&
        this.measurementConfig.tags &&
        this.measurementConfig.drawerAlign
      ) {
        this.measurementConfig.outer = false;
        this.measurementConfig.inner = false;
        this.measurementConfig.tags = false;
        this.measurementConfig.newCabins = false;
        this.measurementConfig.drawerAlign = false;
      } else {
        this.measurementConfig.outer = true;
        this.measurementConfig.inner = true;
        this.measurementConfig.tags = true;
        this.measurementConfig.newCabins = true;
        this.measurementConfig.drawerAlign = true;
      }
      this.navbarService.measurementsEmitter.emit(this.measurementConfig);
    }
  }

  clear(): void {
    if (this.measurementConfig) {
      this.measurementConfig.outer = false;
      this.measurementConfig.inner = false;
      this.navbarService.measurementsEmitter.emit(this.measurementConfig);
    }
  }
}
