import { Vector2d } from 'konva/types/types';
import { Product } from '../model/product';
import { Drawer } from '../model/subproducts/drawer';

export class DrawerUtils {
  static drawerPlacementCalc(product: Product): Drawer[] {
    const noDrawers = product.drawers.length;
    if (noDrawers === 0) {
      return null;
    }

    // later here should be INNER_WIDTH and INNER_HEIGHT
    // const newHeight = product.attrs.height - product.attrs.strokeWidth * 2;
    const newWidth =
      product.attrs.width -
      product.productSettings.CABIN_COVER_LEFT -
      product.productSettings.CABIN_COVER_RIGHT;
    let newHeight = 0;
    product.drawers.forEach((drawer, i) => {
      if (drawer.type === 'top') {
        drawer.attrs.height = Number(product.productSettings.TOP_FRONT);
        drawer.attrs.width = newWidth;
      }
      if (drawer.type === 'center') {
        drawer.attrs.height = Number(
          product.productSettings.FRONTS_MIDDLE_HEIGHT
        );
        drawer.attrs.width = newWidth;
      }
      if (drawer.type === 'bottom') {
        drawer.attrs.height = Number(product.productSettings.BOTTOM_FRONT);
        drawer.attrs.width = newWidth;
      }

      drawer.position({
        x: Number(product.productSettings.CABIN_COVER_LEFT),
        y: newHeight,
      });
      newHeight += drawer.attrs.height + 3;
    });

    return product.drawers;
  }

  static handlePlacementCalc(product: Product): Vector2d[] {
    const handles: Vector2d[] = [];

    product.find('.drawer').each((drawer: Drawer) => {
      product.productSettings.HANDLE_LAT_HORZ = 'L';
      product.productSettings.HANDLE_PLACEMENT = 'MIDDLE';
      let handlePlacement = 'MIDDLE';
      if (product.productSettings.DRAWER_HANDLE_PLACEMENT) {
        handlePlacement = product.productSettings.DRAWER_HANDLE_PLACEMENT;
      }
      // ^ temp

      let rx = 20 + Number(product.productSettings.CABIN_COVER_LEFT);
      let lx =
        drawer.attrs.width -
        40 -
        Number(product.productSettings.CABIN_COVER_RIGHT);

      if (
        product.productSettings.HANDLE_LAT_HORZ === 'L' &&
        product.productSettings.HANDLE_PLACEMENT === 'SIDE'
      ) {
        rx = 20 + Number(product.productSettings.CABIN_COVER_LEFT);
        lx =
          drawer.attrs.width -
          80 -
          Number(product.productSettings.CABIN_COVER_RIGHT);
      } else if (
        product.productSettings.HANDLE_LAT_HORZ === 'S' &&
        product.productSettings.HANDLE_PLACEMENT === 'SIDE'
      ) {
        rx = 20 + Number(product.productSettings.CABIN_COVER_LEFT);
        lx =
          drawer.attrs.width -
          40 -
          Number(product.productSettings.CABIN_COVER_RIGHT);
      } else if (
        product.productSettings.HANDLE_LAT_HORZ === 'L' &&
        product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
      ) {
        rx = drawer.attrs.width / 2 - 30;
        lx = drawer.attrs.width / 2 - 30;
      } else if (
        product.productSettings.HANDLE_LAT_HORZ === 'S' &&
        product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
      ) {
        rx = drawer.attrs.width / 2 - 10;
        lx = drawer.attrs.width / 2 - 10;
      }

      if (product.productSettings.HANDLES_TYPE === 'KNOP') {
        rx += 45;
      }

      const by = drawer.attrs.height - 90;
      const my = drawer.attrs.height / 2 - 15;
      const ty = 30;

      switch (handlePlacement) {
        case 'BOTTOM':
          handles.push({
            x: rx,
            y: by,
          });
          break;
        case 'MIDDLE':
          handles.push({
            x: rx,
            y: my,
          });
          break;
        case 'TOP':
          handles.push({
            x: rx,
            y: ty,
          });
          break;
      }
    });
    return handles;
  }
}
