import { SettingTypesConsts } from '../consts/settingTypes';
import { Setting } from '../model/setting';
import { Mapping } from '../model/subsettings/mapping';
import { Option } from '../model/subsettings/option';

export class SettingMapUtils {
  static mapSettings(set: any[], edgeValue: number): Setting[] {
    const settings = [];
    if (!set) {
      return null;
    }
    set.forEach((setting) => {
      let options: Option[] = null;
      if (setting.setting.settingType.productSettingOptionSet) {
        options =
          setting.setting.settingType.productSettingOptionSet
            .productSettingOptions;
      }
      const newSetting = new Setting(
        setting.setting.settingID,
        setting.setting.name,
        setting.setting.settingMapping,
        setting.setting.settingType.type,
        setting.value,
        setting.productSettingValueItems,
        setting.setting.settingType.dataType,
        setting.setting.settingType.componentType,
        options,
        setting.setting.numericRoundType,
        setting.setting.numericType,
        setting.setting.numericCalculationMax,
        setting.setting.numericMax,
        setting.setting.numericMin,
        setting.setting.override,
        edgeValue,
        setting.setting.sortOrder,
        setting.setting.visibleInDrawing,
        setting.readonly,
        setting.modified,
        setting.previousValue
      );

      if (
        setting.modified &&
        newSetting.type === SettingTypesConsts.CALCULATED
      ) {
        newSetting.isFrozen = true;
      }

      settings.push(newSetting);
    });
    return settings.sort(
      (s1: Setting, s2: Setting) => s1.sortOrder - s2.sortOrder
    );
  }

  // here comes the parser
}
