export class OrderUtils {
  static extractSubcategories(order: any): string[] {
    if (!order || !order.productOrderRows) {
      return [''];
    }
    const subCat = [];
    order.productOrderRows.forEach((row: any) => {
      if (!row.product) {
        return;
      }
      if (row.product.subCategory) {
        subCat.push(row.product.subCategory.name);
      }
    });
    return [...new Set(subCat)];
  }

  static getRowsBySetting(
    order: any,
    settingMapping: string,
    settingValue: string
  ): any[] {
    if (!order.productOrderRows) {
      return [];
    }
    let selectedRows = [];
    order.productOrderRows.forEach((row: any) => {
      if (!row.productOrderSettings) {
        return;
      }
      row.productOrderSettings.forEach((orderSetting: any) => {
        if (
          orderSetting.value == settingValue &&
          orderSetting.setting?.settingMapping?.name === settingMapping
        ) {
          selectedRows.push(row);
        }
      });
    });
    return selectedRows;
  }
}
