export enum StorageConsts {
  REFRESH_TOKEN = 'refresh_token',
  ACCESS_TOKEN = 'access_token',
  ORDER_ID = 'orderId',
  ORDER = 'order',
  CLOSE_BIN_ON_CLICK = 'closeBinOnClick',
  SNAP_VALUE = 'snapValue',
  PDF_STATUS = 'pdf_status',
  HEATSHIELDS = 'heatshields',
  FIXED_DIMENSIONS = 'fixed_dimensions',
  AUTO_WIDTH_SOCKEL = 'auto_width_sockel',
  AUTO_WIDTH_DEKORLIST = 'auto_width_dekorlist',
  AUTO_SAVE = 'auto_save',
}
