export class SaveUtil {
  static saveOrder(order, d, autoSave): string {
    let data = this.parseData(d);
    if (!data || (data && data.productOrderID)) {
      data = [];
    }
    if (!data || data.length < 1) {
      for (let i = 0; i < 5; i++) {
        data.push({});
      }
    }
    if (!order) {
      return this.serializeProductOrder(data);
    }
    let done = false;
    data.forEach((o) => {
      if (o && order && o.orderID === order.productOrderID) {
        done = true;
        o = this.updateSaveOrder(order, o, autoSave);
      }
    });

    if (!done) {
      data.pop();
      data.unshift(this.updateSaveOrder(order, null, autoSave));
    }
    return this.serializeProductOrder(data);
  }

  static updateSaveOrder(order, order2, autoSave) {
    if (!order) {
      return;
    }
    if (!order2 || (order2.saves && order2.saves.length < 1)) {
      order2 = { orderID: order.productOrderID, saves: [] };
      for (let i = 0; i < 5; i++) {
        order2.saves.push({});
      }
    }
    const date = Date.now();
    const orderData = {
      productOrderSettings: order.productOrderSettings,
      productOrderWalls: order.productOrderWalls,
      productOrderRows: this.compressOrderRows(order),
    };
    if (autoSave) {
      order2.saves.pop();
      order2.saves.push({ date, orderData });
    } else {
      order2.saves.splice(3, 1);
      order2.saves.unshift({ date, orderData });
    }
    return order2;
  }

  static compressOrderRows(order): any {
    const data = [];
    order.productOrderRows.forEach((row) => {
      const settings = [];
      if (row.productOrderSettings) {
        row.productOrderSettings.forEach((setting) => {
          settings.push({
            id: setting.id,
            value: setting.value,
            override: setting.override,
            modified: setting.modified,
          });
        });
      }
      data.push({
        positionX: row.positionX,
        positionY: row.positionY,
        id: row.id,
        wallID: row.wallID,
        productOrderSettings: settings,
        fictionalID: row.fictionalID,
        fictionalSettings: row.fictionalSettings,
      });
    });
    return data;
  }

  static updateOrder(order, data, timeStamp): any {
    // choose proper data part
    const nData = this.extractActiveOrderID(data, order.productOrderID);
    let success = false;

    // get orderFromSave
    let d = nData.saves.find((save) => save.date === timeStamp);
    if (d) {
      d = d.orderData;
    } else {
      d = null;
    }

    if (d) {
      success = true;
    }
    order.productOrderRows.forEach((row, i) => {
      if (!d) {
        return;
      }
      if (row.fictionalID !== null || row.id === undefined) {
        order.productOrderRows[i] = null;
        return;
      }
      d.productOrderRows.forEach((row2) => {
        if (row.id && row2.id && row.id == row2.id) {
          row.positionX = row2.positionX;
          row.positionY = row2.positionY;
          row.wallID = row2.wallID;
          if (!row.productOrderSettings) {
            return;
          }
          row.productOrderSettings.forEach((s) => {
            if (!row2.productOrderSettings) {
              return;
            }
            row2.productOrderSettings.forEach((s2) => {
              if (s.id == s2.id) {
                s.value = s2.value;
                s.override = s2.override;
                s.modified = s2.modified;
              }
            });
          });
        }
      });
    });
    order.productOrderRows = order.productOrderRows.filter((x) => x);
    d.productOrderRows.forEach((row2) => {
      if (row2.fictionalID !== null && row2.id === undefined) {
        const row: any = {
          positionX: row2.positionX,
          positionY: row2.positionY,
          wallID: row2.wallID,
          fictionalID: row2.fictionalID,
          isFictional: true,
          fictionalSettings: row2.fictionalSettings,
        };
        order.productOrderRows.push(row);
      }
    });

    order.productOrderWalls = d.productOrderWalls;
    order.productOrderSettings = d.productOrderSettings;
    return { order, successfull: success };
  }

  static serializeProductOrder(order): string {
    return JSON.stringify(order);
  }

  static parseData(data): any {
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  }

  static extractActiveOrderID(data, orderID): any {
    if (!data) {
      return null;
    }
    // @ts-ignore: Unreachable code error
    return data.find((o) => o.orderID == orderID);
  }
}
