<div
  class="modal fade"
  id="productListModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CHOOSE_PRODUCT" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="products?.length !== 0" class="list-group">
          <div *ngFor="let product of products">
            <li
              *ngIf="
                product?.subCategory?.mainCategory?.mainCategoryID &&
                product?.subCategory?.subCategoryID === category?.subCategoryID
              "
              class="list-group-item custom-list-item"
            >
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex align-items-center custom-extra-padding custom-width"
                >
                  <div class="text-truncate d-inline-block">
                    {{ product.name }}
                  </div>
                </div>
                <div
                  class="btn-group btn-group-lg"
                  role="group"
                  (click)="chooseProduct(product)"
                >
                  <button
                    class="btn btn-secondary"
                    data-toggle="modal"
                    data-target="#productSpecificModal"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i-bs name="plus"></i-bs>
                  </button>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <ul *ngIf="isFictionala()" class="list-group">
          <div *ngFor="let fictional of fictionalProducts">
            <li
              *ngIf="fictional.fictionalID <= 59"
              class="list-group-item custom-list-item"
            >
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex align-items-center custom-extra-padding custom-width"
                >
                  <div class="text-truncate d-inline-block">
                    {{ fictional.name }}
                  </div>
                </div>
                <div
                  class="btn-group btn-group-lg"
                  role="group"
                  (click)="chooseFictionalProduct(fictional)"
                >
                  <button
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i-bs name="plus"></i-bs>
                  </button>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <ul *ngIf="isFictionalb()" class="list-group">
          <div *ngFor="let fictional of fictionalProducts">
            <li
              *ngIf="fictional.fictionalID >= 60"
              class="list-group-item custom-list-item"
            >
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex align-items-center custom-extra-padding custom-width"
                >
                  <div class="text-truncate d-inline-block">
                    {{ fictional.name }}
                  </div>
                </div>
                <div
                  class="btn-group btn-group-lg"
                  role="group"
                  (click)="chooseFictionalProduct(fictional)"
                >
                  <button
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i-bs name="plus"></i-bs>
                  </button>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
