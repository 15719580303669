import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';
import { Vector2d } from 'konva/types/types';
import { SidebarSettings } from '../model/sidebarSettings';

export class ZoomUtil {
  static zoomToFit(stage: Stage, activeLayer: Layer): number {
    let scale = 0;
    activeLayer.find('.wall').each((wall) => {
      const w = stage.attrs.width / wall.attrs.width;
      const h = (stage.attrs.height - 80) / wall.attrs.height;

      if (w < h) {
        scale = w;
      } else {
        scale = h;
      }
      stage.attrs.x = -activeLayer.attrs.x * scale;
      stage.scale({ x: scale, y: scale });
    });
    return scale;
  }

  static zoomAllToFit(stage): number {
    let scale = 0;
    let w = 0;
    let h = 0;
    stage.find('.wall').each((wall) => {
      w += wall.attrs.width;
      if (wall.attrs.height > h) {
        h = (stage.attrs.height - 80) / wall.attrs.height;
      }
    });
    if (stage.attrs.width / w < h) {
      scale = stage.attrs.width / w;
    } else {
      scale = h;
    }
    stage.position({ x: 0, y: 0 });
    stage.scale({ x: scale, y: scale });
    return scale;
  }

  static zoom(stage: Stage, settings: SidebarSettings) {
    const oldScale = stage.attrs.scaleX;

    stage.scale({ x: settings.zoom, y: settings.zoom });

    // // console.log(stage)

    // console.log('x', -(settings.zoom - oldScale) * stage.attrs.width / 2)
    // console.log('y', -(settings.zoom - oldScale) * stage.attrs.height / 2)
    // console.log('zoom ',settings.zoom - oldScale)
    // stage.move({
    //     x: -(settings.zoom - oldScale) * stage.attrs.width * 2,
    //     y: -(settings.zoom - oldScale) * stage.attrs.height * 2
    // })
  }

  static getDistance(p1, p2): number {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  static getCenter(p1, p2): Vector2d {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }
}
