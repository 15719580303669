import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { MainViewComponent } from './components/main-view/main-view.component';
import { OrderComponent } from './components/order/order.component';
import { PdfGenerateComponent } from './components/pdf-generate/pdf-generate.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  // { path: 'test-path', component: TestComponent },
  { path: '', component: RedirectComponent },
  { path: 'main', component: MainViewComponent },
  { path: 'oauth/callback', component: AuthComponent },
  { path: 'order', component: OrderComponent },
  { path: 'generatepdf', component: PdfGenerateComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
