export enum ConfigConsts {
  YES = 'Ja',
  NO = 'Nej',
  ZERO = 'Ingen',
  WEAK = 'Svag',
  MEDIUM = 'Medium',
  STRONG = 'Stark',
  FIVE = '5',
  TEN = '10',
  FIFTEEN = '15',
}
