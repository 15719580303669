import Konva from 'konva';

export class Tag extends Konva.Group {
  tag: string;

  constructor(context, tag) {
    super(context);
    this.tag = tag;
    this.attrs.name = 'tag';
    this.drawTag();
  }

  drawTag() {
    // tag measurements are always the same (or only dependent on width)
    if (this.tag) {
      const newText = new Konva.Text({
        x: 0,
        y: 0,
        width: this.attrs.width - 10,
        height: 35,
        text: this.tag,
        fontSize: 35,
        fontFamily: 'Arial',
        align: 'center',
        fill: 'white',
      });

      this.add(
        new Konva.Rect({
          x: 0,
          y: 0,
          width: this.attrs.width - 10,
          height: 35,
          fill: 'black',
          opacity: 0.6,
          // cornerRadius: [0, 0, 5, 5],
        })
      );
      this.add(newText);
    }
  }
}
