import Konva from 'konva';
import { Layer } from 'konva/types/Layer';
import { ConfigConsts } from '../consts/configConsts';

export class Snap {
  static snap(product, layer: Layer, layers: Layer[], strength: string): void {
    layer.find('.guid-line').each((line) => line.destroy());

    const lineGuideStops = this.getLineGuideStops(
      product.target,
      layer,
      layers
    );
    const itemBounds = this.getObjectSnappingEdges(product.target);

    const guides = this.getGuides(lineGuideStops, itemBounds, strength);

    if (!guides.length) {
      return;
    }

    this.drawGuides(guides, layer);

    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              product.target.x(lg.lineGuide + lg.offset);
              break;
            }
            case 'H': {
              product.target.y(lg.lineGuide + lg.offset);
              break;
            }
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              product.target.x(lg.lineGuide + lg.offset);
              break;
            }
            case 'H': {
              product.target.y(lg.lineGuide + lg.offset);
              break;
            }
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              product.target.x(lg.lineGuide + lg.offset);
              break;
            }
            case 'H': {
              product.target.y(lg.lineGuide + lg.offset);
              break;
            }
          }
          break;
        }
      }
    });
  }

  static getLineGuideStops(skipShape: any, layer: Layer, layers: Layer[]) {
    // you can add custom lines here (fe. walls)

    const vertical = [];
    // 0, stage.width() / 2, stage.width()
    const horizontal = [];
    // 0, stage.height() / 2, stage.height()

    layer.find('.product').each((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }
      const box = {
        x: guideItem.attrs.x,
        y: guideItem.attrs.y,
        width: guideItem.attrs.width,
        height: guideItem.attrs.height,
      };
      // const strokeWidth = guideItem.strokeWidth / 2
      const strokeWidth = 0;
      vertical.push(
        box.x,
        box.x + box.width - strokeWidth,
        box.x + box.width / 2 - strokeWidth
      );
      horizontal.push(
        box.y,
        box.y + box.height - strokeWidth,
        box.y + box.height / 2 - strokeWidth
      );
    });

    layer.find('.wall').each((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }

      const box = {
        x: guideItem.attrs.x,
        y: guideItem.attrs.y,
        width: guideItem.attrs.width,
        height: guideItem.attrs.height,
      };

      const strokeWidth = 0;
      vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
      horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
    });

    layers.forEach((layer: Layer) => {
      layer.find('.product').each((guideItem) => {
        if (guideItem === skipShape) {
          return;
        }
        const box = {
          x: 99999990,
          y: guideItem.attrs.y,
          width: guideItem.attrs.width,
          height: guideItem.attrs.height,
        };
        // const strokeWidth = guideItem.strokeWidth / 2
        const strokeWidth = 0;
        vertical.push(
          box.x,
          box.x + box.width - strokeWidth,
          box.x + box.width / 2 - strokeWidth
        );
        horizontal.push(
          box.y,
          box.y + box.height - strokeWidth,
          box.y + box.height / 2 - strokeWidth
        );
      });
    });

    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }

  static getObjectSnappingEdges(node: any) {
    // const off = 2.5;
    const off = 0;
    const box = {
      x: node.attrs.x,
      y: node.attrs.y,
      width: node.attrs.width,
      height: node.attrs.height,
    };
    return {
      vertical: [
        {
          guide: box.x,
          offset: node.x() - box.x - off,
          snap: 'start',
        },
        {
          guide: box.x + box.width / 2,
          offset: node.x() - box.x - box.width / 2,
          snap: 'center',
        },
        {
          guide: box.x + box.width - off,
          offset: node.x() - box.x - box.width - off,
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: box.y,
          offset: node.y() - box.y - off,
          snap: 'start',
        },
        {
          guide: box.y + box.height / 2,
          offset: node.y() - box.y - box.height / 2,
          snap: 'center',
        },
        {
          guide: box.y + box.height - off,
          offset: node.y() - box.y - box.height - off,
          snap: 'end',
        },
      ],
    };
  }

  static getGuides(lineGuideStops: any, itemBounds: any, strength) {
    const resultV = [];
    const resultH = [];

    let x = 30;
    switch (strength) {
      case ConfigConsts.ZERO:
        x = 0;
        break;
      case ConfigConsts.WEAK:
        x = 20;
        break;
      case ConfigConsts.MEDIUM:
        x = 50;
        break;
      case ConfigConsts.STRONG:
        x = 200;
        break;
    }

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < x) {
          resultV.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < x) {
          resultH.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides = [];

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }
    return guides;
  }

  static drawGuides(guides, layer) {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-16000, lg.lineGuide, 16000, lg.lineGuide],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 5,
          name: 'guid-line',
          dash: [4, 6],
        });
        layer.add(line);
        layer.batchDraw();
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [lg.lineGuide, -16000, lg.lineGuide, 16000],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 5,
          name: 'guid-line',
          dash: [4, 6],
        });
        layer.add(line);
        layer.batchDraw();
      }
    });
  }
}
