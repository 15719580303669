import { Component, OnInit } from '@angular/core';
import Konva from 'konva';
import { Stage } from 'konva/types/Stage';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  stage: Stage;

  constructor() {}

  ngOnInit(): void {
    this.stage = this.initStage();

    const layer = new Konva.Layer();
    this.stage.add(layer);

    // create shape
    const box = new Konva.Rect({
      x: 50,
      y: 50,
      width: 100,
      height: 50,
      fill: '#00D2FF',
      stroke: 'black',
      strokeWidth: 4,
      draggable: true,
    });
    layer.add(box);

    layer.draw();

    // add cursor styling
    box.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });
    box.on('mouseout', function () {
      document.body.style.cursor = 'default';
    });
  }

  initStage(): Stage {
    return new Konva.Stage({
      container: 'container',
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
}
