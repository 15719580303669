
import Konva from 'konva';
import { Vector2d } from 'konva/types/types';
import { ProductSettings } from '../productSettings';
import { Setting } from '../setting';
import { PositionNumber } from './position';
import { Windows } from './windows';

export class Door extends Konva.Group {
  hasHandle: boolean;
  hingeWidth = 20;
  windows: Windows;
  visibilityType = 'exterior';
  positionMarker = 0;
  overrideHandles = false;
  disableHinges = false;

  constructor(
    context,
    settings: ProductSettings,
    overrideHandles?: boolean,
    disableHinges?: boolean
  ) {
    super(context);
    this.attrs.name = 'door';
    this.overrideHandles = overrideHandles;
    this.disableHinges = disableHinges;
  }

  drawDoor() {
    if (!this.attrs.width || !this.attrs.height) {
      return;
    }
    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.attrs.width,
        height: this.attrs.height,
        stroke: 'black',
        name: 'doorStroke',
      })
    );

    // if (this.windows) {
    //     this.addWindow(this.windows.noWindows);
    // }
  }

  drawHandle(handlePlacement: Vector2d, handleOr: string, handleType: string) {
    if (!handlePlacement) {
      return;
    }
    let w = !(handleOr === 'L') ? 20 : 60;
    let h = handleOr === 'L' ? 20 : 60;
    let dy = handleOr !== 'L' ? 0 : 30;

    switch (handleType) {
      case 'KNOP':
        w = 30;
        h = 30;
        dy = 0;
    }

    this.add(
      new Konva.Rect({
        x: Number(handlePlacement.x),
        y: Number(handlePlacement.y) + dy,
        width: w,
        height: h,
        fill: 'black',
        name: 'handle',
      })
    );
  }


  drawTipon(handlePlacement: Vector2d): void {
    if (!handlePlacement) {
      return;
    }
    this.add(
      new Konva.Circle({
        x: Number(handlePlacement.x),
        y: Number(handlePlacement.y) + 30,
        radius: 12,
        name: 'handle',
      })
    );
  }

  drawHinges(hingePlacements: Vector2d[]) {
    if (this.disableHinges) {
      return;
    }
    hingePlacements.forEach((placement: Vector2d) => {
      this.drawHinge(placement);
    });
  }

  drawHinge(hingePlacement: Vector2d) {
    if (
      (hingePlacement.x == undefined && hingePlacement.x !== 0) ||
      (hingePlacement.y == undefined && hingePlacement.y !== 0)
    ) {
      return;
    }
    this.add(
      new Konva.Rect({
        y: Number(hingePlacement.y),
        x: Number(hingePlacement.x),
        width: this.hingeWidth,
        height: 20,
        fill: 'black',
        name: 'hinge',
      })
    );
  }

  addWindow(noWindows, type) {
    this.add(new Windows(this.attrs, noWindows, type));
    this.windows = new Windows(this.attrs, noWindows, type);
  }

  removeHandles() {
    this.find('.handle').each((handle) => {
      handle.destroy();
    });
  }

  removeHinges() {
    this.find('.hinge').each((hinge) => {
      hinge.destroy();
    });
  }

  removeWindows() {}

  getNoHinges(): number {
    let i = 0;
    this.find('.hinge').each((hinge) => {
      i += 1;
    });
    return i;
  }

  updatePositionMarker() {
    this.deletePositionMarker();
    this.add(new PositionNumber(this.attrs, this.positionMarker));
  }

  deletePositionMarker() {
    this.find('.positionMarker').each((pos: PositionNumber) => {
      pos.destroy();
    });
  }
}
