import { Layer } from 'konva/types/Layer';
import { Stage } from 'konva/types/Stage';
import { Wall } from '../model/wall';

export class WallUtils {
  static wallSort(layers: Layer[]) {
    let x = 0;
    layers.forEach((layer) => {
      layer.position({ x, y: 0 });
      layer.find('.wall').each((wall) => {
        x += wall.attrs.width;
      });
    });
  }

  static locateWall(stage: Stage, activeLayer: Layer) {
    stage.position({ x: -activeLayer.attrs.x * stage.attrs.scaleX, y: 0 });
    stage.draw();
  }

  static updateDataFromWall(wall: Wall, order: any, index: number) {
    if (index === null) {
      return;
    }

    order.productOrderWalls.push({
      wallID: index,
      name: wall.wallName,
      width: wall.attrs.width,
      height: wall.attrs.height,
      sockelHeight: wall.sockelHeight,
    });
  }
}
