import Konva from 'konva';

export class DekorlistSockel extends Konva.Group {
  h: number;
  w: number;

  constructor(
    context: any,
    height: number,
    width: number,
    y: number,
    drawDekorlist: boolean,
    leftSide: boolean,
    rightSide: boolean
  ) {
    super(context);
    this.attrs.y = y;
    this.attrs.x = 0;
    this.h = height;
    this.w = width;
    this.attrs.name = 'dekorlistSockel';
    if (drawDekorlist) {
      this.drawDekorlist();
    } else {
      this.drawSockel();
    }

    if (leftSide) {
      this.drawLeftSide();
    }

    if (rightSide) {
      this.drawRightSide();
    }
  }

  drawDekorlist(): void {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.w,
        height: 20,
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 0,
        y: this.h - 20,
        width: this.w,
        height: 20,
        fill: 'black',
      })
    );
    this.add(
      new Konva.Line({
        points: [this.w / 2, 0, this.w / 2, this.h],
        strokeWidth: this.w,
        name: 'drawerFillPattern',
        stroke: 'black',
        dash: [1, 10],
      })
    );
  }

  drawSockel(): void {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: this.w,
        height: 20,
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 0,
        y: this.h - 20,
        width: this.w,
        height: 20,
        fill: 'black',
      })
    );
    this.add(
      new Konva.Line({
        points: [this.w / 2, 0, this.w / 2, this.h],
        strokeWidth: this.w,
        name: 'drawerFillPattern',
        stroke: 'black',
        dash: [1, 15],
      })
    );
  }

  drawLeftSide(): void {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: 20,
        height: this.h,
        fill: 'black',
      })
    );
  }

  drawRightSide(): void {
    this.add(
      new Konva.Rect({
        x: this.w - 20,
        y: 0,
        width: 20,
        height: this.h,
        fill: 'black',
      })
    );
  }
}
