import { Component, OnDestroy } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Wall } from 'src/app/model/wall';
import { AuthorisationService } from 'src/app/services/auth.service';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-wall-edit',
  templateUrl: './wall-edit.component.html',
  styleUrls: ['./wall-edit.component.scss'],
})
export class WallEditComponent implements OnDestroy {
  wall: Wall = new Wall({}, '', '', 0);
  editDrawing: boolean;

  width = 0;
  height = 0;
  sockelHeight = 0;

  wallEditEmitterSub;
  permissionsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private authService: AuthorisationService,
    private logService: LogService
  ) {
    this.wallEditEmitterSub = this.navbarService.wallEditEmitter.subscribe(
      (wall) => {
        this.wall = wall;
        this.width = this.wall.attrs.width;
        this.height = this.wall.attrs.height;
        this.sockelHeight = this.wall.sockelHeight;
      }
    );

    this.permissionsEmitterSub = this.authService.permissionsEmitter.subscribe(
      (permissions: any) => {
        if (permissions) {
          this.editDrawing = permissions.editDrawing;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.wallEditEmitterSub.unsubscribe();
    this.permissionsEmitterSub.unsubscribe();
  }

  updateView(): void {
    if (this.width < 3000) {
      this.width = 3000;
    }
    if (this.width > 10000) {
      this.width = 10000;
    }
    this.wall.attrs.width = this.width;
    this.wall.attrs.height = this.height;
    this.wall.sockelHeight = this.sockelHeight;
    this.wall.drawBasicRect();
    this.navbarService.stageActionEmitter.emit(Actions.CLEANUP_LAYERS);
    this.logService
      .postLog(
        LogConsts.INFO,
        'Updating wall: ' +
          this.wall.wallName +
          ', width: ' +
          this.wall.attrs.width +
          ', height: ' +
          this.wall.attrs.height +
          ', sockelHeight: ' +
          this.wall.sockelHeight
        // tslint:disable-next-line: no-empty
      )
      .subscribe(() => {});
    this.wall.recentlyEdited = true;
    this.navbarService.wallEditEmitter.emit(this.wall);
  }
}
