import { Layer } from 'konva/types/Layer';
import { GeneralSettings } from '../model/generalSettings';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Mapping } from '../model/subsettings/mapping';
import { Wall } from '../model/wall';
import { CollisionDetection } from './collisonDetection';
import { DesignUtils } from './designUtils';
import { FictionalSettingsUtil } from './fictionalSettingsUtil';

export class FictionalProductUtils {
  static createNewFictionalProduct(
    fictionalProduct,
    activeLayer: Layer,
    settings: GeneralSettings,
    fixedWidth: boolean
  ): void {
    const isBottom = true;
    const st = [];

    st.push(
      new Setting(
        999,
        'Kommentar',
        new Mapping('COMMENTS', null),
        'COMMENT',
        '',
        null,
        'TEXT',
        'TEXTAREA',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );
    st.push(
      new Setting(
        999,
        'Bredd',
        new Mapping('WIDTH', null),
        'EDITABLE',
        Number(this.setWidth(fictionalProduct)),
        null,
        'NUMERIC',
        'TEXTFIELD',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );
    st.push(
      new Setting(
        999,
        'Höjd',
        new Mapping('HEIGHT', null),
        'EDITABLE',
        Number(this.setHeight(fictionalProduct)),
        null,
        'NUMERIC',
        'TEXTFIELD',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );

    st.push(FictionalSettingsUtil.getFictionalDekorlistSetting());

    const newProduct = new Product(
      {
        draggable: true,
        x: 1,
        y: 1,
        width: this.setWidth(fictionalProduct),
        height: this.setHeight(fictionalProduct),
        strokeWidth: 20,
      },
      fictionalProduct.name,
      '',
      isBottom,
      st,
      true,
      true,
      false,
      null,
      null,
      null,
      null,
      null
    );

    newProduct.fictionalID = fictionalProduct.fictionalID;
    newProduct.fictionalType = this.setDesign(fictionalProduct);
    // newProduct.addFictional();

    const wallX = 0;
    let wallW = 0;
    let wallH = 0;
    let sockelHeight = 0;

    activeLayer.find('.wall').each((x: Wall) => {
      wallW = x.attrs.width;
      wallH = x.attrs.height;
      sockelHeight = x.sockelHeight;
    });

    this.setPosition(newProduct);
    if (newProduct.isBottom) {
      newProduct.attrs.y = wallH - newProduct.attrs.height - sockelHeight;
    } else {
      const productHeight = settings.settings.find((setting: Setting) => {
        return setting.id === 150;
      });
      if (productHeight) {
        newProduct.attrs.y = wallH - Number(productHeight.value);
      } else {
        newProduct.attrs.y = wallH - 2000;
      }
    }

    if (newProduct.isBottom !== null) {
      for (let i = wallX; i < wallW; i++) {
        CollisionDetection.checkCollision(activeLayer, newProduct, null);
        if (!newProduct.isValid) {
          newProduct.attrs.x = i;
        }
      }
    }

    if (!newProduct.isValid) {
      newProduct.attrs.x = 0;
      newProduct.attrs.y = 0;
    }

    newProduct.fixedWidth = fixedWidth;
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();
    // newProduct.updateProduct();
    activeLayer.add(newProduct);
    activeLayer.draw();
  }

  static restoreFictionalProduct(
    fictionalProduct,
    activeLayer: Layer,
    fixedWidth: boolean
  ) {
    const isBottom = false;

    const st = [];

    st.push(
      new Setting(
        999,
        'Kommentar',
        new Mapping('COMMENTS', null),
        'COMMENT',
        '',
        null,
        'TEXT',
        'TEXTAREA',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );
    st.push(
      new Setting(
        999,
        'Bredd',
        new Mapping('WIDTH', null),
        'EDITABLE',
        Number(this.setWidth(fictionalProduct)),
        null,
        'NUMERIC',
        'TEXTFIELD',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );
    st.push(
      new Setting(
        999,
        'Höjd',
        new Mapping('HEIGHT', null),
        'EDITABLE',
        Number(this.setHeight(fictionalProduct)),
        null,
        'NUMERIC',
        'TEXTFIELD',
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        null,
        9999,
        true,
        false,
        false,
        ''
      )
    );

    // only one setting becauseof the sockel/dekorlist update
    st.push(FictionalSettingsUtil.getFictionalDekorlistSetting());

    const newProduct = new Product(
      {
        draggable: true,
        x: fictionalProduct.positionX,
        y: fictionalProduct.positionY,
        width: this.setWidth(fictionalProduct),
        height: this.setHeight(fictionalProduct),
        strokeWidth: 20,
      },
      this.setName(fictionalProduct),
      '',
      isBottom,
      st,
      true,
      true,
      false,
      null,
      null,
      null,
      null,
      null
    );

    //

    const set = JSON.parse(fictionalProduct.fictionalSettings);
    if (set) {
      newProduct.changeValue('COMMENTS', set.comments);
      newProduct.changeValue('WIDTH', set.width);
      newProduct.changeValue('HEIGHT', set.height);
      newProduct.changeValue('HAS_DEKORLIST', set.hasDekorlist);
      newProduct.changeValue('HAS_SOCKEL', set.hasSockel);
    } else {
    }

    newProduct.fictionalID = fictionalProduct.fictionalID;
    newProduct.fictionalType = this.setDesign(fictionalProduct);
    // newProduct.addFictional();

    this.setPosition(newProduct);

    newProduct.fixedWidth = fixedWidth;
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();

    // newProduct.updateProduct();
    if (activeLayer) {
      activeLayer.add(newProduct);
      activeLayer.draw();
    }
  }

  static setWidth(fictionalProduct): number {
    switch (fictionalProduct.fictionalID) {
      case 0:
        return 600;
      case 1:
        return 700;
      case 2:
        return 600;
      case 10:
        return 590;
      case 11:
        return 590;
      case 20:
        return 590;
      case 21:
        return 890;
      case 30:
        return 600;
      case 40:
        return 590;
      case 41:
        return 590;
      case 60:
        return 400;
      case 61:
        return 800;
    }
    return 300;
  }

  static setHeight(fictionalProduct): number {
    switch (fictionalProduct.fictionalID) {
      case 0:
        return 600;
      case 1:
        return 600;
      case 2:
        return 720;
      case 10:
        return 300;
      case 11:
        return 450;
      case 20:
        return 1500;
      case 21:
        return 1800;
      case 30:
        return 720;
      case 40:
        return 120;
      case 41:
        return 600;
      case 50:
        return 70;
      case 60:
        return 700;
      case 61:
        return 700;
    }
    return 300;
  }

  static setDesign(fictionalProduct): string {
    switch (fictionalProduct.fictionalID) {
      case 0:
        return 'oven';
      case 1:
        return 'oven';
      case 2:
        return 'tall-oven';
      case 10:
        return 'microwave';
      case 11:
        return 'microwave';
      case 20:
        return 'refrigerator';
      case 21:
        return 'refrigerator';
      case 30:
        return 'dishwasher';
      case 40:
        return 'fan-small';
      case 41:
        return 'fan';
      case 50:
        return 'dekorlist';
      case 60:
        return 'window';
      case 61:
        return 'double-window';
    }
    return '';
  }

  static setName(fictionalProduct): string {
    switch (fictionalProduct.fictionalID) {
      case 0:
        return 'Ugn';
      case 1:
        return 'Bred Ugn';
      case 2:
        return 'SPIS';
      case 10:
        return 'Mikrovågsugn';
      case 11:
        return 'Hög Mikrovågsugn';
      case 20:
        return 'Kylskåp';
      case 21:
        return 'Stort Kylskåp';
      case 30:
        return 'Diskmaskin';
      case 40:
        return 'Skåpfläkt';
      case 41:
        return 'Köksfläkt';
      case 50:
        return '';
      case 60:
        return 'Fönster';
      case 61:
        return 'Dubbelt Fönster';
    }
    return '';
  }

  static setPosition(fictionalProduct: Product): void {
    switch (fictionalProduct.fictionalID) {
      case 0:
        fictionalProduct.isBottom = true;
        return;
      case 1:
        fictionalProduct.isBottom = true;
        return;
      case 2:
        fictionalProduct.isBottom = true;
        return;
      case 10:
        fictionalProduct.isBottom = false;
        return;
      case 11:
        fictionalProduct.isBottom = false;
        return;
      case 20:
        fictionalProduct.isBottom = true;
        return;
      case 21:
        fictionalProduct.isBottom = true;
        return;
      case 30:
        fictionalProduct.isBottom = true;
        return;
      case 40:
        fictionalProduct.isBottom = false;
        return;
      case 41:
        fictionalProduct.isBottom = false;
        return;
      case 50:
        fictionalProduct.isBottom = false;
        return;
      case 60:
        fictionalProduct.isBottom = false;
        return;
      case 61:
        fictionalProduct.isBottom = false;
        return;
    }
  }
}
