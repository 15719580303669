<div
  class="modal fade"
  id="drawerPositionerMenuModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="drawerModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="drawerModalLongTitle">
          {{ "CHOOSE_PRODUCT" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <ul *ngIf="drawers?.length !== 0" class="list-group">
            <div>{{ "MAIN_DRAWER" | translate }}:</div>
            <div *ngFor="let drawer of drawers">
              <li
                class="list-group-item custom-list-item text-wrap"
                [ngClass]="
                  mainDrawer && drawer.drawer.attrs.id === mainDrawer.attrs.id
                    ? 'list-group-item-success'
                    : 'list-group-item'
                "
                (click)="changeMainDrawer(drawer)"
              >
                <div class="d-flex justify-content-between dr-text-wrap">
                  <div
                    class="d-flex align-items-center custom-extra-padding custom-width"
                  >
                    <div class="text-truncate d-inline-block">
                      {{ drawer.drawer.productSettings.POSITIONNUMBER }}.)
                      {{ drawer.drawer.type }}
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <ul *ngIf="drawers?.length !== 0" class="list-group">
            {{
              "DRAWERS_TO_ADJUST" | translate
            }}:
            <div *ngFor="let drawer of drawers">
              <li
                class="list-group-item custom-list-item text-wrap"
                [ngClass]="
                  drawer.status ? 'list-group-item-info' : 'list-group-item'
                "
                (click)="enableDrawerForEdit(drawer)"
              >
                <div class="d-flex justify-content-between dr-text-wrap">
                  <div
                    class="d-flex align-items-center custom-extra-padding custom-width"
                  >
                    <div class="text-truncate d-inline-block">
                      {{ drawer.drawer.productSettings.POSITIONNUMBER }}.)
                      {{ drawer.drawer.type }}
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="applyDrawerChanges()"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="!mainDrawer"
        >
          <i-bs name="check"></i-bs>
        </button>
      </div>
    </div>
  </div>
</div>
