import Konva from 'konva';
import { Rect } from 'konva/types/shapes/Rect';
import { FixedDimensionsConsts } from '../consts/fixedDimensionsConsts';
import { MandatorySettings } from '../consts/mandatorySettings';
import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';
import { Cabin } from '../model/subproducts/cabin';
import { Door } from '../model/subproducts/door';
import { Drawer } from '../model/subproducts/drawer';
import { Zigzag } from '../model/subproducts/zigzag';
import { DoorUtil } from './doorUtil';
import { DrawerUtils } from './drawerUtils';
import { FictionalProductUtils } from './fictionalUtils';

export class DesignUtils {
    static updateDesign(product: Product, quick: boolean) {

        if (product.isFictional) {
            this.drawFictional(product, quick);
            return;
        }
        if (!product.productSettings || !product.productSettings.DESIGN_OF_PRODUCT) {
            this.drawCabin(product, quick);
            return;
        }

        if (product.design) {
            this.designProduct(product, quick);
            return;
        }


        switch (product.productSettings.DESIGN_OF_PRODUCT) {
            case 'DOOR': //
                this.drawDoor(product, quick);
                break;
            case 'HIGHDOOR': //
                this.drawHighDoor(product, quick);
                break;
            case '90DEGREES': //
                this.drawDoor90(product, quick);
                break;
            case 'DOORWITHOUTHINGES': //
                this.drawDoorWithoutHinges(product, quick);
                break;
            case 'DRAWER5': //
                this.drawDrawer5(product, quick);
                break;
            case 'DRAWER4': //
                this.drawDrawer4(product, quick);
                break;
            case 'DRAWER3': //
                this.drawDrawer3(product, quick);
                break;
            case 'DRAWER2': //
                this.drawDrawer2(product, quick);
                break;
            case 'DRAWER1': //
                this.drawDrawer1(product, quick);
                break;
            case 'DRAWERSIMPLE': //
                this.drawSimpleDrawer(product, quick);
                break;
            case 'DRAWERSIMPLE':
                this.drawSimpleDrawer(product, quick);
                break;
            case 'DRAWERSPECIAL5': //
                this.drawDrawerSpecial5(product, quick);
                break;
            case 'DRAWERSPECIAL4': //
                this.drawDrawerSpecial4(product, quick);
                break;
            case 'DOORWITHWINDOW1': //
                this.drawDoorWithWindow1(product, quick);
                break;
            case 'DOORWITHWINDOW4': //
                this.drawDoorWithWindow4(product, quick);
                break;
            case 'DOORWITHWINDOW6': //
                this.drawDoorWithWindow6(product, quick);
                break;
            case 'DOORWITHWINDOW8': //
                this.drawDoorWithWindow8(product, quick);
                break;
            case 'DOORWITHOUTWINDOW1': //
                this.drawDoorWithoutWindow1(product, quick);
                break;
            case 'DOORWITHOUTWINDOW4': //
                this.drawDoorWithoutWindow4(product, quick);
                break;
            case 'DOORWITHOUTWINDOW6': //
                this.drawDoorWithoutWindow6(product, quick);
                break;
            case 'DOORWITHOUTWINDOW8': //
                this.drawDoorWithoutWindow8(product, quick);
                break;
            case 'DOORDOOR': //
                this.drawDoorDoor(product, quick);
                break;
            case 'DRAWERSPACEDOOR':
                this.drawDrawerSpaceDoor(product, quick);
                break;
            case 'DRAWER5DOOR':
                this.drawDrawer5Door(product, quick);
                break;
            case 'DRAWER4DOOR':
                this.drawDrawer4Door(product, quick);
                break;
            case 'DRAWER3DOOR':
                this.drawDrawer3Door(product, quick);
                break;
            case 'DRAWER2DOOR':
                this.drawDrawer2Door(product, quick);
                break;
            case 'DRAWER1DOOR':
                this.drawDrawer1Door(product, quick);
                break;
            case 'CLOSINGSHELF':
                this.drawEndshelf(product, quick);
                break;
            case 'COVERSIDE':
                this.drawCoverside(product, quick);
                break;
            case 'CUTTINGBOARD':
                this.drawCuttingBoard(product, quick);
                break;
            case 'SPARECOVER':
                this.drawSpareCover(product, quick);
                break;
            default:
                this.drawCabin(product, quick);
                break;
        }
    }



    static designProduct(product: Product, quick: boolean): void {

        const commands = product.design?.configuration;
        // define mandatory settings?
        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();
        product.deleteZigzag();
        product.deleteCustomRect();
        if (quick) { return; }

        commands?.forEach((command: any) => {
            let params = {};
            command.params.forEach((param: any) => {
                params[param.name] = param.value;
            });
            switch (command.command) {
                case 'set_dimensions':
                    this.setDimensions(product, params);
                    break;
                case 'add_cabin':
                    this.drawSubCabin(product, params);
                    break;
                case 'add_door':
                    this.drawSubDoor(product, params);
                    break;
                case 'add_drawer':
                    this.drawSubDrawer(product, params);
                    break;
                case 'add_custom_rect':
                    this.drawCustomRect(product, params);
                    break;
            }
        })

        // additional

        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });


        //to not filter all commands every time we should make variables for each command
    }

    static setDimensions(product: Product, params: any) {
        /* 
        height_setting
        height_value
        width_setting

        */

        if (product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE') {
            product.hasFixedWidth = true;
        } else {
            product.hasFixedWidth = false;
        }
        let height = 0;
        if (params.height_setting) {
            height = this.extractSettingValue(product, params.height_setting);
        } else if (params.height_value) {
            height = this.extractSettingValue(product, params.height_value);
        }
        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = height;
            } else if (product.productSettings.STANDINGLAYING === 'L') {
                const width = this.extractSettingValue(product, params.width_setting);
                product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : FixedDimensionsConsts.WIDTH;
                product.HEIGHT = width;
            } else {
                product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : FixedDimensionsConsts.WIDTH;
                product.HEIGHT = height;
            }
        } else {
            const width = this.extractSettingValue(product, params.width_setting);
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = width * 2;
                product.HEIGHT = height;
            } else if (product.productSettings.STANDINGLAYING === 'L') {
                product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : height;
                product.HEIGHT = width;
            } else {
                product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : width;
                product.HEIGHT = height;
            }
        }
    }

    static drawSubCabin(product: Product, params: any) {
        /*
        perspective
        lean
        */
        if (params.perspective) {
            product.perspective = true;
        }


        if (params.hide_side) {
            if (product.productSettings.RIGHT_LEFT === 'RIGHT') {
                product.specialParams.hideLeftCabin = true;
                product.specialParams.hideRightCabin = false;
            } else {
                product.specialParams.hideLeftCabin = false;
                product.specialParams.hideRightCabin = true;
            }
        }
        product.add(new Cabin({ height: product.HEIGHT, width: product.attrs.width }, product.productSettings, false, null, product.specialParams, product.perspective, params.lean));
    }


    static drawSubDoor(product: Product, params: any) {
        /*
        windows
        window_type
        override_handles
        disable_hinges
        n_degrees
        door_height_setting
        door_position_y_setting
        standard_doors
        */

        const door_position_y_setting = this.extractSettingValue(product, params.door_position_y_setting);
        const door_height_setting = this.extractSettingValue(product, params.door_height_setting);
        product.doors = [];

        if (!params.standard_doors) {
            product.standardDoors = false;
        }
        if (params.n_degrees) {
            product.deleteZigzag();
            if (product.fixedWidth) {
                const door1 = new Door({ x: 0, width: product.WIDTH / 2, height: door_height_setting },
                    product.productSettings);
                product.add(door1);
                product.doors.push(door1);
                const door2 = new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: door_height_setting },
                    product.productSettings);
                product.add(door2);
                product.doors.push(door2);
                product.add(new Zigzag({ x: product.WIDTH / 2, y: 0, width: 0, height: door_height_setting }));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH) + Number(product.productSettings.WIDTH_2);
                const door1 = new Door({ x: 0, width: Number(product.productSettings.WIDTH), height: door_height_setting },
                    product.productSettings);
                product.add(door1);
                product.doors.push(door1);
                const door2 = new Door({ x: Number(product.productSettings.WIDTH), width: Number(product.productSettings.WIDTH_2), height: door_height_setting },
                    product.productSettings);
                product.add(door2);
                product.doors.push(door2);
                product.add(new Zigzag({ x: Number(product.productSettings.WIDTH), y: 0, width: 0, height: door_height_setting }));
            }
        } else {
            if (product.fixedWidth) {
                const hl = product.productSettings.HEATSHIELD_LEFTSIDE === '12' ? 12 : 0;
                if (product.productSettings.DOUBLE_DOOR === '2') {
                    const door1 = new Door({ width: product.attrs.width / 2 - hl, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door1);
                    product.doors.push(door1);
                    const door2 = new Door({ x: product.attrs.width / 2 - hl, width: product.attrs.width / 2 - hl, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door2);
                    product.doors.push(door2);
                } else {
                    const door1 = new Door({ width: product.attrs.width, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door1);
                    product.doors.push(door1);
                }
            } else {
                if (product.productSettings.DOUBLE_DOOR === '2') {
                    const door1 = new Door({ width: product.attrs.width / 2, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door1);
                    product.doors.push(door1);
                    const door2 = new Door({ x: product.attrs.width / 2, width: product.attrs.width / 2, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door2);
                    product.doors.push(door2);
                } else {
                    const door1 = new Door({ width: product.attrs.width, height: door_height_setting, y: door_position_y_setting },
                        product.productSettings, params.override_handles, params.disable_hinges);
                    product.add(door1);
                    product.doors.push(door1);
                }
            }
        }


        if (params.windows) {
            product.doors.forEach((door) => {
                door.addWindow(Number(params.windows), Number(params.window_type));
            });
        }
        product.updateDoors();
    }

    static drawSubDrawer(product: Product, params: any) {

        /*
        drawer_height_setting
        drawer_position_y_setting
        drawer_type
        */

        const drawer_height_setting = this.extractSettingValue(product, params.drawer_height_setting);
        const drawer_position_y_setting = this.extractSettingValue(product, params.drawer_position_y_setting);
        const additional_y = this.extractSettingValue(product, params.additionaly_y);

        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - Number(product.productSettings.CABIN_COVER_LEFT) - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: drawer_position_y_setting + additional_y,
                width: newWidth,
                height: drawer_height_setting
            },
            params.drawer_type));

    }

    static drawCustomRect(product: Product, params: any): void {
        /* 
        pox_x
        pos_y
        width_value
        height_value
        */

        const pos_x = this.extractSettingValue(product, params.pox_x);
        const pos_y = this.extractSettingValue(product, params.pos_y);
        const width_value = this.extractSettingValue(product, params.width_value);
        const height_value = this.extractSettingValue(product, params.height_value);
        product.add(new Konva.Rect(
            {
                x: pos_x,
                y: product.attrs.height / 2 + pos_y,
                width: product.WIDTH + width_value,
                height: height_value,
                fill: 'black',
                name: 'customRect'
            }));
    }

    static extractSettingValue(product: Product, paramValue: any[]): number {
        let sum = 0;
        if (!paramValue || paramValue.length === 0) {
            return 0;
        }
        paramValue.forEach((value: any) => {
            if (typeof value === 'string') {
                if (Number(value)) {
                    sum += Number(value);
                } else {
                    sum += product.productSettings[value] ? Number(product.productSettings[value]) : 0;
                }
            } else {
                sum += value;
            }
        });
        return sum;
    }


    static drawCabin(product: Product, quick) {
        product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
        product.HEIGHT = Number(product.productSettings.HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        product.deleteCabin();
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams));
    }

    static drawFictional(product: Product, quick) {
        product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
        product.HEIGHT = Number(product.productSettings.HEIGHT);
        if (quick) { return; }
        product.deleteCabin();
        product.add(new Cabin({ height: Number(product.HEIGHT), width: Number(product.attrs.width), strokeWidth: product.attrs.strokeWidth }, product.productSettings, true, product.fictionalType, product.specialParams));
    }

    static drawDoor(product: Product, quick) {
        // apply dimensions
        // this is terrible
        // this.should be working in the way that it'll be possible to
        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            const hl = product.productSettings.HEATSHIELD_LEFTSIDE === '12' ? 12 : 0;
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width / 2 - hl, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ x: product.attrs.width / 2 - hl, width: product.attrs.width / 2 - hl, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ x: product.attrs.width / 2, width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));
            }
        }

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();

    }

    static drawDoorWithoutHinges(product: Product, quick) {
        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings, null, true));
                product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings, null, true));
            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings, null, true));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings, null, true));
                product.add(new Door({ x: product.attrs.width / 2, width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings, null, true));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings, null, true));
            }
        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();

    }
    static drawHighDoor(product: Product, quick) {

        // apply dimensions
        // this is terrible
        // this.should be working in the way that it'll be possible to
        product.deleteCabin();
        product.deleteDoors();
        // product.handlePlacement = 'MIDDLE';

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        // calculations depending handle and hinge placement should be done internally
        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ x: product.attrs.width / 2, width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ x: product.attrs.width / 2, width: product.attrs.width / 2, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));
            }

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();

    }

    static drawDoor90(product: Product, quick) {
        product.deleteCabin();
        product.deleteDoors();
        product.deleteZigzag();
        // calculations depending handle and hinge placement should be done internally
        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.WIDTH_2, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {

            product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
            product.HEIGHT = Number(product.productSettings.HEIGHT);
            if (quick) { return; }
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ x: 0, width: product.WIDTH / 2, height: product.attrs.height },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: product.attrs.height },
                product.productSettings));
            product.add(new Zigzag({ x: product.WIDTH / 2, y: 0, width: 0, height: product.attrs.height }));
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH_2) + Number(product.productSettings.WIDTH);
            product.HEIGHT = Number(product.productSettings.HEIGHT);
            if (quick) { return; }
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ x: 0, width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                product.productSettings));
            product.add(new Door({ x: Number(product.productSettings.WIDTH), width: Number(product.productSettings.WIDTH_2), height: product.attrs.height },
                product.productSettings));
            product.add(new Zigzag({ x: Number(product.productSettings.WIDTH), y: 0, width: 0, height: product.attrs.height }));


        }


        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });

        // could use other function than updateDoors
        product.updateDoors();
    }

    static drawDrawer5(product: Product, quick) {
        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);
        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 2 + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 3 + 12 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer4(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);
        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 2 + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer3(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);

        if (quick) { return; }
        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer2(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.TOP_FRONT, Mapping.BOTTOM_FRONT]);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer1(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.BOTTOM_FRONT]);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'top'));

        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawSimpleDrawer(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: 0,
                width: newWidth,
                height: Number(product.productSettings.HEIGHT)
            },
            'top'));

        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawerSpecial5(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT, Mapping.FRONTS_MIDDLE_HEIGHT2, Mapping.FRONTS_MIDDLE_HEIGHT3]);
        }


        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT2)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT2) + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT3)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT2) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT3) + 12 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawerSpecial4(product: Product, quick) {

        if (product.fixedWidth) {
            product.WIDTH = FixedDimensionsConsts.WIDTH;
        } else {
            product.WIDTH = Number(product.productSettings.WIDTH);
        }
        product.HEIGHT = Number(product.productSettings.TOTAL_FRONT_HEIGHT);

        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.TOTAL_FRONT_HEIGHT, Mapping.FRONTS_MIDDLE_HEIGHT2]);
        }

        product.deleteDrawers();
        product.deleteCabin();
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 0 : 0;
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT2)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT2) + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDoorWithWindow1(product: Product, quick) {


        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }
        }

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(1, 1);
        });
        product.updateDoors();
    }

    static drawDoorWithWindow4(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }
        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.productSettings.WIDTH, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.productSettings.WIDTH, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));

            }
        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(4, 1);
        });
        product.updateDoors();
    }

    static drawDoorWithWindow6(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }

        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(6, 1);
        });
        product.updateDoors();
    }

    static drawDoorWithWindow8(product: Product, quick) {


        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));

            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }
        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(8, 1);
        });
        product.updateDoors();
    }
    static drawDoorWithoutWindow1(product: Product, quick) {


        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }
        }

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(1, 2);
        });
        product.updateDoors();
    }

    static drawDoorWithoutWindow4(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }
        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.WIDTH }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.productSettings.WIDTH, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.productSettings.WIDTH, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.attrs.width, height: product.attrs.height },
                    product.productSettings));

            }
        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(4, 2);
        });
        product.updateDoors();
    }

    static drawDoorWithoutWindow6(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));
            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }

        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(6, 2);
        });
        product.updateDoors();
    }

    static drawDoorWithoutWindow8(product: Product, quick) {


        product.deleteCabin();
        product.deleteDoors();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        if (product.fixedWidth) {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = FixedDimensionsConsts.WIDTH * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = FixedDimensionsConsts.WIDTH;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                    product.productSettings));

            }
        } else {
            if (product.productSettings.DOUBLE_DOOR === '2') {
                product.WIDTH = Number(product.productSettings.WIDTH) * 2;
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            } else {
                product.WIDTH = Number(product.productSettings.WIDTH);
                product.HEIGHT = Number(product.productSettings.HEIGHT);
                if (quick) { return; }
                product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
                product.add(new Door({ width: Number(product.productSettings.WIDTH), height: product.attrs.height },
                    product.productSettings));

            }
        }
        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.doors.forEach((door) => {
            door.addWindow(8, 2);
        });
        product.updateDoors();
    }

    static drawDoorDoor(product: Product, quick) {

        product.HEIGHT = Number(product.productSettings.HEIGHT);
        product.standardDoors = false;

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT, Mapping.TOP_DOOR_HEIGHT, Mapping.HEIGHT_2]);
        }

        product.deleteCabin();
        product.deleteDoors();
        product.doors = [];

        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH * 2 : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            const door1 = new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.TOP_DOOR_HEIGHT) },
                product.productSettings, true);
            product.add(door1);
            product.doors.push(door1);
            const door2 = new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.TOP_DOOR_HEIGHT) },
                product.productSettings, true);
            product.add(door2);
            product.doors.push(door2);

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            const door1 = new Door({ width: product.WIDTH, height: Number(product.productSettings.TOP_DOOR_HEIGHT) },
                product.productSettings, true);
            product.add(door1);
            product.doors.push(door1);

        }


        // calculations depending handle and hinge placement should be done internally
        product.updateDoors();
        product.doors = [];
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH * 2 : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            const door1 = new Door({ y: Number(product.productSettings.TOP_DOOR_HEIGHT), width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT_2) },
                product.productSettings);
            product.add(door1);
            product.doors.push(door1);
            const door2 = new Door({ x: product.WIDTH / 2, y: Number(product.productSettings.TOP_DOOR_HEIGHT), width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT_2) },
                product.productSettings);
            product.add(door2);
            product.doors.push(door2);

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            const door1 = new Door({ y: Number(product.productSettings.TOP_DOOR_HEIGHT), width: product.WIDTH, height: Number(product.productSettings.HEIGHT_2) },
                product.productSettings);
            product.add(door1);
            product.doors.push(door1);
        }
        product.updateDoors();
    }

    static drawDrawer1Door(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();
        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.CABIN_HEIGHT, Mapping.BOTTOM_FRONT]);
        }

        product.HEIGHT = Number(product.productSettings.CABIN_HEIGHT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // if drawerBottom
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 3 : 3;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));

        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer2Door(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.CABIN_HEIGHT, Mapping.TOP_FRONT, Mapping.BOTTOM_FRONT]);
        }

        product.HEIGHT = Number(product.productSettings.CABIN_HEIGHT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // if drawerBottom
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 3 : 3;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));



        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer3Door(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.CABIN_HEIGHT]);
        }

        product.HEIGHT = Number(product.productSettings.CABIN_HEIGHT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // if drawerBottom
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 3 : 3;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));
        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer4Door(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.CABIN_HEIGHT]);
        }

        product.HEIGHT = Number(product.productSettings.CABIN_HEIGHT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // if drawerBottom
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 3 : 3;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 2 + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));


        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawer5Door(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DOORS);
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DRAWERS);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.CABIN_HEIGHT]);
        }

        product.HEIGHT = Number(product.productSettings.CABIN_HEIGHT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: Number(product.productSettings.HEIGHT) },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // if drawerBottom
        let heatshieldSum = 0;
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_LEFTSIDE === '12') {
            heatshieldSum += 12;
        }
        if (!product.fixedWidth && product.productSettings.HEATSHIELD_RIGHTSIDE === '12') {
            heatshieldSum += 12;
        }
        const newWidth = product.WIDTH - product.productSettings.CABIN_COVER_LEFT - product.productSettings.CABIN_COVER_RIGHT + heatshieldSum;
        const fictionalHeight = product.productSettings.FICTIONAL_HEIGHT ? Number(product.productSettings.FICTIONAL_HEIGHT) + 3 : 3;
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));

        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + 3 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 2 + 9 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) * 3 + 12 + fictionalHeight,
                width: newWidth,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));



        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawDrawerSpaceDoor(product: Product, quick) {

        product.deleteCabin();
        product.deleteDoors();
        product.deleteDrawers();

        product.HEIGHT = Number(product.productSettings.HEIGHT) + Number(product.productSettings.TOP_FRONT);
        // calculations depending handle and hinge placement should be done internally
        if (product.productSettings.DOUBLE_DOOR === '2') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH * 2 : product.WIDTH = Number(product.productSettings.WIDTH) * 2;
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH / 2, height: product.attrs.height },
                product.productSettings));
            product.add(new Door({ x: product.WIDTH / 2, width: product.WIDTH / 2, height: product.attrs.height },
                product.productSettings));

        } else {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            if (quick) { return; }

            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width }, product.productSettings, false, null, product.specialParams));
            product.add(new Door({ width: product.WIDTH, height: product.attrs.height },
                product.productSettings));

        }

        // calculations depending handle and hinge placement should be done internally

        product.doors = [];
        product.find('.door').each((door: Door) => {
            product.doors.push(door);
        });
        product.updateDoors();
        // empty space
        // put nothing or a fictional cabin



        // drawers
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: 0,
                width: product.WIDTH,
                height: Number(product.productSettings.TOP_FRONT)
            },
            'top'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + 3,
                width: product.WIDTH,
                height: Number(product.productSettings.FRONTS_MIDDLE_HEIGHT)
            },
            'center'));
        product.add(new Drawer(
            {
                x: Number(product.productSettings.CABIN_COVER_LEFT),
                y: Number(product.productSettings.TOP_FRONT) + Number(product.productSettings.FRONTS_MIDDLE_HEIGHT) + 6,
                width: product.WIDTH,
                height: Number(product.productSettings.BOTTOM_FRONT)
            },
            'bottom'));
        product.find('.drawer').each((drawer: Drawer) => {
            drawer.addFront();
        });
    }

    static drawEndshelf(product: Product, quick) {
        product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
        product.HEIGHT = Number(product.productSettings.HEIGHT);
        if (quick) { return; }
        product.deleteCabin();

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT, Mapping.RIGHT_LEFT]);
        }

        product.perspective = true;

        if (product.productSettings.RIGHT_LEFT === 'RIGHT') {
            product.specialParams.hideLeftCabin = true;
            product.specialParams.hideRightCabin = false;
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams, product.perspective));
        } else {
            product.specialParams.hideLeftCabin = false;
            product.specialParams.hideRightCabin = true;
            product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams, product.perspective));
        }
    }

    static drawCoverside(product: Product, quick) {
        if (product.productSettings.STANDINGLAYING != 'L') {
            product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : Number(product.productSettings.WIDTH);
            product.HEIGHT = Number(product.productSettings.HEIGHT);


        } else {
            product.HEIGHT = Number(product.productSettings.WIDTH);
            product.WIDTH = product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE' ? 150 : product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : Number(product.productSettings.HEIGHT);

        }
        if (quick) { return; }
        if (product.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE') {
            product.hasFixedWidth = true;
        } else {
            product.hasFixedWidth = false;
        }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT, Mapping.RIGHT_LEFT, Mapping.COVERSIDE_PERSPECTIVE, Mapping.STANDINGLAYING]);
        }

        product.deleteCabin();
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams, product.perspective));
    }

    static drawSpareCover(product: Product, quick) {
        if (product.productSettings.STANDINGLAYING != 'L') {
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
            product.HEIGHT = Number(product.productSettings.HEIGHT);
        } else {
            product.HEIGHT = Number(product.productSettings.WIDTH);
            product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.HEIGHT);

        }
        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);
            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT, Mapping.STANDINGLAYING]);
        }
        product.deleteCabin();
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams, product.perspective));

    }

    static drawCuttingBoard(product: Product, quick) {
        product.fixedWidth ? product.WIDTH = FixedDimensionsConsts.WIDTH : product.WIDTH = Number(product.productSettings.WIDTH);
        product.HEIGHT = 50;
        product.hasFixedHeight = true;
        if (quick) { return; }

        if (product.mandatorySettings.length === 0) {
            product.mandatorySettings = product.mandatorySettings.concat(MandatorySettings.DESIGN);

            product.mandatorySettings = product.mandatorySettings.concat([Mapping.WIDTH, Mapping.HEIGHT]);
        }

        product.find('.cuttingBoardHandle').each((d) => {
            d.destroy();
        });

        product.deleteCabin();
        product.add(new Cabin({ height: product.attrs.height, width: product.attrs.width, strokeWidth: product.attrs.strokeWidth }, product.productSettings, false, null, product.specialParams, null, true));
        product.add(new Konva.Rect({ x: 100, y: product.attrs.height / 2 - 2.5, width: product.WIDTH - 200, height: 5, fill: 'black', name: 'cuttingBoardHandle' }));
    }
}
