import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnDestroy {
  categories: any[] = [];
  fictionalCategory = {
    name: 'Vitvaror',
  };
  fictionalCategorya = {
    name: 'Fasta hinder',
  };

  categoriesListEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService
  ) {
    this.categoriesListEmitterSub =
      this.productService.categoriesListEmitter.subscribe((categories) => {
        this.categories.push(this.fictionalCategory);
        this.categories.push(this.fictionalCategorya);
        // this.categories.push(...categories[0].subCategories)
        // this.categories.push(...categories[1].subCategories)
      });
  }
  ngOnDestroy(): void {
    this.categoriesListEmitterSub.unsubscribe();
  }

  chooseCategory(category): void {
    this.navbarService.productCategoryCreatorEmitter.emit(category);
  }
}
