<div
  class="modal fade"
  id="infoModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          *ngIf="alert.type === 'ACTION'"
          class="modal-title"
          id="exampleModalLongTitle"
        >
          {{ "INFO" | translate }}
        </h5>
        <h5
          *ngIf="alert.type === 'INFO'"
          class="modal-title"
          id="exampleModalLongTitle"
        >
          {{ "INFO" | translate }}
        </h5>
        <h5
          *ngIf="alert.type === 'ALERT'"
          class="modal-title"
          id="exampleModalLongTitle"
        >
          {{ "ERROR" | translate }}
        </h5>
        <h5
          *ngIf="alert.type === 'ALERT_ACTION'"
          class="modal-title"
          id="exampleModalLongTitle"
        >
          {{ "ERROR" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        {{ alert.text | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button
          *ngIf="alert.type === 'ACTION' || alert.type === 'ALERT_ACTION'"
          type="button"
          class="btn btn-secondary"
          (click)="ok()"
          data-dismiss="modal"
          aria-label="Close"
        >
          {{ "OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" id="backdrop" style="display: none"></div>
