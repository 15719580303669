import Konva from 'konva';
import { ConfigConsts } from '../consts/configConsts';
import { FixedDimensionsConsts } from '../consts/fixedDimensionsConsts';
import { Mapping } from '../consts/mapping';
import { GeneralSettings } from '../model/generalSettings';
import { MeasureLine } from '../model/measureLine';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Door } from '../model/subproducts/door';
import { Front } from '../model/subproducts/front';
import { Wall } from '../model/wall';
import { MeasurementCollisionDetectionUtil } from './measurementColisionDetectionUtil';

export class Measurements {
  static showOutsideMeasurements(layer, zoom, fixedWidth): void {
    // destroy previous lines

    let wall: Wall = null;
    layer.find('.wall').each((w) => {
      wall = w;
    });
    // draw eight lines for each product (two for each side)
    layer.find('.product').each((product) => {
      let top = 10000;
      let right = 10000;
      let bottom = 10000;
      let left = 10000;
      let topSnap: Product = null;
      let rightSnap: Product = null;
      let bottomSnap: Product = null;
      let leftSnap: Product = null;

      // here should be decided if the line should stick to a wall or other product
      layer.find('.product').each((intersectingProduct) => {
        let isTrue = true;
        if (product === intersectingProduct) {
          isTrue = false;
        }

        // top
        const topCheck = Math.abs(
          -product.attrs.y +
            intersectingProduct.attrs.y +
            intersectingProduct.attrs.height
        );
        if (
          isTrue &&
          top > topCheck &&
          product.attrs.y >=
            intersectingProduct.attrs.y + intersectingProduct.attrs.height &&
          product.attrs.x + product.attrs.width / 2 <=
            intersectingProduct.attrs.x + intersectingProduct.attrs.width &&
          product.attrs.x + product.attrs.width / 2 >=
            intersectingProduct.attrs.x
        ) {
          top = topCheck;
          topSnap = intersectingProduct;
        }

        // right
        const rightCheck = Math.abs(
          intersectingProduct.attrs.x - product.attrs.x
        );
        if (
          isTrue &&
          right > rightCheck &&
          product.attrs.x + product.attrs.width <=
            intersectingProduct.attrs.x &&
          product.attrs.y + product.attrs.height / 2 <=
            intersectingProduct.attrs.y + intersectingProduct.attrs.height &&
          product.attrs.y + product.attrs.height / 2 >=
            intersectingProduct.attrs.y
        ) {
          right = rightCheck;
          rightSnap = intersectingProduct;
        }

        // bottom
        const bottomCheck = Math.abs(
          -product.attrs.y + intersectingProduct.attrs.y
        );
        if (
          isTrue &&
          bottom > bottomCheck &&
          product.attrs.y + product.attrs.height <=
            intersectingProduct.attrs.y &&
          product.attrs.x + product.attrs.width / 2 <=
            intersectingProduct.attrs.x + intersectingProduct.attrs.width &&
          product.attrs.x + product.attrs.width / 2 >=
            intersectingProduct.attrs.x
        ) {
          bottom = bottomCheck;
          bottomSnap = intersectingProduct;
        }

        // left
        const leftCheck = Math.abs(
          intersectingProduct.attrs.width +
            intersectingProduct.attrs.x -
            product.attrs.x
        );
        if (
          isTrue &&
          left > leftCheck &&
          product.attrs.x + product.attrs.width > intersectingProduct.attrs.x &&
          product.attrs.y + product.attrs.height / 2 <
            intersectingProduct.attrs.y + intersectingProduct.attrs.height &&
          product.attrs.y + product.attrs.height / 2 >
            intersectingProduct.attrs.y
        ) {
          left = leftCheck;
          leftSnap = intersectingProduct;
        }
      });

      // add lines

      if (topSnap) {
        layer.add(
          new MeasureLine(
            { ...product.attrs },
            { x: product.attrs.width / 2, y: 0 },
            {
              x: product.attrs.width / 2,
              y: -product.attrs.y + topSnap.attrs.y + topSnap.attrs.height,
            },
            zoom
          )
        );
      } else {
        layer.add(
          new MeasureLine(
            { ...product.attrs },
            { x: product.attrs.width / 2, y: 0 },
            { x: product.attrs.width / 2, y: -product.attrs.y + wall.attrs.y },
            zoom
          )
        );
      }
      if (!fixedWidth) {
        if (rightSnap) {
          layer.add(
            new MeasureLine(
              { ...product.attrs },
              { x: product.attrs.width, y: product.attrs.height / 2 },
              {
                x: rightSnap.attrs.x - product.attrs.x,
                y: product.attrs.height / 2,
              },
              zoom
            )
          );
        } else {
          layer.add(
            new MeasureLine(
              { ...product.attrs },
              { x: product.attrs.width, y: product.attrs.height / 2 },
              {
                x: wall.attrs.width + wall.attrs.x - product.attrs.x,
                y: product.attrs.height / 2,
              },
              zoom
            )
          );
        }
      }

      if (bottomSnap) {
        layer.add(
          new MeasureLine(
            { ...product.attrs },
            { x: product.attrs.width / 2, y: product.attrs.height },
            {
              x: product.attrs.width / 2,
              y: -product.attrs.y + bottomSnap.attrs.y,
            },
            zoom
          )
        );
      } else {
        layer.add(
          new MeasureLine(
            { ...product.attrs },
            { x: product.attrs.width / 2, y: product.attrs.height },
            {
              x: product.attrs.width / 2,
              y: -product.attrs.y + wall.attrs.height + wall.attrs.y,
            },
            zoom
          )
        );
      }
      if (!fixedWidth) {
        if (leftSnap) {
          layer.add(
            new MeasureLine(
              { ...product.attrs },
              { x: 0, y: product.attrs.height / 2 },
              {
                x: leftSnap.attrs.width + leftSnap.attrs.x - product.attrs.x,
                y: product.attrs.height / 2,
              },
              zoom
            )
          );
        } else {
          layer.add(
            new MeasureLine(
              { ...product.attrs },
              { x: 0, y: product.attrs.height / 2 },
              {
                x: -product.attrs.x + wall.attrs.x,
                y: product.attrs.height / 2,
              },
              zoom
            )
          );
        }
      }
    });

    this.clearUnnecessaryLines(layer);
    layer.find('.measurement-line').each((line: MeasureLine) => {
      line.createFlag();
    });
    // layer.draw();
  }

  static showInnerMeasurements(
    layer,
    zoom,
    fixedDimensions?,
    filterOn?,
    generalSettings?: GeneralSettings
  ): void {
    // draw line for each side
    layer.find('.product').each((product: Product) => {
      if (product.isFictional) {
        if (product.fictionalID === 50) {
        } else {
          return;
        }
      }
      const hl = product.productSettings.HEATSHIELD_LEFTSIDE
        ? Number(product.productSettings.HEATSHIELD_LEFTSIDE)
        : 0;
      const thcc = product.getSetting(Mapping.HINGES_CC_TOP);
      const thcc2 = product.getSetting(Mapping.HINGES_CC_TOP2);
      const bhcc = product.getSetting(Mapping.HINGES_CC_BOTTOM);
      const bhcc2 = product.getSetting(Mapping.HINGES_CC_BOTTOM2);
      const ehcc = product.getSetting(Mapping.HINGE_CC_EXTRA);

      if (
        product.productSettings.DOUBLE_DOOR === '2' ||
        product.productSettings.DESIGN_OF_PRODUCT === '90DEGREES'
      ) {
        product.add(
          new MeasureLine(
            {
              x: 0,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: product.attrs.height / 2 + 30 },
            { x: product.WIDTH, y: product.attrs.height / 2 + 30 },
            zoom,
            'b: ',
            null,
            product.productSettings.STANDINGLAYING === 'L'
              ? product.productSettings.HEIGHT
              : product.hasFixedWidth
              ? product.productSettings.WIDTH * 2
              : product.productSettings.WIDTH * 2
          )
        );
      } else if (product.HEIGHT > 100) {
        product.add(
          new MeasureLine(
            {
              x: 0,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: product.attrs.height / 2 + 30 },
            { x: product.WIDTH, y: product.attrs.height / 2 + 30 },
            zoom,
            'b: ',
            null,
            product.productSettings.STANDINGLAYING === 'L'
              ? product.productSettings.HEIGHT
              : product.hasFixedWidth
              ? product.productSettings.WIDTH
              : product.productSettings.WIDTH
          )
        );
      } else {
        product.add(
          new MeasureLine(
            {
              x: 0,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: 0 },
            { x: product.WIDTH, y: 0 },
            zoom,
            'b: ',
            null,
            product.productSettings.STANDINGLAYING === 'L'
              ? product.productSettings.HEIGHT
              : product.hasFixedWidth
              ? product.productSettings.WIDTH
              : product.productSettings.WIDTH
          )
        );
      }

      if (product.productSettings.INTERNAL_WIDTH && filterOn) {
        product.add(
          new MeasureLine(
            {
              x: 0,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: product.attrs.height / 2 + 130 },
            { x: product.WIDTH, y: product.attrs.height / 2 + 130 },
            zoom,
            'ib: ',
            null,
            product.hasFixedWidth
              ? product.productSettings.STANDINGLAYING === 'L'
                ? product.productSettings.HEIGHT
                : product.productSettings.INTERNAL_WIDTH
              : product.productSettings.INTERNAL_WIDTH
          )
        );
      }

      product.find('.front').each((front: Front) => {
        if (front.parent && front.parent.attrs.name == 'drawer') {
          product.add(
            new MeasureLine(
              {
                x: 0,
                y: 0,
                width: product.attrs.width,
                height: product.attrs.height,
              },
              { x: 50, y: front.parent.attrs.y },
              { x: 50, y: front.parent.attrs.y + front.parent.attrs.height },
              zoom,
              'fh: '
            )
          );
        } else {
          front.add(
            new MeasureLine(
              {
                x: 0,
                y: 0,
                width: front.attrs.width,
                height: front.attrs.height,
              },
              { x: 50, y: 0 },
              { x: 50, y: front.attrs.height },
              zoom,
              'fh: '
            )
          );
        }
      });

      let l,
        r = 0;
      // if (generalSettings) {
      //     l = generalSettings.getSettingValue(Mapping.CABIN_COVER_LEFT);
      //     r = generalSettings.getSettingValue(Mapping.CABIN_COVER_RIGHT);
      // }

      if (product.productSettings.DOUBLE_DOOR === '2') {
        product.add(
          new MeasureLine(
            {
              x: hl,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: product.attrs.height / 2.2 - 50 },
            { x: product.WIDTH / 2, y: product.attrs.height / 2.2 - 50 },
            zoom,
            'kb: ',
            null,
            product.productSettings.WIDTH
          )
        );
        product.add(
          new MeasureLine(
            {
              x: hl,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: product.WIDTH / 2, y: product.attrs.height / 2.2 - 50 },
            { x: product.WIDTH, y: product.attrs.height / 2.2 - 50 },
            zoom,
            'kb: ',
            null,
            product.productSettings.WIDTH
          )
        );
      }

      // 90deg doors

      if (product.productSettings.DESIGN_OF_PRODUCT === '90DEGREES') {
        product.add(
          new MeasureLine(
            {
              x: hl,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: 0, y: product.attrs.height / 2.2 - 50 },
            { x: product.WIDTH / 2, y: product.attrs.height / 2.2 - 50 },
            zoom,
            'kb: ',
            null,
            product.productSettings.WIDTH
          )
        );

        product.add(
          new MeasureLine(
            {
              x: hl,
              y: 0,
              width: product.attrs.width,
              height: product.attrs.height,
            },
            { x: product.WIDTH / 2, y: product.attrs.height / 2.2 - 50 },
            { x: product.WIDTH, y: product.attrs.height / 2.2 - 50 },
            zoom,
            'kb: ',
            null,
            product.productSettings.WIDTH_2
          )
        );

        const dist =
          product.productSettings.RIGHT_LEFT !== 'RIGHT'
            ? 20
            : product.WIDTH - 30;
        let left = false;
        if (dist !== 20) {
          left = true;
        }

        let noHinges = 0;
        product.find('.hinge').each((hinge) => {
          noHinges += 1;
        });

        if (product.productSettings.DOUBLE_DOOR === '2') {
          noHinges /= 2;
        }

        if (product.productSettings.HINGES_CC_TOP) {
          let changeColor = false;
          if (thcc && thcc.initialValue !== thcc.value) {
            changeColor = true;
          }
          if ((!filterOn || changeColor) && Number(thcc.value)) {
            product.add(
              new MeasureLine(
                { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                { x: dist + 5, y: 0 },
                {
                  x: dist + 5,
                  y: Number(product.productSettings.HINGES_CC_TOP),
                },
                zoom,
                'hcc: ',
                left,
                product.productSettings.HINGES_CC_TOP,
                changeColor,
                'TOP'
              )
            );
          }
        }

        if (product.productSettings.HINGES_CC_TOP2 && noHinges > 2) {
          let changeColor = false;
          if (thcc2 && thcc2.initialValue !== thcc2.value) {
            changeColor = true;
          }
          if ((!filterOn || changeColor) && Number(thcc2.value)) {
            product.add(
              new MeasureLine(
                { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                { x: dist + 5, y: 0 },
                {
                  x: dist + 5,
                  y: Number(product.productSettings.HINGES_CC_TOP2),
                },
                zoom,
                'hcc: ',
                left,
                product.productSettings.HINGES_CC_TOP2,
                changeColor,
                'TOP'
              )
            );
          }
        }
        if (product.productSettings.HINGES_CC_BOTTOM) {
          let changeColor = false;
          if (bhcc && bhcc.initialValue !== bhcc.value) {
            changeColor = true;
          }
          if ((!filterOn || changeColor) && Number(bhcc.value)) {
            product.add(
              new MeasureLine(
                { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                {
                  x: dist + 5,
                  y:
                    product.HEIGHT -
                    Number(product.productSettings.HINGES_CC_BOTTOM),
                },
                { x: dist + 5, y: product.HEIGHT },
                zoom,
                'hcc: ',
                left,
                product.productSettings.HINGES_CC_BOTTOM,
                changeColor,
                'BOTTOM'
              )
            );
          }
        }

        if (product.productSettings.HINGES_CC_BOTTOM2 && noHinges > 3) {
          let changeColor = false;
          if (bhcc2 && bhcc2.initialValue !== bhcc2.value) {
            changeColor = true;
          }
          if ((!filterOn || changeColor) && Number(bhcc2.value)) {
            product.add(
              new MeasureLine(
                { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                {
                  x: dist + 5,
                  y:
                    product.HEIGHT -
                    Number(product.productSettings.HINGES_CC_BOTTOM2),
                },
                { x: dist + 5, y: product.HEIGHT },
                zoom,
                'hcc: ',
                left,
                product.productSettings.HINGES_CC_BOTTOM2,
                changeColor,
                'BOTTOM'
              )
            );
          }
        }

        if (product.productSettings.HINGE_CC_EXTRA) {
          let changeColor = false;
          if (ehcc && ehcc.initialValue !== ehcc.value) {
            changeColor = true;
          }
          if ((!filterOn || changeColor) && Number(ehcc?.value)) {
            product.add(
              new MeasureLine(
                { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                { x: dist + 5, y: 0 },
                {
                  x: dist + 5,
                  y: Number(product.productSettings.HINGE_CC_EXTRA),
                },
                zoom,
                'hcc: ',
                left,
                product.productSettings.HINGE_CC_EXTRA,
                changeColor,
                'TOP'
              )
            );
          }
        }
      } else {
        if (product.productSettings.RIGHT_LEFT === 'VERTICAL') {
          if (product.productSettings.HINGES_CC_TOP) {
            let changeColor = false;
            if (thcc && thcc.initialValue !== thcc.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: 0, y: 40 },
                  { x: Number(product.productSettings.HINGES_CC_TOP), y: 40 },
                  zoom,
                  'hcc: ',
                  false,
                  product.productSettings.HINGES_CC_TOP,
                  changeColor,
                  'TOP'
                )
              );
            }
          }
          if (product.productSettings.HINGES_CC_BOTTOM) {
            let changeColor = false;
            if (bhcc && bhcc.initialValue !== bhcc.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: product.WIDTH, y: 40 },
                  {
                    x:
                      product.WIDTH -
                      Number(product.productSettings.HINGES_CC_BOTTOM),
                    y: 40,
                  },
                  zoom,
                  'hcc: ',
                  true,
                  product.productSettings.HINGES_CC_BOTTOM,
                  changeColor,
                  'BOTTOM'
                )
              );
            }
          }
        } else {
          const dist =
            product.productSettings.RIGHT_LEFT !== 'RIGHT'
              ? 20
              : product.WIDTH - 30;
          let left = false;
          if (dist !== 20) {
            left = true;
          }

          let noHinges = 0;
          product.find('.hinge').each((hinge) => {
            noHinges += 1;
          });

          if (product.productSettings.DOUBLE_DOOR === '2') {
            noHinges /= 2;
          }

          if (product.productSettings.HINGES_CC_TOP) {
            let changeColor = false;
            if (thcc && thcc.initialValue !== thcc.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: dist + 5, y: 0 },
                  {
                    x: dist + 5,
                    y: Number(product.productSettings.HINGES_CC_TOP),
                  },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGES_CC_TOP,
                  changeColor,
                  'TOP'
                )
              );
              if (product.productSettings.DOUBLE_DOOR === '2' && changeColor) {
                product.add(
                  new MeasureLine(
                    {
                      x: 0,
                      y: 0,
                      width: product.WIDTH,
                      height: product.HEIGHT,
                    },
                    { x: product.WIDTH - 20, y: 0 },
                    {
                      x: product.WIDTH - 20,
                      y: Number(product.productSettings.HINGES_CC_TOP),
                    },
                    zoom,
                    'hcc: ',
                    left,
                    product.productSettings.HINGES_CC_TOP,
                    changeColor,
                    'TOP'
                  )
                );
              }
            }
          }

          if (product.productSettings.HINGES_CC_TOP2 && noHinges > 2) {
            let changeColor = false;
            if (thcc2 && thcc2.initialValue !== thcc2.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: dist + 5, y: 0 },
                  {
                    x: dist + 5,
                    y: Number(product.productSettings.HINGES_CC_TOP2),
                  },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGES_CC_TOP2,
                  changeColor,
                  'TOP'
                )
              );
              if (product.productSettings.DOUBLE_DOOR === '2' && changeColor) {
                product.add(
                  new MeasureLine(
                    {
                      x: 0,
                      y: 0,
                      width: product.WIDTH,
                      height: product.HEIGHT,
                    },
                    { x: product.WIDTH - 20, y: 0 },
                    {
                      x: product.WIDTH - 20,
                      y: Number(product.productSettings.HINGES_CC_TOP2),
                    },
                    zoom,
                    'hcc: ',
                    left,
                    product.productSettings.HINGES_CC_TOP2,
                    changeColor,
                    'TOP'
                  )
                );
              }
            }
          }
          if (product.productSettings.HINGES_CC_BOTTOM) {
            let changeColor = false;
            if (bhcc && bhcc.initialValue !== bhcc.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  {
                    x: dist + 5,
                    y:
                      product.HEIGHT -
                      Number(product.productSettings.HINGES_CC_BOTTOM),
                  },
                  { x: dist + 5, y: product.HEIGHT },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGES_CC_BOTTOM,
                  changeColor,
                  'BOTTOM'
                )
              );
              if (product.productSettings.DOUBLE_DOOR === '2' && changeColor) {
                product.add(
                  new MeasureLine(
                    {
                      x: 0,
                      y: 0,
                      width: product.WIDTH,
                      height: product.HEIGHT,
                    },
                    {
                      x: product.WIDTH - 20,
                      y:
                        product.HEIGHT -
                        Number(product.productSettings.HINGES_CC_BOTTOM),
                    },
                    { x: product.WIDTH - 20, y: product.HEIGHT },
                    zoom,
                    'hcc: ',
                    left,
                    product.productSettings.HINGES_CC_BOTTOM,
                    changeColor,
                    'BOTTOM'
                  )
                );
              }
            }
          }

          if (product.productSettings.HINGES_CC_BOTTOM2 && noHinges > 3) {
            let changeColor = false;
            if (bhcc2 && bhcc2.initialValue !== bhcc2.value) {
              changeColor = true;
            }
            if (!filterOn || changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  {
                    x: dist + 5,
                    y:
                      product.HEIGHT -
                      Number(product.productSettings.HINGES_CC_BOTTOM2),
                  },
                  { x: dist + 5, y: product.HEIGHT },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGES_CC_BOTTOM2,
                  changeColor,
                  'BOTTOM'
                )
              );
              if (product.productSettings.DOUBLE_DOOR === '2' && changeColor) {
                product.add(
                  new MeasureLine(
                    {
                      x: 0,
                      y: 0,
                      width: product.WIDTH,
                      height: product.HEIGHT,
                    },
                    {
                      x: product.WIDTH - 20,
                      y:
                        product.HEIGHT -
                        Number(product.productSettings.HINGES_CC_BOTTOM2),
                    },
                    { x: product.WIDTH - 20, y: product.HEIGHT },
                    zoom,
                    'hcc: ',
                    left,
                    product.productSettings.HINGES_CC_BOTTOM2,
                    changeColor,
                    'BOTTOM'
                  )
                );
              }
            }
          }

          if (product.productSettings.HINGE_CC_EXTRA) {
            let changeColor = false;
            if (ehcc && ehcc.initialValue !== ehcc.value) {
              changeColor = true;
            }
            if ((!filterOn || changeColor) && Number(ehcc?.value)) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: dist + 5, y: 0 },
                  {
                    x: dist + 5,
                    y: Number(product.productSettings.HINGE_CC_EXTRA),
                  },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGE_CC_EXTRA,
                  changeColor,
                  'TOP'
                )
              );
            }
            if (product.productSettings.DOUBLE_DOOR === '2' && changeColor) {
              product.add(
                new MeasureLine(
                  { x: 0, y: 0, width: product.WIDTH, height: product.HEIGHT },
                  { x: product.WIDTH - 20, y: 0 },
                  {
                    x: product.WIDTH - 20,
                    y: Number(product.productSettings.HINGE_CC_EXTRA),
                  },
                  zoom,
                  'hcc: ',
                  left,
                  product.productSettings.HINGE_CC_EXTRA,
                  changeColor,
                  'TOP'
                )
              );
            }
          }
        }
      }

      if (
        product.productSettings.CABIN_COVER_LEFT !== undefined &&
        (!filterOn ||
          l !=
            product.productSettings.CABIN_COVER_LEFT -
              Number(product.productSettings.HEATSHIELD_LEFTSIDE))
      ) {
        let lhs = 0;
        if (product.productSettings.HEATSHIELD_LEFTSIDE) {
          lhs = Number(product.productSettings.HEATSHIELD_LEFTSIDE);
        }
        product.add(
          new MeasureLine(
            { x: 0, y: 0, width: product.attrs.width, height: product.HEIGHT },
            { x: 0, y: product.HEIGHT - 20 },
            {
              x: Number(product.productSettings.CABIN_COVER_LEFT) - lhs,
              y: product.HEIGHT - 20,
            },
            zoom,
            'bv: '
          )
        );
      }
      if (
        product.productSettings.CABIN_COVER_RIGHT !== undefined &&
        product.productSettings.CABIN_COVER_RIGHT -
          Number(product.productSettings.HEATSHIELD_RIGHTSIDE) !==
          product.productSettings.CABIN_COVER_LEFT -
            Number(product.productSettings.HEATSHIELD_LEFTSIDE) &&
        (!filterOn || r != product.productSettings.CABIN_COVER_RIGHT)
      ) {
        let rhs = 0;
        if (product.productSettings.HEATSHIELD_RIGHTSIDE) {
          rhs = Number(product.productSettings.HEATSHIELD_RIGHTSIDE);
        }
        product.add(
          new MeasureLine(
            { x: 0, y: 0, width: product.attrs.width, height: product.HEIGHT },
            { x: product.attrs.width, y: product.HEIGHT - 20 },
            {
              x:
                product.attrs.width -
                Number(product.productSettings.CABIN_COVER_RIGHT) +
                rhs,
              y: product.HEIGHT - 20,
            },
            zoom,
            'bh: '
          )
        );
      }
      if (product.perspective) {
        if (product.productSettings.RIGHT_LEFT === 'RIGHT') {
          const a = ((product.attrs.width - 100) * 100) / product.attrs.width;
          product.add(
            new MeasureLine(
              {
                x: 0,
                y: 0,
                width: product.attrs.width,
                height: product.HEIGHT,
              },
              { x: product.attrs.width - 100, y: -a },
              { x: product.attrs.width - 100, y: product.HEIGHT - a },
              zoom,
              'h: ',
              product.hasFixedWidth
                ? product.productSettings.STANDINGLAYING === 'L'
                  ? product.productSettings.WIDTH
                  : product.productSettings.HEIGHT
                : product.productSettings.HEIGHT
            )
          );
        } else {
          const a =
            ((product.attrs.width - 100) * 100) / product.attrs.width - 100;
          product.add(
            new MeasureLine(
              {
                x: 0,
                y: 0,
                width: product.attrs.width,
                height: product.HEIGHT,
              },
              { x: product.attrs.width - 100, y: +a },
              { x: product.attrs.width - 100, y: product.HEIGHT + a },
              zoom,
              'h: '
            )
          );
        }
      } else {
        product.add(
          new MeasureLine(
            { x: 0, y: 0, width: product.attrs.width, height: product.HEIGHT },
            { x: product.attrs.width - 100, y: 0 },
            { x: product.attrs.width - 100, y: product.HEIGHT },
            zoom,
            'h: ',
            null,
            product.hasFixedHeight ? product.productSettings.HEIGHT : null
          )
        );
      }
      // special measurements
      if (
        product.productSettings.DESIGN_OF_PRODUCT === 'CLOSINGSHELF' &&
        product.productSettings.DEPTH
      ) {
        product.add(
          new MeasureLine(
            { x: 0, y: 0, width: product.WIDTH, height: product.WIDTH },
            { x: 20, y: product.HEIGHT },
            { x: 20, y: product.HEIGHT - 10 },
            zoom,
            'de: ',
            null,
            product.productSettings.DEPTH
          )
        );
      }
      MeasurementCollisionDetectionUtil.fitMeasurements(product);
      this.drawLines(product);
    });

    // layer.draw();
  }
  static destroyLines(layer) {
    layer.find('.measurement-line').each((line: MeasureLine) => {
      line.destroy();
    });
    // layer.draw();
  }
  static drawLines(product) {
    product.find('.measurement-line').each((line: MeasureLine) => {
      line.createFlag();
    });
  }

  static clearUnnecessaryLines(parentObject) {
    parentObject.find('.measurement-line').each((line1: MeasureLine) => {
      parentObject.find('.measurement-line').each((line2: MeasureLine) => {
        const L1 = line1.getLength();
        const L2 = line2.getLength();
        if (
          line1 !== line2 &&
          L1.x === L2.x &&
          L1.y === L2.y &&
          (line1.attrs.x !== line2.attrs.x || line1.attrs.y !== line2.attrs.y)
        ) {
          line1.destroy();
        }
      });
    });
  }
}
