<div
  class="modal fade"
  id="wallModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "NEW_WALL" | translate }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #form="ngForm">
        <div class="modal-body d-flex flex-column">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">
              {{ "NAME" | translate }}:
            </label>
            <input
              id="wallName"
              class="col-sm-8 form-control"
              [(ngModel)]="wall.wallName"
              name="wallName"
              #wallName="ngModel"
              type="text"
              placeholder="{{ 'WALL' | translate }} {{ 'NAME' | translate }}"
              required
              [ngClass]="
                !wallName.valid && wallName.dirty
                  ? 'border-danger'
                  : 'border-secondary'
              "
            />
            <div
              *ngIf="!wallName.valid && wallName.dirty"
              class="text-danger custom-left-padding"
            >
              {{ "SET" | translate }} {{ "NAME" | translate }}
            </div>
          </div>
          <div class="form-group row" role="group">
            <label class="col-sm-3 col-form-label"
              >{{ "SIDE" | translate }}:</label
            >
            <button
              class="btn col-sm-3"
              (click)="changeSideSelection('L')"
              [ngClass]="selectedSide === 'L' ? 'btn-primary' : 'btn-secondary'"
            >
              {{ "LEFT" | translate }}
            </button>
            <div class="col-sm-2"></div>
            <button
              class="btn col-sm-3"
              (click)="changeSideSelection('R')"
              [ngClass]="selectedSide === 'R' ? 'btn-primary' : 'btn-secondary'"
            >
              {{ "RIGHT" | translate }}
            </button>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label"
              >{{ "WIDTH" | translate }} (mm):</label
            >
            <input
              id="width"
              class="col-sm-8 form-control"
              type="number"
              [(ngModel)]="wall.attrs.width"
              min="3000"
              max="10000"
              name="width"
              [ngClass]="
                !width.valid && width.dirty
                  ? 'border-danger'
                  : 'border-secondary'
              "
              #width="ngModel"
              placeholder="{{ 'WIDTH' | translate }}  (mm)"
              required
            />
            <div
              *ngIf="!width.valid && width.dirty"
              class="text-danger custom-left-padding"
            >
              {{ "SET" | translate }} {{ "WIDTH" | translate }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"
              >{{ "HEIGHT" | translate }} (mm):</label
            >
            <input
              id="height"
              class="col-sm-8 form-control"
              type="number"
              [(ngModel)]="wall.attrs.height"
              name="height"
              #height="ngModel"
              placeholder="{{ 'HEIGHT' | translate }} (mm)"
              [ngClass]="
                !height.valid && height.dirty
                  ? 'border-danger'
                  : 'border-secondary'
              "
              required
            />
            <div
              *ngIf="!height.valid && height.dirty"
              class="text-danger custom-left-padding"
            >
              {{ "SET" | translate }} {{ "HEIGHT" | translate }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"
              >{{ "SOCKEL" | translate }}
              {{ "HEIGHT" | translate }} (mm):</label
            >
            <input
              id="sockelHeight"
              class="col-sm-8 form-control"
              type="number"
              [(ngModel)]="wall.sockelHeight"
              name="sockelHeight"
              #sockelHeight="ngModel"
              placeholder="{{ 'SOCKEL' | translate }} {{
                'HEIGHT' | translate
              }} (mm)"
              [ngClass]="
                !sockelHeight.valid && sockelHeight.dirty
                  ? 'border-danger'
                  : 'border-secondary'
              "
              required
            />
            <div
              *ngIf="!sockelHeight.valid && sockelHeight.dirty"
              class="text-danger custom-left-padding"
            >
              {{ "SET" | translate }} {{ "SOCKEL" | translate }}
              {{ "HEIGHT" | translate }}
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="
            !wallName.valid || !height.valid || !width.valid || !selectedSide
          "
          (click)="createWall()"
        >
          {{ "CREATE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
