import { Component, OnDestroy } from '@angular/core';
import { Actions } from 'src/app/consts/actions';
import { LogConsts } from 'src/app/consts/logConsts';
import { Product } from 'src/app/model/product';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-minor-bin',
  templateUrl: './minor-bin.component.html',
  styleUrls: ['./minor-bin.component.scss'],
})
export class MinorBinComponent implements OnDestroy {
  products: Product[] = [];
  activeProduct: Product;

  binEmitterSub;
  productsEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService,
    private logService: LogService
  ) {
    this.binEmitterSub = this.navbarService.binEmitter.subscribe((products) => {
      this.products = products;
    });

    this.productsEmitterSub = this.productService.productsEmitter.subscribe(
      (products: Product[]) => {
        this.activeProduct = products.filter((product) => product.isActive)[0];
      }
    );
  }

  ngOnDestroy(): void {
    this.binEmitterSub.unsubscribe();
    this.productsEmitterSub.unsubscribe();
  }

  addProduct(product: Product): void {
    this.activeProduct.subProducts.push(product);

    const x = this.products.indexOf(product);
    this.products.splice(x, 1);
    this.navbarService.stageActionEmitter.emit(Actions.REDRAW);
    this.navbarService.productActionEmitter.emit(Actions.UPDATE_MINOR_PRODUCT);
    // tslint:disable-next-line: no-empty
    this.logService
      .postLog(LogConsts.INFO, 'Adding minor product ' + product.type)
      .subscribe(() => {});
  }
}
