import { Vector2d } from 'konva/types/types';
import { Door } from '../model/subproducts/door';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Option } from '../model/subsettings/option';

export class DoorUtil {
  static doorSizeCalc(product: Product) {
    // if vertical is true -> change
    const noDoors = product.doors.length;
    if (
      noDoors === 0 ||
      (product.productSettings &&
        product.productSettings.DESIGN_OF_PRODUCT === '90DEGREES')
    ) {
      return null;
    }
    if (product.vertical) {
      product.doors.forEach((door: Door, i) => {
        if (product.fixedWidth) {
          const hl = product.productSettings.HEATSHIELD_LEFTSIDE
            ? Number(product.productSettings.HEATSHIELD_LEFTSIDE)
            : 0;
          const hr = product.productSettings.HEATSHIELD_RIGHTSIDE
            ? Number(product.productSettings.HEATSHIELD_RIGHTSIDE)
            : 0;
          if (i % 2) {
            door.attrs.width = product.WIDTH / noDoors - hr;
            // door.attrs.height = product.HEIGHT;
            door.attrs.x = product.WIDTH / noDoors;
          } else {
            door.attrs.width = product.WIDTH / noDoors - hl;
            // door.attrs.height = product.HEIGHT;
            door.attrs.x = hl;
          }
        } else {
          door.attrs.width = product.WIDTH / noDoors;
          // door.attrs.height = product.HEIGHT;
          const hs = product.productSettings.HEATSHIELD_LEFTSIDE
            ? Number(product.productSettings.HEATSHIELD_LEFTSIDE)
            : 0;
          door.attrs.x = (product.WIDTH / noDoors) * (i % 2) + hs;
        }
      });
    } else {
      product.doors.forEach((door, i) => {
        door.attrs.height = product.HEIGHT / noDoors;
        door.attrs.width = product.WIDTH;
        door.attrs.y = (product.HEIGHT / noDoors) * i;
      });
    }
  }

  static handlePlacementCalc(product: Product): Vector2d[] {
    if (!product.doors) {
      return null;
    }
    const noDoors = product.doors.length;
    const handles: Vector2d[] = [];
    if (noDoors === 0) {
      return null;
    }
    if (product.productSettings.DESIGN_OF_PRODUCT !== '90DEGREES') {
      const multiStatement = product.doors.length > 1;
      let statement = product.productSettings.RIGHT_LEFT;
      if (multiStatement) {
        statement = '';
      }

      product.doors.forEach((door, i) => {
        let handlePlacement = product.handlePlacement;

        let rx =
          20 +
          (product.productSettings.CABIN_COVER_LEFT !== undefined
            ? Number(product.productSettings.CABIN_COVER_LEFT)
            : 20);
        let lx =
          product.WIDTH -
          40 -
          (product.productSettings.CABIN_COVER_RIGHT !== undefined
            ? Number(product.productSettings.CABIN_COVER_RIGHT)
            : 20);

        if (
          product.productSettings.HANDLE_LAT_HORZ === 'L' &&
          product.productSettings.HANDLE_PLACEMENT === 'SIDE'
        ) {
          rx =
            20 +
            (product.productSettings.CABIN_COVER_LEFT !== undefined
              ? Number(product.productSettings.CABIN_COVER_LEFT)
              : 20);
          lx =
            door.attrs.width -
            80 -
            (product.productSettings.CABIN_COVER_RIGHT !== undefined
              ? Number(product.productSettings.CABIN_COVER_RIGHT)
              : 20);
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'S' &&
          product.productSettings.HANDLE_PLACEMENT === 'SIDE'
        ) {
          rx =
            20 +
            (product.productSettings.CABIN_COVER_LEFT !== undefined
              ? Number(product.productSettings.CABIN_COVER_LEFT)
              : 20);
          lx =
            door.attrs.width -
            40 -
            (product.productSettings.CABIN_COVER_RIGHT !== undefined
              ? Number(product.productSettings.CABIN_COVER_RIGHT)
              : 20);
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'L' &&
          product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
        ) {
          rx = door.attrs.width / 2 - 30;
          lx = door.attrs.width / 2 - 30;
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'S' &&
          product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
        ) {
          rx = door.attrs.width / 2 - 10;
          lx = door.attrs.width / 2 - 10;
        }

        if (door.overrideHandles) {
          if (product.isBottom) {
            handlePlacement = 'BOTTOM';
          } else {
            handlePlacement = 'TOP';
          }
        }

        let by = door.attrs.height - 120;
        let my = door.attrs.height / 2 - 20;
        let ty = 60;

        if (product.productSettings.HANDLES_TYPE === 'KNOP') {
          lx -= 10;
          if (handlePlacement === 'TOP') {
            by += 10;
            my += 10;
            ty += 10;
          } else {
            by += 30;
            my += 30;
            ty += 30;
          }
        }

        if (product.productSettings.RIGHT_LEFT === 'VERTICAL') {
          switch (handlePlacement) {
            case 'BOTTOM':
              handles.push({
                x: rx,
                y: by,
              });
              break;
            case 'MIDDLE':
              handles.push({
                x: rx,
                y: my,
              });
              break;
            case 'TOP':
              handles.push({
                x: rx,
                y: ty,
              });
              break;
          }
          return;
        }

        if (statement === 'LEFT' || (multiStatement && i === 0)) {
          // left door
          switch (handlePlacement) {
            case 'BOTTOM':
              handles.push({
                x: lx,
                y: by,
              });
              break;
            case 'MIDDLE':
              handles.push({
                x: lx,
                y: my,
              });
              break;
            case 'TOP':
              handles.push({
                x: lx,
                y: ty,
              });
              break;
          }
        } else {
          // right door
          switch (handlePlacement) {
            case 'BOTTOM':
              handles.push({
                x: rx,
                y: by,
              });
              break;
            case 'MIDDLE':
              handles.push({
                x: rx,
                y: my,
              });
              break;
            case 'TOP':
              handles.push({
                x: rx,
                y: ty,
              });
              break;
          }
        }
      });
    } else {
      const multiStatement = product.doors.length > 1;
      let statement = product.productSettings.RIGHT_LEFT;

      if (multiStatement) {
        statement = '';
      }
      product.doors.forEach((door, i) => {
        let rx =
          20 +
          (product.productSettings.CABIN_COVER_LEFT !== undefined
            ? Number(product.productSettings.CABIN_COVER_LEFT)
            : 20);
        let lx =
          product.WIDTH -
          40 -
          (product.productSettings.CABIN_COVER_RIGHT !== undefined
            ? Number(product.productSettings.CABIN_COVER_RIGHT)
            : 20);

        if (
          product.productSettings.HANDLE_LAT_HORZ === 'L' &&
          product.productSettings.HANDLE_PLACEMENT === 'SIDE'
        ) {
          rx =
            20 +
            (product.productSettings.CABIN_COVER_LEFT !== undefined
              ? Number(product.productSettings.CABIN_COVER_LEFT)
              : 20);
          lx =
            product.WIDTH -
            80 -
            (product.productSettings.CABIN_COVER_RIGHT !== undefined
              ? Number(product.productSettings.CABIN_COVER_RIGHT)
              : 20);
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'S' &&
          product.productSettings.HANDLE_PLACEMENT === 'SIDE'
        ) {
          rx =
            20 +
            (product.productSettings.CABIN_COVER_LEFT !== undefined
              ? Number(product.productSettings.CABIN_COVER_LEFT)
              : 20);
          lx =
            product.WIDTH -
            40 -
            (product.productSettings.CABIN_COVER_RIGHT !== undefined
              ? Number(product.productSettings.CABIN_COVER_RIGHT)
              : 20);
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'L' &&
          product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
        ) {
          rx = door.attrs.width / 2 - 30;
          lx = product.productSettings.WIDTH + door.attrs.width / 2 - 30;
        } else if (
          product.productSettings.HANDLE_LAT_HORZ === 'S' &&
          product.productSettings.HANDLE_PLACEMENT === 'MIDDLE'
        ) {
          rx = door.attrs.width / 2 - 10;
          lx = product.productSettings.WIDTH + door.attrs.width / 2 - 10;
        }

        let handlePlacement = product.handlePlacement;
        if (door.overrideHandles) {
          if (product.isBottom) {
            handlePlacement = 'BOTTOM';
          } else {
            handlePlacement = 'TOP';
          }
        }

        const by = door.attrs.height - 120;
        const my = door.attrs.height / 2 - 20;
        const ty = 60;

        if (product.productSettings.HANDLES_TYPE === 'KNOP') {
          lx -= 10;
        }

        if (statement === 'LEFT' || (multiStatement && i === 0)) {
          if (product.productSettings.RIGHT_LEFT === 'LEFT') {
            return;
          }
          // left door
          switch (handlePlacement) {
            case 'BOTTOM':
              handles.push({
                x: rx,
                y: by,
              });
              break;
            case 'MIDDLE':
              handles.push({
                x: rx,
                y: my,
              });
              break;
            case 'TOP':
              handles.push({
                x: rx,
                y: ty,
              });
              break;
          }
        } else {
          if (product.productSettings.RIGHT_LEFT === 'RIGHT') {
            return;
          }
          switch (handlePlacement) {
            case 'BOTTOM':
              handles.push({
                x: lx,
                y: by,
              });
              break;
            case 'MIDDLE':
              handles.push({
                x: lx,
                y: my,
              });
              break;
            case 'TOP':
              handles.push({
                x: lx,
                y: ty,
              });
              break;
          }
          // right door
        }
      });
    }
    return handles;
  }

  static handleDimensionsCalc(): Vector2d {
    return null;
  }

  static hingesPlacementCalc(product: Product): Vector2d[][] {
    if (
      !product.doors ||
      (product.productSettings.HINGES_CC_TOP == 0 &&
        product.productSettings.HINGES_CC_TOP2 == 0 &&
        product.productSettings.HINGES_CC_BOTTOM == 0 &&
        product.productSettings.HINGES_CC_BOTTOM2 == 0)
    ) {
      return null;
    }
    const lhs = Number(product.productSettings.HEATSHIELD_LEFTSIDE)
    ? Number(product.productSettings.HEATSHIELD_LEFTSIDE)
    : 0;
  const rhs = Number(product.productSettings.HEATSHIELD_RIGHTSIDE)
    ? Number(product.productSettings.HEATSHIELD_RIGHTSIDE)
    : 0;
    const noDoors = product.doors.length;
    const hinges: Vector2d[][] = [[]];
    const hl =
      (product.productSettings.CABIN_COVER_LEFT !== undefined
        ? Number(product.productSettings.CABIN_COVER_LEFT) !== 0
          ? Number(product.productSettings.CABIN_COVER_LEFT)
          : 0
        : 20);
    const hr =
      (product.productSettings.CABIN_COVER_RIGHT !== undefined
        ? Number(product.productSettings.CABIN_COVER_RIGHT) !== 0
          ? Number(product.productSettings.CABIN_COVER_RIGHT)
          : 0
        : 20);
    // when placing hinges it should also check if they will colide with the shelves in the cabin
    if (product.productSettings.DESIGN_OF_PRODUCT !== '90DEGREES') {
      const multiStatement = product.doors.length > 1;
      let statement = product.productSettings.RIGHT_LEFT;

      if (multiStatement) {
        statement = '';
      }
      product.doors.forEach((door, i) => {
        const noHinges = this.getNumberOfHinges(product, door); // this line should be changed with number of hinges setting
        const hingeTop = product.productSettings.HINGES_CC_TOP
          ? product.productSettings.HINGES_CC_TOP
          : 100;
        const hingeBottom = product.productSettings.HINGES_CC_BOTTOM
          ? product.productSettings.HINGES_CC_BOTTOM
          : 100;
        const hingeTop2 = product.productSettings.HINGES_CC_TOP2
          ? product.productSettings.HINGES_CC_TOP2
          : 200;
        const hingeBottom2 = product.productSettings.HINGES_CC_BOTTOM2
          ? product.productSettings.HINGES_CC_BOTTOM2
          : 200;

        const a = door.attrs.height / 10;
        const h = door.attrs.height;
        const w = door.attrs.width;

        // if vertical door
        if (product.productSettings.RIGHT_LEFT === 'VERTICAL') {
          if (i === 0 && product.doors.length === 1) {
            for (let j = 0; j < noHinges; j++) {
              if (j === 0) {
                hinges[i].push({
                  x: hingeTop,
                  y: 20,
                });
              } else if (j === noHinges - 1) {
                hinges[i].push({
                  x: product.attrs.width - hingeBottom - 20,
                  y: 20,
                });
              } else {
                hinges[i].push({
                  x: a + ((w - 2 * a) / (noHinges - 1)) * j - 10,
                  y: 20,
                });
              }
            }
          } else if (i === 0) {
            for (let j = 0; j < noHinges; j++) {
              if (j === 0) {
                hinges[i].push({
                  x: hingeTop,
                  y: 20,
                });
              } else if (j === noHinges - 1) {
                hinges[i].push({
                  x: product.attrs.width / 2 - hingeBottom - 20,
                  y: 20,
                });
              } else {
                hinges[i].push({
                  x: a + ((w - 2 * a) / (noHinges - 1)) * j - 10,
                  y: 20,
                });
              }
            }
          } else {
            for (let j = 0; j < noHinges; j++) {
              if (j === 0) {
                hinges[i].push({
                  x: hingeTop,
                  y: 20,
                });
              } else if (j === noHinges - 1) {
                hinges[i].push({
                  x: product.attrs.width / 2 - hingeBottom - 20,
                  y: 20,
                });
              } else {
                hinges[i].push({
                  x: a + ((w - 2 * a) / (noHinges - 1)) * j - 10,
                  y: 20,
                });
              }
            }
          }
        } else if (statement === 'LEFT' || (multiStatement && i === 0)) {
          for (let j = 0; j < noHinges; j++) {
            if (j === 0) {
              hinges[i].push({
                x: hl,
                y: hingeTop,
              });
            } else if (j === noHinges - 1) {
              hinges[i].push({
                x: hl,
                y: door.attrs.height - hingeBottom - 20,
              });
            } else if (product.productSettings.HINGES_CC_TOP2 && j === 1) {
              hinges[i].push({
                x: hl,
                y: hingeTop2,
              });
            } else if (
              product.productSettings.HINGES_CC_BOTTOM2 &&
              j === noHinges - 2
            ) {
              hinges[i].push({
                x: hl,
                y: door.attrs.height - hingeBottom2 - 20,
              });
            } else {
              hinges[i].push({
                x: hl,
                y: a + ((h - 2 * a) / (noHinges - 1)) * j - 10,
              });
            }
          }
          if (product.productSettings.EXTRA_HINGE === 'YES') {
            const hingeExtra = product.productSettings.HINGE_CC_EXTRA
              ? Number(product.productSettings.HINGE_CC_EXTRA)
              : 100;

            hinges[i].push({
              x: hl,
              y: hingeExtra,
            });
          }
        } else {
          for (let j = 0; j < noHinges; j++) {
            if (j === 0) {
              hinges[i].push({
                x: door.attrs.width - hr - door.hingeWidth,
                y: hingeTop,
              });
            } else if (j === noHinges - 1) {
              hinges[i].push({
                x: door.attrs.width - hr - door.hingeWidth,
                y: door.attrs.height - hingeBottom - 20,
              });
            } else if (product.productSettings.HINGES_CC_TOP2 && j === 1) {
              hinges[i].push({
                x: door.attrs.width - hr - door.hingeWidth,
                y: hingeTop2,
              });
            } else if (
              product.productSettings.HINGES_CC_BOTTOM2 &&
              j === noHinges - 2
            ) {
              hinges[i].push({
                x: door.attrs.width - hr - door.hingeWidth,
                y: door.attrs.height - hingeBottom2 - 20,
              });
            } else {
              hinges[i].push({
                x: door.attrs.width - hr - door.hingeWidth,
                y: a + ((h - 2 * a) / (noHinges - 1)) * j - 10,
              });
            }
          }
          if (product.productSettings.EXTRA_HINGE === 'YES') {
            const hingeExtra = product.productSettings.HINGE_CC_EXTRA
              ? Number(product.productSettings.HINGE_CC_EXTRA)
              : 100;

            hinges[i].push({
              x: door.attrs.width - hr - door.hingeWidth,
              y: hingeExtra,
            });
          }
        }

        hinges.push([]);
      });
    } else {
      const multiStatement = product.doors.length > 1;
      let statement = product.productSettings.RIGHT_LEFT;

      if (multiStatement) {
        statement = '';
      }
      product.doors.forEach((door, i) => {
        const noHinges = this.getNumberOfHinges(product, door); // this line should be changed with number of hinges setting
        const hingeTop = product.productSettings.HINGES_CC_TOP
          ? product.productSettings.HINGES_CC_TOP
          : 100;
        const hingeBottom = product.productSettings.HINGES_CC_BOTTOM
          ? product.productSettings.HINGES_CC_BOTTOM
          : 100;
        const a = door.attrs.height / 10;
        const h = door.attrs.height;
        const w = door.attrs.width;

        // if vertical door
        if (statement === 'LEFT' || (multiStatement && i === 0)) {
          if (product.productSettings.RIGHT_LEFT === 'LEFT' || !hinges) {
            return;
          }
          if (!hinges[i]) {
            hinges[i] = [];
          }
          for (let j = 0; j < noHinges; j++) {
            if (j === 0) {
              hinges[i].push({
                x: product.WIDTH - hr - door.hingeWidth + rhs,
                y: hingeTop,
              });
            } else if (j === noHinges - 1) {
              hinges[i].push({
                x: product.WIDTH - hr - door.hingeWidth + rhs,
                y: door.attrs.height - hingeBottom - 20,
              });
            } else {
              hinges[i].push({
                x: product.WIDTH - hr - door.hingeWidth + rhs,
                y: a + ((h - 2 * a) / (noHinges - 1)) * j - 10,
              });
            }
          }
          if (product.productSettings.EXTRA_HINGE === 'YES') {
            const hingeExtra = product.productSettings.HINGE_CC_EXTRA
              ? Number(product.productSettings.HINGE_CC_EXTRA)
              : 100;

            hinges[i].push({
              x: product.WIDTH - hr - door.hingeWidth,
              y: hingeExtra,
            });
          }
        } else {
          if (product.productSettings.RIGHT_LEFT === 'RIGHT' || !hinges) {
            return;
          }
          if (!hinges[i]) {
            hinges[i] = [];
          }
          if (product.fixedWidth) {
            for (let j = 0; j < noHinges; j++) {
              if (j === 0) {
                hinges[i].push({
                  x: hl - Number(product.WIDTH / 2),
                  y: hingeTop,
                });
              } else if (j === noHinges - 1) {
                hinges[i].push({
                  x: hl - Number(product.WIDTH / 2),
                  y: door.attrs.height - hingeBottom - 20,
                });
              } else {
                hinges[i].push({
                  x: hl - Number(product.WIDTH / 2),
                  y: a + ((h - 2 * a) / (noHinges - 1)) * j - 10,
                });
              }
            }
            if (product.productSettings.EXTRA_HINGE === 'YES') {
              const hingeExtra = product.productSettings.HINGE_CC_EXTRA
                ? Number(product.productSettings.HINGE_CC_EXTRA)
                : 100;

              hinges[i].push({
                x: hl - Number(product.WIDTH / 2),
                y: hingeExtra,
              });
            }
          } else {
            for (let j = 0; j < noHinges; j++) {
              if (j === 0) {
                hinges[i].push({
                  x: hl - Number(product.productSettings.WIDTH),
                  y: hingeTop,
                });
              } else if (j === noHinges - 1) {
                hinges[i].push({
                  x: hl - Number(product.productSettings.WIDTH),
                  y: door.attrs.height - hingeBottom - 20,
                });
              } else {
                hinges[i].push({
                  x: hl - Number(product.productSettings.WIDTH),
                  y: a + ((h - 2 * a) / (noHinges - 1)) * j - 10,
                });
              }
            }
            if (product.productSettings.EXTRA_HINGE === 'YES') {
              const hingeExtra = product.productSettings.HINGE_CC_EXTRA
                ? Number(product.productSettings.HINGE_CC_EXTRA)
                : 100;

              hinges[i].push({
                x: hl - Number(product.productSettings.WIDTH),
                y: hingeExtra,
              });
            }
          }
        }

        if (hinges) {
          hinges.push([]);
        }
      });
    }
    return hinges;
  }

  static getNumberOfHinges(product, door): number {
    if (!product.settings) {
      return 2;
    }
    const s: Setting = product.settings.find(
      (s: Setting) => s.mapping && s.mapping.name === 'HINGES_AMOUNT'
    );
    let n = 2;
    if (s && s.settingOptionSet.length > 0) {
      let check = true;
      s.settingOptionSet.forEach((set: Option) => {
        if (set.value >= door.attrs.height && check) {
          check = false;
          n = Number(set.name);
        }
      });
    }
    return n;
  }
}
