import { Product } from '../model/product';
import { Front } from '../model/subproducts/front';
import { Shelf } from '../model/subproducts/shelf';

export class ShelfUtils {
  static autoShelfPlacementCalc(product: Product): Shelf[] {
    if (!product.shelves) {
      return null;
    }

    const noShelves = product.shelves.length;

    if (noShelves === 0) {
      return null;
    }

    product.shelves.forEach((shelf: Shelf, i) => {
      shelf.attrs.width = product.attrs.width;
      shelf.position({ x: 0, y: (product.attrs.height / noShelves) * i });
      // todo: wip
      // if (product.fronts[0].placement === 'top' && i === 1) {
      //     product.fronts[0].attrs.width = product.attrs.width;
      //     product.fronts[0].attrs.height = (product.attrs.height / noShelves);
      //     product.fronts[0].drawFront();
      // }
    });
    return product.shelves;
  }

  static spicerackShelfPlacementCalc(product: Product): Shelf[] {
    if (!product.productSettings.AMOUNT_SHELF) {
      return null;
    }

    let n = Number(product.productSettings.AMOUNT_SHELF);

    let newHeight = 0;
    const shelves: Shelf[] = [];

    if (
      product.productSettings.FRONT_TOP &&
      product.productSettings.FRONT_SPICERACK_TOP === '1'
    ) {
      n += 1;
    }

    if (
      product.productSettings.FRONT_BOTTOM &&
      product.productSettings.FRONT_SPICERACK_BOTTOM === '1'
    ) {
      n += 1;
    }

    product.deleteFronts();

    for (let i = 0; i < n; i++) {
      if (
        product.productSettings.FRONT_TOP &&
        i === 0 &&
        product.productSettings.FRONT_SPICERACK_TOP === '1'
      ) {
        newHeight = 20 + Number(product.productSettings.FRONT_TOP);
        shelves.push(
          new Shelf(
            {
              x: 0,
              y: newHeight,
              width: product.attrs.width,
              height: 16,
            },
            product.perspective,
            product.productSettings.RIGHT_LEFT,
            true
          )
        );
        const newFront = new Front(
          {
            x: 0,
            y: 20,
            width: product.attrs.width,
            height: Number(product.productSettings.FRONT_TOP),
          },
          null
        );
        newFront.position({ x: 0, y: 20 });
        product.add(newFront);
      } else if (
        product.productSettings.FRONT_BOTTOM &&
        i === n - 1 &&
        product.productSettings.FRONT_SPICERACK_BOTTOM === '1'
      ) {
        newHeight =
          product.attrs.height -
          Number(product.productSettings.FRONT_BOTTOM) -
          36;
        shelves.push(
          new Shelf(
            {
              x: 0,
              y: newHeight,
              width: product.attrs.width,
              height: 16,
            },
            product.perspective,
            product.productSettings.RIGHT_LEFT,
            true
          )
        );
        const newFront = new Front(
          {
            x: 0,
            y: 0,
            width: product.attrs.width,
            height: Number(product.productSettings.FRONT_BOTTOM),
          },
          null
        );
        newFront.position({ x: 0, y: newHeight + 16 });
        product.add(newFront);
      } else {
        if (
          product.productSettings.FRONT_SPICERACK_BOTTOM === '1' &&
          product.productSettings.FRONT_SPICERACK_TOP === '1'
        ) {
          const shelvesLeft = n - 2;
          const heightLeft =
            product.attrs.height -
            Number(product.productSettings.FRONT_BOTTOM) -
            56 -
            Number(product.productSettings.FRONT_TOP);
          newHeight += heightLeft / (shelvesLeft + 1);
        } else if (
          product.productSettings.FRONT_SPICERACK_BOTTOM === '1' &&
          product.productSettings.FRONT_SPICERACK_TOP !== '1'
        ) {
          const shelvesLeft = n - 1;
          const heightLeft =
            product.attrs.height -
            Number(product.productSettings.FRONT_BOTTOM) -
            38;
          newHeight += heightLeft / (shelvesLeft + 1);
        } else if (
          product.productSettings.FRONT_SPICERACK_BOTTOM !== '1' &&
          product.productSettings.FRONT_SPICERACK_TOP === '1'
        ) {
          const shelvesLeft = n - 1;
          const heightLeft =
            product.attrs.height -
            38 -
            Number(product.productSettings.FRONT_TOP);
          newHeight += heightLeft / (shelvesLeft + 1);
        } else {
          newHeight += product.attrs.height / (n + 1) - 4;
        }
        shelves.push(
          new Shelf(
            {
              x: 0,
              y: newHeight,
              width: product.attrs.width,
              height: 16,
            },
            product.perspective,
            product.productSettings.RIGHT_LEFT,
            false
          )
        );
      }
    }
    return shelves;
  }
}
