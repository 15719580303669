import { EventEmitter, Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  verifier: string;
  codeChallenge: string;
  environment: string;

  permissionsEmitter = new EventEmitter<any>();

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  authorizeAndLogin() {
    this.verifier = this.strRandom(128);
    const codeVerifierHash = crypto
      .SHA256(this.verifier)
      .toString(crypto.enc.Base64);
    this.codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    this.storage.set('codeVerifier', this.verifier);

    const params = [
      'response_type=code',
      'state=' + 'state',
      'client_id=' + environment.oauthClientId,
      'scope=read_user_data write_user_data',
      'code_challenge=' + this.codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
    ];

    window.location.href = environment.oauthLoginUrl + '?' + params.join('&');
  }

  verifyToken(): Observable<any> {
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + this.storage.get(StorageConsts.ACCESS_TOKEN)
    );

    return this.httpClient.get(environment.oauthVerifyToken, {
      headers,
    });
  }

  refreshToken(): Observable<any> {
    const params = new HttpParams()
      .append('grant_type', 'refresh_token')
      .append('refresh_token', this.storage.get(StorageConsts.REFRESH_TOKEN));

    return this.httpClient.post(environment.oauthRefreshToken, params);
  }

  getUserSettings(): Observable<any> {
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + token
    );

    return this.httpClient.get(environment.userSettings, {
      headers,
    });
  }

  private strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  base64URLEncode(str): string {
    return str
      .toString()
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  sha256(buffer): any {
    return crypto.SHA256(buffer);
  }
}
