<div
  class="modal fade"
  id="productSpecificModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "NEW_PRODUCT" | translate }}: {{ product.type }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column list-group">
        <div *ngFor="let setting of product.settings; index as i">
          <div *ngIf="setting.type === 'EDITABLE'">
            <div
              *ngIf="setting.settingType === 'TEXTFIELD'"
              class="form-group row"
            >
              <label class="col-sm-7 col-form-label">
                {{ setting.name }}:
              </label>

              <input
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-4 form-control"
                [(ngModel)]="product.settings[i].value"
                (ngModelChange)="onSettingChange($event)"
                type="number"
                [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
              />

              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>

            <div
              *ngIf="setting.settingType === 'DROPDOWN'"
              class="form-group row"
            >
              <label class="col-sm-7 col-form-label">
                {{ setting.name }}:
              </label>

              <select
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-4 form-control"
                [(ngModel)]="product.settings[i].value"
                (ngModelChange)="onSettingChange($event)"
                type="number"
                [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
              >
                <option
                  *ngFor="let option of setting.settingOptionSet"
                  value="{{ option.value }}"
                >
                  {{ option.name }}
                </option>
              </select>

              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>

            <div
              *ngIf="setting.settingType === 'RADIO_VERTICAL'"
              class="form-group row"
            >
              <label class="col-sm-7 col-form-label">
                {{ setting.name }}:
              </label>

              <select
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-4 form-control"
                [(ngModel)]="product.settings[i].value"
                (ngModelChange)="onSettingChange($event)"
                type="number"
                [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
              >
                <option
                  *ngFor="let option of setting.settingOptionSet"
                  value="{{ option.value }}"
                >
                  {{ option.name }}
                </option>
              </select>

              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal-body d-flex flex-column list-group">
                <div *ngFor="let setting of product.settings ; index as i">
                    <div *ngIf="setting.type === 'CALCULATED'">
                        <label class="custom-label">
                            {{setting.name}}:
                        </label>
                        <input class="form-control" [(ngModel)]="product.settings[i].value"
                            (ngModelChange)="onSettingChange($event)" type="number" [disabled]="true">
                    </div>
                </div>
            </div> -->

      <div class="modal-footer">
        <div
          *ngIf="!product.productSettings.DESIGN_OF_PRODUCT"
          class="text-danger custom-left-padding"
        >
          {{ "PRODUCT_NO_DESIGN" | translate }}
        </div>
        <div *ngIf="checkValidity()" class="text-danger custom-left-padding">
          {{ "SOME_SETTINGS_UNSET" | translate }}
        </div>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="createProduct()"
          [disabled]="checkValidity()"
        >
          {{ "CREATE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
