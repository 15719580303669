import Konva from 'konva';

export class Zigzag extends Konva.Group {
  constructor(context) {
    super(context);
    this.attrs.name = 'zigzag';
    this.drawLines();
  }

  drawLines() {
    const points = [];
    for (let i = 1; i < 10; i++) {
      points.push(i % 2 === 0 ? -20 : 20);
      points.push(((i - 1) * this.attrs.height) / 8);
    }
    this.add(
      new Konva.Line({
        points,
        stroke: 'black',
        strokeWidth: 4,
        name: 'shelfStroke',
        opacity: 0.3,
        // dash: [16, 8]
      })
    );
    this.add(
      new Konva.Line({
        points: [0, 0, 0, this.attrs.height],
        stroke: 'black',
        strokeWidth: 4,
        name: 'shelfStroke',
        opacity: 0.3,
        // dash: [16, 8]
      })
    );
  }
}
