<div class="custom d-flex justify-content-center flex-column">
  <div class="btn-group btn-group-lg p-2 custom-button" role="group">
    <button
      type="button"
      class="btn btn-secondary custom-button"
      (click)="redirect()"
    >
      <i-bs name="arrow-clockwise"></i-bs>
    </button>
  </div>
</div>
