<div
  class="modal fade"
  id="backToHKModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-lg modal-dialog-centered custom-modal"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "BACK_TO_HK" | translate }}
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setTab(0)"
              [ngClass]="tab === 0 ? 'active' : ''"
              >{{ "ERROR" | translate }}
              <span class="badge badge-pill badge-danger custom-badge">{{
                errorMessages.length
              }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setTab(1)"
              [ngClass]="tab === 1 ? 'active' : ''"
              >{{ "REMAINING" | translate }}
              <span class="badge badge-pill badge-info custom-badge">{{
                unvalidatedMessages.length
              }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setTab(2)"
              [ngClass]="tab === 2 ? 'active' : ''"
              >{{ "VALIDATED" | translate }}
              <span class="badge badge-pill badge-success custom-badge">{{
                validatedMessages.length
              }}</span>
            </a>
          </li>
          <li *ngIf="editDrawing" class="nav-item">
            <a
              class="nav-link"
              (click)="setTab(3)"
              [ngClass]="tab === 3 ? 'active' : ''"
              >{{ "SIMPLE_VALIDATIONS" | translate }}
              <span class="badge badge-pill badge-warning custom-badge">{{
                simpleValidationsUnchecked
              }}</span>
            </a>
          </li>
        </ul>

        <ul *ngIf="tab === 0" class="list-group">
          <li
            *ngFor="let message of errorMessages"
            class="list-group-item custom-list-item container"
          >
            <div class="row">
              <h6 class="custom-text-width col-4">{{ message.productName }}</h6>
              <div class="custom-text-padding text-danger col-8">
                {{ message.out }}
              </div>
            </div>
          </li>
        </ul>

        <ul *ngIf="tab === 1" class="list-group">
          <li
            *ngFor="let message of unvalidatedMessages"
            class="list-group-item custom-list-item"
          >
            <h6>{{ message.productName }}</h6>
          </li>
        </ul>

        <ul *ngIf="tab === 2" class="list-group">
          <li
            *ngFor="let message of validatedMessages"
            class="list-group-item custom-list-item"
          >
            <h6>{{ message.productName }}</h6>
          </li>
        </ul>

        <ul *ngIf="tab === 3" class="list-group">
          <li
            class="list-group-item custom-list-item d-flex align-content-center"
          >
            <button class="btn btn-secondary" (click)="selectAllCheckboxes()">
              {{ "SELECT_ALL" | translate }}
            </button>
          </li>
          <li
            *ngFor="let message of simpleValidations"
            class="list-group-item custom-list-item d-flex align-content-center"
            (click)="checkSimpleValidation(message)"
          >
            <input
              class="align-self-center"
              type="checkbox"
              [(ngModel)]="message.simpleValidationChecked"
              (ngModelChange)="checkboxChecked()"
            />
            <h6 class="custom-text-padding align-self-end">
              {{ message.message.message }}
            </h6>
          </li>
        </ul>
      </div>
      <div class="modal-footer custom-footer">
        <div *ngIf="localData && editDrawing" class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {{ "LOAD_LOCAL_SAVE" | translate }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li *ngFor="let save of localData.saves">
              <div
                *ngIf="save.date && localData.saves[4].date === save.date"
                class="custom-item"
              >
                -- {{ "AUTO_SAVE" | translate }} --
              </div>
              <a
                *ngIf="save.date"
                class="dropdown-item"
                (click)="loadLocal(save.date)"
                data-dismiss="modal"
                aria-label="Close"
                >{{ convertDate(save.date) }}</a
              >
            </li>
          </ul>
        </div>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="logout()"
        >
          {{ "LOGOUT" | translate }}
        </button>
        <button
          *ngIf="!editDrawing || activatePdfOnly"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="pdfOnly()"
        >
          {{ "PDF_ONLY" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="returnWithoutSaving()"
        >
          {{ "RETURN_WITHOUT_SAVING" | translate }}
        </button>
        <button
          *ngIf="editDrawing"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="returnAndSave()"
        >
          {{ "SAVE_AND_RETURN" | translate }}
        </button>
        <button
          *ngIf="editDrawing"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="simpleValidationsUnchecked > 0"
          (click)="returnAndCreatePdf()"
        >
          {{ "SAVE_AND_PDF" | translate }}
        </button>
        <button
          *ngIf="editDrawing"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="save()"
        >
          {{ "SAVE" | translate }}
        </button>
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">{{'CANCEL' |
                    translate}}</button> -->
      </div>
    </div>
  </div>
</div>
