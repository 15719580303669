import { Injectable, EventEmitter } from '@angular/core';
import { Layer } from 'konva/types/Layer';
import { Product } from '../model/product';
import { SaveUtil } from '../utils/saveUtil';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  productsEmitter = new EventEmitter<Product[]>();
  allProductsEmitter = new EventEmitter<Product[]>();

  productsListEmitter = new EventEmitter<any[]>();
  categoriesListEmitter = new EventEmitter<any[]>();
  fictionalProductListEmitter = new EventEmitter<any[]>();

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {
    this.getCategoriesListData().subscribe(
      (categories: any[]) => {
        this.categoriesListEmitter.emit(categories);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }

  saveLocally(order, autoSave) {
    localStorage.setItem(
      StorageConsts.ORDER,
      SaveUtil.saveOrder(
        order,
        localStorage.getItem(StorageConsts.ORDER),
        autoSave
      )
    );
  }

  loadLocalSave(): any {
    return JSON.parse(localStorage.getItem(StorageConsts.ORDER));
  }

  getProductListData(): Observable<any> {
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + this.storage.get(StorageConsts.ACCESS_TOKEN)
    );

    return this.httpClient.get(environment.products, {
      headers,
    });
  }

  getTempDesighnsData(): Observable<any> {
    return this.httpClient.get('./../assets/tempDesigns.json');
  }

  getLocalProductListData(): Observable<any> {
    return this.httpClient.get('./../assets/testProducts.json');
  }

  getCategoriesListData(): Observable<any> {
    // return this.httpClient.get('https://rapport.harjedalskok.se/test/drawing/maincategories')
    return this.httpClient.get('./../assets/testCategories.json');
  }

  getFictionalProductListData(): Observable<any> {
    return this.httpClient.get('./../assets/fictionalProducts.json');
  }

  getImage(imageUrl: string): Observable<Blob> {
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + this.storage.get(StorageConsts.ACCESS_TOKEN)
    );

    return this.httpClient.get(imageUrl, { headers, responseType: 'blob' });
  }
}
