import Konva from 'konva';
import { FictionalProductUtils } from 'src/app/utils/fictionalUtils';
export class FictionalProduct extends Konva.Group {
  constructor(context: any, id: number) {
    super(context);
    this.attrs.name = 'fictional';
    this.attrs.x = 0;
    this.attrs.y = 0;
    // this.attrs.draggable = false;
    // const type = FictionalProductUtils.getFictionalType(id)
    // if (type === 'microwave') {
    //     this.drawMicrowave();
    // } else if (type === 'refrigerator') {
    //     this.drawRefrigerator();
    // }
  }

  drawMicrowave() {
    this.add(
      new Konva.Rect({
        x: 50,
        y: 50,
        width: this.attrs.width - 150,
        height: this.attrs.height - 100,
        name: 'rect',
        strokeWidth: 20,
        stroke: 'black',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: this.attrs.height - 55,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: this.attrs.height / 2,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
    this.add(
      new Konva.Circle({
        x: this.attrs.width - 50,
        y: 55,
        radius: 20,
        fill: 'black',
        name: 'circle',
      })
    );
  }

  drawRefrigerator() {
    this.add(
      new Konva.Rect({
        x: 0,
        y: 500,
        width: this.attrs.width,
        height: 20,
        name: 'rect',
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 100,
        y: 150,
        width: 20,
        height: 200,
        name: 'rect',
        fill: 'black',
      })
    );
    this.add(
      new Konva.Rect({
        x: 100,
        y: 800,
        width: 20,
        height: 450,
        name: 'rect',
        fill: 'black',
      })
    );
  }
}
