import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import Konva from 'konva';
import { Layer } from 'konva/types/Layer';
import { Rect } from 'konva/types/shapes/Rect';
import { Stage, stages } from 'konva/types/Stage';
import { Vector2d } from 'konva/types/types';
import { layers, x } from 'ngx-bootstrap-icons';
import { ConfigConsts } from '../consts/configConsts';
import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Cabin } from '../model/subproducts/cabin';
import { Drawer } from '../model/subproducts/drawer';
import { Tag } from '../model/subproducts/tag';
import { Wall } from '../model/wall';
import { CommentUtil } from './commentUtil';
import { FictionalSettingsUtil } from './fictionalSettingsUtil';

export class ProductInteractionUtils {

    static drawerAlign(layer: Layer, layers: Layer[], showDrawerAlign: boolean) {
        if (!layer || !layers) {
            return;
        }

        layers.forEach((layer: Layer) => {
            layer.find('.drawerAlignRect').each((rect: Rect) => {
                rect.destroy();
            });
            layer.find('.colorRect').each((rect: Rect) => {
                rect.destroy();
            });
        });

        if (!showDrawerAlign) {
            return;
        }

        const avalaibleColors: any[] = [
            { color: 'lime', lineType: '' },
            { color: 'pink', lineType: '' },
            { color: 'orange', lineType: '' },

            { color: 'lime', lineType: [20, 10] },
            { color: 'pink', lineType: [20, 10] },
            { color: 'orange', lineType: [20, 10] },

            { color: 'pink', lineType: [5, 10] },
            { color: 'lime', lineType: [5, 10] },
            { color: 'orange', lineType: [5, 10] },

        ];

        const occupiedYs = [];

        let colorIterator = 0;

        let biggestId;
        let biggestHeight = 0;

        let wallh = 0;

        layers.forEach((l1: Layer) => {

            l1.find('.wall').each((wall: Wall) => {
                const ocy = occupiedYs.find((ocy: any) => ocy.y === wall.attrs.height + wall.attrs.y);
                if (ocy) {

                } else {
                    occupiedYs.push({ y: wall.attrs.y + wall.attrs.height, color: colorIterator });
                    colorIterator += 1;

                    if (colorIterator >= avalaibleColors.length) {
                        colorIterator = 0;
                    }
                }
                wallh = wall.attrs.height - 1000;
            })
            l1.find('.product').each((p1: Product) => {
                if (p1.productSettings && p1.productSettings.TOP_FRONT > biggestHeight) {
                    biggestId = p1.attrs.id;
                    biggestHeight = p1.productSettings.TOP_FRONT;
                }


                layers.forEach((l2: Layer) => {
                    l2.find('.product').each((p2: Product) => {
                        if (p1.id() === p2.id()) {
                            return;
                        }
                        const ocy = occupiedYs.find((ocy: any) => ocy.y === p1.attrs.y);
                        if (ocy) {
                            l1.add(new Konva.Line({
                                points: [p1.attrs.x, p1.attrs.y + 5, p1.attrs.x + p1.attrs.width, p1.attrs.y + 5],
                                stroke: avalaibleColors[ocy.color].color,
                                strokeWidth: 10,
                                name: 'drawerAlignRect',
                                dash: avalaibleColors[ocy.color].lineType
                            }));
                        } else if (p1.attrs.y === p2.attrs.y || p1.attrs.y === p2.attrs.y + p2.attrs.height) {
                            if (p1.attrs.y > wallh) {
                                l1.add(new Konva.Line({
                                    points: [p1.attrs.x, p1.attrs.y + 5, p1.attrs.x + p1.attrs.width, p1.attrs.y + 5],
                                    stroke: avalaibleColors[colorIterator].color,
                                    strokeWidth: 10,
                                    name: 'drawerAlignRect',
                                    dash: avalaibleColors[colorIterator].lineType
                                }));



                                occupiedYs.push({ y: p1.attrs.y, color: colorIterator });
                                colorIterator += 1;

                                if (colorIterator >= avalaibleColors.length) {
                                    colorIterator = 0;
                                }
                            }
                        }

                        const ocy2 = occupiedYs.find((ocya: any) => ocya.y === p1.attrs.y + p1.attrs.height);
                        if (ocy2) {
                            l1.add(new Konva.Line({
                                points: [p1.attrs.x,
                                p1.attrs.y + p1.attrs.height + 5,
                                p1.attrs.x + p1.attrs.width,
                                p1.attrs.y + p1.attrs.height + 5],
                                stroke: avalaibleColors[ocy2.color].color,
                                strokeWidth: 10,
                                name: 'drawerAlignRect',
                                dash: avalaibleColors[ocy2.color].lineType
                            }));
                        } else if (p1.attrs.y + p1.attrs.height === p2.attrs.y || p1.attrs.y + p1.attrs.height === p2.attrs.y + p2.attrs.height) {
                            if (p1.attrs.y + p1.attrs.height > wallh) {
                                l1.add(new Konva.Line({
                                    points: [p1.attrs.x,
                                    p1.attrs.y + p1.attrs.height + 5,
                                    p1.attrs.x + p1.attrs.width,
                                    p1.attrs.y + p1.attrs.height + 5],
                                    stroke: avalaibleColors[colorIterator].color,
                                    strokeWidth: 10,
                                    name: 'drawerAlignRect',
                                    dash: avalaibleColors[colorIterator].lineType
                                }));


                                occupiedYs.push({ y: p1.attrs.y + p1.attrs.height, color: colorIterator });
                                colorIterator += 1;

                                if (colorIterator >= avalaibleColors.length) {
                                    colorIterator = 0;
                                }
                            }
                        }

                        p1.find('.drawer').each((d1: Drawer) => {
                            const p1y = p1.attrs.y + d1.attrs.y + d1.attrs.height;
                            const ocy = occupiedYs.find((ocy: any) => ocy.y === p1y);
                            let hasLine = false;
                            if (ocy) {
                                l1.add(new Konva.Line({
                                    points: [p1.attrs.x, p1y - 5, p1.attrs.x + p1.attrs.width, p1y - 5],
                                    stroke: avalaibleColors[ocy.color].color,
                                    strokeWidth: 10,
                                    name: 'drawerAlignRect',
                                    dash: avalaibleColors[ocy.color].lineType
                                }));
                                hasLine = true;
                                return;
                            }
                            if (p1.attrs.y + p1.attrs.height > wallh) {

                                if (!this.hasDrawers(p2)) {
                                    if (p1y === p2.attrs.y + p2.attrs.height) {
                                        l1.add(new Konva.Line({
                                            points: [p1.attrs.x, p1y - 5, p1.attrs.x + p1.attrs.width, p1y - 5],
                                            stroke: avalaibleColors[colorIterator].color,
                                            strokeWidth: 10,
                                            name: 'drawerAlignRect',
                                            dash: avalaibleColors[colorIterator].lineType
                                        }));
                                        hasLine = true;
                                    }
                                } else {
                                    p2.find('.drawer').each((d2: Drawer) => {
                                        if (p1y === p2.attrs.y + d2.attrs.y + d2.attrs.height) {
                                            l1.add(new Konva.Line({
                                                points: [p1.attrs.x, p1y - 5, p1.attrs.x + p1.attrs.width, p1y - 5],
                                                stroke: avalaibleColors[colorIterator].color,
                                                strokeWidth: 10,
                                                name: 'drawerAlignRect',
                                                dash: avalaibleColors[colorIterator].lineType
                                            }));
                                            hasLine = true;
                                        }
                                    });
                                }


                                if (hasLine) {
                                    occupiedYs.push({ y: p1y, color: colorIterator });
                                    colorIterator += 1;

                                    if (colorIterator >= avalaibleColors.length) {
                                        colorIterator = 0;
                                    }
                                }
                            }
                        });
                    });
                });
            });
            l1.find('.product').each((product: Product) => {
                if (product.attrs.id === biggestId) {
                    l1.add(new Konva.Rect({
                        x: product.attrs.x,
                        y: product.attrs.y + 10,
                        width: product.WIDTH,
                        height: biggestHeight - 10,
                        fill: 'blue',
                        name: 'colorRect',
                        opacity: 0.3
                    }));
                }
            });
        });
    }

  static commentPlacement(layer: Layer, stage: Stage) {
    if (!layer || !stage) {
      return;
    }
    layer.find('.product').each((product: Product) => {
      // add if statement when there is a
      if (
        (product.attrs.x + product.attrs.width) * stage.attrs.scaleX >
        stage.attrs.width / 2
      ) {
        product.commentLeft = false;
        product.updateComment();
      } else {
        product.commentLeft = true;
        product.updateComment();
      }
    });
  }

  static automaticComments(product: Product) {
    if (product.productSettings.DOORTYPE == 7) {
      CommentUtil.addPhrase(product, '45 graders lucka rak \n');
      CommentUtil.removePhrase(product, '45 grader lucka sned \n');
    } else if (product.productSettings.DOORTYPE == 11) {
      CommentUtil.addPhrase(product, '45 grader lucka sned \n');
      CommentUtil.removePhrase(product, '45 graders lucka rak \n');
    } else {
      CommentUtil.removePhrase(product, '45 grader lucka sned \n');
      CommentUtil.removePhrase(product, '45 graders lucka rak \n');
    }

    if (product.productSettings.NEW_CABIN === 'YES') {
      CommentUtil.addPhrase(product, 'Valbart \n');
    } else {
      CommentUtil.removePhrase(product, 'Valbart \n');
    }
  }

  static fictionalLightstraplistStretcher(layer, config): void {
    if (!layer) {
      return;
    }
    if (config === ConfigConsts.NO || !layer) {
      layer.find('.product').each((p1: Product) => {
        if (p1.fictionalID !== 50) {
          return;
        }
        let leftConnection = false;
        let rightConnection = false;
        layer.find('.product').each((p2: Product) => {
          if (p2.fictionalID === 50) {
            if (this.checkLeftConnection(p1, p2)) {
              leftConnection = true;
            }
            if (this.checkRightConnection(p1, p2)) {
              rightConnection = true;
            }
            return;
          }
          if (this.checkTopCornerConnection(p1, p2)) {
            p1.attrs.width = p2.WIDTH;
            p1.position({ x: p2.attrs.x, y: p1.attrs.y });
            if (p2.productSettings.DOUBLE_DOOR === '2') {
              p1.productSettings.WIDTH = p2.productSettings.WIDTH * 2;
            } else {
              p1.productSettings.WIDTH = p2.productSettings.WIDTH;
            }
            p1.updatePreviousPosition();
          }
        });
        p1.specialParams.hideLeftCabin = leftConnection;
        p1.specialParams.hideRightCabin = rightConnection;
      });
    } else {
      layer.find('.product').each((p1: Product) => {
        if (p1.fictionalID !== 50) {
          return;
        }
        const availableProducts = [];
        let leftBorder = 0;
        let rightBorder = 1000000;
        layer.find('.product').each((p2: Product) => {
          if (p2.fictionalID === 50) {
            return;
          }
          if (this.checkTopConnectionWithoutX(p1, p2)) {
            availableProducts.push(p2);
            if (
              p1.attrs.x >= p2.attrs.x + p2.attrs.width &&
              p2.attrs.x + p2.attrs.width > leftBorder
            ) {
              leftBorder = p2.attrs.x + p2.attrs.width;
            }
          }
          if (
            p1.attrs.x <= p2.attrs.x &&
            p1.attrs.y === p2.attrs.y &&
            p2.attrs.x < rightBorder
          ) {
            rightBorder = p2.attrs.x;
          }
        });
        p1.attrs.width = 0;
        p1.productSettings.WIDTH = 0;
        if (availableProducts.length === 0) {
          p1.attrs.width = 100;
        } else {
          let startX = 0;
          availableProducts
            .sort((a: Product, b: Product) => a.attrs.x - b.attrs.x)
            .forEach((p: Product, i: number) => {
              if (this.checkTopCornerConnection(p1, p)) {
                p1.position({ x: p.attrs.x, y: p1.attrs.y });
                p1.updatePreviousPosition();
                if (
                  p.attrs.x + p.attrs.width <= rightBorder &&
                  p.attrs.x >= leftBorder
                ) {
                  p1.attrs.width += p.WIDTH;
                  if (p.productSettings.DOUBLE_DOOR === '2') {
                    p1.productSettings.WIDTH += p.productSettings.WIDTH * 2;
                  } else {
                    p1.productSettings.WIDTH += p.productSettings.WIDTH;
                  }
                }
                startX = p.attrs.x;
              }
              if (startX) {
                if (
                  availableProducts[i + 1] &&
                  this.checkRightConnection(p, availableProducts[i + 1])
                ) {
                  if (
                    availableProducts[i + 1].attrs.x +
                      availableProducts[i + 1].attrs.width <=
                      rightBorder &&
                    availableProducts[i + 1].attrs.x > leftBorder
                  ) {
                    p1.attrs.width += availableProducts[i + 1].WIDTH;
                    if (
                      availableProducts[i + 1].productSettings.DOUBLE_DOOR ===
                      '2'
                    ) {
                      p1.productSettings.WIDTH +=
                        availableProducts[i + 1].productSettings.WIDTH * 2;
                    } else {
                      p1.productSettings.WIDTH +=
                        availableProducts[i + 1].productSettings.WIDTH;
                    }
                  }
                }
              }
            });
        }
      });
    }
  }

  static lightstraplistStretcher(layer, config) {
    if (!layer) {
      return;
    }
    if (config === ConfigConsts.NO || !layer) {
      return;
    }
    layer.find('.product').each((p1: Product) => {
      if (p1.productSettings.DESIGN_OF_PRODUCT !== 'LIGHTRAMPSTRIP') {
        return;
      }
      const connectedProducts: Product[] = [];
      layer.find('.product').each((p2: Product) => {
        if (this.checkTopConnectionWithoutX(p1, p2)) {
          connectedProducts.push(p2);
        }
      });
      if (connectedProducts.length > 0) {
        const virtualWidth: Vector2d = { x: 0, y: 0 };
        let checker = true;
        connectedProducts
          .sort((a, b) => a.attrs.x - b.attrs.x)
          .forEach((p2: Product, i: number) => {
            if (!checker) {
              return;
            }
            if (i === 0) {
              virtualWidth.x = p2.attrs.width;
              virtualWidth.y = p2.attrs.x;
            }
            if (
              p2.productSettings &&
              p2.productSettings.LIGHT_RAMP_YES_NO === '0'
            ) {
              p2.changeValue(Mapping.LIGHT_RAMP_YES_NO, '70');
              checker = false;
              return;
            }
            if (
              connectedProducts[i + 1] &&
              this.checkRightConnection(p2, connectedProducts[i + 1])
            ) {
              virtualWidth.x += connectedProducts[i + 1].attrs.width;
            } else {
              if (
                p1.attrs.x >= virtualWidth.y &&
                p1.attrs.x < virtualWidth.x + virtualWidth.y
              ) {
                p1.position({ x: virtualWidth.y, y: p1.attrs.y });
                p1.updatePreviousPosition();
                p1.changeValue(Mapping.WIDTH, virtualWidth.x);
                // for some reason stopDrag() works on every product
                // p1.stopDrag();
                checker = false;
              } else if (connectedProducts[i + 1]) {
                virtualWidth.x = connectedProducts[i + 1].attrs.width;
                virtualWidth.y = connectedProducts[i + 1].attrs.x;
              }
            }
          });
      } else {
        p1.changeValue(Mapping.WIDTH, 400);
      }
    });
  }

  static sockelUpdate(layer: Layer) {
    let sockelHeight;
    let wall: Wall;
    layer.find('.wall').each((w: Wall) => {
      sockelHeight = w.sockelHeight;
      wall = w;
    });
    layer.find('.product').each((p: Product) => {
      p.sockel = sockelHeight;
      if (wall.attrs.height - p.attrs.y - p.HEIGHT < sockelHeight + 50) {
        if (p.productSettings.HAS_DEKORLIST) {
          FictionalSettingsUtil.switchFictionalSettings(
            p.getSetting('HAS_DEKORLIST'),
            FictionalSettingsUtil.getFictionalSockelSetting(),
            p
          );
        }
      } else {
        if (p.productSettings.HAS_SOCKEL) {
          FictionalSettingsUtil.switchFictionalSettings(
            p.getSetting('HAS_SOCKEL'),
            FictionalSettingsUtil.getFictionalDekorlistSetting(),
            p
          );
        }
      }
    });
  }

  static fitToSockel(layer: Layer): void {
    let sockelHeight;
    let wallH;
    layer.find('.wall').each((w: Wall) => {
      sockelHeight = w.sockelHeight;
      wallH = w.attrs.height;
    });

    layer.find('.product').each((p: Product) => {
      if (
        p.isBottom &&
        p.fictionalID !== 51 &&
        p.fictionalID !== 20 &&
        p.fictionalID !== 21 &&
        !p.isFictional
      ) {
        p.sockel = sockelHeight;
        p.position({ x: p.attrs.x, y: wallH - p.HEIGHT - sockelHeight });
        p.updatePreviousPosition();
      }
    });
  }

  static sockelStretcher(layer: Layer, config) {
    if (!layer) {
      return;
    }
    if (config === ConfigConsts.NO) {
      return;
    }
    layer.find('.product').each((p1: Product) => {
      if (p1.productSettings.DESIGN_OF_PRODUCT !== 'SOCKEL') {
        return;
      }
      const connectedProducts: Product[] = [];
      layer.find('.product').each((p2: Product) => {
        if (this.checkTopConnectionWithoutX(p1, p2)) {
          connectedProducts.push(p2);
        }
        p2.bottomPlacement = 0;
      });
      if (connectedProducts.length > 0) {
        const virtualWidth: Vector2d = { x: 0, y: 0 };
        let checker = true;
        connectedProducts
          .sort((a, b) => a.attrs.x - b.attrs.x)
          .forEach((p2: Product, i: number) => {
            if (!checker) {
              return;
            }
            if (i === 0) {
              virtualWidth.x = p2.attrs.width;
              virtualWidth.y = p2.attrs.x;
            }
            p2.bottomPlacement = p1.HEIGHT;
            if (
              connectedProducts[i + 1] &&
              this.checkRightConnection(p2, connectedProducts[i + 1])
            ) {
              virtualWidth.x += connectedProducts[i + 1].attrs.width;
            } else {
              if (
                p1.attrs.x >= virtualWidth.y &&
                p1.attrs.x < virtualWidth.x + virtualWidth.y
              ) {
                p1.position({ x: virtualWidth.y, y: p1.attrs.y });
                p1.updatePreviousPosition();
                p1.changeValue(Mapping.WIDTH, virtualWidth.x);
                // for some reason stopDrag() works on every product
                // p1.stopDrag();
                checker = false;
              } else if (connectedProducts[i + 1]) {
                virtualWidth.x = connectedProducts[i + 1].attrs.width;
                virtualWidth.y = connectedProducts[i + 1].attrs.x;
              }
            }
          });
      } else {
        p1.changeValue(Mapping.WIDTH, 400);
      }
    });
  }

  static updateSockelSides(layer: Layer): void {
    layer?.find('.product').each((p1: Product) => {
      if (
        p1.productSettings.HAS_DEKORLIST === 'YES' ||
        p1.productSettings.HAS_SOCKEL === 'YES'
      ) {
        let left = true;
        let right = true;
        layer.find('.product').each((p2: Product) => {
          if (
            p1 === p2 ||
            p2.productSettings.HAS_DEKORLIST === 'NO' ||
            p2.productSettings.HAS_SOCKEL === 'NO'
          ) {
            return;
          }
          if (this.checkRightConnection(p1, p2)) {
            right = false;
          }
          if (this.checkLeftConnection(p1, p2)) {
            left = false;
          }
        });

        p1.updateDekorlistSockel(left, right);
      } else {
        p1.updateDekorlistSockel();
      }
    });
  }

  static ffDegDoorsInteraction(layer: Layer): void {
    if (!layer) {
      return;
    }
    layer.find('.product').each((p1: Product) => {
      if (p1.productSettings.DOORTYPE == 7) {
        layer.find('.product').each((p2: Product) => {
          if (p1 === p2) {
            return;
          }
          const rightCover = p2.settings.find(
            (s: Setting) => s.mapping?.name === Mapping.CABIN_COVER_RIGHT
          );
          const leftCover = p2.settings.find(
            (s: Setting) => s.mapping?.name === Mapping.CABIN_COVER_LEFT
          );

          const lc = this.checkLeftConnection(p1, p2);
          const rc = this.checkRightConnection(p1, p2);
          if (lc) {
            p2.addLastInteracted(p1.index);
            if (
              Number(rightCover?.previousValue) === Number(rightCover?.value)
            ) {
              p2.changeValue(
                Mapping.CABIN_COVER_RIGHT,
                Number(p2.productSettings.CABIN_COVER_RIGHT) - 5
              );
              p2.updateProduct();
              p2.position({ x: p1.attrs.x - p2.WIDTH, y: p2.attrs.y });
              p2.updatePreviousPosition();
            }
          }
          if (rc) {
            p2.addLastInteracted(p1.index);
            if (Number(leftCover?.previousValue) === Number(leftCover?.value)) {
              p2.changeValue(
                Mapping.CABIN_COVER_LEFT,
                Number(p2.productSettings.CABIN_COVER_LEFT) - 5
              );
              p2.updateProduct();
            }
          }

          if (
            !lc &&
            Number(rightCover?.previousValue) !== Number(rightCover?.value) &&
            p2.checkLastInteracted(p1.index)
          ) {
            p2.changeValue(
              Mapping.CABIN_COVER_RIGHT,
              Number(rightCover?.previousValue)
            );
            p2.removeLastInteracted(p1.index);
          }

          if (
            !rc &&
            Number(leftCover?.previousValue) !== Number(leftCover?.value) &&
            p2.checkLastInteracted(p1.index)
          ) {
            p2.changeValue(
              Mapping.CABIN_COVER_LEFT,
              Number(leftCover?.previousValue)
            );
            p2.removeLastInteracted(p1.index);
          }
        });
      }
    });
  }

  static fictionalProductsInteraction(layer: Layer) {
    if (!layer) {
      return;
    }
    layer.find('.product').each((p1: Product) => {
      if (!p1.isFictional) {
        return;
      }
      layer.find('.product').each((p2: Product) => {
        // determine if fictional is an oven or dishwasher
        if (!this.checkHeatshieldNeed(p1)) {
          return;
        }
        if (
          !this.checkLeftConnection(p1, p2) &&
          !this.checkRightConnection(p1, p2) &&
          p2.checkLastInteracted(p1.index)
        ) {
          p2.changeValue(Mapping.HEATSHIELD_LEFTSIDE, '0');
          p2.changeValue(Mapping.HEATSHIELD_RIGHTSIDE, '0');
          p2.removeLastInteracted(p1.index);
        }
        if (
          this.checkRightConnection(p1, p2) &&
          p2.productSettings.HEATSHIELD_LEFTSIDE !== undefined
        ) {
          p2.changeValue(Mapping.HEATSHIELD_LEFTSIDE, '12');
          p2.addLastInteracted(p1.index);
        }
        if (
          this.checkLeftConnection(p1, p2) &&
          p2.productSettings.HEATSHIELD_RIGHTSIDE !== undefined
        ) {
          p2.changeValue(Mapping.HEATSHIELD_RIGHTSIDE, '12');
          p2.addLastInteracted(p1.index);
        }
      });
    });
  }

  static fictionalProductsInteractionAfterDelete(
    layer: Layer,
    p1: Product
  ): void {
    if (!layer) {
      return;
    }

    layer.find('.product').each((p2: Product) => {
      if (p2.checkLastInteracted(p1.index)) {
        p2.changeValue(Mapping.HEATSHIELD_LEFTSIDE, '0');
        p2.changeValue(Mapping.HEATSHIELD_RIGHTSIDE, '0');
        p2.removeLastInteracted(p1.index);
      }
    });
  }

  static applyProductNames(layers: Layer[], generate: boolean) {
    if (!layers) {
      return;
    }
    layers.forEach((layer: Layer) => {
      layer.find('.tag').each((t: Tag) => {
        t.destroy();
      });
      if (!generate) {
        return;
      }
      layer.find('.product').each((p1: Product) => {
        let typeOfConnection = '';
        layer.find('.product').each((p2: Product) => {
          if (p1.positionMarker === p2.positionMarker) {
            return;
          }

          if (
            typeOfConnection !== 'BOTTOM' &&
            typeOfConnection !== 'BOTTOMTOP' &&
            this.checkBottomConnection(p1, p2)
          ) {
            if (typeOfConnection === 'TOP') {
              typeOfConnection = 'BOTTOMTOP';
            } else {
              typeOfConnection = 'BOTTOM';
            }
          }
          if (
            typeOfConnection !== 'TOP' &&
            typeOfConnection !== 'BOTTOMTOP' &&
            this.checkTopConnection(p1, p2)
          ) {
            if (typeOfConnection === 'BOTTOM') {
              typeOfConnection = 'BOTTOMTOP';
            } else {
              typeOfConnection = 'TOP';
            }
          }
        });
        if (typeOfConnection === '' || typeOfConnection === 'BOTTOM') {
          p1.add(
            new Tag(
              {
                x: 5,
                y: -35,
                width: p1.attrs.width,
              },
              p1.type
            )
          );
        } else if (typeOfConnection === 'TOP') {
          p1.add(
            new Tag(
              {
                x: 5,
                y: p1.attrs.height,
                width: p1.attrs.width,
              },
              p1.type
            )
          );
        } else if (typeOfConnection === 'BOTTOMTOP') {
          p1.add(
            new Tag(
              {
                x: 5,
                y: 0,
                width: p1.attrs.width,
              },
              p1.type
            )
          );
        }
        // p1.add(new Tag({
        //     x: 0,
        //     y: 0,
        //     width: p1.attrs.width
        // },
        //     p1.type))
      });
    });
  }

  static changeFixedWidth(
    layers: Layer[],
    bin: Product[],
    fixedWidth: boolean
  ) {
    layers.forEach((layer: Layer) => {
      layer.find('.product').each((p: Product) => {
        p.fixedWidth = fixedWidth;
      });
    });
    if (!bin) {
      return;
    }
    bin.forEach((p: Product) => {
      p.fixedWidth = fixedWidth;
    });
  }

  static applyNewCabins(layers: Layer[], show: boolean) {
    if (layers) {
      layers.forEach((layer: Layer) => {
        layer.find('.product').each((p: Product) => {
          p.newCabinCheck(show);
        });
      });
    }
  }

  static checkRightConnection(p1: Product, p2: Product): boolean {
    return (
      p1.attrs.x + p1.attrs.width === p2.attrs.x &&
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkRightPlacement(p1: Product, p2: Product): boolean {
    return (
      p1.attrs.x + p1.attrs.width <= p2.attrs.x &&
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkRightPlacementMultiwall(
    p1: Product,
    p2: Product,
    l1: Layer,
    l2: Layer
  ): boolean {
    return (
      p1.attrs.x + p1.attrs.width + l1.attrs.x <= p2.attrs.x + l2.attrs.x &&
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkRightConnectionMultiwall(
    p1: Product,
    p2: Product,
    l1: Layer,
    l2: Layer
  ): boolean {
    return (
      p1.attrs.x + p1.attrs.width + l1.attrs.x == p2.attrs.x + l2.attrs.x &&
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkPlacementMultiwall(
    p1: Product,
    p2: Product,
    l1: Layer,
    l2: Layer
  ): boolean {
    return (
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkLeftConnection(p1: Product, p2: Product): boolean {
    return (
      p1.attrs.x === p2.attrs.x + p2.attrs.width &&
      p1.attrs.y + p1.attrs.height > p2.attrs.y &&
      p1.attrs.y < p2.attrs.y + p2.attrs.height
    );
  }

  static checkTopConnection(p1: Product, p2: Product): boolean {
    return (
      p1.attrs.y === p2.attrs.y + p2.attrs.height &&
      p1.attrs.x + p1.attrs.width > p2.attrs.x &&
      p1.attrs.x < p2.attrs.x + p2.attrs.width
    );
  }

  static checkTopCornerConnection(p1: Product, p2: Product): boolean {
    return (
      p1.attrs.y === p2.attrs.y + p2.attrs.height &&
      p1.attrs.x >= p2.attrs.x &&
      p1.attrs.x < p2.attrs.x + p2.attrs.width
    );
  }

  static checkBottomConnection(p1: Product, p2: Product): boolean {
    return (
      p2.attrs.y === p1.attrs.y + p1.attrs.height &&
      p1.attrs.x + p1.attrs.width > p2.attrs.x &&
      p1.attrs.x < p2.attrs.x + p2.attrs.width
    );
  }

  static checkTopConnectionWithoutX(p1: Product, p2: Product): boolean {
    return p1.attrs.y === p2.attrs.y + p2.attrs.height;
  }

  static hasDrawers(p: Product): boolean {
    let b = false;
    p.find('.drawer').each((d: Drawer) => {
      b = true;
    });
    return b;
  }

  static checkHeatshieldNeed(p1: Product): boolean {
    switch (p1.fictionalID) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        return true;
      case 30:
        return true;
      default:
        return false;
    }
  }
}
