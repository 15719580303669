import { Component, OnDestroy } from '@angular/core';
import { LogConsts } from 'src/app/consts/logConsts';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { Product } from 'src/app/model/product';
import { ProductSettings } from 'src/app/model/productSettings';
import { LogService } from 'src/app/services/log.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductUtils } from 'src/app/utils/productUtils';

@Component({
  selector: 'app-product-form-specific',
  templateUrl: './product-form-specific.component.html',
  styleUrls: ['./product-form-specific.component.scss'],
})
export class ProductFormSpecificComponent implements OnDestroy {
  product: Product = new Product(
    {},
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
  gSettings: GeneralSettings;

  generalSettingsEmitterSub;
  productCreatorEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private logService: LogService
  ) {
    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe((settings) => {
        this.gSettings = settings;
      });
    this.productCreatorEmitterSub =
      this.navbarService.productCreatorEmitter.subscribe(
        (productSettings: ProductSettings) => {
          this.product = ProductUtils.createProductFromData(
            productSettings,
            null
          );
        }
      );
  }

  ngOnDestroy(): void {
    this.generalSettingsEmitterSub.unsubscribe();
    this.productCreatorEmitterSub.unsubscribe();
  }

  createProduct(): void {
    this.navbarService.newProductEmitter.emit(this.product);
    if (this.product.isFictional) {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Creating new fictional product')
        .subscribe(() => {});
    } else {
      // tslint:disable-next-line: no-empty
      this.logService
        .postLog(LogConsts.INFO, 'Createing new product')
        .subscribe(() => {});
    }
  }

  onSettingChange(event): void {
    this.product.updateSettings();
  }

  checkValidity(): boolean {
    if (!this.product.settings) {
      return false;
    }
    return this.product.settings.find((setting) => {
      return !setting.value;
    })
      ? true
      : false;
  }
}
