<div class="custom-canvas">
  <div class="d-flex justify-content-end custom-padding">
    <div class="btn-group p-2">
      <button
        *ngIf="allowPicture"
        (click)="togglePicture()"
        class="btn btn-secondary"
      >
        <i-bs *ngIf="showPicture" name="image"></i-bs>
        <i-bs *ngIf="!showPicture" name="image-fill"></i-bs>
      </button>
    </div>
    <div class="btn-group p-2">
      <button
        *ngIf="showAddButton"
        class="btn btn-secondary"
        [disabled]="!product.isValid || checkValidity(product)"
        (click)="addProduct()"
      >
        <i-bs name="plus"></i-bs>
      </button>
    </div>
  </div>
  <div>
    <div [hidden]="showPicture" id="minorContainer" class="custom-canvas"></div>
    <div [hidden]="!showPicture" class="custom-canvas">
      <img [src]="imageToShow" class="custom-picture" />
    </div>
  </div>
</div>
