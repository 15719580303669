import Konva from 'konva';
import { Front } from './front';

export class Shelf extends Konva.Group {
  visibilityType = 'interior';
  solid = false;

  front: Front;

  constructor(
    context: any,
    perspective: boolean,
    Right_Left: string,
    solid: boolean
  ) {
    super(context);
    this.attrs.x = 0;
    this.attrs.name = 'shelf';
    this.solid = solid;
    if (perspective && Right_Left === 'RIGHT') {
      this.drawPerspectiveShelfLeft();
    } else if (perspective && Right_Left === 'LEFT') {
      this.drawPerspectiveShelfRight();
    } else {
      this.drawNormalShelf();
    }
  }

  drawNormalShelf() {
    this.deleteShelf();
    let dash = [16, 8];
    if (this.solid) {
      dash = [];
    }
    this.add(
      new Konva.Line({
        points: [0, 8, this.attrs.width, 8],
        stroke: 'black',
        strokeWidth: 16,
        name: 'shelfStroke',
        dash: dash,
      })
    );
  }

  drawPerspectiveShelfLeft() {
    this.deleteShelf();
    this.deleteShelf();
    let dash = [16, 8];
    if (this.solid) {
      dash = [];
    }
    this.add(
      new Konva.Line({
        points: [0, 8, this.attrs.width, -92],
        stroke: 'black',
        strokeWidth: 16,
        name: 'shelfStroke',
        dash: dash,
      })
    );
  }

  drawPerspectiveShelfRight() {
    this.deleteShelf();
    this.add(
      new Konva.Line({
        points: [0, -92, this.attrs.width, 8],
        stroke: 'black',
        strokeWidth: 16,
        name: 'shelfStroke',
        dash: [16, 8],
      })
    );
  }

  addTopFront(height: number) {
    this.add(new Front({ width: this.attrs.width, height }, null));
  }

  deleteShelf() {
    this.find('.shelfStroke').each((stroke) => {
      stroke.destroy();
    });
    this.find('.rect').each((rect) => {
      rect.destroy();
    });
  }
}
