import { Component, OnDestroy } from '@angular/core';
import { InfoBox } from 'src/app/model/infoBox';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent implements OnDestroy {
  infoBox: InfoBox[] = [
    { text: 'CHECKLIST_TEXT_1', value: false },
    { text: 'CHECKLIST_TEXT_2', value: false },
    { text: 'CHECKLIST_TEXT_3', value: false },
    { text: 'CHECKLIST_TEXT_4', value: false },
    { text: 'CHECKLIST_TEXT_5', value: false },
  ];

  orderStatusService;

  constructor(private navbarService: NavbarService) {
    this.orderStatusService = this.navbarService.orderStatusEmitter.subscribe(
      (status: string) => {
        if (status === 'COMPLETED') {
          this.infoBox.forEach((box) => {
            box.value = true;
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.orderStatusService.destroy();
  }

  checkInfoBox(box: InfoBox): void {
    box.value = !box.value;
  }
}
