import { Product } from '../model/product';
import { Setting } from '../model/setting';

export class MandatorySettingsUtil {
  static getMandatorySettings(product: Product): void {
    product.mandatorySettings = [...new Set(product.mandatorySettings)];
  }

  static checkSettings(product: Product): void {
    const absentSettings = [];
    if (!product.mandatorySettings) {
      return;
    }
    product.mandatorySettings.forEach((msetting: string) => {
      if (
        !product.settings.find((setting: Setting) => {
          return setting.mapping && setting.mapping.name === msetting;
        })
      ) {
        absentSettings.push(msetting);
      }
    });
    product.absentSettings = absentSettings;
  }
}
