<div
  class="modal fade"
  id="binModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered custom-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "BIN" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          #close
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="products.length !== 0" class="list-group">
          <li
            *ngFor="let product of products"
            class="list-group-item custom-list-item container"
          >
            <div class="row custom-row">
              <div class="constainer col-8">
                <div class="row flex-nowrap">
                  <div class="d-flex align-items-center custom-width col-6">
                    <div
                      class="text-truncate d-inline-block text-wrap custom-extra-padding"
                    >
                      {{ product.type }}
                    </div>
                  </div>
                  <div
                    *ngIf="product.additionalInfo"
                    class="d-flex justify-content-start align-items-center flex-wrap custom-sm-padding col-6"
                  >
                    <div
                      *ngIf="product.additionalInfo.color"
                      class="custom-sm-padding"
                    >
                      {{ product.additionalInfo.color }},
                    </div>
                    <div
                      *ngIf="product.additionalInfo.material"
                      class="custom-sm-padding"
                    >
                      {{ product.additionalInfo.material }},
                    </div>
                    <div
                      *ngIf="product.additionalInfo.edge"
                      class="custom-sm-padding"
                    >
                      {{ product.additionalInfo.edge }}
                    </div>
                    <div
                      *ngIf="product.additionalInfo.handle"
                      class="custom-sm-padding"
                    >
                      {{ product.additionalInfo.handle }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end col-4">
                <div class="custom-icon-width custom-extra-padding">
                  <i-bs
                    *ngIf="
                      product.productSettings &&
                      product.productSettings.DOUBLE_DOOR === '2'
                    "
                    class="custom-icon"
                    name="layout-split"
                  ></i-bs>
                </div>
                <div
                  class="btn-group btn-group-lg custom-btn-width"
                  role="group"
                >
                  <button
                    class="btn btn-secondary"
                    [disabled]="!product.isValid || checkValidity(product)"
                    (click)="addProduct(product)"
                  >
                    <i-bs class="" name="plus"></i-bs>
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="editProduct(product)"
                    data-toggle="modal"
                    data-target="#interiorModal"
                  >
                    <i-bs name="tools"></i-bs>
                  </button>
                  <!-- <button class="btn btn-secondary" (click)="deleteProduct(product)">
                                        <i-bs name="trash"></i-bs>
                                    </button> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h6 *ngIf="products.length === 0">
          {{ "NO_PRODUCTS_HERE" | translate }}.
        </h6>
      </div>
    </div>
  </div>
</div>
