<div
  class="modal fade"
  id="minorBinModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "BIN" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          #close
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul *ngIf="products.length !== 0" class="list-group">
          <li
            *ngFor="let product of products"
            class="list-group-item custom-list-item"
          >
            <div
              *ngIf="product.canBeSubproduct"
              class="d-flex justify-content-between"
            >
              <div
                class="d-flex align-items-center custom-extra-padding custom-width"
              >
                <div class="text-truncate d-inline-block text-wrap">
                  {{ product.type }}
                </div>
              </div>
              <div class="btn-group btn-group-lg" role="group">
                <i-bs
                  *ngIf="
                    product.productSettings &&
                    product.productSettings.DOUBLE_DOOR === '2'
                  "
                  class="custom-icon"
                  name="layout-split"
                ></i-bs>
                <button class="btn btn-secondary" (click)="addProduct(product)">
                  <i-bs class="" name="plus"></i-bs>
                </button>
                <!-- [disabled]="!product.isValid || checkValidity(product)" -->

                <!-- <button type="button" class="btn btn-secondary" (click)="editProduct(product)"
                                    data-toggle="modal" data-target="#interiorModal">
                                    <i-bs name="tools"></i-bs>
                                </button> -->
              </div>
            </div>
          </li>
        </ul>
        <h6 *ngIf="products.length === 0">
          {{ "NO_PRODUCTS_HERE" | translate }}.
        </h6>
      </div>
    </div>
  </div>
</div>
