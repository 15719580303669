import Konva from 'konva';
import { DoorUtil } from '../utils/doorUtil';
import { Cabin } from './subproducts/cabin';
import { Door } from './subproducts/door';
import { Entity } from './entity';
import { Drawer } from './subproducts/drawer';
import { Front } from './subproducts/front';
import { Shelf } from './subproducts/shelf';
import { DesignUtils } from '../utils/designUtils';
import { ShelfUtils } from '../utils/shelfUtils';
import { ProductSettings } from './productSettings';
import { Setting } from './setting';
import { PositionNumber } from './subproducts/position';
import { FictionalProduct } from './subproducts/fictional';
import { ProductComment } from './subproducts/comment';
import { Tag } from './subproducts/tag';
import { ValidationCommunicate } from './subvalidators/validationMessage';
import { Zigzag } from './subproducts/zigzag';
import { hideSettingsUtil } from '../utils/hideSettingUtil';
import { DrawerUtils } from '../utils/drawerUtils';
import { MandatorySettingsUtil } from '../utils/mandatorySettingsUtil';
import { SettingTypesConsts } from '../consts/settingTypes';
import { SubProductsUtil } from '../utils/subproductsUtil';
import { Rect } from 'konva/types/shapes/Rect';
import { MeasureLine } from './measureLine';
import { AdditionalProductInfo } from './additionalProductInfo';
import { Mapping } from '../consts/mapping';
import { SpecialParams } from './specialParams';
import { Design } from './design/design';
import { Vector } from 'html2canvas/dist/types/render/vector';
import { Vector2d } from 'konva/types/types';
import { AlertDots } from './subproducts/alertDots';
import { CommentUtil } from '../utils/commentUtil';
import { DekorlistSockel } from './subproducts/dekorlist-sockel';

export class Product extends Entity {

  isProduct: boolean;
  _isValid: boolean;
  isActive: boolean;
  type: string;
  handlePlacement: string;
  vertical: boolean;
  isBottom: boolean;
  bottomPlacement = 0;
  isFictional: boolean;
  fictionalID: number;
  fictionalType: string;
  materialMinHeight: number;
  materialMinWidth: number;
  WIDTH = 0;
  HEIGHT = 0;
  perspective: boolean;
  validationMessages: ValidationCommunicate[];
  hasFixedWidth = false;
  hasFixedHeight = false;
  isVisibleInDrawing = true;
  canBeSubproduct = false;
  mandatorySettings: string[] = [];
  absentSettings: string[] = [];
  fixedWidth: boolean;
  modified = false;
  hasMaterialAlert = false;
  additionalInfo: AdditionalProductInfo;
  sockel: number = 0;

  previousPosition: Vector2d;

  hasNewCabin = false;

  tab: number = 1;

  subcategoryID: number;
  productID: number;

  design: Design;

  positionMarker = 0;

  settings: Setting[];
  productSettings: ProductSettings = new ProductSettings();
  specialParams: SpecialParams = new SpecialParams();

  _doors: Door[] = [];
  _drawers: Drawer[] = [];
  _fronts: Front[] = [];
  _shelves: Shelf[] = [];

  cabin: Cabin; // should be cabins

  standardDoors = true;
  standardDrawers = true;
  standardShelves = true;

  isConnectedToAnother = false;

  commentLeft = false;

  lastInteracted: number[] = []; // used fe. on product disconnection

  subProducts: Product[];

  graphicFileID: number;

  // change constructor
  // stuff like handlePlacement and doors are bad :(
  constructor(
    context: any,
    type: string,
    handlePlacement: string,
    isBottom: boolean,
    settings: Setting[],
    isFictional: boolean,
    isVisibleInDrawing: boolean,
    canBeSubproduct: boolean,
    additionalInfo: AdditionalProductInfo,
    subCategoryID: number,
    productID: number,
    graphicFileID: number,
    design: Design
  ) {
    super(context);

    // most of these should be deleted imo
    this.type = type;
    this.isProduct = true;
    this.isValid = true;
    this.isActive = false;
    this.vertical = true;
    this.attrs.name = 'product';
    this.attrs.draggable = true;
    this.handlePlacement = handlePlacement;
    this.isVisibleInDrawing = isVisibleInDrawing;
    this.additionalInfo = additionalInfo;

    this.isBottom = isBottom;
    this.settings = settings;
    this.isFictional = isFictional;
    this.canBeSubproduct = canBeSubproduct;

    this.subcategoryID = subCategoryID;
    this.productID = productID;

    this.design = design;

    this.graphicFileID = graphicFileID;

    this.previousPosition = { x: context?.x, y: context?.y };

    this.subProducts = [];

    this.updateSettings();
    if (this.design) {
      DesignUtils.updateDesign(this, false);
    }

    this.checkMandatorySettings();
    if (this.settings) {
      this.settings.forEach((setting: Setting) => {
        setting.applyMinMax();
        setting.applyOdds(this.settings);
      });
    }
    this.checkIsModified();

  }

  get isValid(): boolean {
    return this._isValid;
  }

  set isValid(x) {
    this._isValid = x;
    this.updateBorderColors();
  }

  get doors(): Door[] {
    return this._doors;
  }

  set doors(value: Door[]) {
    if (value) {
      this._doors = value;
    } else {
      this._doors = [];
    }
  }

  get drawers(): Drawer[] {
    return this._drawers;
  }

  set drawers(value: Drawer[]) {
    if (value) {
      this._drawers = value;
    } else {
      this._drawers = [];
    }
  }

  get fronts(): Front[] {
    return this._fronts;
  }

  set fronts(value: Front[]) {
    if (value) {
      this._fronts = value;
    } else {
      this._fronts = [];
    }
  }

  get shelves(): Shelf[] {
    return this._shelves;
  }

  set shelves(value: Shelf[]) {
    if (value) {
      this._shelves = value;
    } else {
      this._shelves = [];
    }
  }


  getTotalHeightWithSubs() {
    let x = 0;
    if (this.subProducts && this.subProducts.length > 0) {
      this.subProducts.forEach((s: Product) => {
        x += s.attrs.height + 50;
      });
    }
    return this.attrs.height + x;
  }

  updateOnClick(connectToAnother: boolean) {
    console.log(this, connectToAnother);
    if (connectToAnother) {
      this.isConnectedToAnother = !this.isConnectedToAnother;
    } else {
      this.isConnectedToAnother = false;
      this.isActive = !this.isActive;
    }
    this.updateBorderColors();
    this.updateComment();
    this.moveToTop();
  }

  updateBorderColors() {
    if (this.isConnectedToAnother) {
      this.attrs.stroke = 'blue';
    } else if (this.isActive) {
      this.attrs.stroke = 'green';
    } else if (!this.modified && this.modified !== undefined) {
      this.attrs.stroke = 'orange';
    } else if (this.hasNewCabin) {
      this.attrs.stroke = 'purple';
    } else if (!this.isValid) {
      this.attrs.stroke = 'red';
    } else {
      this.attrs.stroke = 'black';
    }
    this.find('.prodStroke').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
    this.find('.drawerStroke').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
    this.find('.handle').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
    this.find('.hinge').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
    this.find('.tag').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
    this.find('.shelfStroke').each((stroke) => {
      stroke.attrs.stroke = this.attrs.stroke;
    });
    this.find('.positionMarkerLabel').each((stroke) => {
      stroke.attrs.fill = this.attrs.stroke;
    });
  }

  addLastInteracted(id: number) {
    if (!this.checkLastInteracted(id)) {
      this.lastInteracted.push(id);
    }
  }

  removeLastInteracted(id: number) {
    if (this.checkLastInteracted(id)) {
      this.lastInteracted = this.lastInteracted.filter((n: number) => n !== id);
    }
  }

  removeInteractions() {
    this.lastInteracted = [];
  }

  checkLastInteracted(id: number): boolean {
    return this.lastInteracted.find((n: number) => n === id) ? true : false;
  }

  checkIsModified() {
    if (this.modified) {
      return;
    } else if (this.isFictional) {
      this.modified = true;
    } else if (this.settings) {
      if (
        this.settings.find((s: Setting) => {
          if (s.mapping && s.mapping.name === Mapping.POSITIONNUMBER) {
            return false;
          }
          return s.modified;
        })
      ) {
        this.modified = true;
      }
    }
  }

  updatePreviousPosition(): void {
    this.previousPosition = { x: this.attrs.x, y: this.attrs.y };
  }

  updatePositionMarker(upsideDown) {
    if (this.isFictional) {
      return;
    }
    this.deletePositionMarker();
    let x = false;
    if (this.drawers && this.drawers.length > 0) {
      x = true;
    }
    this.add(
      new PositionNumber(this.attrs, this.positionMarker, upsideDown, x)
    );
  }

  updateDoors() {
    if (!this.doors) {
      return;
    }

    // /experimental
    if (this.standardDoors) {
      DoorUtil.doorSizeCalc(this);
    }
    // this should be calculated inside the door class
    const handlePlacements = DoorUtil.handlePlacementCalc(this); // if door is standard. else this actions should be prevented
    const hingePlacements = DoorUtil.hingesPlacementCalc(this);
    if (this.doors) {
      this.doors.forEach((door: Door, i) => {
        this.add(door);

        // const vPlacement = this.productSettings.RIGHT_LEFT === 'VERTICAL' ? true : false;
        if (
          this.productSettings.HANDLES_YES_NO !== 'NO' &&
          !(this.productSettings.TIPON1 && this.productSettings.TIPON1 === '1')
        ) {
          door.drawHandle(
            handlePlacements[i],
            this.productSettings.HANDLE_LAT_HORZ,
            this.productSettings.HANDLES_TYPE
          );
        }
        if (this.productSettings.TIPON1 && this.productSettings.TIPON1 == '1') {
          door.drawTipon(handlePlacements[i]);
        }
        if (hingePlacements && hingePlacements.length > 0) {
          door.drawHinges(hingePlacements[i]);
        }
        door.drawDoor();
      });
    }
  }

  removeDoor() {
    if (!this.doors) {
      this.doors = [];
    }
    this.doors.pop(); // change this to splice maybe / or disable door removal
    if (!this.doors) {
      this.doors = [];
    }
    this.updateDoors();
  }

  updateDrawers() {
    const handlePlacements = DrawerUtils.handlePlacementCalc(this);
    if (this.productSettings.HANDLES_YES_NO === 'YES') {
      this.find('.drawer').each((drawer: Drawer, i) => {
        if (i === 0 && this.productSettings.BLINDFRONT_HANDLE === 'NO') {
          return;
        }
        drawer.addHandle(
          handlePlacements[i],
          this.productSettings.HANDLE_LAT_HORZ,
          this.productSettings.HANDLES_TYPE
        );
        // drawer.addFront();
      });
    }
  }

  addDrawer(type: string) {
    if (!this.drawers) {
      this.drawers = [new Drawer({ x: 0, y: 0 }, type)];
    } else {
      this.drawers.push(new Drawer({ x: 0, y: 0 }, type));
    }
  }

  addShelf() {
    if (!this.shelves) {
      this.shelves = [
        new Shelf(
          { x: 0, y: 0 },
          this.perspective,
          this.productSettings.RIGHT_LEFT,
          false
        ),
      ];
    } else {
      this.shelves.push(
        new Shelf(
          { x: 0, y: 0 },
          this.perspective,
          this.productSettings.RIGHT_LEFT,
          false
        )
      );
    }
    this.updateShelves();
  }

  updateShelves() {
    this.deleteShelves();

    if (this.standardShelves) {
      this.shelves = ShelfUtils.spicerackShelfPlacementCalc(this);
    } else {
      // this.shelves = ShelfUtils.endingshelfShelfPlacementCalc(this);
    }

    if (this.shelves) {
      this.shelves.forEach((shelf) => {
        this.add(shelf);
      });
    }
  }

  addFront(placement: string) {
    // put it in some util
    if (!this.fronts) {
      this.fronts = [new Front({ x: 0, y: 0 }, placement)];
    } else {
      this.fronts.push(new Front({ x: 0, y: 0 }, placement));
    }
  }


  addFictional() {
    if (this.fictionalID && this.isFictional) {
      this.add(new FictionalProduct({ ...this.attrs }, this.fictionalID));
    }
  }


  updateSettings() {
    if (!this.settings) {
      return;
    }

    // try to find way not to go through every setting at least two times
    this.settings.forEach((setting: Setting) => {
      setting.applyMinMax();
      setting.applyOdds(this.settings);
    });

    this.settings
      ? this.settings
          .filter(
            (setting: Setting) => setting.type === SettingTypesConsts.CALCULATED
          )
          .forEach((setting: Setting) => {
            setting.calculate(this.settings);
          })
      : null;
    // append setting values to corelated values

    this.settings.forEach((setting: Setting) => {
      setting.mapping
        ? (this.productSettings[setting.mapping.name] = setting.value)
        : 0;
    });

  }

  updateDimensions(): void {
    const leftHeatshield =
      this.productSettings.HEATSHIELD_LEFTSIDE === '12' ? 12 : 0;
    const rightHeatshield =
      this.productSettings.HEATSHIELD_RIGHTSIDE === '12' ? 12 : 0;
    if (this.fixedWidth) {
      this.attrs.width = this.WIDTH;
    } else {
      this.WIDTH += leftHeatshield + rightHeatshield;
      this.attrs.width = this.WIDTH;
      // this.attrs.width = this.WIDTH + leftHeatshield + rightHeatshield;
    }

    if (this.productSettings.HAS_SOCKEL === 'YES') {
      this.attrs.height = this.HEIGHT + this.sockel;
    } else if (this.productSettings.HAS_DEKORLIST === 'YES') {
      this.attrs.height = this.HEIGHT + 70;
    } else {
      this.attrs.height = this.HEIGHT;
    }
  }

  changeValue(settingName: string, value: any, dontUpdate?: boolean) {
    if (!this.settings) {
      return;
    }
    this.settings.forEach((setting: Setting) => {
      if (setting.mapping && setting.mapping.name === settingName) {
        setting ? (setting.value = value) : null;
        if (!dontUpdate) {
          this.updateSettings();
        }
        setting.isUnfrozing = false;
        setting.modified = true;
      }
      setting.checkIsChanged();
    });

  }

  unfreezeSettings() {
    this.settings.forEach((setting: Setting) => {
      setting.unFreeze();
    });
  }

  updatePerspective() {
    if (this.productSettings.COVERSIDE_PERSPECTIVE === 'SIDE') {
      this.perspective = true;
    } else {
      this.perspective = false;
    }
  }

  updatePositioning() {
    if (!this.isFictional) {
      switch (this.productSettings.TOP_BOTTOM) {
        case 'TOP': // wall
          this.isBottom = true;
          this.handlePlacement = 'TOP';
          break;
        case 'BOTTOM': // bench (bottom)
          this.isBottom = false;
          this.handlePlacement = 'BOTTOM';
          break;
        case 'MIDDLE': // switchable
          this.isBottom = false;
          this.handlePlacement = 'MIDDLE';
          break;
      }
    }
  }

  checkMandatorySettings() {
    MandatorySettingsUtil.getMandatorySettings(this);
    MandatorySettingsUtil.checkSettings(this);
  }

  updateDots() {
    this.deleteDots();
    this.hasMaterialAlert = this.checkForAlertDots();
    if (this.hasMaterialAlert) {
      CommentUtil.addPhrase(
        this,
        'Valt material stöder inte dessa dimensioner. \n'
      );
    } else {
      CommentUtil.removePhrase(
        this,
        'Valt material stöder inte dessa dimensioner. \n'
      );
    }
    this.add(
      new AlertDots(
        this.attrs,
        !!this.productSettings.COMMENTS,
        this.hasMaterialAlert
      )
    );
  }

  checkForAlertDots(): boolean {
    if (this.materialMinHeight) {
      let x = false;
      this.find('.front').each((f: Front) => {
        if (f.attrs.height < this.materialMinHeight) {
          x = true;
        }
      });
      if (x) {
        return true;
      }
      this.find('.door').each((d: Door) => {
        if (d.attrs.height < this.materialMinHeight) {
          x = true;
        }
      });
      if (x) {
        return true;
      }
    }

    if (this.materialMinWidth) {
      let x = false;
      this.find('.front').each((f: Front) => {
        if (f.attrs.width < this.materialMinWidth) {
          x = true;
        }
      });
      if (x) {
        return true;
      }
      this.find('.door').each((d: Door) => {
        if (d.attrs.width < this.materialMinWidth) {
          x = true;
        }
      });
      if (x) {
        return true;
      }
    }

    return false;
  }

  updateComment() {

    this.deleteComment();
    if (this.productSettings.COMMENTS) {
      this.add(
        new ProductComment(
          this.attrs,
          this.productSettings.COMMENTS,
          this.isActive,
          this.commentLeft
        )
      );
    }
  }

  updateDekorlistSockel(leftSide = false, rightSide = false) {
    this.deleteDekorlistSockel();
    if (this.productSettings.HAS_DEKORLIST === 'YES') {
      this.add(
        new DekorlistSockel(
          {},
          70,
          this.WIDTH,
          this.HEIGHT,
          true,
          leftSide,
          rightSide
        )
      );
    }
    if (this.productSettings.HAS_SOCKEL === 'YES') {
      this.add(
        new DekorlistSockel(
          {},
          this.sockel,
          this.WIDTH,
          this.HEIGHT,
          false,
          leftSide,
          rightSide
        )
      );
    }
  }

  updateSubproductIndicator() {
    this.deleteSubproductIndicator();
    if (this.subProducts.length > 0) {
      this.add(
        new Konva.Rect({
          x: 25,
          y: 25,
          width: 40,
          height: 40,
          fill: '	rgb(156,91,156)',
          name: 'subproductIndicator',
          cornerRadius: [5, 5, 5, 5],
          opacity: 0.8,
        })
      );
    }
  }

  updateTags() {
    this.find('.tag').each((tag: Tag) => {
      tag.moveToTop();
      tag.drawTag();
    });
  }

  newCabinCheck(showNewCabin) {
    if (showNewCabin && this.productSettings.NEW_CABIN === 'YES') {
      this.hasNewCabin = true;
    } else {
      this.hasNewCabin = false;
    }
  }

  getSettingValue(mapping: string): any {
    const x = this.settings.find((s: Setting) => {
      return s.mapping && s.mapping.name === mapping;
    });
    return x ? x.value : null;
  }

  getSetting(mapping: string): Setting {
    const x = this.settings.find((s: Setting) => {
      return s.mapping && s.mapping.name === mapping;
    });
    return x ? x : null;
  }

  updateSubproducts() {
    this.find('.product').each((p: Product) => {
      p.destroy();
    });
    let height = this.attrs.height + 50;
    this.subProducts.forEach((p: Product) => {
      p.isActive = false;
      p.position({ x: 0, y: height });
      p.attrs.x = 0;
      p.attrs.y = height;
      height += p.attrs.height + 50;
      p.updateProduct();
      // p.deleteCabin();
      this.add(p);
    });
  }


  deleteCabin(): void {
    this.find('.cabin').each((cabin) => {
      cabin.destroy();
    });
  }

  deleteDoors() {
    this.find('.door').each((door: Door) => {
      door.destroy();
    });
  }

  deleteDrawers() {
    this.find('.drawer').each((drawer: Drawer) => {
      drawer.destroy();
    });
  }

  deleteShelves() {
    this.find('.shelf').each((shelf: Shelf) => {
      shelf.destroy();
    });
  }

  deletePositionMarker() {
    this.find('.positionMarker').each((pos: PositionNumber) => {
      pos.destroy();
    });
  }

  deleteDots() {
    this.find('.dots').each((dot: AlertDots) => {
      dot.destroy();
    });
  }

  deleteFictional() {
    this.find('.fictional').each((fic: FictionalProduct) => {
      fic.destroy();
    });
  }

  deleteSubproductIndicator() {
    this.find('.subproductIndicator').each((r: Rect) => {
      r.destroy();
    });
  }

  deleteFronts() {
    this.find('.front').each((front: Front) => {
      front.destroy();
    });
  }

  deleteComment() {
    this.find('.comment').each((comment: ProductComment) => {
      comment.destroy();
    });
  }

  deleteZigzag() {
    this.find('.zigzag').each((zigzag: Zigzag) => {
      zigzag.destroy();
    });
  }

  deleteCustomRect() {
    this.find('.customRect').each((c: Rect) => {
      c.destroy();
    });
  }

  deleteDekorlistSockel() {
    this.find('.dekorlistSockel').each((d: Konva.Rect) => {
      d.destroy();
    });
  }

  clearMeasurements() {
    this.find('.measurement-line').each((measurement: MeasureLine) => {
      measurement.destroy();
    });
  }

  updateProduct() {
    this.updatePerspective();
    hideSettingsUtil.hideUnusedSettings(this);
    DesignUtils.updateDesign(this, false);
    this.updateDimensions();
    this.updateDrawers();
    this.updatePositioning();
    this.updateBorderColors();
    this.updateComment();
    this.updateDots();
    this.updateSubproductIndicator();
    this.updateShelves();
    this.updateTags();
    this.checkIsModified();
  }

}
