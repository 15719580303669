import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageConsts } from '../consts/storage';

@Injectable({
  providedIn: 'root',
})
export class ProductOrderService {
  LoadOrderEmitter = new EventEmitter<any>();

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  getProductOrder(productOrderId: string): Observable<any> {
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + token
    );

    return this.httpClient.get(environment.productOrderUrl + productOrderId, {
      headers,
    });
  }

  setProductOrder(data): Observable<any> {
    console.log('set order', data);
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'charset=utf-8');

    return this.httpClient.post(environment.updateProductOrderUrl, data, {
      headers,
    });
  }

  setAutoProductOrder(data): Observable<any> {
    console.log('set order', data);
    const token = this.storage.get(StorageConsts.ACCESS_TOKEN);
    const headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'charset=utf-8');

    return this.httpClient.post(environment.autoUpdateProductOrderUrl, data, {
      headers,
    });
  }

  orderSent() {
    const prodId: string = this.storage.get(StorageConsts.ORDER_ID);
    if (prodId) {
      window.location.href = environment.showProductOrder + prodId;
    }
  }
}
