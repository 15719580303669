<div
  class="modal fade"
  id="configurationModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "CONFIGURATION" | translate }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body d-flex flex-column list-group">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "SNAP" | translate }}
          </label>

          <select class="col-sm-6 form-control" [(ngModel)]="config.snap">
            <option *ngFor="let option of snapOptions" value="{{ option }}">
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "CLOSE_AFTER_ADDING" | translate }}
          </label>

          <select
            class="col-sm-6 form-control"
            [(ngModel)]="config.closeBinOnClick"
          >
            <option *ngFor="let option of closeOptions" value="{{ option }}">
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "AUTOMATIC_HEATSHIELDS" | translate }}
          </label>

          <select
            class="col-sm-6 form-control"
            [(ngModel)]="config.automaticHeatshields"
          >
            <option *ngFor="let option of heatshields" value="{{ option }}">
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "FIXED_WIDTH" | translate }}
          </label>

          <select
            class="col-sm-6 form-control"
            [(ngModel)]="config.fixedDimensions"
          >
            <option *ngFor="let option of fixedDimensions" value="{{ option }}">
              {{ option }}
            </option>
          </select>
        </div>

        <!-- <div class="form-group row">
                    <label class="col-sm-5 col-form-label">
                        {{'AUTO_WIDTH_SOCKEL' | translate}}
                    </label>

                    <select class="col-sm-6 form-control" [(ngModel)]="config.autoWidthSockel">
                        <option *ngFor="let option of autoWidthSockel" value="{{option}}">
                            {{option}}</option>
                    </select>
                </div> -->

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "AUTO_WIDTH_DEKORLIST" | translate }}
          </label>

          <select
            class="col-sm-6 form-control"
            [(ngModel)]="config.autoWidthDekorlist"
          >
            <option
              *ngFor="let option of autoWidthDekorlist"
              value="{{ option }}"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            {{ "AUTO_SAVE" | translate }}
          </label>

          <select class="col-sm-6 form-control" [(ngModel)]="config.autoSave">
            <option *ngFor="let option of autoSave" value="{{ option }}">
              {{ option }}
            </option>
          </select>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="update()"
        >
          {{ "SAVE_SETTINGS" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
