export enum Actions {
  SAVE = 'save',
  AUTOSAVE = 'autoSave',
  SAVE_LOCALLY = 'saveLocally',
  LOAD_LOCAL = 'loadLocal',
  SAVE_ORDER = 'saveOrder',
  SAVE_AND_RETURN = 'saveAndReturn',
  SAVE_AND_PDF = 'saveAndPdf',
  SAVE_COMPLETE = 'saveComplete',
  SAVE_FAILED = 'saveFailed',
  REAUTHORIZE = 'reauthorize',
  REAUTHORIZE_AND_SAVE = 'reauthorize_and_save',
  CANCEL = 'cancel',
  DELETE = 'delete',
  COPY = 'copy',
  REDRAW = 'redraw',
  RETURN_TO_HK = 'returnToHK',
  ZOOM = 'zoom',
  CLEANUP_LAYERS = 'cleanupLayers',
  CREATE_STAGE = 'createStage',
  GENERATE_PDF = 'generate_pdf',
  UPDATE_MINOR_PRODUCT = 'update_minor_product',
  UPDATE_LOCAL_SAVE = 'update_local_save',
  PDF_ONLY = 'pdf_only',
  WALL_EDIT = 'wall_edit',
  CLICK = 'click',
  ACTIVATE_PDF_ONLY = 'activate_pdf_only',
}
