<div class="fixed-bottom d-flex flex-row-reverse">
  <div class="custom badge badge-secondary fixed-right">
    <div class="d-flex flex-column">
      <button type="button" class="btn btn-secondary" (click)="saveOrder()">
        {{ "SAVE_ORDER" | translate }}
      </button>
      <!-- <button type="button" class="btn btn-secondary" (click)="saveLocally()">{{'SAVE_LOCALLY' | translate}}</button> -->
      <!-- <button type="button" class="btn btn-secondary" (click)="loadLocal()">{{'LOAD_LOCAL_SAVE' | translate}}</button> -->
    </div>
  </div>
</div>
