import { Group } from 'konva/types/Group';
import { Layer } from 'konva/types/Layer';
import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';
import { Door } from '../model/subproducts/door';
import { Drawer } from '../model/subproducts/drawer';
import { Front } from '../model/subproducts/front';

export class PositionNumberUtil {
  static setPositionNumbers(layers: Layer[]) {
    let number = 0;
    layers.forEach((layer: Layer) => {
      const bottomArray = [];
      const topArray = [];
      const tempArr = [];
      layer.find('.product').each((product: Product) => {
        if (!product.isFictional) {
          if (product.isBottom) {
            bottomArray.push(product);
          } else {
            topArray.push(product);
          }
        }
      });
      topArray
        .sort((a: Product, b: Product) => {
          if (a.attrs.y !== b.attrs.y) {
            return a.attrs.y - b.attrs.y;
          } else {
            return a.attrs.x - b.attrs.x;
          }
        })
        .concat(
          bottomArray.sort((a: Product, b: Product) => {
            if (a.attrs.y + a.attrs.height !== b.attrs.y + b.attrs.height) {
              return a.attrs.y + a.attrs.height - (b.attrs.y + b.attrs.height);
            } else {
              return a.attrs.x - b.attrs.x;
            }
          })
        )
        .forEach((p: Product) => {
          let index = 0;
          p.positionMarker = number;
          const startingNumber = number + 1;
          let endingNumber = number + 1;
          let upsideDown = false;
          if (p.productSettings.DESIGN_OF_PRODUCT === 'CUTTINGBOARD') {
            upsideDown = true;
          }

          p.children.each((child: any) => {
            if (child.attrs.name === 'door') {
              number += 1;
              index += 1;
              child.positionMarker = number;
              child.updatePositionMarker(upsideDown);
            }
          });

          p.find('.front').each((front: Front) => {
            number += 1;
            index += 1;
            front.positionMarker = number;
            front.updatePositionMarker(upsideDown);
          });

          if (index === 0) {
            number += 1;
            index += 1;
            p.positionMarker = number;
            p.updatePositionMarker(upsideDown);
          }

          endingNumber = number;

          // // update pos
          if (startingNumber !== endingNumber) {
            p.changeValue(
              Mapping.POSITIONNUMBER,
              startingNumber + '-' + endingNumber,
              true
            );
            p.productSettings.POSITIONNUMBER =
              startingNumber + '-' + endingNumber;
          } else {
            p.changeValue(Mapping.POSITIONNUMBER, startingNumber, true);
            p.productSettings.POSITIONNUMBER = startingNumber;
          }

          if (p.subProducts && p.subProducts.length > 0) {
            p.subProducts.forEach((s: Product) => {
              const subStartingNumber = number + 1;

              s.children.each((child: any) => {
                if (child.attrs.name === 'door') {
                  number += 1;
                  index += 1;
                  child.positionMarker = number;
                  child.updatePositionMarker(upsideDown);
                }
              });

              s.find('.front').each((front: Front) => {
                number += 1;
                index += 1;
                front.positionMarker = number;
                front.updatePositionMarker(upsideDown);
              });

              if (number === endingNumber) {
                number += 1;
                s.positionMarker = number;
                s.updatePositionMarker(upsideDown);
              }

              const subEndingNumber = number;
              if (subStartingNumber !== subEndingNumber) {
                s.changeValue(
                  Mapping.POSITIONNUMBER,
                  subStartingNumber + '-' + subEndingNumber,
                  true
                );
                s.productSettings.POSITIONNUMBER =
                  startingNumber + '-' + endingNumber;
              } else {
                s.changeValue(Mapping.POSITIONNUMBER, subStartingNumber, true);
                s.productSettings.POSITIONNUMBER = startingNumber;
              }
            });
          }
        });
    });
  }

  static sortByPositionnumber(products: Product[]): Product[] {
    return products.sort((a: Product, b: Product) => {
      if (
        a.productSettings.POSITIONNUMBER &&
        b.productSettings.POSITIONNUMBER
      ) {
        return (
          a.productSettings.POSITIONNUMBER.toString().split('-')[0] -
          b.productSettings.POSITIONNUMBER.toString().split('-')[0]
        );
      } else {
        return a.attrs.x - b.attrs.x;
      }
    });
  }
}
