import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Actions } from 'src/app/consts/actions';
import { StorageConsts } from 'src/app/consts/storage';
import { ValidationCommunicate } from 'src/app/model/subvalidators/validationMessage';
import { Validation } from 'src/app/model/validation';
import { AuthorisationService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';
import { ValidationService } from 'src/app/services/validation.service';
import { SaveUtil } from 'src/app/utils/saveUtil';

@Component({
  selector: 'app-back-to-hk',
  templateUrl: './back-to-hk.component.html',
  styleUrls: ['./back-to-hk.component.scss'],
})
export class BackToHkComponent implements OnInit, OnDestroy {
  tab = 0;

  errorMessages: ValidationCommunicate[] = [];
  unvalidatedMessages: ValidationCommunicate[] = [];
  validatedMessages: ValidationCommunicate[] = [];
  simpleValidations: Validation[] = [];
  simpleValidationsUnchecked = 0;
  editDrawing: boolean;

  activatePdfOnly = false;

  validationMessagesEmitterSubscriber: any;
  simpleValidationEmitterSubscriber: any;
  stageActionEmitterSubscriber: any;

  localData: any;

  constructor(
    private validationService: ValidationService,
    private navbarService: NavbarService,
    private storage: LocalStorageService,
    private productService: ProductService,
    private authService: AuthorisationService,
    private router: Router
  ) {
    this.authService.permissionsEmitter.subscribe((permissions: any) => {
      if (permissions) {
        this.editDrawing = permissions.editDrawing;
      }
    });
  }

  ngOnInit(): void {
    this.localData = SaveUtil.extractActiveOrderID(
      this.productService.loadLocalSave(),
      this.storage.get(StorageConsts.ORDER_ID)
    );

    this.validationMessagesEmitterSubscriber =
      this.validationService.validationMessagesEmitter.subscribe(
        (messages: ValidationCommunicate[]) => {
          this.sortMessages(messages);
        }
      );

    this.simpleValidationEmitterSubscriber =
      this.validationService.simpleValidationEmitter.subscribe((x) => {
        this.simpleValidations = x;
        this.checkSimpleValidations();
      });
    this.stageActionEmitterSubscriber =
      this.navbarService.stageActionEmitter.subscribe((x) => {
        if (x === Actions.UPDATE_LOCAL_SAVE) {
          this.localData = SaveUtil.extractActiveOrderID(
            this.productService.loadLocalSave(),
            this.storage.get(StorageConsts.ORDER_ID)
          );
        }
        if (x === Actions.ACTIVATE_PDF_ONLY) {
          this.activatePdfOnly = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.validationMessagesEmitterSubscriber.unsubscribe();
    this.simpleValidationEmitterSubscriber.unsubscribe();
    this.stageActionEmitterSubscriber.unsubscribe();
  }

  loadLocal(num): void {
    this.navbarService.loadActionEmitter.emit(num);
  }

  save(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_ORDER);
  }

  returnAndCreatePdf(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_AND_PDF);
  }

  returnAndSave(): void {
    this.navbarService.stageActionEmitter.emit(Actions.SAVE_AND_RETURN);
  }

  returnWithoutSaving(): void {
    this.navbarService.stageActionEmitter.emit(Actions.RETURN_TO_HK);
  }

  pdfOnly(): void {
    this.navbarService.stageActionEmitter.emit(Actions.PDF_ONLY);
  }

  setTab(tab): void {
    this.tab = tab;
  }

  checkboxChecked(): void {
    this.checkSimpleValidations();
  }

  logout(): void {
    this.navbarService.spinnerActionEmitter.emit({
      action: 'add',
      text: 'LOGOUT',
    });
    this.storage.set(StorageConsts.ACCESS_TOKEN, '');
    this.storage.set(StorageConsts.REFRESH_TOKEN, '');
    this.navbarService.stageActionEmitter.emit(Actions.RETURN_TO_HK);
  }

  selectAllCheckboxes(): void {
    if (!this.simpleValidations) {
      return;
    }
    if (this.simpleValidationsUnchecked === 0) {
      this.simpleValidations.forEach((val: Validation) => {
        val.checkSimpleValidation();
      });
    } else {
      this.simpleValidations.forEach((val: Validation) => {
        if (!val.simpleValidationChecked) {
          val.checkSimpleValidation();
        }
      });
    }
    this.checkSimpleValidations();
  }

  checkSimpleValidation(val: Validation): void {
    val.checkSimpleValidation();
    this.checkSimpleValidations();
  }

  checkSimpleValidations(): void {
    if (!this.simpleValidations) {
      return;
    }
    let x = this.simpleValidations.length;
    this.simpleValidations.forEach((sv: Validation) => {
      if (sv.simpleValidationChecked) {
        x -= 1;
      }
    });

    this.simpleValidationsUnchecked = x;
  }

  sortMessages(messages: ValidationCommunicate[]): void {
    this.errorMessages = [];
    this.unvalidatedMessages = [];
    this.validatedMessages = [];

    if (!messages) {
      return;
    }
    messages.forEach((message) => {
      if (message.isError) {
        this.errorMessages.push(message);
      }
      if (message.isValidated) {
        this.validatedMessages.push(message);
      }
      if (message.isUnvalidated) {
        this.unvalidatedMessages.push(message);
      }
    });
  }

  convertDate(n: number): string {
    const dateObject = new Date(n);
    return dateObject.toLocaleString();
  }
}
