import { Component, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Actions } from 'src/app/consts/actions';
import { StorageConsts } from 'src/app/consts/storage';
import { AuthorisationService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductOrderService } from 'src/app/services/productorder.service';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnDestroy {
  orderId = '';

  permissionsEmitterSub;
  orderEmitterSub;
  actionEmitterSub;
  userName: string;

  saveTime: string;
  saveUpdated = false;

  constructor(
    private storage: LocalStorageService,
    private auth: AuthorisationService,
    private productOrderService: ProductOrderService,
    private navbarService: NavbarService
  ) {
    this.permissionsEmitterSub = auth.permissionsEmitter.subscribe((x) => {
      this.userName = x.userName;
    });
    
    this.orderEmitterSub = this.productOrderService.LoadOrderEmitter.subscribe(
      (order: any) => {
        if (order.updated) {
          this.saveTime = new Date(order.updated).toLocaleString();
        } else {
          this.saveTime = null;
        }
        if (order.quoteNumber) {
          this.orderId = order.quoteNumber;
        } else {
          this.orderId = this.storage.get(StorageConsts.ORDER_ID);
        }
      }
    );

    this.actionEmitterSub = this.navbarService.stageActionEmitter.subscribe(
      (action: string) => {
        if (action === Actions.SAVE_COMPLETE) {
          this.saveTime = new Date(Date.now()).toLocaleString();
          this.saveUpdated = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.permissionsEmitterSub.unsubscribe();
    this.orderEmitterSub.unsubscribe();
    this.actionEmitterSub.unsubscribe();
  }
}
