import { option } from 'ngx-bootstrap-icons';
import { Mapping } from '../consts/mapping';
import { SettingUtil } from '../utils/settingUtil';
import { Option } from './subsettings/option';
import { SettingValueItem } from './subsettings/settingValueItem';

export class Setting {
  id: number;
  valueItems: SettingValueItem[];
  name: string;
  mapping: any;
  type: string;
  dataType: string;
  value: any;
  previousValue: any;
  settingType: string;
  settingOptionSet: Option[];
  numericRoundType: string;
  numericType: string;
  numericCalculationMax: number;
  numericMax: number;
  numericMin: number;
  showOverrideButton = false;
  override: boolean;
  edge: number;
  sortOrder: number;
  show: boolean;
  readonly: boolean;
  isUnfrozing = false;
  isFrozen = false;
  initialValue: any;
  isChanged: boolean;
  modified: boolean;
  fictional: boolean;

  constructor(
    id,
    name,
    mapping,
    type,
    value,
    valueItems,
    dataType,
    settingType,
    options,
    numericRoundType,
    numericType,
    numericCalculationMax,
    numericMax,
    numericMin,
    override,
    edge,
    sortOrder,
    show,
    readonly,
    modified,
    previousValue,
    fictional?
  ) {
    this.id = id;
    this.name = name;
    this.mapping = mapping;
    this.type = type;
    this.value = value;
    this.valueItems = valueItems;
    this.dataType = dataType;
    this.settingType = settingType;
    this.settingOptionSet = options;
    this.numericRoundType = numericRoundType;
    this.numericType = numericType;
    this.numericCalculationMax = numericCalculationMax;
    this.numericMax = numericMax;
    this.numericMin = numericMin;
    this.override = override;
    this.edge = Number(edge);
    this.sortOrder = sortOrder;
    this.show = show;
    this.readonly = readonly;
    this.initialValue = value;
    this.fictional = fictional;
    if (previousValue) {
      this.previousValue = Number(previousValue);
    } else {
      this.previousValue = Number(value);
    }
    this.modified = modified;
    this.isChanged = false;
    if (this.settingOptionSet) {
      this.settingOptionSet.forEach((o: Option) => (o.active = true));
    }
  }

  parseDataType(dataType: string): string {
    switch (dataType) {
      case 'NUMERIC':
        return 'number';
      case 'TEXT':
        return 'text';
    }
    return 'number';
  }

  calculate(settings: Setting[]) {
    let op = '';
    if (!this.valueItems) {
      return;
    }
    this.valueItems.forEach((item) => {
      switch (item.type) {
        case 'ARITHMETIC':
          op += this.parseArithmetics(item.item);
          break;
        case 'SETTING':
          op += this.getSettingValue(item.item, settings);
          break;
        case 'VALUE':
          op += item.item;
          break;
        case 'EDGE':
          op += this.edge;
          break;
      }
    });

    if (!op.search('.*/0([^.]|$|.(0{4,}.*|0{1,4}([^0-9]|$))).*')) {
      op = '0';
    }

    if (this.isFrozen) {
      return;
    }

    if (Number(op) === 0) {
      console.log('setting zeroed', this.name);
      op = '0';
    }

    if (this.numericType === 'INTEGER') {
      if (this.numericRoundType === 'FLOOR') {
        this.value = Math.floor(this.parse(op));
      } else if (this.numericRoundType === 'CEIL') {
        this.value = Math.ceil(this.parse(op));
      } else {
        this.value = Math.round(this.parse(op));
      }
    } else {
      this.value = Math.round(this.parse(op) * 100) / 100;
    }
  }

  parseArithmetics(arithmetic): string {
    switch (arithmetic) {
      case 'PLUS':
        return '+';
      case 'MINUS':
        return '-';
      case 'DIVIDE':
        return '/';
      case 'MULTIPLY':
        return '*';
      case 'RIGHT_PARENTHESIS':
        return ')';
      case 'LEFT_PARENTHESIS':
        return '(';
      case 'POWER':
        return '**';
      case 'SQRT':
        return 'Math.sqrt';
    }
    return null;
  }

  getSettingValue(s, settings: Setting[]): string {
    const x = settings.find((setting) => setting.id == s);
    if (x) {
      if (x.isUnfrozing) {
        this.isFrozen = false;
      }

      x.calculate(settings);
      if (x) {
        const xx = x.getValue();
        if (xx >= 0) {
          return xx.toString();
        } else {
          return '(' + xx.toString() + ')';
        }
      }
    }
    return '0';
  }

  parse(str): number {
    return Function(`'use strict'; return (${str})`)();
  }

  applyMinMax() {
    if (this.numericMax !== null && this.value > this.numericMax) {
      this.value = this.numericMax;
    }

    if (this.numericMin !== null && this.value < this.numericMin) {
      this.value = this.numericMin;
    }
  }

  applyOdds(settings: Setting[]): void {
    if (this.mapping && this.mapping.name === Mapping.RIGHT_LEFT) {
      if (this.value === 'RIGHT') {
        const x = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_LEFT
        );
        x ? x.showOverride() : null;
        const y = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_RIGHT
        );
        y ? y.hideOverride() : null;
      }
      if (this.value === 'LEFT') {
        const x = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_RIGHT
        );
        x ? x.showOverride() : null;
        const y = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_LEFT
        );
        y ? y.hideOverride() : null;
      }
      if (this.value === 'VERTICAL') {
        const x = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_RIGHT
        );
        x ? x.showOverride() : null;
        const y = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_LEFT
        );
        y ? y.showOverride() : null;
      }
    }
    if (this.mapping && this.mapping.name === Mapping.BOTTOM_FRONT) {
      const x = settings.find(
        (s: Setting) =>
          s.mapping && s.mapping.name === Mapping.CABIN_COVER_RIGHT
      );
      x ? x.showOverride() : null;
      const y = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.CABIN_COVER_LEFT
      );
      y ? y.showOverride() : null;
    }

    if (this.mapping && this.mapping.name === Mapping.HEATSHIELD_RIGHTSIDE) {
      if (this.value === '12') {
        const x = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_RIGHT
        );
        x && x.showOverrideButton ? (x.override = true) : null;
        if (!x.showOverrideButton) {
          x.override = false;
        }
      }
    }

    if (this.mapping && this.mapping.name === Mapping.HEATSHIELD_LEFTSIDE) {
      if (this.value === '12') {
        const x = settings.find(
          (s: Setting) =>
            s.mapping && s.mapping.name === Mapping.CABIN_COVER_LEFT
        );
        x && x.showOverrideButton ? (x.override = true) : null;
        if (!x.showOverrideButton) {
          x.override = false;
        }
      }
    }

    if (this.mapping && this.mapping.name === Mapping.DOUBLE_DOOR) {
      if (this.value === '1') {
        const x = settings.find(
          (s: Setting) => s.mapping && s.mapping.name === Mapping.RIGHT_LEFT
        );
        if (!x) {
          return;
        }
        x.settingOptionSet.forEach((o: Option, i) => {
          if (o.value === 'BOTH') {
            x.settingOptionSet.splice(i, 1);
          }
        });
      }
    }
    if (this.mapping && this.mapping.name === Mapping.DEPTH) {
      const x1 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.DRAWER_1_DEPTH
      );
      x1 ? SettingUtil.disableHigherSettingValues(this.value, x1) : null;
      const x2 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.DRAWER_2_DEPTH
      );
      x2 ? SettingUtil.disableHigherSettingValues(this.value, x2) : null;
      const x3 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.DRAWER_3_DEPTH
      );
      x3 ? SettingUtil.disableHigherSettingValues(this.value, x3) : null;
      const x4 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.DRAWER_4_DEPTH
      );
      x4 ? SettingUtil.disableHigherSettingValues(this.value, x4) : null;
      const x5 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.DRAWER_5_DEPTH
      );
      x5 ? SettingUtil.disableHigherSettingValues(this.value, x5) : null;
    }

    if (
      this.mapping &&
      this.mapping.name === Mapping.TIPON1 &&
      this.value === '1'
    ) {
      const x1 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.HANDLES_YES_NO
      );
      x1 ? (x1.value = 'NO') : null;
      const x2 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.HANDLE_LAT_HORZ
      );
      x2 ? (x2.value = 'S') : null;
      const x3 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.HANDLES_TYPE
      );
      x3 ? (x3.value = 'KNOP') : null;
    }

    if (
      this.mapping &&
      this.mapping.name === Mapping.EXTRA_HINGE &&
      this.value === 'NO'
    ) {
      const x1 = settings.find(
        (s: Setting) => s.mapping && s.mapping.name === Mapping.HINGE_CC_EXTRA
      );
      x1 ? (x1.value = null) : null;
    }
  }

  getValue(): number {
    if (
      this.numericCalculationMax !== null &&
      Number(this.value) > this.numericCalculationMax &&
      !this.override
    ) {
      return Number(this.numericCalculationMax);
    } else if (this.value !== null) {
      return Number(this.value);
    } else {
      return 0;
    }
  }

  checkIsChanged() {
    if (this.initialValue === this.value) {
      this.isChanged = false;
    } else {
      this.isChanged = true;
    }
  }

  freeze() {
    this.isFrozen = true;
  }

  unFreeze() {
    this.isFrozen = false;
  }

  showOverride() {
    this.showOverrideButton = true;
  }

  hideOverride() {
    this.showOverrideButton = false;
    this.override = false;
  }
}
