import { Vector } from 'html2canvas/dist/types/render/vector';
import { Layer } from 'konva/types/Layer';
import { Mapping } from '../consts/mapping';
import { Product } from '../model/product';

export class SubProductsUtil {
  static addSubProduct(product: Product, subProduct: Product) {
    product.add(subProduct);
  }

  static drawSubproduct(product: Product, layer: Layer) {}

  static calculateSubProducts(product: Product) {
    // possibly we will have types of subproducts.
    product.subProducts.forEach((s: Product) => {
      s.attrs.x = 0;
      s.attrs.y = 0;
      // s.productSettings.WIDTH = product.productSettings.WIDTH;
      // s.productSettings.HEIGHT = product.productSettings.HEIGHT;
      s.WIDTH = product.WIDTH;
      s.HEIGHT = product.HEIGHT;
    });
  }

  static updateSubProducts(product: Product) {
    if (product.subProducts && product.subProducts.length > 0) {
      product.subProducts.forEach((sub: Product) => {
        sub.changeValue(
          Mapping.CONNECTED_TO_POSITIONNUMBER,
          product.productSettings.POSITIONNUMBER,
          false
        );
      });
    }
  }

  static getWidest(product: Product): number {
    let w = product.WIDTH;
    if (product.subProducts && product.subProducts.length > 0) {
      product.subProducts.forEach((sub: Product) => {
        w = sub.WIDTH > w ? sub.WIDTH : w;
      });
    }
    return w;
  }

  static assignSubProducts(layers: Layer[]) {
    layers.forEach((layer: Layer) => {
      layer.find('.product').each((product: Product) => {
        if (
          product.productSettings &&
          product.productSettings.CONNECTED_TO_POSITIONNUMBER
        ) {
          layer.find('.product').each((p: Product) => {
            if (
              p.productSettings &&
              p.productSettings.POSITIONNUMBER ===
                product.productSettings.CONNECTED_TO_POSITIONNUMBER
            ) {
              p.subProducts.push(product);
              product.destroy();
            }
          });
        }
      });
    });
  }
}
