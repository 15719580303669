import { Vector } from 'html2canvas/dist/types/render/vector';
import Konva from 'konva';

export class Entity extends Konva.Group {
  // represents object on the canvas and their common methods (fe. collision detection, glueing)

  isEntity = true;

  constructor(context: any) {
    super(context);
  }
}
