<div class="custom-fixed-right-2 d-flex flex-wrap justify-content-start">
  <div class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button
        type="button"
        class="btn custom-button"
        (click)="toggleGeneralComment()"
      >
        <label
          *ngIf="toggledGeneralComment"
          class="custom-text"
          [innerHTML]="comment"
        >
        </label>
      </button>
    </div>
  </div>
</div>

<div class="custom-fixed-right d-flex flex-wrap justify-content-start">
  <!-- <div class="custom-navgroup">
        <div class="btn-group btn-group-sm p-2" role="group">
            <button type="button" class="btn btn-secondary custom-button" (click)="changeLanguage()">
                <i-bs>{{language}}</i-bs>
            </button>
        </div>
    </div> -->
  <div class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#infoBoxModal"
      >
        <i-bs name="info"></i-bs>
      </button>
    </div>
  </div>
  <div class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button type="button" class="btn btn-secondary" (click)="toggleSidebar()">
        <i-bs name="list"></i-bs>
      </button>
    </div>
  </div>

  <!-- <div *ngIf="toggledSidebar" class="custom-navgroup">
        <div class="btn-group btn-group-sm p-2" role="group">
            <button type="button" class="btn"
                [ngClass]="sidebarSettings.moveable === true ? 'btn-primary' : 'btn-secondary'"
                (click)="toggleMoveable()">
                <i-bs name="arrows-fullscreen"></i-bs>
            </button>
        </div>
    </div> -->

  <div *ngIf="toggledSidebar" class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button
        type="button"
        class="btn"
        [ngClass]="
          sidebarSettings.automaticZoom === true
            ? 'btn-primary'
            : 'btn-secondary'
        "
        (click)="toggleAutoZoom()"
      >
        <i-bs name="aspect-ratio"></i-bs>
      </button>
    </div>
  </div>

  <div *ngIf="toggledSidebar" class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button
        type="button"
        class="btn"
        [ngClass]="
          sidebarSettings.automaticZoomOut === true
            ? 'btn-primary'
            : 'btn-secondary'
        "
        (click)="toggleAutoZoomOut()"
      >
        <i-bs name="arrows-fullscreen"></i-bs>
      </button>
    </div>
  </div>

  <div *ngIf="toggledSidebar" class="custom-navgroup">
    <div class="btn-group btn-group-sm p-2" role="group">
      <button
        type="button"
        class="btn"
        [ngClass]="
          sidebarSettings.manualZoom === true ? 'btn-primary' : 'btn-secondary'
        "
        (click)="toggleManualZoom()"
      >
        <i-bs name="zoom-in"></i-bs>
      </button>
    </div>
  </div>
  <div
    *ngIf="toggledSidebar && sidebarSettings.manualZoom === true"
    class="custom-navgroup"
  >
    <div class="btn-group btn-group-sm custom-group">
      <input
        class="vertival-range"
        type="range"
        min="0.1"
        max="0.5"
        step="0.001"
        [(ngModel)]="sidebarSettings.zoom"
        (ngModelChange)="onZoomChange()"
      />
    </div>
  </div>
</div>
