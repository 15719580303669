import Konva from 'konva';
import { Rect } from 'konva/types/shapes/Rect';

export class PositionNumber extends Konva.Group {
  number: number;
  numberLength: number;
  upsideDown = false;
  moveRight = false;

  constructor(context, number, upsideDown?: boolean, moveRight?: boolean) {
    super(context);
    this.attrs.x = 0;
    this.attrs.y = 0;
    this.attrs.name = 'positionMarker';
    this.number = number;
    this.upsideDown = upsideDown;
    this.moveRight = moveRight;
    this.numberLength = String(this.number).length;
    this.drawLabel();
  }

  drawLabel() {
    let posX = this.attrs.width / 2;
    if (this.moveRight) {
      posX = (this.attrs.width * 7) / 8;
    }
    if (this.upsideDown) {
      // this.add(new Konva.Rect({
      //     x: posX - 25,
      //     y: -40,
      //     width: 50,
      //     height: 40,
      //     fill: 'black',
      //     name: 'positionMarkerLabel',
      //     cornerRadius: [20, 20, 0, 0]
      // }))

      this.add(
        new Konva.Text({
          x: posX - this.numberLength * 10,
          y: -30,
          text: String(this.number),
          fontSize: 45,
          fontFamily: 'Arial',
          align: 'center',
          fill: 'black',
        })
      );
    } else {
      // this.add(new Konva.Rect({
      //     x: posX - 25,
      //     y: 0,
      //     width: 50,
      //     height: 60,
      //     fill: 'black',
      //     name: 'positionMarkerLabel',
      //     cornerRadius: [0, 0, 20, 20]
      // }))

      this.add(
        new Konva.Text({
          x: posX - this.numberLength * 10,
          y: 20,
          text: String(this.number),
          fontSize: 45,
          fontFamily: 'Arial',
          align: 'center',
          fill: 'black',
        })
      );
    }
  }
}
