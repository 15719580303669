<div
  class="modal fade"
  id="generalModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "GENERAL_SETTINGS" | translate }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        *ngIf="generalSettings"
        class="modal-body d-flex flex-column list-group"
      >
        <div *ngFor="let setting of generalSettings.settings; index as i">
          <div *ngIf="setting.type === 'EDITABLE'">
            <div
              *ngIf="setting.settingType === 'TEXTFIELD'"
              class="form-group row"
            >
              <label class="col-sm-5 col-form-label">
                {{ setting.name }}:
              </label>

              <input
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-6 form-control"
                [(ngModel)]="setting.value"
                type="number"
              />
              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>

            <div
              *ngIf="setting.settingType === 'DROPDOWN'"
              class="form-group row"
            >
              <label class="col-sm-5 col-form-label">
                {{ setting.name }}:
              </label>

              <select
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-6 form-control"
                [(ngModel)]="setting.value"
                type="number"
                [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
              >
                <option
                  *ngFor="let option of setting.settingOptionSet"
                  value="{{ option.value }}"
                >
                  {{ option.name }}
                </option>
              </select>

              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>

            <div
              *ngIf="
                setting.settingType === 'RADIO' ||
                setting.settingType === 'RADIO_VERTICAL'
              "
              class="form-group row"
            >
              <label class="col-sm-5 col-form-label">
                {{ setting.name }}:
              </label>

              <select
                id="name"
                name="name"
                #name="ngModel"
                class="col-sm-6 form-control"
                [(ngModel)]="setting.value"
                type="number"
                [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
              >
                <option
                  *ngFor="let option of setting.settingOptionSet"
                  value="{{ option.value }}"
                >
                  {{ option.name }}
                </option>
              </select>

              <div *ngIf="!name.valid" class="text-danger custom-left-padding">
                {{ "SET_VALUE" | translate }}
              </div>
              <div
                *ngIf="!setting.mapping"
                class="text-danger custom-left-padding"
              >
                {{ "SETTING_ISNT_MAPPED" | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="setting.type === 'COMMENT'">
            <div *ngIf="setting.settingType === 'TEXTAREA'">
              <div class="form-group row">
                <label class="col-sm-5 col-form-label">
                  {{ setting.name }}:
                </label>

                <textarea
                  id="name"
                  name="name"
                  #name="ngModel"
                  class="col-sm-6 form-control"
                  [(ngModel)]="setting.value"
                  type="text"
                  rows="8"
                  [ngClass]="!name.valid ? 'border-danger' : 'border-secondary'"
                ></textarea>

                <div
                  *ngIf="!name.valid"
                  class="text-danger custom-left-padding"
                >
                  {{ "SET_VALUE" | translate }}
                </div>
                <div
                  *ngIf="!setting.mapping"
                  class="text-danger custom-left-padding"
                >
                  {{ "SETTING_ISNT_MAPPED" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          (click)="updateSettings()"
        >
          {{ "SAVE_SETTINGS" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
