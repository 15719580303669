<div
  *ngIf="!productMenuActive"
  class="navbar fixed-bottom d-flex justify-content-between custom-navbar"
>
  <div class="custom-navgroup">
    <div class="btn-group btn-group-lg p-2" role="group">
      <button
        *ngIf="measurementMenuButtonToggled"
        type="button"
        class="btn btn-secondary"
        (click)="measurementMenuToggle()"
      >
        <i-bs name="rulers"></i-bs>
      </button>
      <app-measurement-menu
        *ngIf="!measurementMenuButtonToggled"
        (click)="measurementMenuToggle()"
        [measurementConfig]="measurementConfig"
      >
      </app-measurement-menu>
    </div>
  </div>
  <div class="custom-navgroup custom-center">
    <div
      *ngIf="editDrawing"
      class="btn-group btn-group-lg p-2"
      role="group"
      aria-label=""
    >
      <button type="button" class="btn btn-secondary" (click)="save()">
        {{ "SAVE" | translate }}
      </button>
    </div>
    <div class="btn-group btn-group-lg p-2" role="group">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchWall(false)"
      >
        <i-bs name="arrow-bar-left"></i-bs>
      </button>
      <button
        class="btn btn-secondary text-wrap custom-button"
        data-toggle="modal"
        data-target="#wallListModal"
      >
        <div class="dr-text-wrap">{{ activeWallName }}</div>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="switchWall(true)"
      >
        <i-bs name="arrow-bar-right"></i-bs>
      </button>
    </div>
    <div
      *ngIf="editDrawing"
      class="btn-group btn-group-lg p-2"
      role="group"
      aria-label=""
    >
      <button
        type="button"
        class="btn btn-secondary custom-hundred"
        data-toggle="modal"
        data-target="#binModal"
      >
        <i-bs name="archive"></i-bs>
        <span class="badge badge-pill badge-danger custom-badge">{{
          bin.length
        }}</span>
      </button>
    </div>
    <div
      *ngIf="editDrawing"
      class="btn-group btn-group-lg p-2 custom-navgroup"
      role="group"
      aria-label=""
    >
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#wallModal"
        (click)="cleanupWall()"
      >
        <i-bs name="border-outer"></i-bs>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#productModal"
      >
        <i-bs name="box-seam"></i-bs>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#generalModal"
      >
        <i-bs name="toggles"></i-bs>
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#configurationModal"
      >
        <i-bs name="gear"></i-bs>
      </button>
    </div>
  </div>
  <div class="custom-navgroup d-flex flex-row-reverse">
    <div class="btn-group btn-group-lg p-2" role="group">
      <!-- <button *ngIf="fileMenuButtonToggled" type="button" class="btn btn-secondary" (click)="fileMenuToggle()">
                <i-bs name="file-earmark"></i-bs>
            </button>
            <app-file-menu *ngIf="!fileMenuButtonToggled" (click)="fileMenuToggle()"></app-file-menu> -->
      <button
        *ngIf="fileMenuButtonToggled"
        type="button"
        class="btn btn-secondary"
        data-toggle="modal"
        data-target="#backToHKModal"
      >
        <span class="icon-hk-back"></span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="productMenuActive">
  <app-selected-product-menu
    [selectedProducts]="selectedProducts"
  ></app-selected-product-menu>
</div>
