import Konva from 'konva';

export class ProductComment extends Konva.Group {
  text: string;
  isOpened: boolean;
  isLeft: boolean;

  constructor(context, text: string, isOpened: boolean, isLeft: boolean) {
    super(context);
    this.attrs.x = 0;
    this.attrs.y = 0;
    this.attrs.name = 'comment';
    this.isOpened = isOpened;
    this.text = text;
    this.isLeft = isLeft;
    this.update();
  }

  drawComment() {
    if (this.isLeft) {
      const newText = new Konva.Text({
        x: this.attrs.width + 50,
        y: 15,
        width: 400,
        text: this.text,
        fontSize: 40,
        fontFamily: 'Arial',
        align: 'center',
      });

      this.add(
        new Konva.Rect({
          x: this.attrs.width + 50,
          y: 0,
          width: 400,
          height: newText.height() + 25,
          fill: 'rgb(242, 192, 40)',
          opacity: 0.8,
          cornerRadius: [0, 5, 5, 5],
        })
      );

      this.add(
        new Konva.Rect({
          x: this.attrs.width,
          y: 0,
          width: 50,
          height: 25,
          fill: 'rgb(242, 192, 40)',
          opacity: 0.8,
        })
      );
      this.add(newText);
    } else {
      const newText = new Konva.Text({
        x: -450,
        y: 15,
        width: 400,
        text: this.text,
        fontSize: 40,
        fontFamily: 'Arial',
        align: 'center',
      });

      this.add(
        new Konva.Rect({
          x: -450,
          y: 0,
          width: 400,
          height: newText.height() + 25,
          fill: 'rgb(242, 192, 40)',
          opacity: 0.8,
          cornerRadius: [0, 5, 5, 5],
        })
      );

      this.add(
        new Konva.Rect({
          x: -50,
          y: 0,
          width: 50,
          height: 25,
          fill: 'rgb(242, 192, 40)',
          opacity: 0.8,
        })
      );
      this.add(newText);
    }
  }

  update() {
    if (this.isOpened) {
      this.drawComment();
    }
  }
}
