import { Component, OnDestroy } from '@angular/core';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-form-list',
  templateUrl: './product-form-list.component.html',
  styleUrls: ['./product-form-list.component.scss'],
})
export class ProductFormListComponent implements OnDestroy {
  products = [];
  category: any;
  fictionalProducts = [];

  productCategoryCreatorEmitterSub;
  productsListEmitterSub;
  fictionalProductListEmitterSub;

  constructor(
    private navbarService: NavbarService,
    private productService: ProductService
  ) {
    this.productCategoryCreatorEmitterSub =
      this.navbarService.productCategoryCreatorEmitter.subscribe((category) => {
        this.category = category;
      });
    this.productsListEmitterSub =
      this.productService.productsListEmitter.subscribe((products) => {
        this.products = products;
      });
    this.fictionalProductListEmitterSub =
      this.productService.fictionalProductListEmitter.subscribe(
        (fictionalProducts) => {
          this.fictionalProducts = fictionalProducts;
        }
      );
  }

  ngOnDestroy(): void {
    this.productCategoryCreatorEmitterSub.unsubscribe();
    this.productsListEmitterSub.unsubscribe();
    this.fictionalProductListEmitterSub.unsubscribe();
  }

  chooseProduct(product): void {
    this.navbarService.productCreatorEmitter.emit(product);
  }

  isFictionala(): boolean {
    return this.category && this.category.name === 'Vitvaror';
  }

  isFictionalb(): boolean {
    return this.category && this.category.name === 'Fasta hinder';
  }

  chooseFictionalProduct(fictional): void {
    this.navbarService.newFictionalProductEmitter.emit(fictional);
  }
}
