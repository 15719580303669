import { GeneralSettings } from '../model/generalSettings';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Mapping } from '../model/subsettings/mapping';
import { Option } from '../model/subsettings/option';
import { SettingValueItem } from '../model/subsettings/settingValueItem';
import { SettingValue } from './drawingConfigExtractor';

export class FictionalSettingsUtil {
  static updateGeneralSettings(
    generalSettings: GeneralSettings,
    settingValues: SettingValue[]
  ): void {
    if (!settingValues?.length) {
      return;
    }
    generalSettings.settings.forEach((s: Setting) => {
      if (s.fictional) {
        const sv = settingValues?.find((sv: SettingValue) => sv.id === s.id);
        sv ? (s.value = sv.value) : null;
      }
    });
  }

  static getFictionalDekorlistSetting(): Setting {
    const valueItems = [
      new SettingValueItem('Ja', 'YES'),
      new SettingValueItem('Nej', 'NO'),
    ];
    const options = [new Option(1, 'Ja', 'YES'), new Option(2, 'Nej', 'NO')];
    return new Setting(
      9999,
      'Dekorlist',
      new Mapping('HAS_DEKORLIST', null),
      'EDITABLE',
      'YES',
      valueItems,
      'TEXT',
      'RADIO',
      options,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      9999,
      true,
      false,
      false,
      '',
      true
    );
  }

  static getFictionalSockelSetting(): Setting {
    const valueItems = [
      new SettingValueItem('Ja', 'YES'),
      new SettingValueItem('Nej', 'NO'),
    ];
    const options = [new Option(1, 'Ja', 'YES'), new Option(2, 'Nej', 'NO')];
    return new Setting(
      99999,
      'Sockel',
      new Mapping('HAS_SOCKEL', null),
      'EDITABLE',
      'YES',
      valueItems,
      'TEXT',
      'RADIO',
      options,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      9999,
      true,
      false,
      false,
      '',
      true
    );
  }

  static getFictionalNewCabinSetting(): Setting {
    const valueItems = null;
    const options = [new Option(1, 'Ja', 'YES'), new Option(2, 'Nej', 'NO')];
    return new Setting(
      999991,
      'Valbart',
      new Mapping('NEW_CABIN', null),
      'EDITABLE',
      'NO',
      valueItems,
      'TEXT',
      'RADIO',
      options,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      9999,
      true,
      false,
      false,
      '',
      true
    );
  }

  static getFictionalTIPON(): Setting {
    const valueItems = [
      new SettingValueItem('Ja', 'YES'),
      new SettingValueItem('Nej', 'NO'),
    ];
    const options = [new Option(1, 'Ja', '1'), new Option(2, 'Nej', '0')];
    return new Setting(
      200,
      'Tip-on',
      new Mapping('TIPON1', null),
      'EDITABLE',
      'NO',
      valueItems,
      'TEXT',
      'RADIO',
      options,
      null,
      null,
      null,
      null,
      null,
      false,
      null,
      200,
      true,
      false,
      false,
      '',
      true
    );
  }

  static switchFictionalSettings(
    oldSetting: Setting,
    newSetting: Setting,
    product: Product
  ): void {
    if (!oldSetting || !newSetting || !product) {
      return;
    }
    product.settings.splice(
      product.settings.indexOf(oldSetting),
      1,
      newSetting
    );
    product.changeValue(newSetting.mapping.name, oldSetting.value);
    // product.changeValue(oldSetting.mapping.name, null);
    product.productSettings[oldSetting.mapping.name] = null;
  }
}
