import { Component, OnDestroy } from '@angular/core';
import { Mapping } from 'src/app/consts/mapping';
import { GeneralSettings } from 'src/app/model/generalSettings';
import { Setting } from 'src/app/model/setting';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-general-comment',
  templateUrl: './general-comment.component.html',
  styleUrls: ['./general-comment.component.scss'],
})
export class GeneralCommentComponent implements OnDestroy {
  comment: Setting;
  generalSettingsEmitterSub;

  constructor(private navbarService: NavbarService) {
    this.generalSettingsEmitterSub =
      this.navbarService.generalSettingsEmitter.subscribe(
        (gs: GeneralSettings) => {
          this.comment = this.findComment(gs);
        }
      );
  }

  ngOnDestroy(): void {
    this.generalSettingsEmitterSub.unsubscribe();
  }

  findComment(gs: GeneralSettings): Setting {
    return gs.settings.find((s: Setting) => {
      return s.mapping && s.mapping.name === Mapping.GLOBAL_COMMENT;
    });
  }
}
