import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor(
    private activeRoute: ActivatedRoute,
    private storage: LocalStorageService,
    private router: Router
  ) {}

  redirect(): void {
    this.router.navigate(['main']);
  }
}
